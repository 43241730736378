import React, { useState } from "react";
import { Form, Button, Col, Row, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import { api } from "../../../Utils/API";
import { FORGOT_PASSWORD } from "../../../Utils/GlobalConstants";
import BrandDetails from "../../../GlobalComponents/BrandDetails";
import {
  Check2Circle,
  XCircle,
} from "react-bootstrap-icons";
import AnonymousPageFormHeader from "../../../GlobalComponents/AnonymousPageFormHeader";
import { TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from "../../../Utils/ColorsConfiguration";

const ForgotPassword = () => {
  const [mobileActive, setMobileActive] = useState("active");                           // Mobile Screen Check
  const [userName, setUserName] = useState("");                                 // Email Input Field Data
  const [isActivationLinkSent, setIsActivationLinkSent] = useState(false);      // Set Activation Link Set Check
  const [emptyUserNameCheck, setEmptyUserNameCheck] = useState(false);          // Empty User Name Check UseState
  const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;           // Regex Expression for Email
  const [landingPageData, setLandingPageData] = useState();                     // Landing Page Data from CMS
  const [bannerData, setBannerData] = useState();                               // Banner Data from CMS
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();               // BG Image from CMS

  // Use State for Toasts
  const [myProfileSuccess, SetMyProfileSuccess] = useState(false);
  const [myProfileError, setMyProfileError] = useState(false);
  const [myProfileToastMessage, setMyProfileToastMessage] = useState("");

  // Send Activation Link Function
  async function sendActivationLink() {
    if (!(emailRegEx.test(userName))) {
      setEmptyUserNameCheck(true);
      setTimeout(() => {
        setEmptyUserNameCheck(false);
      }, 4000);
    } else {
      let data = {
        "email": userName
      }
      await api
        .post(FORGOT_PASSWORD, data)
        .then((response) => {
          // console.log(response.data.message);
          setMyProfileToastMessage(response.data.message);
          SetMyProfileSuccess(true);
          setUserName("");
          setTimeout(() => {
            SetMyProfileSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          setMyProfileToastMessage(`${error.response.data.errors[0]} Please try again !`);
          setMyProfileError(true);
          setTimeout(() => {
            setMyProfileError(false);
          }, 2000);
        });
    }
  }



  // Adding CSS Style to Landing Page Main Component
  const loginComponent = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: "cover",
  };


  // To activate CSS Class when on mobile Screen
  const showMobileSceen = () => {
    setMobileActive("active");
  };



  return (
    <>
      <div className={`landing-page-alert ${myProfileSuccess ? "active" : ""}`}>
        <Alert variant="success">
          <div className='d-flex align-items-center'>
            <Check2Circle size={24} color={TOAST_SUCCESS_ICON_COLOR} />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>

      <div className={`landing-page-alert ${myProfileError ? "active" : ""}`}>
        <Alert variant="danger">
          <div className='d-flex align-items-center'>
            <XCircle size={24} color={TOAST_ERROR_ICON_COLOR} />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>

      <div className="d-flex flex-column login-page" style={loginComponent}>
        <div className="anonymous-login-main-div active">
          <Container>
            <Row>
              <Col md={6}>
                <BrandDetails
                  handleClick={showMobileSceen}
                  bannerData={bannerData}
                  customClass="d-none d-md-block"
                />
              </Col>
              <Col md={6}>
                <div className={`anonymous-login-main-div ${mobileActive}`}>
                  <Col xl={8} lg={10} md={12} className="login-block">
                      <>
                        <Form className="activation-form" onSubmit={e => {
                          e.preventDefault();
                          if (userName.length > 0)
                            sendActivationLink();
                        }}>
                          <div>
                            <AnonymousPageFormHeader page="forgotPassword" pageData={landingPageData} setPageData={setLandingPageData} setBannerData={setBannerData} setBackgroundImageUrl={setBackgroundImageUrl} />

                            <Form.Group controlId="userName">
                              <Form.Label>User Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter User Name"
                                value={userName}
                                onChange={(e) => (e.target.value.length <= 250 ? setUserName(e.target.value) : "")}
                              />
                              {emptyUserNameCheck && (
                                <Form.Text className="text-danger activation-error-message-username text-small">
                                  Enter a valid Email Address
                                </Form.Text>
                              )}
                              {!isActivationLinkSent && (
                                <Form.Text className="text-muted activation-error-message-username text-small">
                                  &nbsp;
                                </Form.Text>
                              )}
                              {/* {isSuccess && (
                      <Form.Text className="text-success activation-error-message-username text-small">
                        Activation link sent to your email.
                      </Form.Text>
                    )}
                    {isFailure && (
                      <Form.Text className="text-danger activation-error-message-username text-small">
                        {errorMessage}
                      </Form.Text> */}
                              {/* )} */}
                            </Form.Group>
                          </div>
                          {userName && <div className="hidden-btn">
                            <Button
                              className="btn-none"
                              variant="none"
                              type="submit"
                              onClick={sendActivationLink}
                            >
                              &nbsp;
                            </Button>
                          </div>}
                        </Form>
                        <div className="mobile-login-btns">
                          <div className="w-100">
                            {userName && <Button
                              className="col-12"
                              variant="primary"
                              type="submit"
                              onClick={sendActivationLink}
                            >
                              Send Reset Link
                            </Button>}
                            {!userName && <Button
                              className="col-12"
                              variant="secondary"
                              disabled
                            >
                              Send Reset Link
                            </Button>}
                            <p className="text-center login-back-link">
                              <Link to="/">
                                <label>Back to Sign In?</label>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;