import { Container, Row, Col, Button, Table } from "react-bootstrap";
import styles from './styles.scss';
import { useEffect, useState } from "react";
import TableBlock from "../../../../../GlobalComponents/TableBlock";
import { api } from "../../../../../Utils/API";
import { CLOUD_INVOICE_LISTING_TABLE, CLOUD_INVOICE_DOWNLOAD_PDF_SUMMARY } from "../../../../../Utils/GlobalConstants";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../../GlobalComponents/Toast";
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"
import { BACKGROUND_COLOR_SECONDARY, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from "../../../../../Utils/ColorsConfiguration";
import { GenericDownloadEmptyToast } from "../../../../../Utils/StaticMessages/ToastMessages";
import { InvoicesAcronisNote, InvoicesCloudCareNote } from "../../../../../Utils/StaticMessages/NotesDisclaimers";

const InvoiceDetails = (props) => {
    const location = useLocation();                                                                                     // get Location                     
    const navigate = useNavigate();                                                                                     // to use navigate function from react router dom
    const date = new Date();                                                                                            // for js date time function
    const [loading, setLoading] = useState(false);                                                                      // loading state when api is called
    const [invoiceNumber, setInvoiceNumber] = useState(location.state !== null ? location.state.Id : "");               // Get Invoice Number From Table
    const [summaryNumber, setSummeryNumber] = useState(location.state !== null ? location.state.summaryNumber : "");    // Get Summery Number From Table
    const [resellerId, setResellerId] = useState(location.state !== null ? location.state.resellerId : "");             // Get Summery Number From Listing Table

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                                                            // toast message displaying success message
    const [toastError, setToastError] = useState(false);                                                                // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                                                               // toast message usestate

    // Scroll to Top on Initial loading
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [invoiceDetailsDummy, setInvoiceDetailsDummy] = useState(null);
    // CLOUD Invoice Details TABLE DATA
    const [invoiceDetails, setInvoiceDetails] = useState(
        {
            "adjustmentChargeLineItems": "",
            "nceAnnualChargeLineItems": "",
            "nceMonthlyChargeLineItems": "",
            "invoiceNumber": "",
            "invoiceDate": "",
            "dueDate": "",
            "billingStartDate": "",
            "billingEndDate": "",
            "currency": "",
            "grossTotal": 0.00,
            "tax": "",
            "status": "",
            "netTotal": 0.00,
            "paymentTerm": "",
            "total": 0.00,
            "discountAmount": 0.00,
            "billTo": {
                "companyName": "",
                "firstName": "",
                "lastName": "",
                "email": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "terriCode": "",
                "abnNumber": "",
                "salesPerson": "",
                "externalCompanyCode": ""
            },
            "shipTo": {
                "customerCompanyName": "",
                "firstName": "",
                "lastName": "",
                "email": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipcode": ""
            },
            "billBy": {
                "companyName": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "phone": "",
                "fax": "",
                "abn": "",
                "acn": ""
            },
            "invoiceLines": [{
                "product": "",
                "description": "",
                "type": "",
                "usageUnits": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "planName": "",
                "serviceName": "",
                "planCode": "",
                "cloudInvoiceLineId": 0,
                "customerName": "",
                "productType": "",
                "publisherName": "",
                "termAndBillingCycle": "",
                "isLegacy": true
            }],
            "gcpInvoiceLineItems": [],
            "invoiceLineItems": [],
            "adjustmentChargeLines": [{
                "serviceName": "",
                "planName": "",
                "planCode": "",
                "description": "",
                "unitPrice": "",
                "quantity": "",
                "amount": 0.00,
                "billingFrequency": "",
                "provisionTag": ""
            }],
            "advancedChargeLines": [{
                "serviceName": "",
                "planName": "",
                "planCode": "",
                "description": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "billingFrequency": "",
                "provisionTag": ""
            }],
            "annualChargeLineItems": [],
            "monthlyChargeLineItems": [],
            "monthlyChargeLines": [{
                "planName": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "provisionTag": "",
                "proratedPrice": "",
                "activeDays": "",
                "startDate": "",
                "endDate": "",
                "commitmentTerm": ""
            }],
            "annualChargeLines": [{
                "planName": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "provisionTag": "",
                "proratedPrice": "",
                "activeDays": "",
                "startDate": "",
                "endDate": "",
                "commitmentTerm": ""
            }],
            "nceMonthlyChargeLines": [{
                "planName": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "provisionTag": "",
                "proratedPrice": "",
                "activeDays": "",
                "startDate": "",
                "endDate": "",
                "commitmentTerm": ""
            }],
            "advancedChargeLineItems": [],
            "nceAnnualChargeLines": [{
                "planName": "",
                "unitPrice": 0.00,
                "quantity": "",
                "amount": 0.00,
                "provisionTag": "",
                "proratedPrice": "",
                "activeDays": "",
                "startDate": "",
                "endDate": "",
                "commitmentTerm": ""
            }],
            "discountLineResources": [{
                "brand": "",
                "description": "",
                "amount": 0.00
            }],
            "cloudService": {
                "name": "",
                "integrationCode": ""
            },
            "gcpInvoiceLineResource": {}
        }
    );

    //Invoice Details Table 
    const [invoiceCommonDetails, setInvoiceCommonDetails] = useState([]);                                               //Invoice Details Table 
    const [invoiceCommonDetailsData, setInvoiceCommonDetailsData] = useState([]);                                       //Invoice Details Table Data
    let invoiceCommonDetailsColumns = ["Invoice Date", "Billing Start Date", "Billing End Date", "Customer Code", "TERR. Code", "ABN Number", "Sales Person", "Currency"];  //Invoice Details Table Columns

    // Acronics Line Items
    const [acronisLineItems, setAcronisLineItems] = useState([]);                                                       // Acronis Line Items Table
    const [acronisLineItemsData, setAcronisLineItemsData] = useState([]);                                               // Acronis Line Items Table Data
    let acronisTableColumns = ["No.", "Plan Name and Description", "Fee Type", "Usage Units", "Qty", "Unit Price (Ex GST)", "Amount (Ex GST)"]; // Acronis Line Items Table Columns

    // GWS - IBM Product Charges / Line Items
    const [gwsIBMLineItems, setGWSIBMLineItems] = useState([]);                                                         // GWS - IBM Product Charges / Line Items Table
    const [gwsIBMLineItemsData, setGWSIBMLineItemsData] = useState([]);                                                 // GWS - IBM Product Charges / Line Items Table Data
    let gwsIBMTableColumns = ["No.", "Brand", "Plan Name and Description", "SKU", "Qty", "Price (Ex GST)", "Amount (Ex GST)"]; // GWS - IBM Product Charges / Line Items Columns

    // GCP Line Items 
    const [gcpInvoiceLineItems, setGCPInvoiceLineItems] = useState([]);                                                 // GCP Line Items Table
    const [gcpInvoiceLineItemsData, setGCPInvoiceLineItemsData] = useState([]);                                         // GCP Line Items Table Data
    let gcpInvoceLineItemsColumns = ["No.", "Service", "Sku Id", "Sku Description", "Qty", "Unit Price (Ex GST)", "Amount (Ex GST)"]; // GCP Line Items Columns


    // Microsoft SAAS
    const [mspsaasInvoiceLineItems, setMspsaasInvoiceLineItems] = useState([]);                                         // Microsoft SAAS Table
    const [mspsaasInvoiceLineItemsData, setMspsaasInvoiceLineItemsData] = useState([]);                                 // Microsoft SAAS Table Data
    let mspsaasNSEAnnualChargeLineItems = ["No.", "Your PO No.", "Plan Name", "Commitment Term", "Start Date", "End Date", "Activity Qty", "Billed Days", "Unit Price (Ex GST)", "Prorate Price (Ex GST)", "Sub Total (Ex GST)"]; // Microsoft SAAS Table Columns

    // Microsoft Azure Invoice Line Items
    const [msazureInvoiceLineItems, setMsazureInvoiceLineItems] = useState([]);                                         // Microsoft Azure Invoice Line Items Table
    const [msazureInvoiceLineItemsData, setMsazureInvoiceLineItemsData] = useState([]);                                 // Microsoft Azure Invoice Line Items Table Data
    let msazureInvoiceLineItemsColumns = ["No.", "Brand", "Plan Name and Description", "SKU", "Qty", "Price (Ex GST)", "Amount (Ex GST)"]; // Microsoft Azure Invoice Line Items Table Columns

    // Microsoft SAAS NCE Monthly Charge Line Items
    const [mspsaasNSEMonthlyChargeLineItems, setMspsaasNSEMonthlyChargeLineItems] = useState([]);                       // Microsoft Azure Invoice Line Items Table
    const [mspsaasNSEMonthlyChargeLineItemsData, setMspsaasNSEMonthlyChargeLineItemsData] = useState([]);               // Microsoft Azure Invoice Line Items Table Data
    let mspsaasNSEMonthlyChargeLineColumns = ["No.", "Your PO No.", "Plan Name", "Commitment Term", "Start Date", "End Date", "Activity Qty", "Billed Days",
        "Unit Price (Ex GST)", "Prorate Price (Ex GST)", "Sub Total (Ex GST)"];                                         // Microsoft Azure Invoice Line Items Table Columns

    // Adjustment Charge Line Items
    const [adjustmentChargeLineItems, setAdjustmentChargeLineItems] = useState([]);                                     // Adjustment Charge Line Items Table
    const [adjustmentChargeLineItemsData, setAdjustmentChargeLineItemsData] = useState([]);                             // Adjustment Charge Line Items Table Data
    let adjustmentChargeLineItemsColumns = ["No.", "Your PO No.", "Brand", "Plan Name and Description", "Billing Frequency", "SKU", "Qty", "Price (Ex GST)", "Amount (Ex GST)"]; // Adjustment Charge Line Items Table Columns


    // Microsoft SAAS Monthly Charge Line Items
    const [mspsaasMonthlyChargeLineItems, setMspsaasMonthlyChargeLineItems] = useState([]);                             // Microsoft SAAS Monthly Charge Line Items Table
    const [mspsaasMonthlyChargeLineItemsData, setMspsaasMonthlyChargeLineItemsData] = useState([]);                     // Microsoft SAAS Monthly Charge Line Items Table Data
    let mspsaasMonthlyChargeLineColumns = ["No.", "Your PO No.", "Plan Name", "Start Date", "End Date", "Activity Qty", "Active Days", "Unit Price (Ex GST)", "Prorate Price (Ex GST)", "Sub Total (Ex GST)"]; // Microsoft SAAS Monthly Charge Line Items Table Columns

    // Microsoft SAAS Annual Charge Line Items
    const [mspsaasAnnualChargeLineItems, setMspsaasAnnualChargeLineItems] = useState([]);                               // Microsoft SAAS Monthly Charge Line Items Table
    const [mspsaasAnnualChargeLineItemsData, setMspsaasAnnualChargeLineItemsData] = useState([]);                       // Microsoft SAAS Monthly Charge Line Items Data
    let mspsaasAnnualChargeLineColumns = ["No.", "Your PO No.", "Plan Name", "Start Date", "End Date", "Activity Qty", "Active Days", "Unit Price (Ex GST)", "Prorate Price (Ex GST)", "Sub Total (Ex GST)"]; // Microsoft SAAS Monthly Charge Line Items Columns

    // Advanced Charge LineItems
    const [advancedChargeLineItems, setAdvancedChargeLineItems] = useState([]);                                         // Advanced Charge LineItems Table
    const [advancedChargeLineItemsData, setAdvancedChargeLineItemsData] = useState([]);                                 // Advanced Charge LineItems Table Data
    let advancedChargeLineItemsColumns = ["No.", "Your PO No.", "Brand", "Plan Name and Description", "Billing Frequency", "SKU", "Qty", "Price (Ex GST)", "Amount (Ex GST)"]; // Advanced Charge LineItems Table Columns

    // Discount Line Items
    const [discountLineItems, setDiscountLineItems] = useState([]);                                                     // Discount Line Items Table
    const [discountLineItemsData, setDiscountLineItemsData] = useState([]);                                             // Discount Line Items Table Data
    let discountLineItemsColumns = ["No.", "Brand", "Description", "Amount (Ex GST)"];                                  // Discount Line Items Table Columns

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Get Invoice Details with Invoice Number from Listing Table
    async function getInvoiceDetails() {
        setLoading(true);

        await api.get(`${CLOUD_INVOICE_LISTING_TABLE + "/" + invoiceNumber}?requestedResellerCode=${resellerId}`, config)  
            .then(resp => {

                if (resp.status == 200) {
                    setInvoiceDetails(resp.data);
                    setInvoiceDetailsDummy(resp.data);
                    if (resp.data.gcpInvoiceLineItems !== null) {
                        setGCPInvoiceLineItemsData(resp.data.gcpInvoiceLineItems);
                    }

                    if (resp.data.cloudService.integrationCode == "microsoftazure") {
                        if (resp.data.invoiceLineItems !== null && resp.data.invoiceLineItems.length > 0) {
                            setMsazureInvoiceLineItems(resp.data.invoiceLineItems);
                        }
                    }


                    // Invoice Details Common Table 

                    let invoiceCommonDetailsTable = [];
                    let invoiceCommonDetailsTableData = [];

                    
                    invoiceCommonDetailsTableData.push
                        (
                            {
                                "Invoice Date": resp.data.invoiceDate,
                                "Billing Start Date": resp.data.billingStartDate,
                                "Billing End Date": resp.data.billingEndDate,
                                "Customer Code": resp.data.billTo.externalCompanyCode,
                                "TERR. Code": resp.data.billTo.terriCode,
                                "ABN Number": resp.data.billTo.abnNumber,
                                "Sales Person": resp.data.billTo.salesPerson,
                                "Currency": resp.data.currency
                            }
                        )


                    setInvoiceCommonDetailsData(invoiceCommonDetailsTableData);
                    invoiceCommonDetailsColumns.map((td) => {

                        if (resp.data.invoiceLineItems.length > 0 ||
                            (resp.data.gcpInvoiceLineItems != null && resp.data.cloudService.integrationCode === "GCP")) {
                            invoiceCommonDetailsTable.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: false
                            });

                        } else {
                            if (td !== "Billing Start Date" && td !== "Billing End Date") {
                                invoiceCommonDetailsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                });
                            }
                        }

                    });
                    setInvoiceCommonDetails(invoiceCommonDetailsTable);

                    // GWS/IBM Invoice Table Details
                    let gwsIBMTable = [];
                    let gwsIBMTableData = [];

                    if (resp.data.cloudService.integrationCode === "googleworkspace" || resp.data.cloudService.integrationCode === "softlayer") {
                        let i = 0;
                        (resp.data.invoiceLineItems).map((td) => {
                            i++
                            gwsIBMTableData.push
                                (
                                    {
                                        "No.": i,
                                        "Brand": td.serviceName,
                                        "Plan Name and Description": <span className="text-medium">{td.planName} <br /> {td.description}</span>,
                                        "Usage Units": td.usageUnits,
                                        "SKU": td.planCode,
                                        "Qty": td.quantity,
                                        "Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                        "Amount (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                                    }
                                )
                        });

                        setGWSIBMLineItemsData(gwsIBMTableData);
                        

                        gwsIBMTableColumns.map((td) => {
                            if (td == "Price (Ex GST)" || td == "Amount (Ex GST)" || td == "Qty") {
                                gwsIBMTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else {
                                gwsIBMTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setGWSIBMLineItems(gwsIBMTable);
                      
                    }

                    let acronisTable = [];
                    let acronisTableData = [];

                    if (resp.data.cloudService.integrationCode === "acronis1" || resp.data.cloudService.integrationCode === "acronis2") {
                        let i = 0;
                        (resp.data.invoiceLineItems).map((td) => {
                            i++
                            acronisTableData.push
                                (
                                    {
                                        "No.": i,
                                        "Plan Name and Description": <span className="text-medium">{td.product} <br /> {td.description}</span>,
                                        "Fee Type": td.type,
                                        "Usage Units": td.usageUnits,
                                        "Qty": td.quantity,
                                        "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                        "Amount (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                                    }
                                )
                        });

                        setAcronisLineItemsData(acronisTableData);
                      

                        acronisTableColumns.map((td) => {
                            if (td == "Unit Price (Ex GST)" || td == "Amount (Ex GST)" || td == "Qty") {
                                acronisTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else if (td == "Plan Name and Description") {
                                acronisTable.push({
                                    id: `${td}`,
                                    name: <div className="plan-name-column">{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: false,
                                    left: true
                                })
                            } else {
                                acronisTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setAcronisLineItems(acronisTable);
                       
                    }

                    // GCP Invoice Line Items
                    let gcpTable = [];
                    let gcpTableData = [];
                    if (resp.data.cloudService.integrationCode === "GCP" && resp.data.gcpInvoiceLineItems !== null && resp.data.gcpInvoiceLineItems) {
                        let i = 0;

                       
                        (Object.values(resp.data.gcpInvoiceLineItems)).map((item, index) => {
                            

                            item.map(td => {
                               
                                i++
                                gcpTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Service": <span className="text-medium">{td.serviceName}</span>,
                                            "Sku Id": td.skuId,
                                            "Sku Description": td.skuName,
                                            "Qty": <span>{td.quantity}</span>,
                                            "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(td.unitPrice))).toFixed(2))}`,
                                            "Amount (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(td.amount))).toFixed(2))}`,
                                        }
                                    )
                            })

                        });

                        setGCPInvoiceLineItemsData(gcpTableData);
                        

                        gcpInvoceLineItemsColumns.map((td) => {
                            if (td == "Unit Price (Ex GST)" || td == "Amount (Ex GST)") {
                                gcpTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else {
                                gcpTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setGCPInvoiceLineItems(gcpTable);
                       
                    }

                    // Microsoft Sass
                    let mspsaasNSEAnnualTable = [];
                    let mspsaasNSEAnnualTableData = [];

                    let mspsaasNSEMonthlyTable = [];
                    let mspsaasNSEMonthlyTableData = [];

                    let monthlyChargeLineItemsTable = [];
                    let monthlyChargeLineItemsTableData = [];

                    let annualChargeLineItemsTable = [];
                    let annualChargeLineItemsTableData = [];

                    if (resp.data.cloudService.integrationCode === "microsoftsaas") {
                        if (resp.data.nceMonthlyChargeLineItems !== null) {
                            let i = 0;
                            (resp.data.nceAnnualChargeLineItems).map((td) => {
                                i++
                                mspsaasNSEAnnualTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Your PO No.": td.provisionTag, // Need to ask
                                            "Plan Name": td.planName,
                                            "Commitment Term": td.commitmentTerm,
                                            "Start Date": td.startDate,
                                            "End Date": td.endDate,
                                            "Activity Qty": td.quantity,
                                            "Billed Days": td.activeDays,
                                            "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                            "Prorate Price (Ex GST)": td.proratedPrice >= 0 ? `$${td.proratedPrice}` : `-$${td.proratedPrice}`,
                                            "Sub Total (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}` // Need to ask
                                        }
                                    )
                            });

                            setMspsaasInvoiceLineItemsData(mspsaasNSEAnnualTableData);
                           

                            mspsaasNSEAnnualChargeLineItems.map((td) => {
                                if (td == "Sub Total (Ex GST)" || td == "Unit Price (Ex GST)" || td == "Prorate Price (Ex GST)") {
                                    mspsaasNSEAnnualTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                }
                                else if (td == "Activity Qty" || td == "Billed Days") {
                                    mspsaasNSEAnnualTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        right: true
                                    })
                                }
                                else {
                                    mspsaasNSEAnnualTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false
                                    })
                                }
                            });
                            setMspsaasInvoiceLineItems(mspsaasNSEAnnualTable);
                         
                        }

                        if (resp.data.nceMonthlyChargeLineItems !== null) {
                            let i = 0;
                            (resp.data.nceMonthlyChargeLineItems).map((td) => {
                                i++
                                mspsaasNSEMonthlyTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Your PO No.": td.provisionTag, // Need to ask
                                            "Plan Name": td.planName,
                                            "Commitment Term": td.commitmentTerm,
                                            "Start Date": td.startDate,
                                            "End Date": td.endDate,
                                            "Activity Qty": td.quantity,
                                            "Billed Days": td.activeDays,
                                            "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                            "Prorate Price (Ex GST)": td.proratedPrice >= 0 ? `$${td.proratedPrice}` : `-$${td.proratedPrice}`,
                                            "Sub Total (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`
                                        }
                                    )
                            });

                            setMspsaasNSEMonthlyChargeLineItemsData(mspsaasNSEMonthlyTableData);
                         

                            mspsaasNSEMonthlyChargeLineColumns.map((td) => {
                                if (td == "Unit Price (Ex GST)" || td == "Sub Total (Ex GST)" || td == "Prorate Price (Ex GST)") {
                                    mspsaasNSEMonthlyTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">${row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                } else {
                                    mspsaasNSEMonthlyTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false
                                    })
                                }
                            });
                            setMspsaasNSEMonthlyChargeLineItems(mspsaasNSEAnnualTable);
                           
                        }

                        // microsoftsaas Monthly Charge Line Items
                        if (resp.data.monthlyChargeLineItems !== null && resp.data.monthlyChargeLineItems.length > 0) {
                          
                            let i = 0;
                            (resp.data.monthlyChargeLineItems).map((td) => {
                                i++
                                monthlyChargeLineItemsTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Your PO No.": td.provisionTag,
                                            "Plan Name": td.planName,
                                            "Start Date": td.startDate,
                                            "End Date": td.endDate,
                                            "Activity Qty": td.quantity,
                                            "Active Days": td.activeDays,
                                            "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                            "Prorate Price (Ex GST)": td.proratedPrice >= 0 ? `$${td.proratedPrice}` : `-$${td.proratedPrice}`,
                                            "Sub Total (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`
                                        }
                                    )
                            });

                            monthlyChargeLineItemsTableData.push
                                (
                                    {
                                        "No.": "",
                                        "Your PO No.": "",
                                        "Plan Name": "",
                                        "Start Date": "",
                                        "End Date": "",
                                        "Activity Qty": "",
                                        "Active Days": "",
                                        "Unit Price (Ex GST)": "",
                                        "Prorate Price (Ex GST)": "Total",
                                        "Sub Total (Ex GST)": "$" + resp.data.monthlyChargeResource.amount
                                    }
                                )

                            setMspsaasMonthlyChargeLineItemsData(monthlyChargeLineItemsTableData);

                            mspsaasMonthlyChargeLineColumns.map((td) => {
                                if (td == "Sub Total (Ex GST)" || td == "Unit Price (Ex GST)" || td == "Prorate Price (Ex GST)") {
                                    monthlyChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                } else if (td == "Activity Qty" || td == "Active Days") {
                                    monthlyChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                }
                                else {
                                    monthlyChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false
                                    })
                                }
                            });
                            setMspsaasMonthlyChargeLineItems(monthlyChargeLineItemsTable);
                        }

                        // microsoftsaas Annual Charge Line Items
                        if (resp.data.annualChargeLineItems !== null && resp.data.annualChargeLineItems.length > 0) {
                            
                            let i = 0;
                            (resp.data.annualChargeLineItems).map((td) => {
                                i++
                                annualChargeLineItemsTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Your PO No.": td.provisionTag,
                                            "Plan Name": td.planName,
                                            "Start Date": td.startDate,
                                            "End Date": td.endDate,
                                            "Activity Qty": td.quantity,
                                            "Active Days": td.activeDays,
                                            "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                            "Prorate Price (Ex GST)": td.proratedPrice >= 0 ? `$${td.proratedPrice}` : `-$${td.proratedPrice}`,
                                            "Sub Total (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`
                                        }
                                    )
                            });

                            annualChargeLineItemsTableData.push
                                (
                                    {
                                        "No.": "",
                                        "Your PO No.": "",
                                        "Plan Name": "",
                                        "Start Date": "",
                                        "End Date": "",
                                        "Activity Qty": "",
                                        "Active Days": "",
                                        "Unit Price (Ex GST)": "",
                                        "Prorate Price (Ex GST)": "Total",
                                        "Sub Total (Ex GST)": "$" + resp.data.annualChargeResource.amount,
                                    }
                                )

                            setMspsaasAnnualChargeLineItemsData(annualChargeLineItemsTableData);

                            mspsaasAnnualChargeLineColumns.map((td) => {
                                if (td == "Unit Price (Ex GST)" || td == "Prorate Price (Ex GST)" || td == "Sub Total (Ex GST)") {
                                    annualChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                } else {
                                    annualChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false
                                    })
                                }
                            });
                            setMspsaasAnnualChargeLineItems(annualChargeLineItemsTable);
                        }
                    }


                    let advancedChargeLineItemsTable = [];
                    let advancedChargeLineItemsTableData = [];
                    if (resp.data.advancedChargeLineItems !== null && resp.data.advancedChargeLineItems.length > 0) {
                       
                        let i = 0;
                        (resp.data.advancedChargeLineItems).map((td) => {
                            i++
                            advancedChargeLineItemsTableData.push
                                (
                                    {
                                        "No.": i,
                                        "Your PO No.": td.provisionTag,
                                        "Brand": resp.data.cloudService.name,
                                        "Plan Name and Description": td.planName,
                                        "Billing Frequency": td.billingFrequency,
                                        "SKU": td.planCode,
                                        "Qty": td.quantity,
                                        "Price (Ex GST)": "$" + td.unitPrice,
                                        "Amount (Ex GST)": "$" + td.amount
                                    }
                                )
                        });

                        if (resp.data.advanceChargeResource.amount !== null) {
                            advancedChargeLineItemsTableData.push
                                (
                                    {
                                        "No.": "",
                                        "Your PO No.": "",
                                        "Brand": "",
                                        "PlanName and Description": "",
                                        "Billing Frequency": "",
                                        "SKU": "",
                                        "Qty": "",
                                        "Price (Ex GST)": "Total",
                                        "Amount (Ex GST)": "$" + resp.data.advanceChargeResource.amount
                                    }
                                )
                        }

                        setAdvancedChargeLineItemsData(advancedChargeLineItemsTableData);

                        advancedChargeLineItemsColumns.map((td) => {
                            if (td == "Price (Ex GST)" || td == "Amount (Ex GST)") {
                                advancedChargeLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap etxt">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else if (td == "Qty") {
                                advancedChargeLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else if (td == "SKU" && resp.data.cloudService.integrationCode === "cloudcare") {
                                advancedChargeLineItemsTable.push({
                                    omit: true
                                })
                            } else {
                                advancedChargeLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setAdvancedChargeLineItems(advancedChargeLineItemsTable);
                    }


                    // Adjustment Charge Line Items
                    let adjustmentChargeLineItemsTable = [];
                    let adjustmentChargeLineItemsData = [];
                    if (resp.data.adjustmentChargeLineItems != null) {
                        if (resp.data.adjustmentChargeLineItems.length > 0) {
                            let i = 0;
                            (resp.data.adjustmentChargeLineItems).map((td) => {
                                i++
                                adjustmentChargeLineItemsData.push
                                    (
                                        {
                                            "No.": i,
                                            "Your PO No.": td.provisionTag,
                                            "Brand": td.serviceName,
                                            "Plan Name and Description": td.planName + " " + (td.description !== null ? td.description : ""),
                                            "Billing Frequency": td.billingFrequency,
                                            "SKU": td.planCode,
                                            "Qty": td.quantity,
                                            "Price (Ex GST)": "$" + td.unitPrice,
                                            "Amount (Ex GST)": "$" + td.amount
                                        }
                                    )
                            });

                            if (resp.data.adjustmentChargeResource.amount !== null) {
                                adjustmentChargeLineItemsData.push
                                    (
                                        {
                                            "No.": "",
                                            "Your PO No.": "",
                                            "Brand": "",
                                            "Plan Name and Description": "",
                                            "Billing Frequency": "",
                                            "SKU": "",
                                            "Qty": "",
                                            "Price (Ex GST)": "Total",
                                            "Amount (Ex GST)": "$" + resp.data.adjustmentChargeResource.amount
                                        }
                                    )
                            }

                            setAdjustmentChargeLineItemsData(adjustmentChargeLineItemsData);


                            adjustmentChargeLineItemsColumns.map((td) => {
                                if (td == "Price (Ex GST)" || td == "Amount (Ex GST)") {
                                    adjustmentChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                        right: true
                                    })
                                } else if (td == "SKU" && resp.data.cloudService.integrationCode === "cloudcare") {
                                    adjustmentChargeLineItemsTable.push({
                                        omit: true
                                    })
                                } else if (td == "Qty") {
                                    adjustmentChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false,
                                        right: true
                                    })
                                } else {
                                    adjustmentChargeLineItemsTable.push({
                                        id: `${td}`,
                                        name: <div>{td}</div>,
                                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                        center: false
                                    })
                                }
                            });
                            setAdjustmentChargeLineItems(adjustmentChargeLineItemsTable);
                        }
                    }


                    // Discount Line Items
                    if (resp.data.discountLineItems.length > 0) {
                        setDiscountLineItems(resp.data.discountLineItems);
                    }

                    let discountLineItemsTable = [];
                    let discountLineItemsTableData = [];
                    if (resp.data.discountLineItems != null) {
                        if (resp.data.discountLineItems.length > 0) {
                            let i = 0;
                            (resp.data.discountLineItems).map((td) => {
                                i++
                                discountLineItemsTableData.push
                                    (
                                        {
                                            "No.": i,
                                            "Brand": td.brand,
                                            "Description": td.description,
                                            "Amount (Ex GST)": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`
                                        }
                                    )
                            });

                            setDiscountLineItemsData(discountLineItemsTableData);


                            discountLineItemsColumns.map((td) => {

                                if (resp.data.cloudService.integrationCode !== "microsoftsaas") {
                                    if (td == "Amount (Ex GST)") {
                                        discountLineItemsTable.push({
                                            id: `${td}`,
                                            name: <div>{td}</div>,
                                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                            center: false,
                                            right: true
                                        })
                                    } else {
                                        discountLineItemsTable.push({
                                            id: `${td}`,
                                            name: <div>{td}</div>,
                                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                            center: false,
                                        })
                                    }
                                } else {
                                    if (td == "Amount (Ex GST)") {
                                        discountLineItemsTable.push({
                                            id: `${td}`,
                                            name: <div>{td}</div>,
                                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                            center: false,
                                            right: true
                                        })
                                    } else if (td !== "Brand") {
                                        discountLineItemsTable.push({
                                            id: `${td}`,
                                            name: <div>{td}</div>,
                                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                            center: false
                                        })
                                    }
                                }
                            });
                            setDiscountLineItems(discountLineItemsTable);

                        }
                    }
                }
            })
            .catch(error => {
                
                if (error?.status == 401 || error?.response?.status == 401) {
                    
                    props.Logout();
                }
                

            })
    }

    useEffect(() => {
        getInvoiceDetails(); // Get Invoice Details On Initial Load
    }, []);

    useEffect(() => {
        const groupByProductType = msazureInvoiceLineItems.reduce((group, product) => {
            const { productType } = product;
            group[productType] = group[productType] || [];
            group[productType].push(product);
            
            return (group);
        }, {});
        setMsazureInvoiceLineItemsData(groupByProductType);
    }, [msazureInvoiceLineItems])

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url + id}?requestedResellerCode=${resellerId}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Custom Styles for React Data Table
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
                textTransform: 'capitalize',
                textAlign: 'center',
            },
        },
    };

    return (
        <Container fluid className="main-content px-0 cloud-invocies-details-table">
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
            {
                !invoiceDetailsDummy && 
                    <div className="no-data-cloud-invoice-details">
                        <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading invoice details...</p>
                    </div>
            }
            {
                invoiceDetailsDummy && 
                    <Container fluid className="my-3 invoice-details-screen">

                <Row className="align-items-center">

                    <Col className="gotoback col-md-6" onClick={() => navigate(-1)}>
                        <span className="back">&nbsp;</span>
                        <span>Tax Invoice No.: </span>
                        <span className="text-normal">{invoiceDetails.invoiceNumber}</span>
                    </Col>

                    <div className="col-md-6">
                        <div className="btn-wrapper">
                            <Button className="px-4 mx-2" onClick={() => { DownloadAction(invoiceNumber, CLOUD_INVOICE_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${invoiceNumber}_invoice.pdf`, "pdf") }}>Download Invoice</Button>
                        </div>
                    </div>

                </Row>


                <Row className="details-box px-1">
                    <Col lg={12} className="mt-3 p-3">
                        <h2 className="text-strong mb-0">
                            Cloud Bill Summary No. : <label><span className="text-normal subscription-heading-table-header"> {summaryNumber}</span></label>
                        </h2>
                    </Col>

                    <Col md={4} className="mb-3 p-3">
                        <div>
                            <h3 className="text-primary mb-5 text-uppercase">Details</h3>
                            <p>
                                <span className="text-strong">{invoiceDetails.billBy.companyName}</span><br />
                                {invoiceDetails.billBy.addressLine1},<br />
                                {invoiceDetails.billBy.addressLine2 && <>{invoiceDetails.billBy.addressLine2},<br /></>}
                                {invoiceDetails.billBy.city}<br />
                                {invoiceDetails.billBy.state}, {invoiceDetails.billBy.country} - {invoiceDetails.billBy.zipCode}<br />

                                ABN:{invoiceDetails.billBy.abn}<br />
                                ACN:{invoiceDetails.billBy.acn}<br />
                                TEL:{invoiceDetails.billBy.phone}<br />
                                FAX:{invoiceDetails.billBy.fax}
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3 p-3 d-flex justify-content-sm-center">
                        <div>
                            <h3 className="text-primary mb-5 text-uppercase">Sold To</h3>
                            <p>
                                <span className="text-strong">{invoiceDetails.billTo.firstName} {invoiceDetails.billTo.lastName}</span> <br />
                                {invoiceDetails.billTo.companyName}<br />
                                {invoiceDetails.billTo.addressLine1}<br />
                                {invoiceDetails.billTo.addressLine2 && <>{invoiceDetails.billTo.addressLine2}<br /></>}
                                {invoiceDetails.billTo.city}<br />
                                {invoiceDetails.billTo.state}, {invoiceDetails.billTo.country} - {invoiceDetails.billTo.zipCode}<br />
                                {invoiceDetails.billTo.email}<br />
                                {/* {invoiceDetails.billTo.externalCompanyCode}<br />
                                ABN:{invoiceDetails.billTo.abnNumber}<br /> */}
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3 p-3 d-flex justify-content-lg-center justify-content-md-end">
                        <div>
                            <h3 className="text-primary mb-5 text-uppercase">Ship To</h3>
                            <p>
                                {invoiceDetails.shipTo.customerCompanyName}<br />
                                {invoiceDetails.shipTo.firstName} {invoiceDetails.shipTo.lastName}<br />
                                {invoiceDetails.shipTo.addressLine1},<br />
                                {invoiceDetails.shipTo.addressLine2 && <>{invoiceDetails.shipTo.addressLine2}, <br /></>}
                                {invoiceDetails.shipTo.city}<br />
                                {invoiceDetails.shipTo.state}, {invoiceDetails.shipTo.country} - {invoiceDetails.shipTo.zipcode}<br />
                                {invoiceDetails.shipTo.email}<br />
                            </p>
                        </div>
                    </Col>
                </Row>

                {
                    (
                        invoiceDetails.cloudService.integrationCode == "microsoftazure" ||
                        invoiceDetails.cloudService.integrationCode == "microsoftsaas" ||
                        invoiceDetails.cloudService.integrationCode == "googleworkspace" ||
                        invoiceDetails.cloudService.integrationCode == "cloudcare" ||
                        invoiceDetails.cloudService.integrationCode == "acronis1" ||
                        invoiceDetails.cloudService.integrationCode == "acronis2" ||
                        invoiceDetails.cloudService.integrationCode == "softlayer" ||
                        invoiceDetails.cloudService.integrationCode == "GCP") &&
                    <TableBlock
                        tableName=""
                        programName={invoiceDetails.cloudService.name}
                        domainName=""
                        customStyles={customStyles}
                        loadingMessage="invoice details..."
                        columnNames={invoiceCommonDetails}
                        columnData={invoiceCommonDetailsData}
                    />

                }


                {
                    (invoiceDetails.cloudService.integrationCode === "googleworkspace" || invoiceDetails.cloudService.integrationCode === "softlayer") &&
                    <>

                        <div className="has-serial-number">
                            <TableBlock
                                tableName="Product Charges"
                                programName=""
                                domainName=""
                                customStyles={customStyles}
                                loadingMessage="Product Charges..."
                                columnNames={gwsIBMLineItems}
                                columnData={gwsIBMLineItemsData}
                            />
                        </div>
                    </>
                }


                {/* Invoice Line Items Table  */}

                {
                    (
                        acronisLineItemsData.length > 0 &&
                        (invoiceDetails.cloudService.integrationCode === "acronis1" || invoiceDetails.cloudService.integrationCode === "acronis2")) &&
                    <>
                        <div className="has-serial-number">
                            <TableBlock
                                tableName="Product Charges"
                                programName=""
                                domainName=""
                                customStyles={customStyles}
                                loadingMessage="Product Charges..."
                                columnNames={acronisLineItems}
                                columnData={acronisLineItemsData}
                            />
                        </div>
                    </>
                }


                {/* GCP Invoice Monthly Charges  */}

                {
                    (invoiceDetails.cloudService.integrationCode === "GCP" &&
                        invoiceDetails.gcpInvoiceLineItems !== null &&
                        invoiceDetails.gcpInvoiceLineItems) &&
                    
                        <div className="details-box row">
                            <div className="px-2 pt-3 pb-3">
                                <h2>GCP Monthly Charges</h2>
                                <h3>Domain: <span className="text-regular">{invoiceDetails.gwCustomerDomain}</span></h3>
                            </div>

                            

                            {
                                Object.entries(invoiceDetails.gcpInvoiceLineItems).map((item) => {
                                    return (
                                      
                                            
                                            <div className="mx-0 px-0">
                                                <div className="px-2">
                                                    <h3>
                                                        Subaccount Name: <span className="text-regular">
                                                            {item[1][0].subBillingAccountName}
                                                        </span> &nbsp; &nbsp;(Subaccount Id: <span className="text-regular">{item[0]}</span>)
                                                    </h3>
                                                </div>
                                                <div className="table-responsive mt-4">
                                                    <Table>
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                <th><span>No.</span></th>
                                                                <th><span className="text-strong">Service</span></th>
                                                                <th><span className="text-strong">Sku Id</span></th>
                                                                <th><span className="text-strong">Sku Description</span></th>
                                                                <th className="text-right"><span className="text-strong">Qty</span></th>
                                                                <th className="text-right"><span className="text-strong">Price (Ex GST)</span></th>
                                                                <th className="text-right"><span className="text-strong">Amount (Ex GST)</span></th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            Object.entries(item[1]).map((td) => {
                                                                return (
                                                                    
                                                                        
                                                                        <tbody>
                                                                            {
                                                                                td.map((subItem, i) => {
                                                                                    if (typeof (subItem) == "object") {
                                                                                        return (
                                                                                            <>                                                                        <tr>
                                                                                                <td>{i}</td>                                                                                                <td>{subItem.serviceName}</td>
                                                                                                <td>{subItem.skuId}</td>
                                                                                                <td>{subItem.skuName}</td>
                                                                                                <td className="text-right">{subItem.quantity}</td>
                                                                                                <td className="text-right">{subItem.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(subItem.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(subItem.unitPrice))).toFixed(2))}`}</td>
                                                                                                <td className="text-right">{subItem.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(subItem.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(subItem.amount))).toFixed(2))}`}</td>
                                                                                            </tr>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                   
                                                                )
                                                            })
                                                        }
                                                    </Table>
                                                </div>
                                            </div>
                                     
                                    )

                                })
                            }
                        </div>
                    
                }

                {/* Microsoft Azure Grouped by Product Type */}

                {
                    (invoiceDetails.cloudService.integrationCode === "microsoftazure" &&
                        msazureInvoiceLineItems !== null &&
                        msazureInvoiceLineItems.length > 0) &&
                    <>
                        <div className="details-box row">

                            {
                                Object.entries(msazureInvoiceLineItemsData).map((item) => {
                                    return (
                                        <>
                                           

                                            <div className="mx-0 px-0">
                                                {item[0] !== "null" && <div className="table-heading mb-0 pb-0">
                                                    <h3>
                                                        {item[0]}
                                                    </h3>
                                                </div>}

                                                <div className="table-responsive">
                                                    <Table className="microsoft-azure-table">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                <th><span>No.</span></th>
                                                                <th><span>Brand</span></th>
                                                                <th><span>Plan Name and Description</span></th>
                                                                <th><span>Sku</span></th>
                                                                <th className="text-right"><span>Qty</span></th>
                                                                <th className="text-right"><span>Price (Ex GST)</span></th>
                                                                <th className="text-right"><span>Amount (Ex GST)</span></th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            Object.entries(item[1]).map((td, i) => {
                                                                return (
                                                                    <>
                                                                         
                                                                        <tbody>
                                                                            {
                                                                                <>
                                                                                    {td.map(subItem => {
                                                                                        if (typeof (subItem) == "object") {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>{i + 1}</td>                                                                                                    <td>{subItem.serviceName}</td>
                                                                                                        <td>{subItem.planName} <br />{subItem.description}</td>
                                                                                                        <td>{subItem.planCode}</td>
                                                                                                        <td className="text-right">{subItem.quantity}</td>
                                                                                                        <td className="text-right">${subItem.unitPrice}</td>
                                                                                                        <td className="text-right">${subItem.amount}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                    }

                                                                                </>
                                                                            }
                                                                        </tbody>
                                                                    </>
                                                                )


                                                            })
                                                        }
                                                    </Table>
                                                </div>
                                            </div>
                                        </>
                                    )

                                })
                            }
                        </div>
                    </>

                }

                {/* Microsoftsaas Monthly Charge Line Items */}

                {
                    invoiceDetails.cloudService.integrationCode === "microsoftsaas" &&

                    <>
                        {
                            (invoiceDetails.monthlyChargeLineItems !== null && invoiceDetails.monthlyChargeLineItems.length > 0) &&
                            <>
                                <div className="has-serial-number">
                                    <TableBlock
                                        tableName="Monthly Charges"
                                        programName=""
                                        domainName=""
                                        customStyles={customStyles}
                                        loadingMessage="Monthly Charges"
                                        columnNames={mspsaasMonthlyChargeLineItems}
                                        columnData={mspsaasMonthlyChargeLineItemsData}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
                {/* Microsoftsaas Annual Charge Line Items */}
                {
                    invoiceDetails.cloudService.integrationCode === "microsoftsaas" &&
                    <>
                        {
                            (invoiceDetails.annualChargeLineItems !== null && invoiceDetails.annualChargeLineItems.length > 0) &&
                            <>
                                <div className="has-serial-number">
                                    <TableBlock
                                        tableName="Annual Charges"
                                        programName=""
                                        customStyles={customStyles}
                                        domainName=""
                                        loadingMessage="Annual Charges..."
                                        columnNames={mspsaasAnnualChargeLineItems}
                                        columnData={mspsaasAnnualChargeLineItemsData}
                                    />
                                </div>
                            </>
                        }
                    </>
                }

                {/* microsoftsaas NCE Monthly Charges*/}
                {
                    (invoiceDetails.nceMonthlyChargeLineItems.length > 0) &&
                    <>
                        <div className="has-serial-number">
                            <TableBlock
                                tableName="NCE Monthly Charges"
                                programName=""
                                customStyles={customStyles}
                                domainName=""
                                loadingMessage="NCE Monthly Charges..."
                                columnNames={mspsaasNSEMonthlyChargeLineItems}
                                columnData={mspsaasNSEMonthlyChargeLineItemsData}
                            />
                        </div>
                    </>

                }

                {/* microsoftsaas NCE Annual Charges*/}
                {
                    (invoiceDetails.cloudService.integrationCode === "microsoftsaas" && invoiceDetails.nceAnnualChargeLineItems.length > 0) &&
                    <>
                        <div className="has-serial-number">
                            <TableBlock
                                tableName="NCE Annual Charges"
                                programName=""
                                customStyles={customStyles}
                                domainName=""
                                loadingMessage="NCE Annual Charges..."
                                columnNames={mspsaasInvoiceLineItems}
                                columnData={mspsaasInvoiceLineItemsData}
                            />
                        </div>
                    </>

                }

                {/*Adjustment Charge LineItems */}
                {
                    invoiceDetails.cloudService.integrationCode !== "microsoftsaas" &&
                    <>
                        {
                            (invoiceDetails.advancedChargeLineItems !== null && invoiceDetails.advancedChargeLineItems.length > 0) &&
                            <>
                                <div className="has-serial-number">
                                    <TableBlock
                                        tableName="Advance Charges"
                                        programName=""
                                        customStyles={customStyles}
                                        domainName=""
                                        loadingMessage="Advance Charges..."
                                        columnNames={advancedChargeLineItems}
                                        columnData={advancedChargeLineItemsData}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
                {/*Adjustment Charge LineItems */}
                {
                    invoiceDetails.cloudService.integrationCode !== "microsoftsaas" &&
                    <>
                        {
                            (invoiceDetails.adjustmentChargeLineItems !== null && invoiceDetails.adjustmentChargeLineItems.length > 0) &&
                            <>
                                <div className="has-serial-number">
                                    <TableBlock
                                        tableName="Adjustment Charges"
                                        programName=""
                                        customStyles={customStyles}
                                        domainName=""
                                        loadingMessage="Adjustment Charge Line Items..."
                                        columnNames={adjustmentChargeLineItems}
                                        columnData={adjustmentChargeLineItemsData}
                                    />
                                </div>
                            </>
                        }
                    </>
                }

                {/* Discount Line Items */}
                {
                    (discountLineItems.length > 0) &&
                    <>
                        <div className="has-serial-number mini-table">
                            <TableBlock
                                tableName="Product Discounts"
                                programName=""
                                customStyles={customStyles}
                                domainName=""
                                loadingMessage="Loading Product Discounts . . ."
                                columnNames={discountLineItems}
                                columnData={discountLineItemsData}
                            />
                        </div>
                    </>
                }

                <Row className="details-box py-4 px-1">
                    <Col md={6}>
                        <p className="text-regular mb-1">Payment Terms<br /></p>
                        <h2 className="text-strong ">{invoiceDetails.paymentTerm}</h2>

                        <p className="text-regular mb-1">Due Date<br /></p>
                        <h2 className="text-strong">{invoiceDetails.dueDate}</h2>
                    </Col>
                    <Col md={6}>
                        <table className="text-right float-right mobile-table">
                            <tr>
                                <td><label className="text-strong">Gross Amount (Ex GST):</label></td>
                                <td><label className="text-strong ml-5 pl-5">{invoiceDetails.grossAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((invoiceDetails.grossAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(invoiceDetails.grossAmount)).toFixed(2))}`}</label></td>
                            </tr>
                            <tr>
                                <td><label className="text-strong">Discount Amount:</label></td>
                                <td><label className="text-strong ml-5 pl-5">{invoiceDetails.discountAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((invoiceDetails.discountAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(invoiceDetails.discountAmount)).toFixed(2))}`}</label></td>
                            </tr>
                            <tr>
                                <td colSpan="2"><hr /></td>
                            </tr>
                            <tr>
                                <td><label className="text-strong">Net Amount:</label></td>
                                <td><label className="text-strong ml-5 pl-5">{invoiceDetails.netAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((invoiceDetails.netAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(invoiceDetails.netAmount)).toFixed(2))}`}</label></td>
                            </tr>
                            <tr>
                                <td><label className="text-strong">Plus GST:</label></td>
                                <td><label className="text-strong ml-5 pl-5">{invoiceDetails.taxAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((invoiceDetails.taxAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(invoiceDetails.taxAmount)).toFixed(2))}`}</label></td>
                            </tr>
                            <tr>
                                <td colSpan="2"><hr /></td>
                            </tr>
                            <tr>
                                <td><label><h2 className="text-strong">Invoice Total (Inc GST):</h2></label></td>
                                <td><label><h2 className="text-primary ml-5 pl-5 text-strong">{invoiceDetails.totalAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((invoiceDetails.totalAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(invoiceDetails.totalAmount)).toFixed(2))}`}</h2></label></td>
                            </tr>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {invoiceDetails.cloudService.integrationCode == "cloudcare" &&
                            <p><strong>Note:</strong> {InvoicesCloudCareNote}</p>
                        }

                        {invoiceDetails.cloudService.integrationCode == "acronis1" &&
                            <p><strong>Note:</strong> {InvoicesAcronisNote}</p>
                        }
                    </Col>
                </Row>
                    </Container>
            }
        </Container>
    );
}

export default InvoiceDetails;