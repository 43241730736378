import React from 'react'
import { Row, Col, Button, Accordion, Form } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';
import { CSPTransitionDomainNote } from '../../../../../../../../Utils/StaticMessages/NotesDisclaimers';

const TransitionDomain = (props) => {

  
  // Define a handler for the right-click event
  const handleContextMenuOrganization = (event) => {
    // event.preventDefault(); // Prevent the default context menu from appearing

    // Update state on right-click
    props.setTransitionDomainChecks({
      ...props.transitionDomainChecks,
      organizationProfileChecked: true,
    });
  };


  // Define a handler for the right-click event
  const handleContextMenuAcceptTerms = (event) => {
    // event.preventDefault(); // Prevent the default context menu from appearing

    // Update state on right-click
    props.setTransitionDomainChecks({
      ...props.transitionDomainChecks, 
      acceptInvitationChecked: true
    });
  };

  // onContextMenu={handleContextMenuOrganization} onClick={handleContextMenuOrganization}
  return (
    <>
      {/* Tranffer Domain */}
      <Accordion.Item className={`mt-5 done ${props.transitionErrorBlock ? "accordionErrors" : ""} ${props.stepsCompletedCheck.isDomainCheckCompleted ? "" : "disabled"}`} eventKey="transitionDomain">
        <Accordion.Header>
          <h3 className={`${styles['accordion-header-wrapper']}`}>
            <label>
              {props.stepsCompletedCheck.isDomainTransitionCompleted ?
                <span className="step-completed">&nbsp;</span>
                : <span className={`${styles['accordion-number']}`}>2</span>
              }
            </label>
            <label className="pl-2">
              <span className={`${styles['accordion-name']}`}>Transition Domain</span>
            </label>
          </h3>
        </Accordion.Header>
        {props.stepsCompletedCheck.isDomainCheckCompleted &&
          <Accordion.Body>
            <div className={`${styles['accordion-content']}`}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formOrganaisation">
                    {/* <Form.Label>Customer ID / Microsoft ID</Form.Label> */}
                    <Row>
                      {/* <Col lg={4} md={6} >
                        <Form.Control
                          type="text"
                          value={props.microsoftID}
                          placeholder={props.microsoftID}
                          disabled
                          readOnly
                        />
                      </Col> */}
                      <Col md={12} className="mt-3">
                        <p>To provision subscriptions for an existing Microsoft account, you will first need to authorize Synnex as the CSP Provider for that tenant. Please follow the following steps:</p>
                      </Col>

                      <Col md={12} className={`${styles['transition-domain-checks']}`}>
                        <div className="d-flex">
                          <span className={`${styles['round-number']}`}>1</span>
                          <p><a className="link-text" href={props.getDomainTrasnitionUri.microsoftCspUri} target="_blank" onContextMenu={handleContextMenuOrganization} onClick={handleContextMenuOrganization}>Click here</a> and sign in using the Admin Credentials for this account and make sure that the ‘Organization information’ under ‘Organization profile’ for this customer is complete.</p>
                        </div>
                        <div className="d-flex">
                          <span className={`${styles['round-number']}`}>2</span>
                          <p><a className="link-text" href={props.getDomainTrasnitionUri.tenantUri} target="_blank" onContextMenu={handleContextMenuAcceptTerms} onClick={handleContextMenuAcceptTerms}>Click here</a> sign in using the Admin Credentials for the tenant to be transitioned. Accept Microsoft Cloud Solution Provider invitation.(Accept the terms and conditions, and click ‘Authorize CSP’)</p>
                        </div>
                      </Col>
                      
                      <Col md={12} className="mt-5">
                      <p className='text-muted'><strong>Note:</strong> {CSPTransitionDomainNote}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <div className="button-wrapper">
                    {props.isProceeding == true &&
                      <>
                        <Button
                          className="btn btn-lg ml-2 px-4"
                          variant="light"
                          disabled
                        >
                          Back
                        </Button>
                        <Button
                          className="btn btn-lg ml-2 px-4"
                          variant="light"
                          disabled
                        >
                          Proceeding...
                        </Button>
                      </>

                    }
                    {props.isProceeding == false &&
                      <>
                        <Button
                          className="btn btn-lg btn-border-light px-4"
                          variant="light"
                          onClick={() => props.setActiveAccordion(["domainCheck"])}
                        >
                          Back
                        </Button>
                       
                          <Button
                            className="btn btn-lg ml-2 px-4"
                            onClick={() => { props.getGDAPDetails(); props.getTransitionDomainStatusRoles() }}>
                            Proceed
                          </Button>  
                      </>
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        }
      </Accordion.Item>

      {props.transitionErrorBlock &&
        <p className='red text-small mt-3' dangerouslySetInnerHTML={{ __html: props.transitionErrorMessage }}></p>}
      {/* {props.transitionErrorBlock &&
        <p className='red text-small mt-3'>Note: Please complete both the steps to proceed. Please <a
          className="cpointer"
          onClick={() => {
            props.toggleDrawer(true)();
            props.setSelectService(props.defaultServiceName);
            props.setSelectCategory(props.defaultCategoryName)
          }}>Raise ticket</a> if you need help.</p>
      } */}
    </>
  );
}

export default TransitionDomain;