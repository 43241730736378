import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import { styled } from '@mui/material/styles';
import styles from './summary.module.scss'
import { DASHBOARD_SUBSCRIPTIONS_DETAILS, DASHBOARD_SUBSCRIPTIONS_DOWNLOAD_REPORT, DASHBOARD_SUBSCRIPTIONS_PLAN_LIST, DASHBOARD_SUBSCRIPTIONS_WIDGET, GET_CUSTOMERS_LIST, STATUS_TYPE_SUBSCRIPTIONS } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import Toast from '../../../../../../../GlobalComponents/Toast';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import SummaryFilterBox from './LocalComponents/FilterBox';
import LazyTable from './LocalComponents/LazyTable';
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR, WIDGETS_LABEL_FILL_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericEmptyMatchingSubscription, GenericEmptySubscriptionError, GenericServerError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { GenericDownloadEmptyToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';

const Summary = ({ setSummaryPopup, summaryPopup, tabName, setTabName, Logout }) => {
    const location = useLocation();
    const date = new Date();                                                    // for js date time function
    const navigate = useNavigate();                                             // to use navigate function from react router dom    

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);                     // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                          // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                               // getTableData() Status 
    const [pieChartData, setPieChartData] = useState([null])                   // for storing piechart data from api
    const [total, setTotal] = useState(null);                                  // total custumers to be displayed in center of piechart label
    const [fromDate, setFromDate] = useState(null);                       // getTableData() summary from date
    const [toDate, setToDate] = useState(null);                           // getTableData() summary to date
    const [pageNo, setPageNo] = useState(1);                                             // table active page number
    const [pageSize, setPageSize] = useState(15);                                         // table active page size
    const [totalRows, setTotalRows] = useState(0);                                       // table total count of data from api 
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                             // data state used to display data in react data table component
    let ColumnName = ["Customer", "Service",                              // table headers used to make object of react data table component headers
        "Plan", "Billing Term", "Quantity", "Start Date", "Auto Renew", "Status", "Change Status"];
    let ColumnNameNoAction = ["Customer", "Service",                              // table headers used to make object of react data table component headers
        "Plan", "Billing Term", "Quantity", "Start Date", "Auto Renew", "Status"];
    const [initialLoading, setInitialLoading] = useState(true);                         // loader for table
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  
    const [planName, setPlanName] = useState(null);                                    // table active page number
    const [sortCol, setSortCol] = useState("sortStartDate");                                    // table active page number
    const [sortDir, setSortDir] = useState("DESC");                                    // table active page number
    const [isDescStartDate, setIsDescStartDate] = useState(true);               // sort state check for summary number column
    const [planListData, setPlanListData] = useState(null);                                    // table active page number
    const [customerId, setCustomerId] = useState(null);                                    // table active page number
    const [customerData, setCustomerData] = useState(null);                         // data in customer dropdown
    const [statusData, setStatusData] = useState(null);                             // data in status dropdown

    // if custom was selected in date in filter box
    const [dateCustomFromLast, setDateCustomFromLast] =
        useState(date.toISOString().slice(0, 10));            // from date saved data after applying filter having custom selection
    const [dateCustomToLast, setDateCustomToLast] =
        useState(date.toISOString().slice(0, 10));            // to date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    const [isDisabledManage, setIsDisabledManage] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Manage") ? true : false);
    const [isReadManage, setIsReadManage] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 14,
    }));

    function PieCenterLabel({ children }) {
        const { width, height, left, top } = useDrawingArea();

        // Convert children to string
        const textContent = children.toString();
        const [labelWithCommas, numberWithCommas] = textContent.split(' ');

        // Remove commas from label
        const label = labelWithCommas.replace(/,/g, '');

        // Format number with commas
        const number = isNaN(parseInt(numberWithCommas.replace(/,/g, ''), 10)) ? '' : parseInt(numberWithCommas.replace(/,/g, ''), 10).toLocaleString();

        return (
            <StyledText x={left + width / 2} y={top + height / 2}>
                <tspan className="text-large" fill="grey">{label}</tspan>
                <tspan className="text-strong text-extra-large" fill={WIDGETS_LABEL_FILL_COLOR} x={left + width / 2} dy="1.2em">{number}</tspan>
            </StyledText>
        );
    }

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(STATUS_TYPE_SUBSCRIPTIONS, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call subscriptions widget api
    async function GetSubscriptions() {
        await api.get(`${DASHBOARD_SUBSCRIPTIONS_WIDGET}`, config)
            .then(resp => {
                setPieChartData(resp.data);
                // Calculate the total sum
                let initialTotal = 0;
                resp.data.map((item) => {
                    initialTotal += item.value;
                });
                setTotal(initialTotal);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTableData(page, newPerPage, search, status, fromDate, toDate, planName, customerId, sortCol, sortDir, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        (fromDate === undefined || fromDate === null || fromDate === "null") ? fromDate = "" : fromDate = fromDate;
        (toDate === undefined || toDate === null || toDate === "null") ? toDate = "" : toDate = toDate;
        (planName === undefined || planName === null || planName === "null") ? planName = "" : planName = planName;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${DASHBOARD_SUBSCRIPTIONS_DETAILS}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&planName=${planName}&customerCode=${customerId}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && status === "" && fromDate === "" && toDate === "" && planName === "" && customerId === "") {
                        setEmptyCustomer(true);
                    }
                    else if ((status != "" || fromDate != "" || toDate != "" || planName != "" || customerId != "") && isFilter) {
                        setEmptyCustomerFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                    }

                }
                // handle success
                else {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Customer": td.customerName,
                                    "Service": td.serviceName,
                                    "Plan": <>{td.planName}
                                        <div className={`py-1 ${styles['csp-vendor-id']}`}>{td.vendorSubscriptionId}</div>
                                    </>,
                                    "Billing Term": td.billingTerm,
                                    "Status": td.status === 'ACTIVE' ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : td.status === 'PENDING' ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>
                                    ) : td.status === 'DELETED' ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Deleted</div>
                                        </div>
                                    )
                                        : (
                                            td.status
                                        ),
                                    "Quantity": td.quantity,
                                    "Start Date": td.startDate,
                                    "Auto Renew": td.autoRenew ? "On" : "Off",
                                    "Change Status": td.isTrail ? <span className='dashboard-change-status-disabled'></span>
                                        : <span className='dashboard-change-status cpointer'
                                            onClick={() => {
                                                if (td.serviceName == "Google Workspace") {
                                                    if (td.isAddOnPlan) {
                                                        navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName } })
                                                    }
                                                    else {
                                                        navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName } })
                                                    }
                                                }
                                                else {
                                                    if (td.isAddOnPlan) {
                                                        navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName } })
                                                    }
                                                    else {
                                                        navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName } })
                                                    }
                                                }
                                            }}></span>
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ((!isDisabledManage && !isReadManage) ? ColumnName : ColumnNameNoAction).map((td) => {
                        if (td === "Status" || td === "Change Status" || td === "Auto Renew") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center'>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                left: false
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}<OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                    <span className="infoIcon-support-table ml-1"></span>
                                </OverlayTrigger>
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Start Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else if (td === "Plan") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCustomer(true);
                }
            });
    };

    // Function to call all dropdown values in plan Section in Filter Box
    async function GetAllPlanList() {
        await api.get(`${DASHBOARD_SUBSCRIPTIONS_PLAN_LIST}`, config)
            .then(resp => {
                setPlanListData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        GetTableData(pageNo, newPerPage, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
    };

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
            },
        },
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir, false, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Function to trigger getTable data function 
    useEffect(() => {
        GetTableData(pageNo, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
        GetSubscriptions();
        GetAllPlanList();
        GetAllStatuses();
        GetAllCustomers();
    }, [])

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, planName, customerId, sortCol, sortDir);
    }, [isDescStartDate])


    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCustomer(false); setEmptyCustomer(false); setEmptyCustomerFilters(false); setEmptyCustomerSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Subscriptions</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={(e) => { DownloadAction(DASHBOARD_SUBSCRIPTIONS_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_subscriptions.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div className='mb-10'></div>
                                                    <div className={styles["height-subscriptions-dashboard"]} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <PieChart
                                                            height={300}
                                                            series={[{ data: pieChartData, innerRadius: 110 }]}
                                                            colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR]}
                                                            slotProps={{
                                                                legend: {
                                                                    direction: 'row',
                                                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                                                    padding: 0,
                                                                    labelStyle: {
                                                                        fill: 'grey',
                                                                    },
                                                                },
                                                                popper: {
                                                                    sx: {
                                                                        zIndex: 99999,
                                                                    },
                                                                },
                                                            }}
                                                            margin={{ top: 0, bottom: 40, left: 80 }}
                                                        >

                                                            {pieChartData.length > 0 && <PieCenterLabel>Subscriptions {pieChartData && total}</PieCenterLabel>}
                                                        </PieChart>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row >
                                                            <Col lg={3} md={4}>
                                                                <h2 className='mx-7'>Subscriptions {!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            {/* desktop and tab search and filter */}
                                                            <Col className="px-1 d-none d-md-block mb-2" lg={9} md={8}>
                                                                <Row className="justify-content-end mobile-padding-search-open-sub">
                                                                    {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCustomerFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCustomerFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }

                                                                        {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                    </Col>}
                                                                    {
                                                                        !emptyCustomerSearch && showFilterBox &&
                                                                        <SummaryFilterBox GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} searchValueTable={searchValueTable} fromDate={fromDate} toDate={toDate}
                                                                            setFromDate={setFromDate} setToDate={setToDate} planListData={planListData} planName={planName} setPlanName={setPlanName} customerId={customerId} setCustomerId={setCustomerId}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} dateLastUsedDate={dateLastUsedDate} status={status} setStatus={setStatus} statusData={statusData} customerData={customerData}
                                                                            setDateLastUsedDate={setDateLastUsedDate} dateCustomFromLast={dateCustomFromLast} setDateCustomFromLast={setDateCustomFromLast} dateCustomToLast={dateCustomToLast}
                                                                            setDateCustomToLast={setDateCustomToLast} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} sortCol={sortCol} sortDir={sortDir} setScrollToDivTop={setScrollToDivTop}
                                                                        />
                                                                    }
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericEmptyMatchingSubscription}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerFilters && !emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericEmptySubscriptionError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericEmptySubscriptionError}</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCustomer}
                                                                                emptyUser={emptyCustomer}
                                                                                emptyUserFilters={emptyCustomerFilters}
                                                                                emptyUserSearch={emptyCustomerSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="btn-wrapper text-right">
                                                            <Button
                                                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                variant="light"
                                                                onClick={() => {
                                                                    close();
                                                                }}
                                                            >
                                                                Close
                                                            </Button>
                                                            {!isDisabledManage && <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    navigate('/management-console')
                                                                }}
                                                            >Manage Subscriptions</Button>}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary