import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import PasswordToggle from '../../../../../../GlobalComponents/PasswordToggle';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import SuccessImagePreRequisite from '../../../../../../Assets/Images/Illustrations/SuccessImagePreRequisite.svg';
import failure from '../../../../../../Assets/Images/Illustrations/failure.png';
import { numberRegEx } from '../../../../../../GlobalComponents/RegExPatterns';
import styles from '../creditCard.module.scss';
import { SAVE_CREDIT_CARD_DETAILS, UPDATE_CARD_DETAILS } from '../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../Utils/API';
import { TEXT_SECONDARY } from '../../../../../../Utils/ColorsConfiguration';

const EditCreditCard = ({editCardDetails,editCardClicked,setEditCardClicked,GetCreditCardDetails,setAlreadyHavingCard,setEditCardDetails}) => {

    // Credit Card details
    const [expirationDate, setExpirationDate] = useState("");                             // data for table regarding card details 
    const [creditCardNumber, setCreditCardNumber] = useState("");                         // data for table regarding card details
    const [isTypeCard, setIsTypeCard] = useState("");                         // type of credit card in input field 
    const [isErrorCardExpiry, setIsErrorCardExpiry] = useState(false);                         // type of credit card in input field 
    const [isErrorCardNumber, setIsErrorCardNumber] = useState(false);                         // type of credit card in input field 
    const [isTermsChecked, setIsTermsChecked] = useState(false);                         // Terms and condition checked condition       
    const [isDefaultChecked, setIsDefaultChecked] = useState(false);                         // Terms and condition checked condition   
    const [creditCardName, setCreditCardName] = useState("");
    const [creditCardCVV, setCreditCardCVV] = useState("");

    const [creditCardEditStatus, setCreditCardEditStatus] = useState(null);
    const [modalFlag, setModalFlag] = useState(false);                     // error success popup flag after card submission
    
     
     // Header Authorization for General API's
     const config = {
        headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

     // Post Credit Card Data
     async function UpdateCreditCard(cardId) {
        let body ={
            "cardNumber": creditCardNumber,
            "cardType": isTypeCard,
            "cardName": creditCardName,
            "expiryMonth": expirationDate.split("/")[0],
            "expiryYear": expirationDate.split("/")[1],
            "isPrimary": isDefaultChecked,
            "cvv": creditCardCVV,
            "cardId":cardId
        }
        await api.put(UPDATE_CARD_DETAILS,body, config)
          .then(resp => {
            if(resp.status===200){
                if(resp.data.isSuccess){
                    setCreditCardEditStatus(true);
                    setModalFlag(true);
                }
                else{
                    setCreditCardEditStatus(false);
                    setModalFlag(true);
                }  
            }
          })
          .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response?.status == 401) {
              // console.log("Error 401");
              // Logout();
            }
            else {
                setCreditCardEditStatus(false);
                setModalFlag(true);
              // console.log(error);
            }
    
          });
      };

    useEffect(() => {
        if(editCardDetails){
            setIsDefaultChecked(editCardDetails.isPrimary);
            setCreditCardName(editCardDetails.cardName);
            setExpirationDate(editCardDetails.expiryMonth + "/" + editCardDetails.expiryYear);
            setIsTypeCard(editCardDetails.cardType);
            setCreditCardNumber(editCardDetails.cardNumber);
        }
    }, [editCardDetails])

  return (
    <div className='mx-1 my-3 mt-0'>
        <span className='visa-mastercard'></span>
        <Row className='px-3 py-1'>
            <Col lg={4} md={5}>
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label >Credit Card Number <span className="red">*</span></Form.Label>
                    <InputGroup className="password-toggle">
                        <Form.Control
                            type="text"
                            // value={creditCardNumber.replace(/[^0-9]/g, "").replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')}
                            value={creditCardNumber}
                            // placeholder=" Enter first name"
                            maxlength="16"
                            name="cardNumber"
                            className={isErrorCardNumber ? "border-danger" : ""}
                            onChange={(e) => {
                                setIsErrorCardNumber(false);
                                if((e.target.value.length)>=4){
                                    const prefix = (e.target.value).substring(0, 1);
                                    const prefix2 = (e.target.value).substring(0, 2);
                                    if (prefix === '4') {
                                        setIsTypeCard("VISA")
                                    } else if (prefix2 === '51' || prefix2 === '52' || prefix2 === '53' || prefix2 === '54' || prefix2 === '55') {
                                        setIsTypeCard("MASTER")
                                    } else {
                                        setIsTypeCard("OTHERS")
                                    }
                                }
                                if(e.target.value.includes("X")){
                                    setCreditCardNumber("");
                                }
                                if(numberRegEx.test(e.target.value)){
                                    setCreditCardNumber(e.target.value);
                                }
                                
                            }}
                        />
                        <InputGroup.Text>
                            <span className={creditCardNumber<4 ? 'credit-card-defualt-icon' : (isTypeCard=="VISA"? 'credit-card-visa-icon' : (isTypeCard=="MASTER"? 'credit-card-master-icon' : 'credit-card-defualt-icon'))}></span>
                        </InputGroup.Text>
                    </InputGroup>
                    {isErrorCardNumber && <p className='mb-0 text-danger'>Only Visa and Master cards are accepted</p>}
                    {!isErrorCardNumber && <p className='mb-0'>&nbsp;</p>}
                </Form.Group>
            </Col>
            <Col lg={2} md={4}>
                <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label >Card Expiry Date <span className="red">*</span></Form.Label>
                    <Form.Control
                        type="text"
                        name="expiration"
                        className={isErrorCardExpiry ? "border-danger" : ""}
                        value={expirationDate.replace(/\//g, "").substring(0, 2) + 
                            (expirationDate.length > 2 ? '/' : '') + 
                            expirationDate.replace(/\//g, "").substring(2, 6)}
                        // placeholder="Enter last name"
                        maxlength="7"
                        placeholder='MM/YYYY'
                        onChange={(e) => {
                            if((/^[0-9\/]*$/).test(e.target.value)){
                                setExpirationDate(e.target.value)
                                setIsErrorCardExpiry(false);
                            }
                            
                        }}
                    />
                    {isErrorCardExpiry && <p className='mb-0 text-danger'>Credit card is expired</p>}
                    {!isErrorCardExpiry && <p className='mb-0'>&nbsp;</p>}
                </Form.Group>
                
            </Col>
            <Col lg={2} md={5}>
                <Form.Group className="mb-3" controlId="Email">
                    <Form.Label >CVV <span className="red">*</span></Form.Label>
                    <PasswordToggle 
                        passwordValue={creditCardCVV}
                        passwordToggleEvent={(e) => (e.target.value.length <= 3 && numberRegEx.test(e.target.value) ? setCreditCardCVV(e.target.value) : "")}
                        maxLength={3} />
                </Form.Group>
            </Col>
            <Col lg={12}></Col>

            <Col lg={4} md={5}>
                <Form.Group className="mb-3" controlId="cardholder">
                    <Form.Label >Name of Cardholder <span className="red">*</span></Form.Label>
                    <Form.Control
                        type="text"
                        value={creditCardName}
                        // placeholder=" Enter first name"
                        name="Cardholder"
                    onChange={(e) => {
                        if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                            setCreditCardName(e.target.value);
                        }
                    }}
                    />
                </Form.Group>
            </Col>
        </Row>
        <div className='pb-0'>
            <Form.Check // prettier-ignore
                type="checkbox"
                id="termsCheckBox"
                label=""
                // disabled
                checked={isDefaultChecked}
                onChange={()=>{setIsDefaultChecked(!isDefaultChecked)}}
                className="d-inline-block w-auto"
            />
            <div className="d-inline-block text-small w-auto">
                Make Default
            </div>
        </div>
        <div>
            <Form.Check // prettier-ignore
                type="checkbox"
                id="termsCheckBox"
                label=""
                disabled
                checked={isTermsChecked}
                className="d-inline-block w-auto"
            />
            <div className="d-inline-block text-small w-auto">
                I agree to the <a href="/terms-and-conditions" target='_blank'  onClick={() => { setIsTermsChecked(true); }}>Terms & Conditions</a> of synnex cloud platform (Synnex Australia Pty Ltd).
            </div>
        </div>
        <Row className='mt-4'>
            <Col lg={8} md={6}>
                {<p className=' text-small notes-confirmation'> <b>Note :</b> A temporary charge of <b>AUD 1 </b>will be applied to your credit card for validation purposes. This charge will be refunded within 10 business days to the same card. Please note that your primary credit card will be automatically charged for all future cloud invoice payments. If you choose the Trial plan, you won't be billed unless you decide to upgrade later.</p>}
            </Col>
            <Col lg={4} md={6}>
                <div className="btn-wrapper mt-5 text-right">
                    {/* when length of selected item is graeter than 0 */}
                    {<>
                        {
                            <>
                                <Button
                                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    onClick={() => { setEditCardDetails(null);setExpirationDate("");setEditCardClicked(false);setIsErrorCardExpiry(false);setIsErrorCardNumber(false); }}
                                >
                                    Back
                                </Button>
                                {creditCardNumber.length==16 && !(creditCardNumber.includes("X")) && creditCardName.length>0 && creditCardCVV.length==3 && expirationDate.length==7 && isTermsChecked && parseInt(expirationDate.split("/")[0])<13 && parseInt(expirationDate.split("/")[0])>0 && !(expirationDate.split("/")[0].includes("-"))  ? <Button
                                    className="px-4 mx-2"
                                    onClick={() => {
                                        // setModalFlag(true);
                                        const today = new Date();
                                        const currentMonth = today.getMonth() + 1;
                                        const currentYear = today.getFullYear();
                                        const [month, year] = expirationDate.split('/');
                                        const expiryMonth = parseInt(month);
                                        const expiryYear = parseInt(year);
                                        if(isTypeCard!=="OTHERS" && !(expiryYear < currentYear || (expiryYear === currentYear && expiryMonth <= currentMonth)) ){
                                            UpdateCreditCard(editCardDetails.cardId)
                                        }
                                        else{
                                            if(isTypeCard==="OTHERS"){
                                            setIsErrorCardNumber(true);
                                            }
                                            else{
                                            setIsErrorCardNumber(false);
                                            }
                                            if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth <= currentMonth)) {
                                            // console.log('Expiry date must be more than the current date!');
                                            setIsErrorCardExpiry(true);
                                            } else {
                                            // console.log('Valid expiry date!');
                                            setIsErrorCardExpiry(false);
                                            }
                                        }
                                            
                                    }}
                                >Save</Button> :
                                <Button
                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    disabled
                                >Save</Button>
                                }
                            </>
                            }
                        {/* {!confirmLoadingFlag && !((JSON.stringify(defaultChecked) !== JSON.stringify(checkedItems)) || (value != gdapData?.duration)) &&
            <>
                <Button
                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                variant="light"
                disabled
                >
                Back
                </Button>
                <Button
                className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                variant="light"
                disabled
                >Save</Button></>} */}
                    </>}
                    {/* when length of selected item is equal to 0 */}
                    {/* {!confirmLoadingFlag && checkedIds?.length == 0 && <>
            <Button
            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
            variant="light"
            disabled
            >
            Back
            </Button>
            <Button
            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
            variant="light"
            disabled
            >Save</Button>
        </>} */}
                    {/* buttons used when post api is called */}
                    {/* {<>
            <Button
            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
            variant="light"
            disabled
            >
            Back
            </Button>
            <Button
            disabled
            className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
            variant="light"
            >
            Processing . . .
            </Button>
        </>} */}
                </div>
            </Col>
        </Row>

        {/* popup for success / error message on card details submission */}
        <Popup
            className="custom-modal custom-modal-sm"
            open={modalFlag}
            onClose={() => {setModalFlag(false);setCreditCardEditStatus(null);setAlreadyHavingCard(true);setEditCardClicked(false);
                setEditCardDetails(null);
                GetCreditCardDetails();
                }}
            modal
            nested
        >
            {(close) => (
                <div className="modal">
                    <div className="header p-4 py-3">
                        <h2 className="mb-0 px-3">{creditCardEditStatus ? "Credit Card Successfully Verified" : "Credit Card  Verification failed"} </h2>
                        <XLg
                            size={18}
                            className="cpointer text-strong close-icon-popup-sm"
                            onClick={()=>{if(creditCardEditStatus){close()}else{setModalFlag(false);}}}
                            color={TEXT_SECONDARY}
                        />
                    </div>
                    <Container>
                        <div className={styles["no-data-cloud-invoice"]}>
                            {creditCardEditStatus ? <img src={SuccessImagePreRequisite} className="empty-cloud-invoice-table-svg" />
                                : <img src={failure} className="empty-cloud-invoice-table-svg" />}
                            {creditCardEditStatus ? <div className='my-3 text-center text-medium'>Your credit card has been verified and saved
                                successfully
                            </div>
                                : <div className='my-3 text-center text-medium'>Looks like there might be an issue with your credit card. Would you like to try a different card or payment method</div>}
                            <Button onClick={()=>{if(creditCardEditStatus){close()}else{setModalFlag(false);}}}>Ok</Button>
                        </div>
                    </Container>
                </div>
            )}
        </Popup>
    </div>
  )
}

export default EditCreditCard
