import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, Col, Container, Dropdown, Form, InputGroup, OverlayTrigger, Row, Table } from 'react-bootstrap';
import styles from './directDebit.module.scss';
import EmptyDataTable from '../../../../../Assets/Images/Illustrations/no-credit-card-data.svg'
import { Box, Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import DeleteCardImage from '../../../../../Assets/Images/Illustrations/delete-card-img.svg';
import { api } from '../../../../../Utils/API';
import { DIRECT_DEBIT_POST, DOWNLOAD_DIRECT_DEBIT_DDR_FORM, DOWNLOAD_DIRECT_DEBIT_DOCUMENTS, GET_DIRECT_DEBITS } from '../../../../../Utils/GlobalConstants';
import Toast from '../../../../../GlobalComponents/Toast';
import FileUploadPaymentMethods from '../LocalComponents/FileUploadPaymentMethods';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../Utils/ColorsConfiguration';
import { DirectDebitSuccess } from '../../../../../Utils/StaticMessages/GenericErrorMessages';
import { DirectDebitFailureToast, DirectDebitSucessToast, GenericDownloadEmptyToast } from '../../../../../Utils/StaticMessages/ToastMessages';

const DirectDebit = (props) => {
  //Stepper
  const steps = ['Account Information', 'Required Documents', 'Upload DDR Document'];
  const [activeStep, setActiveStep] = useState(0);
  const [downloadForm, setDownloadForm] = useState(false);

  const date = new Date();                                                            // for js date time function
  const [deleteCard, setDeleteCard] = useState(false);                   // error success popup flag for deleting card or resetting primary card
  const [alreadyHavingCard, setAlreadyHavingCard] = useState(null);      // already having credit card
  let ColumnName = ["Type", "Account Name / Account No.", "Status", "Documents"];         // Columns for table
  const [cardData, setCardData] = useState(null);
  const [addCardClicked, setAddCardClicked] = useState(false);           // add card is clicked when no card is there
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bsbNumber, setBsbNumber] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [surname, setSurname] = useState("");
  const [abnNumber, setAbnNumber] = useState("");
  const [address, setAddress] = useState("");
  const [secondAccountSignatoryAddress, setSecondAccountSignatoryAddress] = useState("");
  const [financialInstitutionName, setFinancialInstitutionName] = useState("");
  const [financialInstitutionAddress, setFinancialInstitutionAddress] = useState("");

  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);               // loading state for processing button
  const [selectedOption, setSelectedOption] = useState('BankStatementHeader');

  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const [pending, setPending] = useState(false);

  //file upload:
  const [uploadedFile, setUploadedFile] = useState();                         // for uploading file 
  const [selectedFile, setSelectedFile] = useState(null);
  const [emptyFileError, setEmptyFileError] = useState(null);                 // Error for empty state

  const [uploadedFileLastStep, setUploadedFileLastStep] = useState();                         // for uploading file 
  const [selectedFileLastStep, setSelectedFileLastStep] = useState(null);
  const [emptyFileErrorLastStep, setEmptyFileErrorLastStep] = useState(null);                 // Error for empty state

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // Three Dot Actions Functionality
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <>
      <a
        href=""
        className={styles['view-doc']}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <span className={`cpointer`}>View Documents </span>
        <span className={`cpointer gdap-down-button`} />
      </a>
    </>
  ));

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // for selection of document in second step  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Header Authorization for Download PDF API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/pdf") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download PDF file from VIEW DOCUMENTS tab
  async function DownloadAction(applicationNumber, type, url, name, fileType) {
    let configuration;
    if (fileType === "pdf") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }

    let urls = '';
    if (type == "")
      urls = `${url}?applicationNumber=${applicationNumber}`
    else
      urls = `${url}?applicationNumber=${applicationNumber}&documentType=${type}`

    await api.get(urls, configuration)
      .then(resp => {
        if (resp.status === 204) {
          setToastMessage(GenericDownloadEmptyToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          // handle success
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
      }
        else {
        }
      });
  };

  // Download PDF DDR form 
  async function DownloadActionDDRForm(url, name, fileType) {
    let configuration;
    if (fileType === "pdf") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }

    let urls = '';
    if (!downloadForm)
      urls = url
    else
      urls = `${url}?surname=${surname}&abnNumber=${abnNumber}&financialInstitutionName=${financialInstitutionName}&financialInstitutionAddress=${financialInstitutionAddress}&accountName=${accountHolderName}&bSBNumber=${bsbNumber}&accountNumber=${bankAccount}&address=${address}&secondAccountSignatoryAddress=${secondAccountSignatoryAddress}`

    await api.get(urls, configuration)
      .then(resp => {
        if (resp.status === 204) {
          setToastMessage(GenericDownloadEmptyToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          // handle success
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
      }
        else {
        }
      });
  };

  // Function to call Card details 
  async function GetCardDetails() {
    await api.get(GET_DIRECT_DEBITS, config)
      .then(resp => {
        // console.log(resp.status)
        if (resp.status == 204) {
          setAlreadyHavingCard(false);
        }
        else {
          setCardData(resp.data);
          setAlreadyHavingCard(true);
          //if there are pending status data
          const hasPendingItem = Array.isArray(resp.data) ? resp.data.some(item => item.status === 'Pending') : false;
          setPending(hasPendingItem);
          let allowed = false;
          resp.data.map((data) => {
            if (data.status === 'Approved') {
              allowed = true;
              props.setIsDirectDebitAllowed(true);
            }
          })
          if (allowed == false) {
            props.setIsDirectDebitAllowed(false);
          }
        }
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
      }
        else {
        }

      });
  };

  //post api for creating clone user
  async function SubmitDirectDebit() {
    setConfirmLoadingFlag(true);
    // console.log("data", uploadedFile);
    const requestBody = {
      "BankAccountVerificationFile": uploadedFile,
      "DDRFormFile": uploadedFileLastStep
    };
    // Header Authorization for General API's
    const configFormData = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    // Combine both files into one FormData object
    const formData = new FormData();

    if (uploadedFile) {
      // console.log("Uploaded File 1:", uploadedFile.get('file'));
      formData.append('BankAccountVerificationFile', uploadedFile.get('file'));
    }

    if (uploadedFileLastStep) {
      // console.log("Uploaded File Last Step:", uploadedFileLastStep.get('file'));
      formData.append('DDRFormFile', uploadedFileLastStep.get('file'));
    }

    await api.post(`${DIRECT_DEBIT_POST}?accountNumber=${bankAccount}&accountName=${accountHolderName}&bsbNumber=${bsbNumber}&bankVerificationDocument=${selectedOption}`, formData, configFormData)
      .then(resp => {
        if (resp.status == 200) {
          setConfirmLoadingFlag(false);
          GetCardDetails();
          setAccountHolderName("");
          setBsbNumber("");
          setBankAccount("");
          setSurname("");
          setAbnNumber("");
          setSecondAccountSignatoryAddress("");
          setFinancialInstitutionName("");
          setFinancialInstitutionAddress("");
          setDownloadForm(false);
          setUploadedFile();
          setSelectedFile(null);
          setEmptyFileError(null);
          setUploadedFileLastStep();
          setSelectedFileLastStep(null);
          setEmptyFileErrorLastStep(null);
          setToastSuccess(true);
          setToastMessage(DirectDebitSucessToast);
          setTimeout(() => {
            setToastSuccess(false);
            setActiveStep(0);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
      }
        else {
          setToastMessage(DirectDebitFailureToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }

      });
  }

  useEffect(() => {
    // console.log("isDirectDebitBlocked",props.isDirectDebitBlocked);
    if (props.activeAccordion === 2 && props.isDirectDebitBlocked==false) {
      GetCardDetails();
    }
  }, [props])

  return (
    <div className={props.isDirectDebitBlocked==true ? "d-none" : ""}>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

      {!addCardClicked && !alreadyHavingCard && alreadyHavingCard != null &&
        <div className={styles["no-data-cloud-invoice"]}>
          <img src={EmptyDataTable} className="empty-cloud-invoice-table-svg" />
          <h2 className="mt-4 mb-3">No Bank Accounts found</h2>
          <div className={`text-center my-3 ${styles['mobile-display']}`}>
            <Button className="px-4" onClick={() => { setAddCardClicked(true); }}>Add New Bank Account</Button>
          </div>
        </div>}

      {
        alreadyHavingCard == null &&
        <div className={styles["loading-customers"]}>
          <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
        </div>
      }
      {!alreadyHavingCard && addCardClicked && alreadyHavingCard != null &&
        <Box sx={{ width: '100%' }} className='pt-6 px-3'>
          <Row >
            <Col md={2}></Col>
            <Col md={8}
              className={`${styles['button-color-manage-gws']}`}>
              {activeStep !== steps.length && (
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </Col>
            <Col md={2}></Col>
          </Row>

          <React.Fragment>
            {/* {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                        </div>
                                    }
                                    {emptyDataError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">{activeStep===0 ? "This Subscription can't be upgraded" : "No Plans Found"}</h2>
                                        </div>
                                    } */}
            {activeStep === 0 &&
              <Typography sx={{ mt: 2, mb: 1 }}>
                <h2 className='px-2'>Enter Your Direct Debit account Information</h2>
                <Row className='px-2 py-1'>
                  <Col lg={4} md={5}>
                    <Form.Group className="mb-3" controlId="firstName">
                      <Form.Label >Name of Account Holder <span className="red">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        value={accountHolderName}
                        name="accountHolderName"
                        onChange={(e) => {
                          setAccountHolderName(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4}>
                    <Form.Group className="mb-3" controlId="lastName">
                      <Form.Label >BSB Number <span className="red">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="bsbNumber"
                        value={bsbNumber}
                        maxlength="6"
                        onChange={(e) => {
                          if (e.target.value === '' || /^[0-9]*$/.test(e.target.value)) {
                            setBsbNumber(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}></Col>

                  <Col lg={4} md={5}>
                    <Form.Group className="mb-4" controlId="password">
                      <Form.Label >Bank Account Number <span className="red">*</span></Form.Label>
                      <InputGroup className="password-toggle">
                        <Form.Control
                          type="text"
                          value={bankAccount}
                          name="Cardholder"
                          onChange={(e) => {
                            if (e.target.value === '' || /^[0-9]*$/.test(e.target.value)) {
                              setBankAccount(e.target.value);
                            }
                          }}
                        />
                        <InputGroup.Text>
                          <span className={`account-balance`}>&nbsp;</span>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                  </Col>
                  <Col lg={12}>
                    <span className='text-strong'>Note:</span>
                    <ul className='text-small pl-4' >
                      <li className='mb-2 mt-2'>Your nominated account must belong to you</li>
                      <li className='mb-2'>Overseas accounts are not eligible</li>
                      <li className='mb-2'>If you nominate a company account then you must be a signatory
                        on that account</li>
                    </ul>
                  </Col>
                </Row>
                <div className="btn-wrapper mt-5 text-right">
                  {(accountHolderName != "" || bsbNumber != "" || bankAccount != "") && <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    onClick={() => {
                      setAccountHolderName(""); setBsbNumber(""); setBankAccount("");
                    }}
                  >
                    Cancel
                  </Button>}
                  {(accountHolderName != "" && bsbNumber.length === 6 && bankAccount != "") && <Button
                    className="px-4 mx-2"
                    onClick={() => {
                      setActiveStep(1);
                    }}
                  >Next</Button>}
                  {(accountHolderName === "" && bsbNumber === "" && bankAccount === "") && <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >
                    Cancel
                  </Button>}
                  {!(accountHolderName != "" && bsbNumber.length === 6 && bankAccount != "") && <Button
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >Next</Button>}
                </div>
              </Typography>}

            {activeStep === 1 &&
              <Typography sx={{ mt: 2, mb: 1 }}>
                <div className='px-2'>
                  <h2 className='my-3'>Upload Required Documents</h2>
                  <div className='text-medium text-strong my-3'>Verify your Bank Account
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className={`px-2 py-1 ${styles['gws-tooltip']}`}>Bank account name and details shown in supporting document
                        <br />must be the same as the bank account details provided in step 1</Tooltip>}
                    >
                      <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                    </OverlayTrigger></div>
                  <div className='text-medium my-3'>Please provide One Of the supporting documents below to verify that you are the rightful owner.</div>
                  <div className='text-medium'>
                    <input
                      type='radio'
                      className='mr-2 my-2'
                      style={{ verticalAlign: 'middle' }}
                      name="creditCardData"
                      value="BankStatementHeader"
                      checked={selectedOption === 'BankStatementHeader'}
                      onChange={handleOptionChange}
                    />
                    Bank Statement Header
                    <br />
                    <input
                      type='radio'
                      className='mr-2 my-2'
                      style={{ verticalAlign: 'middle' }}
                      name="creditCardData"
                      value="AccountLetter"
                      checked={selectedOption === 'AccountLetter'}
                      onChange={handleOptionChange}
                    />
                    Proof of Account Letter
                    <br />
                    <input
                      type='radio'
                      className='mr-2 my-2'
                      style={{ verticalAlign: 'middle' }}
                      name="creditCardData"
                      value="CancelledCheque"
                      checked={selectedOption === 'CancelledCheque'}
                      onChange={handleOptionChange}
                    />
                    Cancelled Cheque / Deposit Slip
                  </div>
                  <Row className='mt-7'>
                    <Col md={6}>
                      <FileUploadPaymentMethods fileFormat=".txt, .png ,.pdf"
                        setUploadedFile={setUploadedFile}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        setEmptyFileError={setEmptyFileError}
                      />
                    </Col>
                  </Row>
                  <div className="btn-wrapper mt-5 text-right">
                    <Button
                      className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      onClick={() => {
                        setActiveStep(0);
                      }}
                    >
                      Back
                    </Button>
                    {selectedFile && <Button
                      className="px-4 mx-2"
                      onClick={() => {
                        setActiveStep(2);
                      }}
                    >Next</Button>}
                    {!selectedFile &&
                      <Button
                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled
                      >Next</Button>
                    }
                  </div>
                </div>
              </Typography>}
            {activeStep === 2 &&
              <Typography sx={{ mt: 2, mb: 1 }}>
                <div className='px-2'>
                  <Row>
                    <Col md={7}>
                      <h2 className='my-3'>Sign & Approve DDR Document</h2>
                      <div className='text-medium text-strong my-3'>Direct Debit Request (DDR)
                      </div>
                      <p className='text-medium text-muted'>
                        The DDR Form serves as your request and authority to instruct synnex to direct debit your nominated financial account (bank account) for the payment of your due invoice
                      </p>
                      <Row className='mb-3'>
                        <Col md={1}>
                          <p className={`mb-0 ${styles["numbers"]} `}>1</p>
                        </Col>
                        <Col><div className='text-medium'>Fill DDR form to download or download the file and fill the details offline </div></Col>
                        <Row className='my-4'>
                          <Col md={1}>
                          </Col>
                            <Card className={`${styles["card-payment"]} `}>
                              <Card.Body className={`${styles["card-payment"]}`}>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <Card.Subtitle className='pdf-icon d-flex align-items-center'></Card.Subtitle>
                                </div>
                                <Card.Text className='d-flex align-items-center justify-content-center'>
                                  <div style={{ height: '2rem' }}>Click below to fill DDR form </div>
                                </Card.Text>
                                <Card.Link className={`${styles["view-doc"]}`}> <div className='d-flex align-items-center justify-content-center' >
                                  <Button
                                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    onClick={() => {
                                      setDownloadForm(true);
                                    }}
                                  >
                                    Click Here
                                  </Button>
                                </div></Card.Link>
                              </Card.Body>
                            </Card>
                          <Col className='d-flex align-items-center justify-content-center'>Or</Col>
                            <Card className={`${styles["card-payment"]} `}>
                              <Card.Body className={`${styles["card-payment"]}`}>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <Card.Subtitle className='pdf-icon d-flex align-items-center'></Card.Subtitle>
                                </div>
                                <Card.Text className='d-flex align-items-center justify-content-center'>
                                  <div style={{ height: '2rem' }} className='mx-1 d-flex align-items-center justify-content-center'>Download the file & fill the details offline </div>
                                </Card.Text>
                                <Card.Link className={`${styles["view-doc"]}`}> <div className='d-flex align-items-center justify-content-center' >
                                  <Button
                                    className="ml-3 mr-3 px-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    onClick={() => {
                                      setDownloadForm(false);
                                      DownloadActionDDRForm(DOWNLOAD_DIRECT_DEBIT_DDR_FORM, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_DDR Documents.pdf`, "pdf");
                                    }}
                                  >
                                    Download
                                  </Button>
                                </div></Card.Link>
                              </Card.Body>
                            </Card>
                        </Row>
                        {/* </Col> */}
                      </Row>
                      <Row className='mb-3'>
                        <Col md={1}>
                          <p className={`mb-0 ${styles["numbers"]} `}>2</p>
                        </Col>
                        <Col><span className='text-medium'>Upload the DDR Forum </span></Col>
                        <Row className='mt-3'>
                          <Col lg={6} md={9} className={`${styles["file-upload-iphone-pro"]} `}>
                            <FileUploadPaymentMethods fileFormat=".txt, .png ,.pdf"
                              setUploadedFile={setUploadedFileLastStep}
                              selectedFile={selectedFileLastStep}
                              setSelectedFile={setSelectedFileLastStep}
                              setEmptyFileError={setEmptyFileErrorLastStep}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                  <div className="btn-wrapper mt-5 text-right">
                    {!confirmLoadingFlag &&
                      <>

                        <Button
                          className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                          variant="light"
                          onClick={() => {
                            setActiveStep(1);
                          }}
                        >
                          Back
                        </Button>
                        {selectedFileLastStep && <Button
                          className="px-4 mx-2"
                          onClick={() => {
                            SubmitDirectDebit();
                          }}
                        >Submit</Button>}
                        {!selectedFileLastStep && <Button
                          className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                          variant="light"
                          disabled
                        >Submit</Button>}
                      </>}

                    {/* buttons used when post api is called */}
                    {confirmLoadingFlag &&
                      <>
                        <Button
                          className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                          variant="light"
                          disabled
                        >
                          Back
                        </Button>
                        <Button
                          disabled
                          className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                          variant="light"
                        >
                          Processing . . .
                        </Button>
                      </>}
                  </div>
                  {/* download template */}
                  <Popup
                    className="custom-modal custom-modal-xxl"
                    open={downloadForm}
                    onClose={() => setDownloadForm(false)}
                    modal
                    nested
                  >
                    {(close) => (
                      <div className={`modal`}>
                        <div className="header p-4 py-3">
                          <h2 className="mb-0 px-3">Direct Debit Download From</h2>
                          <XLg
                            size={18}
                            className="cpointer text-strong close-icon-popup-sm"
                            onClick={close}
                            color={TEXT_SECONDARY}
                          />
                        </div>
                        <div className={`${styles['popup-debit-card']}`}>
                          <div className='px-4 py-2'>
                            <h3>Request and Authority to Debit</h3>
                            <Row className='px-2 py-1'>
                              <Col lg={4} md={5}>
                                <Form.Group className="mb-3" controlId="firstName">
                                  <Form.Label >Your Given Names Or ABN / ARBN</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={abnNumber}
                                    name="abnNumber"
                                    onChange={(e) => {
                                      setAbnNumber(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} md={4}>
                                <Form.Group className="mb-3" controlId="lastName">
                                  <Form.Label >Surname or Company Name </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="Surname"
                                    value={surname}
                                    onChange={(e) => {
                                      setSurname(e.target.value)
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={12}></Col>

                              <Col lg={4} md={5}>
                                <Form.Group className="mb-3" controlId="cardholder">
                                  <Form.Label >Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={address}
                                    name="address"
                                    onChange={(e) => {
                                      setAddress(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <h3 className='py-1'>Insert the name and address of financial institution at which account is held </h3>
                            <Row className='px-2 py-1'>
                              <Col lg={4} md={5}>
                                <Form.Group className="mb-3" controlId="firstName">
                                  <Form.Label >Financial Institution Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={financialInstitutionName}
                                    name="financialInstitutionName"
                                    onChange={(e) => {
                                      setFinancialInstitutionName(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} md={4}>
                                <Form.Group className="mb-3" controlId="lastName">
                                  <Form.Label >Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="financialInstitutionAddress"
                                    value={financialInstitutionAddress}
                                    onChange={(e) => {
                                      setFinancialInstitutionAddress(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={12}></Col>
                            </Row>

                            <h3 className='py-1'>Insert details of account to be Debited</h3>
                            <Row className='px-2 pt-1'>
                              <Col lg={4} md={5}>
                                <Form.Group className="mb-3" controlId="firstName">
                                  <Form.Label >Name on account</Form.Label>
                                  <Form.Control
                                    type="text"
                                    disabled
                                    value={accountHolderName}
                                    maxlength="50"
                                    name="firstName"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} md={4}>
                                <Form.Group className="mb-3" controlId="lastName">
                                  <Form.Label >BSB number (Must be 6 Digits)</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={bsbNumber}
                                    maxlength="50"
                                    disabled
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={12}></Col>

                              <Col lg={4} md={5}>
                                <Form.Group className="mb-3" controlId="cardholder">
                                  <Form.Label >Account Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={bankAccount}
                                    maxlength="50"
                                    disabled
                                    name="Cardholder"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={8} md={6}>
                                {<p className=' text-small notes-confirmation'> <b>Note :</b> If you are unable to provide the details above, please download PDF From and share with the account signatory to complete the details offline </p>}
                              </Col>
                              <Col lg={4} md={6}>
                                <div className="btn-wrapper text-right">
                                  {/* when length of selected item is graeter than 0 */}
                                  {<>
                                    {
                                      <>
                                        <Button
                                          className="px-4 mx-2"
                                          onClick={() => {
                                            DownloadActionDDRForm(DOWNLOAD_DIRECT_DEBIT_DDR_FORM, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_DDR Documents.pdf`, "pdf")
                                            setDownloadForm(false);
                                          }}
                                        >Download</Button></>}
                                  </>}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div></div>
                    )}

                  </Popup>
                </div>
              </Typography>}

          </React.Fragment>
        </Box>}

      {alreadyHavingCard &&
        <>
          {cardData && cardData.filter(item => item.status === "Pending").length > 0 &&
            <Row className='mx-3'>
              <Card className={`mb-4 mt-1 ${styles['success-card']} ${styles['pending-card']}`}>
                <Card.Body className={`d-flex align-items-center ${styles['success-card']}`}> <span className='pending-state-message mr-2'></span>{DirectDebitSuccess}</Card.Body>
              </Card>
            </Row>}
          <div className={styles["table-height-adjustment"]}>
            <Table responsive="md">
              <thead>
                {/* <tr>
                <th className={`text-bold ${styles['width-type']}`}><span>Type</span></th>
                <th className={`text-bold ${styles['width-accountname']}`}><span>Account Name / Account No.</span></th>
                <th className={`text-bold text-center ${styles['width-status']}`}><span>Status</span></th>
                <th className={`text-bold text-center ${styles['width-type']}`}><span>Documents</span></th>
                </tr> */}
                <tr>
                  {ColumnName.map(th => {
                    return (<th className={(th === "Status" || th === "Documents") ? "text-center" : ""}>{th}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  cardData ? (
                    cardData.filter(item => item.status === "Approved" || item.status === "Pending").length > 0 ? (
                      cardData
                        .filter(item => item.status === "Approved" || item.status === "Pending")
                        .map((item, index) => (
                          <tr key={item.card} className={`${styles['vertical-align-middle']}`}>
                            <td className={`${styles['width-type']}`}>{item.type}</td>
                            <td className={`${styles['width-accountname']}`}>{item.accountName} ({item.accountNumber})</td>
                            <td className={`text-center ${styles['width-status']}`}>
                              {item.status === 'Approved' ? (
                                <div className={`${styles['status-orderhistory']}`}>
                                  <span className="status-paid"></span>
                                  <div className="text-muted text-small">Approved</div>
                                </div>
                              ) : (
                                <div className={`${styles['status-orderhistory']}`}>
                                  <span className="status-pending"></span>
                                  <div className="text-muted text-small">Pending</div>
                                </div>
                              )}
                            </td>
                            <td className={`text-center ${styles['width-type']}`}>
                              <Dropdown drop={"down-centered"}>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                  <Dropdown.Item onClick={(e) => {
                                    DownloadAction(item.applicationNumber, "", DOWNLOAD_DIRECT_DEBIT_DOCUMENTS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_DDR Documents.pdf`, "pdf")
                                  }} id="1">DDR Documents</Dropdown.Item>
                                  <Dropdown.Item onClick={(e) => {
                                    DownloadAction(item.applicationNumber, item.documentType, DOWNLOAD_DIRECT_DEBIT_DOCUMENTS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${item.documentType}.pdf`, "pdf")
                                  }} id="1">{item.documentType}</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Active Bank Accounts found</td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">No Active Bank Accounts found</td>
                    </tr>
                  )
                }


              </tbody>
            </Table>
            {/* View history icon */}
            {cardData && cardData.filter(item => !(item.status === "Approved" || item.status === "Pending")).length > 0 && <div>
              {!isArrowClicked ?
                <>
                  <span className={`ml-4 mr-2 gdap-down-button`} onClick={() => { setIsArrowClicked(true); }} ></span>
                  <span className='cpointer' onClick={() => { setIsArrowClicked(true); }}>View History </span>
                </>
                :
                <>
                  <span className={`ml-4 mr-2 gdap-up-button`} onClick={() => { setIsArrowClicked(false); }} ></span>
                  <span className='cpointer' onClick={() => { setIsArrowClicked(false); }}>View History </span>
                </>}
              <hr className='mb-0' />
            </div>}

            {isArrowClicked &&
              <Table responsive="md">
                <tbody>
                  {
                    cardData ? (
                      cardData.filter(item => !(item.status === "Approved" || item.status === "Pending")).length > 0 ? (
                        cardData
                          .filter(item => !(item.status === "Approved" || item.status === "Pending"))
                          .map((item, index) => (
                            <tr key={item.card} className={`${styles['vertical-align-middle']}`}>
                              <td className={`${styles['width-type']}`}>{item.type}</td>
                              <td className={`${styles['width-accountname']}`}>{item.accountName} ({item.accountNumber})</td>
                              <td className={`text-center ${styles['width-status']}`}>
                                {item.status === 'Rejected' ? (
                                  <div className={`${styles['status-orderhistory']}`}>
                                    <span className="status-failed"></span>
                                    <div className="text-muted text-small">Rejected</div>
                                  </div>
                                ) : (
                                  <div className={`${styles['status-orderhistory']}`}>
                                    <span className="status-expired"></span>
                                    <div className="text-muted text-small">Expired</div>
                                  </div>
                                )}
                              </td>
                              <td className={`text-center ${styles['width-type']}`}>
                                <Dropdown drop={"down-centered"}>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item onClick={(e) => {
                                      DownloadAction(item.applicationNumber, "", DOWNLOAD_DIRECT_DEBIT_DOCUMENTS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_DDR Documents.pdf`, "pdf")
                                    }} id="1">DDR Documents</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => {
                                      DownloadAction(item.applicationNumber, item.documentType, DOWNLOAD_DIRECT_DEBIT_DOCUMENTS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${item.documentType}.pdf`, "pdf")
                                    }} id="1">{item.documentType}</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No History found</td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No History found</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>}
          </div>
          <div className={`btn-wrapper my-4 text-right ${styles['mobile-display']}`}>

            {pending ?
              <Button
                className="px-4 mx-2"
                variant='light'
                disabled
              >
                Add New Bank Account
              </Button>
              :
              <Button
                className="px-4 mx-2"
                onClick={() => {
                  setAddCardClicked(true);
                  setAlreadyHavingCard(false);
                }}
              >
                Add New Bank Account
              </Button>}

          </div>
          {/* popup for switching direct debit */}
          <Popup
            className="custom-modal custom-modal-sm"
            open={deleteCard}
            onClose={() => setDeleteCard(false)}
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <div className="header p-4 py-3">
                  <h2 className="mb-0 px-3">Switching Payment Method</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong close-icon-popup-sm"
                    onClick={close}
                    color={TEXT_SECONDARY}
                  />
                </div>
                <Container>

                  <div className={styles["no-data-cloud-delete"]}>
                    <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg" />
                    <div className='my-3 text-center text-medium'>All your future and pending invoices wil be processed
                      with Bank Account XXXXX 4567
                    </div>
                    <div className='my-3 text-center text-medium'>Please ensure you have sufficient clear funds with BankAccount XXXXX 4567 to meet your pending and future payment dates to avoid dishonour and surcharge fees.
                    </div>
                    <div className='text-center text-medium text-strong mb-6'>Would you like to proceed?</div>


                    <div> <Button
                      className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      onClick={close}>No</Button>
                      <Button onClick={close}>Yes</Button></div>

                  </div>
                </Container>
              </div>
            )}
          </Popup>
        </>}
    </div>
  )
}

export default DirectDebit