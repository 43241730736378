import React, { useState, useEffect, useRef } from 'react'
import { Container, Button, Row, Col, Tab, NavDropdown, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom"
import styles from './myCustomers.module.scss'
import  { createTheme } from 'react-data-table-component';
import { api } from "../../../Utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { CUSTOMER_LISTING_TABLE, CUSTOMER_DOWNLOAD_CSV_SUMMARY, UPDATE_CUSTOMER_STATUS } from '../../../Utils/GlobalConstants';
import MyCustomersFilterBox from './LocalComponents/FilterBox';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { XLg } from "react-bootstrap-icons";
import Toast from '../../../GlobalComponents/Toast';
 

import AccessDenied from '../../../GlobalComponents/EmptyStates/AccessDenied';
import AuditReport from './LocalComponents/AuditReportTab';
import CustomersWidgets from './LocalComponents/Widgets';
import LazyTable from './LocalComponents/LazyTable';
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../Utils/ColorsConfiguration';
import { GenericEmptyCustomerError, GenericEmptyStateError, GenericNoMatchingCustomerError, GenericServerError } from '../../../Utils/StaticMessages/GenericErrorMessages';
import { ChangeStatusErrorToast, CustomerActivatedSuccessToast, CustomerDeactivatedSuccessToast, GenericDownloadEmptyToast } from '../../../Utils/StaticMessages/ToastMessages';


const MyCustomers = (props) => {
    const navigate = useNavigate();                                             // to use navigate function from react router dom    
    const date = new Date();                                                    // for js date time function
    const location = useLocation();                                                  // use to access data from other page data

 

    const [tabName, setTabName] = useState("Customers");                         // Default Tab

    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Customers") ? true : false);
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Customers") ? true : false);
    const [searchIconFlag, setSearchIconFlag] = useState(location.state !== null ? true : false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(location.state !== null ? location.state.searchData : "");             // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(true);                        // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                  // showing filter box
    const searchInputRef = useRef();                                            // Search Input Box

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Organization", "Full name",                              // table headers used to make object of react data table component headers
        "Email", "Creation date", "Status", "Actions"];
    let ColumnNameReadOnly = ["Organization", "Full name",                              // table headers used to make object of react data table component headers
        "Email", "Creation date", "Status"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [status, setStatus] = useState(["true"]);                                 // getTableData() status
    const [customerCode, setCustomerCode] = useState("");                       // getTableData() customer code 

    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(true);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data

    const [isDescOrganization, setIsDescOrganization] = useState(null);        // sort state check for Organization column
    const [isDescEmail, setIsDescEmail] = useState(null);                       // sort state check for Email column
    const [isDescCreationDate, setIsDescCreationDate] = useState(null);         // sort state check for Creation date column
    const [isDescStatus, setIsDescStatus] = useState(true);                     // sort state check for Status column
 

    // Get Table Data Function Parameters
    const [dateFrom, setDateFrom] = useState(null);                             // getTableData() from date
    const [dateTo, setDateTo] = useState(null);                                 // getTableData()  to date
    const [sortCol, setSortCol] = useState("isActive");                     // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                              // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  

    // if custom was selected in date in filter box
    const [dateCustomFromLast, setDateCustomFromLast] =
        useState(date.toISOString().slice(0, 10));            // from date saved data after applying filter having custom selection
    const [dateCustomToLast, setDateCustomToLast] =
        useState(date.toISOString().slice(0, 10));            // to date saved data after applying filter having custom selection

    const [deactiveStatusModalFlag, setDeactiveStatusModalFlag]                 // deactive popup
        = useState(false);
    const [activeStatusModalFlag, setActiveStatusModalFlag]                     // activate popup
        = useState(false);
    const [customerCodeStatusChange, setCustomerCodeStatusChange]               // storing customer code when deactivate or activate is clicked in actions
        = useState();

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);
    const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading


    const [bulkUpload, setBulkUpload] = useState("Bulk Upload");                //  Set Upload Type on change
    const uploadType = ["Customer bulk upload", "MCA Bulk upload"];             // Upload Types

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url + id, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                  
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                  
                }
            });
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    async function CustomerStatusActivation(isActive, customerCode) {
        //request body for the PUT API
        const requestBody = {
            isActive: isActive,
            externalCustomerCompanyCode: customerCode,
        };

        await api.put(UPDATE_CUSTOMER_STATUS, requestBody, config)
            .then(resp => {
                // handle success
                GetTableData(pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
                isActive ? setToastMessage(CustomerActivatedSuccessToast) : setToastMessage(CustomerDeactivatedSuccessToast);
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 2000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    
                    setToastMessage(ChangeStatusErrorToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    }

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, dateFrom, dateTo, sortCol, sortDir, status, appendData, isFilter) {
        // setInitialLoading(false);
        (search === undefined || search === null) ? search = "" : search = search;
        (dateFrom === undefined || dateFrom === null) ? dateFrom = "" : dateFrom = dateFrom;
        (dateTo === undefined || dateTo === null) ? dateTo = "" : dateTo = dateTo;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${CUSTOMER_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&fromDate=${dateFrom}&toDate=${dateTo}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInfinityLoading(false);
                setInitialLoading(false);
                // console.log("Data ::::",status,isFilter,(dateFrom != "" || dateTo != "" || status != ""))
                if (resp.status == 204) {
                    if(filterFlag){
                        isFilter = filterFlag
                        isFilter = search != "" ? !isFilter : isFilter; 
                    }
                    setServerErrorCustomer(false);
                    if (search === "" && ((dateFrom === "" && dateTo === "" && status === "") || (status.length === 0 && dateTo.length === 0 && dateFrom.length === 0) )) {
                        setShowFilterBox(false);
                        setEmptyCustomer(true);
                        setInitialLoading(false);
                    } else if (((dateFrom != "" || dateTo != "" || status != "")) && isFilter ) {
                        setEmptyCustomerFilters(true);
                        setInitialLoading(false);
                    } else if (search != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                        setInitialLoading(false);
                    }
                    console.log("Data ::::",search,isFilter,search != "" && !isFilter)
                }
                // handle success
                else {
                    setInitialLoading(false);
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Organization": td.organizationName,
                                    "Full name": td.firstName + " " + td.lastName,
                                    "Email": td.email,
                                    "Creation date": td.createdDate,
                                    "Status": td.isActive ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) :
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-deactive"></span>
                                            <div className="py-1 text-muted text-small">Deactive</div>
                                        </div>,
                                    "customerCode": td.customerCode,
                                    "Actions": (
                                        <Dropdown drop={"start"}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                {td.isActive ? (
                                                    <>
                                                        <Dropdown.Item onClick={() => { navigate("/my-customers/edit-customer", { state: { tabName: "Customer Summary", customerCode: td.customerCode, customerName: (td.organizationName) }, }); }} id={td.customerCode}>Customer Summary</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => { navigate("/my-customers/edit-customer", { state: { tabName: "Customer Details", customerCode: td.customerCode, customerName: (td.organizationName) }, }); }} id={td.customerCode}>Edit Customer</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => { navigate("/my-customers/edit-customer", { state: { tabName: "Cloud Requirements", customerCode: td.customerCode, customerName: (td.organizationName) }, }); }} id={td.customerCode}>Cloud Requirements</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { setCustomerCodeStatusChange(td.customerCode); setDeactiveStatusModalFlag(true); }} id="1">Deactivate</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <Dropdown.Item onClick={(e) => { setCustomerCodeStatusChange(td.customerCode); setActiveStatusModalFlag(true); }} id="1">Activate</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ),
                                }
                            );
                    })
                     
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div className='d-none-mobile'>{td}</div>,
                                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Organization") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescOrganization === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescOrganization(true); setSortCol("organization"); setIsDescEmail(null); setIsDescCreationDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescOrganization === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescOrganization(false); setSortCol("organization"); }}></span></>}
                                    {isDescOrganization === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescOrganization(true); setSortCol("organization"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Email") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescEmail === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEmail(true); setSortCol("email"); setIsDescOrganization(null); setIsDescCreationDate(null); setIsDescStatus(null); }} ></span></>}
                                    {isDescEmail === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescEmail(false); setSortCol("email"); }}></span></>}
                                    {isDescEmail === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEmail(true); setSortCol("email"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1,
                            })
                        }
                        else if (td === "Creation date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescCreationDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreationDate(true); setSortCol("createdDate"); setIsDescOrganization(null); setIsDescEmail(null); setIsDescStatus(null); }}></span></>}
                                    {isDescCreationDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCreationDate(false); setSortCol("createdDate"); }}></span></>}
                                    {isDescCreationDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreationDate(true); setSortCol("createdDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                grow: 0.8,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStatus === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("isActive"); setIsDescOrganization(null); setIsDescCreationDate(null); setIsDescEmail(null); }}></span></>}
                                    {isDescStatus === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStatus(false); setSortCol("isActive"); }}></span></>}
                                    {isDescStatus === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("isActive"); }}></span></>}
                                </div>,
                                selector: row => <div className="">{row[`${td}`]}</div>,
                                center: true,
                                grow: 0.7,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1
                            })
                        }

                    })
                    setColumns(d);             // Setting Column Data
                    
                }
            })
            .catch(error => {
                
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCustomer(true);
                    setEmptyCustomer(false);
                    
                }

            });
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status,true))();

            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
       
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
                // textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
    }, [isDescCreationDate, isDescEmail, isDescOrganization, isDescStatus]);


    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

 

 

    // Scroll to Top on Initial loading
    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/my-customers");
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
            <div className="main-content">
                {!isDisabled && <div className="customer-management-console-main-div mb-5">
                    <Popup
                        className="custom-modal custom-modal-md"
                        open={deactiveStatusModalFlag}
                        onClose={() => setDeactiveStatusModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Deactivate Status</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color={TEXT_SECONDARY}
                                    />
                                </div>
                                <Container className="p-5">
                                    <p className='pt-0 px-3 pb-5 mb-0'>
                                        <strong>Are you sure you want to deactivate the status ?</strong>
                                    </p>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    
                                                    close();
                                                }}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    CustomerStatusActivation(false, customerCodeStatusChange);
                                                    close();
                                                }}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    <Popup
                        className="custom-modal custom-modal-md"
                        open={activeStatusModalFlag}
                        onClose={() => setActiveStatusModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Activate Status</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color={TEXT_SECONDARY}
                                    />
                                </div>
                                <Container className="p-5">
                                    <p className='pt-0 px-3 pb-5 mb-0'>
                                        <strong>Are you sure you want to activate the status ?</strong>
                                    </p>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                   
                                                    close();
                                                }}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    CustomerStatusActivation(true, customerCodeStatusChange);
                                                    close();
                                                }}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>

                    <Container fluid>
                        <div className="mgmt-console-table-row">

                            <div className="my-0 table-heading align-items-center px-0 d-flex">
                                <Col md={6} className={`col-6 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                    <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">My Customers</h5>
                                </Col>
                                {tabName == "Customers" &&
                                    <Col md={6} className="d-flex justify-content-end col-6">
                                        <span className={`${styles['download-report']}`} onClick={(e) => { DownloadAction(customerCode, CUSTOMER_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerCode}_customer.csv`, "csv") }}><span className="download">&nbsp;</span>Download Report</span>
                                    </Col>
                                }
                            </div>
                            <CustomersWidgets Logout={props.Logout} />

                            <Tab.Container activeKey={tabName}>
                                <Row>
                                    <Col sm={12} className="mt-0">
                                        <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link eventKey="Customers" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                                    onClick={() => { setTabName("Customers") }}
                                                >
                                                    Customers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Audit Report" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                                    onClick={() => { setTabName("Audit Report") }}
                                                >
                                                    Audit Report</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col lg={12}>
                                        <Tab.Content>
                                            {/* Customer Tab */}
                                            <Tab.Pane eventKey="Customers">
                                                <div className='cloud-invoice-table-inner table-details details-box'>
                                                    <div className="my-0 table-heading align-items-center row py-0">
                                                        <div className={`mb-3 d-flex px-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Col className={`py-0 ${styles['customers-table']}`}>

                                                                <div className="my-0 table-heading align-items-center row">
                                                                    <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                                                        <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">My Customers{!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && !initialLoading && <span className='px-1 text-large text-muted'>({totalRows})</span>}</h5>
                                                                    </Col>
                                                                    {/* <Col className="my-1 d-none d-block text-right mr-1">
                                                                        <Button onClick={() => navigate('/my-customers/add-customer')}>Create customer</Button>
                                                                    </Col> */}

                                                                    {/* desktop and tab search and filter */}
                                                                    <Col className="px-1 d-none d-md-block" md={9}>
                                                                        <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                                            {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                                {!searchIconFlag &&
                                                                                    <span className="mx-3">&nbsp;</span>
                                                                                }
                                                                                {!searchIconFlag && !emptyCustomerFilters &&
                                                                                    <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                                }
                                                                                {searchIconFlag && !emptyCustomerFilters &&
                                                                                    <InputGroup className="search-input-box search-input-box-xl">
                                                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                            <span className="searchgrey cpointer"
                                                                                            >
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </InputGroup.Text>
                                                                                        <Form.Control
                                                                                            placeholder="Search organization, full name, email"
                                                                                            aria-label="Search"
                                                                                            aria-describedby="basic-addon2"
                                                                                            className="search-input-box-input"
                                                                                            value={searchValueTable}
                                                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                            ref={searchInputRef}
                                                                                        />
                                                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                            onClick={() => {
                                                                                                setSearchValueTable("");
                                                                                                setSearchIconFlag(!searchIconFlag);
                                                                                            }}>
                                                                                            <span className="closebtn">
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </InputGroup.Text>
                                                                                    </InputGroup>
                                                                                }



                                                                                {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                                    setShowFilterBox(!showFilterBox);
                                                                                }}></span>}
                                                                                {!initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                                {!isreadOnly && <Col lg={2} md={5} className="d-inline-block pl-3">
                                                                                    <Form.Group
                                                                                        className="form-group col-lg-12 mb-0"
                                                                                        controlId="uploadTypeId"
                                                                                    >
                                                                                        <NavDropdown
                                                                                            title={bulkUpload}
                                                                                            id="select-role-dropdown"
                                                                                            className={`servcies-dropdown ${styles['role-select-dropdown']}`}
                                                                                        >
                                                                                            {uploadType?.map((dropdownItem) => {
                                                                                                    return (
                                                                                                        <NavDropdown.Item
                                                                                                            key={dropdownItem}
                                                                                                            id={dropdownItem}
                                                                                                            value={dropdownItem}
                                                                                                            defaultValue={"Bulk Upload"}
                                                                                                            className=""
                                                                                                            onClick={() => {
                                                                                                                setBulkUpload(dropdownItem);
                                                                                                                if (dropdownItem == "Customer bulk upload") {
                                                                                                                    navigate("/my-customer/customer-bulk-upload");
                                                                                                                }

                                                                                                                if (dropdownItem == "MCA Bulk upload") {
                                                                                                                    navigate("/my-customer/mca-bulk-upload");
                                                                                                                }
                                                                                                            }}>
                                                                                                            {dropdownItem}
                                                                                                        </NavDropdown.Item>
                                                                                                    )
                                                                                                })}
                                                                                        </NavDropdown>
                                                                                    </Form.Group>

                                                                                </Col>}
                                                                                {/* Download csv file button */}
                                                                                {!isreadOnly && <Button className="ml-3 px-4 mr-2" onClick={() => navigate('/my-customers/add-customer')}>Create customer</Button>}
                                                                            </Col>}
                                                                        </Row>
                                                                    </Col>

                                                                    {/* mobile search and filter */}
                                                                    <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                                                        <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
                                                                            {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                                <InputGroup className="search-input-box search-input-box-xl">
                                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                        <span className="searchgrey cpointer"
                                                                                        >
                                                                                            &nbsp;
                                                                                        </span>
                                                                                    </InputGroup.Text>
                                                                                    <Form.Control
                                                                                        placeholder="Search Organization, Full name, Email"
                                                                                        aria-label="Search"
                                                                                        aria-describedby="basic-addon2"
                                                                                        className="search-input-box-input"
                                                                                        value={searchValueTable}
                                                                                        onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    />
                                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                        onClick={() => {
                                                                                            setSearchValueTable("");
                                                                                            setSearchIconFlag(!searchIconFlag);
                                                                                        }}>
                                                                                        <span className="closebtn">
                                                                                            &nbsp;
                                                                                        </span>
                                                                                    </InputGroup.Text>
                                                                                </InputGroup>


                                                                                {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                                    setShowFilterBox(!showFilterBox);
                                                                                }}></span>}
                                                                                {!initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                                            </Col>}
                                                                        </Row>
                                                                    </Col>


                                                                </div>
                                                                {
                                                                    !emptyCustomerSearch && showFilterBox &&
                                                                    <MyCustomersFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo} pageSize={pageSize} searchValueTable={searchValueTable} dateFrom={dateFrom} dateTo={dateTo}
                                                                        setDateFrom={setDateFrom} setDateTo={setDateTo} sortCol={sortCol} sortDir={sortDir} setEmptyCustomerFilters={setEmptyCustomerFilters}
                                                                        setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} dateLastUsedDate={dateLastUsedDate} status={status} setStatus={setStatus}
                                                                        setDateLastUsedDate={setDateLastUsedDate} dateCustomFromLast={dateCustomFromLast} setDateCustomFromLast={setDateCustomFromLast} dateCustomToLast={dateCustomToLast}
                                                                        setDateCustomToLast={setDateCustomToLast} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} setScrollToDivTop={setScrollToDivTop} Logout={props.Logout}
                                                                    />
                                                                }
                                                                <Container fluid className="mb-5 p-0">
                                                                    <Row>
                                                                        <Col>
                                                                            <div className={`table-responsive ${styles['customers-history-table']}`}>
                                                                                {serverErrorCustomer &&
                                                                                    <div className={styles["no-data-customers"]}>
                                                                                        <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                        <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                                                    </div>
                                                                                }
                                                                                {!initialLoading && emptyCustomerSearch && !emptyCustomerFilters &&
                                                                                    <div className={styles["no-data-customers"]}>
                                                                                        <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                        <h2 className="mt-4 mb-3">{GenericNoMatchingCustomerError}</h2>
                                                                                    </div>
                                                                                }
                                                                                {!initialLoading && emptyCustomerFilters &&
                                                                                    <div className={styles["no-data-customers"]}>
                                                                                        <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                        <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                                                                    </div>
                                                                                }
                                                                                {!initialLoading && emptyCustomer &&
                                                                                    <div className={styles["no-data-customers"]}>
                                                                                        <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                        <h2 className="mt-4 mb-3">{GenericEmptyCustomerError}</h2>
                                                                                        {!isreadOnly && <Button className="ml-3 px-4 mr-2" onClick={() => navigate('/my-customers/add-customer')}>Create customer</Button>}
                                                                                    </div>
                                                                                }
                                                                                {!serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                                                                   <LazyTable
                                                                                   d={columns}
                                                                                   table={table}
                                                                                   customStyles={customStyles}
                                                                                   loading={infinityLoading}
                                                                                   pageNo={pageNo}
                                                                                   totalRows={totalRows}
                                                                                   handlePerRowsChange={handlePerRowsChange}
                                                                                   handlePageChange={handlePageChange}
                                                                                   styles={styles}
                                                                                   handleLazyCalls={handleLazyCalls}
                                                                                   serverErrorUser={serverErrorCustomer}
                                                                                   emptyUser={emptyCustomer}
                                                                                   emptyUserFilters={emptyCustomerFilters}
                                                                                   emptyUserSearch={emptyCustomerSearch}
                                                                                   setScrollToDivTop={setScrollToDivTop}
                                                                                   scrollToDivTop={scrollToDivTop}
                                                                               />
                                                                                 }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>

                                                            </Col>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            {/* Audit Report Tab */}
                                            <Tab.Pane eventKey="Audit Report">
                                                <AuditReport Logout={props.Logout}/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </Container>
                </div>}
                {
                    isDisabled && <AccessDenied />
                }
            </div>
        </>
    )
}

export default MyCustomers