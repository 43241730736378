import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './CSPMigrate.module.scss';
import { api } from '../../../../../../../../Utils/API';
import { CSP_ADDONS, CSP_MIGRATION, CSP_MIGRATION_CHECK_ELIGIBILITY, CSP_MIGRATION_COTERM_GET, CSP_MIGRATION_NCE_PLANS } from '../../../../../../../../Utils/GlobalConstants';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import { TEXT_SECONDARY } from '../../../../../../../../Utils/ColorsConfiguration';
import { CSPMigrateEligibilityFail, CSPMigrateEmailConfirmationMessage, CSPMigrateFailError, CSPMigrateNonEligiblePlans, CSPMigrateSuccess, GenericEmptyPlanError, GenericServerError, GenericUpgradeError } from '../../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { LegacyEligibilityErrorToast, LegacyEligibilitySuccessToast } from '../../../../../../../../Utils/StaticMessages/ToastMessages';

const CSPMigrate = ({ subscriptionTableAPIData, setSubscriptionTableAPIData, migrationActionPopupModalOpen, setMigrationActionPopupModalOpen,
    setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, customerId, vendorAccountId, Logout, toggleDrawer, setSelectService, setSelectCategory,
    setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {

    let MigrateCoreColumnName = ["Plan", "Subscription ID", "Commitment Term (Billing Frequency)", "Subscription Type", "Quantity", "Auto Renew"];                                                                      //first upgrade table columns
    let MigrateCoreColumnNameStep2 = ["Plan", "Commitment Term (Billing Frequency)", "Subscription Type", "Quantity", "Auto Renew", "RRP (Inc GST)", "Price (Ex GST)"];                    //second step columns in upgrade
    const [coreMigrateDetails, setCoreMigrateDetails] = useState(null);                                                               //data containing all details of step 1 in stepper 
    const [coreUpgradeDetailsStep2, setCoreUpgradeDetailsStep2] = useState(null);                                                     //data containing all details of step 2 in stepper                                                   //data stored in step 2
    const [emptyDataError, setEmptyDataError] = useState(false);                                                                  // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                                                        // to check server error-400
    const defaultServiceName = "Microsoft CSP";
    const defaultCategoryName = "Cloud Technical Support";

    //Stepper
    const steps = ['View Subscriptions', 'Manage Co-term'];
    const [activeStep, setActiveStep] = useState(0);

    const [checkEligibiltyFlag, setCheckEligibiltyFlag] = useState("Initial");
    const [processingFlag, setProcessingFlag] = useState(false);
    const [loadingRequestFlag, setLoadingRequestFlag] = useState(true);
    const [statusMessageFlag, setStatusMessageFlag] = useState(false);
    const [errorStatusMessageFlag, setErrorStatusMessageFlag] = useState(false);

    //co term
    const [cotermData, setCotermData] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);
    let ColumnNameCoterm = ["Plan Name", "Subscription ID ", "Commitment Term (Billing Frequency)"];
    const [cotermChosen, setCotermChosen] = useState(null);
    const [isCotermFLag, setIsCotermFLag] = useState(true);
    const [cotermDateSelectedObject, setCotermDateSelectedObject] = useState([]);       // all the selected coterm date 
    const [selectedOption, setSelectedOption] = useState(null);     // for checking selected index in coterm date

    // State to manage active tab key
    const [activeKey, setActiveKey] = useState(0);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // function for opening and closing expandable under coterm dates
    const toggleRow = (index) => {
        if (expandedRow === index) {
            setExpandedRow(null); // Collapse if already expanded
        } else {
            setExpandedRow(index); // Expand the clicked row
        }
    };

    const arrowDirection = (index) => (expandedRow === index ? 'up' : 'down');

    // function without unselect option in radio
    // const cotermPlansWithDateSelection = (currentSubscriptionId, dateSelected, planId, quantity, isAddonPlan, isAlignedDate) => {
    //     const updatedData = cotermDateSelectedObject.filter(data => data.currentSubscriptionId !== currentSubscriptionId);

    //     // Add new data to the updated array
    //     updatedData.push({
    //         "currentSubscriptionId": currentSubscriptionId,
    //         "alignedDate": dateSelected,
    //         "isAlignedDate": isAlignedDate,
    //         "isAlignedMonthEndDate": !isAlignedDate,
    //         "planId": planId,
    //         "quantity": quantity,
    //         "isAddonPlan": isAddonPlan,
    //     });

    //     // Update state with the updated array
    //     setCotermDateSelectedObject(updatedData);
    //     console.log(cotermDateSelectedObject)
    // };

    // function called while coterm date is selected
    const cotermPlansWithDateSelection = (currentSubscriptionId, dateSelected, planId, quantity, isAddonPlan, isAlignedDate) => {
        let isDataExists = false;

        cotermDateSelectedObject.forEach((data, index) => {
            if (
                data.currentSubscriptionId === currentSubscriptionId &&
                data.alignedDate === dateSelected &&
                data.isAlignedDate === isAlignedDate &&
                data.planId === planId &&
                data.quantity === quantity &&
                data.isAddonPlan === isAddonPlan
            ) {
                isDataExists = true;
                // Remove the similar data if it exists in the array
                cotermDateSelectedObject.splice(index, 1);
                return;
            }
        });

        if (isDataExists) {
            setCotermDateSelectedObject([...cotermDateSelectedObject]); // Update state with the updated array
        } else {
            // Add new data to the array if similar data does not exist

            // filter the data with same subscription id 
            const updatedData = cotermDateSelectedObject.filter(data => data.currentSubscriptionId !== currentSubscriptionId);

            // Add new data to the updated array
            updatedData.push({
                "currentSubscriptionId": currentSubscriptionId,
                "alignedDate": dateSelected,
                "isAlignedDate": isAlignedDate,
                "isAlignedMonthEndDate": !isAlignedDate,
                "planId": planId,
                "quantity": quantity,
                "isAddonPlan": isAddonPlan,
            });

            // Update state with the updated array
            setCotermDateSelectedObject(updatedData);
        }
        // console.log(cotermDateSelectedObject);
    };

    // get co-term 
    async function GetCotermData(billingTerm, vendorAccountId, planName) {
        await api.get(`${CSP_MIGRATION_COTERM_GET}?billingTerm=${billingTerm}&vendorAccountId=${vendorAccountId}&planName=${planName}`, config)
            .then(resp => {
                if (resp.status == 204) { }
                else {
                    setCotermData(resp.data);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            });
    };

    //Migrate step 1
    async function GetAddOnPlans(subscriptionId) {
        setLoadingRequestFlag(true);
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_ADDONS}?parentSubscriptionId=${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    // setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreMigrateDetails(resp.data);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
                setLoadingRequestFlag(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
                setLoadingRequestFlag(false);
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            });
    };

    //Migrate step 2
    async function GetNCEPlans(subscriptionId) {
        setLoadingRequestFlag(true);
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_MIGRATION_NCE_PLANS}?susbcriptionId=${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreUpgradeDetailsStep2(resp.data);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
                setLoadingRequestFlag(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
                setLoadingRequestFlag(false);
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            });
    };

    //Migrate step 1
    async function CheckEligibility(subscriptionId) {
        setProcessingFlag(true);
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_MIGRATION_CHECK_ELIGIBILITY}?susbcriptionId=${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                }
                else {
                    setCheckEligibiltyFlag("Success");
                    setToastMessage(LegacyEligibilitySuccessToast);
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
                setStatusMessageFlag(false);
                setErrorStatusMessageFlag(false);
                setProcessingFlag(false);
            })
            .catch(error => {
                setProcessingFlag(false);
                if (error.response.status == 400) {
                    setCheckEligibiltyFlag("Failed");
                    setStatusMessageFlag(true);
                    setErrorStatusMessageFlag(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setToastMessage(LegacyEligibilityErrorToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            });
    };

    //post api for Initiate migration
    async function InitiateMigration() {
        setProcessingFlag(true);
        let migratePlans = [];
        coreUpgradeDetailsStep2.map((plan) => {
            migratePlans.push({
                "currentSubscriptionId": plan.currentSubscriptionId,
                "planId": plan.planId,
                "quantity": plan.quantity,
                "isAddonPlan": plan.isAddonPlan,
                "alignedDate": "",
                "isAlignedDate": false,
                "isAlignedMonthEndDate": false
            })
        })

        let currentSubscriptionIdsToRemove = cotermDateSelectedObject.map(obj => obj.currentSubscriptionId);

        // Filter out migratePlans array based on which are already there in cotermDateSelectedObject
        migratePlans = migratePlans.filter(plan => !currentSubscriptionIdsToRemove.includes(plan.currentSubscriptionId));

        // console.log("Filtered migratePlans:", migratePlans);

        // Combine migratePlans and cotermdateselectedobject into a single array
        let combinedPlans = [...migratePlans, ...cotermDateSelectedObject];
        // console.log("Combined Plans:", combinedPlans);

        const data = {
            "customerCode": customerId,
            "migratePlans": combinedPlans
        };
        // console.log("Data:::: ", data);
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(CSP_MIGRATION, data, configFile)
            .then(resp => {
                setProcessingFlag(false);
                if (resp.status = 200) {
                    setStatusMessageFlag(true);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setStatusMessageFlag(true);
                setErrorStatusMessageFlag(true);
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            })
    };

    useEffect(() => {
        setCotermChosen(null);
        setExpandedRow(null);
    }, [activeKey]);

    useEffect(() => {
        if (subscriptionTableAPIData) {
            GetAddOnPlans(subscriptionTableAPIData.subscriptionId);
            GetCotermData(subscriptionTableAPIData?.billingTerm, vendorAccountId, subscriptionTableAPIData?.planName)
        }
    }, [subscriptionTableAPIData])

    return (
        <div>
            <Popup
                className={statusMessageFlag ? "custom-modal custom-modal-md" : "custom-modal custom-modal-xxl"}
                open={migrationActionPopupModalOpen}
                onClose={() => { setMigrationActionPopupModalOpen(false); setSubscriptionTableAPIData(null); setCoreMigrateDetails(null); setCoreUpgradeDetailsStep2(null); setServerError(false); setEmptyDataError(false); setActiveStep(0); setCheckEligibiltyFlag("Initial"); setErrorStatusMessageFlag(false); setStatusMessageFlag(false); setCotermDateSelectedObject([]); setCotermChosen(null); setExpandedRow(null);
                    setIsActionInProgress(true);
                    setIsAutoRefreshEnabled(false);
                 }}
                closeOnDocumentClick={!(processingFlag)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            {!statusMessageFlag ? <h2>Migration</h2> : errorStatusMessageFlag ? <h2>Migration Failed</h2> : <h2>Migration - Confirmation</h2>}
                            <XLg
                                size={18}
                                className="cpointer text-strong"
                                onClick={() => { if (!processingFlag) {
                                close();
                                setIsActionInProgress(true);
                                setIsAutoRefreshEnabled(false);
                                }
                                }}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        {!statusMessageFlag && <div className={`mx-3 my-3 upgrade-modal-height ${styles['modal-width']}`}>
                            <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                <Col md={5} className="mt-2 mb-2">
                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                </Col>
                                <Col md={6} className="mt-2 mb-2">
                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                </Col>
                            </Row>
                            <Box sx={{ width: '100%' }} className='pt-6'>
                                <Row >
                                    <Col md={3}></Col>
                                    <Col md={6}
                                        className={`${styles['button-color-manage-gws']}`}>
                                        {activeStep !== steps.length && (
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Col>
                                    <Col md={3}></Col>
                                </Row>
                                <React.Fragment>
                                    {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                        </div>
                                    }
                                    {emptyDataError && !isCotermFLag &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">{activeStep === 0 ? GenericUpgradeError : GenericEmptyPlanError}</h2>
                                        </div>
                                    }
                                    {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-upgrade-core']}`}>
                                                {loadingRequestFlag &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                {(coreMigrateDetails || subscriptionTableAPIData) && !loadingRequestFlag &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {MigrateCoreColumnName.map(th => {
                                                                    return (<th className={`${th === "Plan" ? styles['gws-upgrade-plan-min-width'] : ""} ${(th === "Quantity") ? "text-right" : ""} ${(th === "Auto Renew") ? "text-center" : ""}`}>
                                                                        {th}
                                                                    </th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subscriptionTableAPIData &&
                                                                <tr>
                                                                    <td>{subscriptionTableAPIData.planName}</td>
                                                                    <td>{subscriptionTableAPIData.vendorSubscriptionId}</td>
                                                                    <td>{subscriptionTableAPIData.billingTerm}</td>
                                                                    <td>{subscriptionTableAPIData.planType ? subscriptionTableAPIData.planType : "Legacy Base"}</td>
                                                                    <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                                                                    <td className='text-center'>{subscriptionTableAPIData.autoRenew ? "Yes" : "No"}</td>
                                                                </tr>
                                                            }
                                                            {
                                                                coreMigrateDetails && coreMigrateDetails.map((td) => {
                                                                    return (<tr>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.vendorSubscriptionId}</td>
                                                                        <td>{td.billingTerm}</td>
                                                                        <td>{td.planType}</td>
                                                                        <td className='text-right'>{td.quantity}</td>
                                                                        <td className='text-center'>{td.autoRenew ? "Yes" : "No"}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError && !isCotermFLag &&
                                            <div className={`table-responsive pt-3 ${styles['gws-upgrade-core']}`}>
                                                <h2 className='pl-3'>NCE Plans</h2>
                                                {!coreUpgradeDetailsStep2 && loadingRequestFlag &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                {coreUpgradeDetailsStep2 && !loadingRequestFlag &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {MigrateCoreColumnNameStep2.map(th => {
                                                                    return (<th className={`${th === "Plan" ? styles['gws-upgrade-plan-min-width'] : ""} ${(th === "Quantity") ? "text-right" : ""} ${(th === "RRP (Inc GST)") ? "text-right" : ""} ${(th === "Price (Ex GST)") ? "text-right" : ""} ${(th === "Auto Renew") ? "text-center" : ""}`}>
                                                                        {th}
                                                                    </th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                coreUpgradeDetailsStep2.map((td) => {
                                                                    return (<tr>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.billingTerm}</td>
                                                                        <td>{td.isAddonPlan ? "NCE Add-on" : "NCE Base"}</td>
                                                                        <td className='text-right'>{td.quantity}</td>
                                                                        <td className='text-center'>{td.isRenewal ? "Yes" : "No"}</td>
                                                                        <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                        <td className='text-right'>${td.price}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}

                                        {isCotermFLag &&
                                            <Tab.Container id="left-tabs-example"
                                                defaultActiveKey="0"
                                                activeKey={activeKey}
                                                onSelect={(key) => {
                                                    setActiveKey(key);
                                                }}>
                                                <Row className={`${styles['dates-coterm-height']}`}>
                                                    <Col md={3}>
                                                        <label className='label-sm text-strong'>Plan</label>
                                                        <Nav variant="pills" className={`flex-column mt-2`}>
                                                            {
                                                                coreUpgradeDetailsStep2.map((plan, index) => (
                                                                    <div key={index} className="align-end-date-tabs">
                                                                        <Nav.Item className onClick={() => { setActiveKey(index); }}>
                                                                            <Nav.Link eventKey={index} className={activeKey === index ? `${styles['active-nav-link']}` : ''}>
                                                                                <div className='mx-2 my-2'>
                                                                                    <h4>
                                                                                        {plan.planName}
                                                                                    </h4>
                                                                                    <div className='text-muted text-small'>Commitment Term (Billing Frequency) : {plan.billingTerm}</div>
                                                                                </div>
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                        <hr className='my-2' />
                                                                    </div>
                                                                ))}
                                                        </Nav>
                                                    </Col>
                                                    <Col md={9}>
                                                        <label className='label-sm text-strong'>Here is a list of your existing subscription end dates.</label>
                                                        <Tab.Content>
                                                            {coreUpgradeDetailsStep2.map((plan, index) => (
                                                                <Tab.Pane eventKey={index} key={index}>
                                                                    <Card className={`mt-2 ${styles['card-height']}`}>
                                                                        <div className='ml-6 mt-3 mr-3 mb-15'>
                                                                            <div className={`ml-3`}>
                                                                                {cotermData &&
                                                                                    <Form>
                                                                                        {cotermData && cotermData.item
                                                                                            .filter(item => item.allowedCustomTermEndDateType === "subscriptionAligned")
                                                                                            .map((option, index) => (
                                                                                                <div key={index}>
                                                                                                    <div className='mr-5'>
                                                                                                        <input
                                                                                                            className='mr-2'
                                                                                                            type="radio"
                                                                                                            name="cotermOption"
                                                                                                            style={{ verticalAlign: 'middle' }}
                                                                                                            checked={
                                                                                                                // (selectedOption === index) &&
                                                                                                                cotermDateSelectedObject.some(data => data.currentSubscriptionId === plan.currentSubscriptionId && data.alignedDate === option.allowedCustomTermEndDate && data.isAlignedDate === true)
                                                                                                            }
                                                                                                            onClick={() => {
                                                                                                                if (selectedOption == index) {
                                                                                                                    setSelectedOption(null);
                                                                                                                    cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, true);
                                                                                                                } else {
                                                                                                                    setSelectedOption(index);
                                                                                                                    cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, true);
                                                                                                                }
                                                                                                            }}
                                                                                                        // onChange={() => { cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, true); }}
                                                                                                        />
                                                                                                        <span onClick={() => { setCotermChosen(option.allowedCustomTermEndDate); toggleRow(index) }}>
                                                                                                            <span className='cpointer'>{option.allowedCustomTermEndDate}</span>
                                                                                                            <span className={`ml-2 gdap-${arrowDirection(index)}-button`} />
                                                                                                        </span>
                                                                                                        {cotermChosen === option.allowedCustomTermEndDate && expandedRow !== null &&
                                                                                                            <div className={`mt-3`}>
                                                                                                                {<Table responsive="md">
                                                                                                                    <thead className='sticky-top'>
                                                                                                                        <tr>
                                                                                                                            {ColumnNameCoterm.map(th => {
                                                                                                                                return (<th>{th}</th>)
                                                                                                                            })}
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>

                                                                                                                        {cotermData && option.cotermSubscriptions.map((item, index) => (
                                                                                                                            <tr key={item.planName} className={`${styles['vertical-align-middle']}`}>
                                                                                                                                <td className={`${styles['plan-width']}`}>{item.planName}</td>
                                                                                                                                <td className={`${styles['vendor-subscription-width']}`}>{item.vendorSubscriptionId}</td>
                                                                                                                                <td>{item.billingTerm}</td>
                                                                                                                            </tr>))}

                                                                                                                    </tbody>
                                                                                                                </Table>}
                                                                                                            </div>}
                                                                                                    </div>
                                                                                                    <br />
                                                                                                </div>
                                                                                            ))}
                                                                                        {cotermData && cotermData.item
                                                                                            .filter(item => item.allowedCustomTermEndDateType === "calendarMonthAligned")
                                                                                            .map((option, index) => (
                                                                                                <div className='mb-2'>
                                                                                                    <label className='mr-5'>
                                                                                                        <input
                                                                                                            className='mr-1'
                                                                                                            type="radio"
                                                                                                            name="cotermOption"
                                                                                                            style={{ verticalAlign: 'middle' }}
                                                                                                            checked={
                                                                                                                // (selectedOption === "9999") &&
                                                                                                                cotermDateSelectedObject.some(data => data.currentSubscriptionId === plan.currentSubscriptionId && data.alignedDate === option.allowedCustomTermEndDate && data.isAlignedDate === false)
                                                                                                            }
                                                                                                            onClick={() => {
                                                                                                                if (selectedOption == "9999") {
                                                                                                                    setSelectedOption(null);
                                                                                                                    cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, false);
                                                                                                                } else {
                                                                                                                    setSelectedOption("9999");
                                                                                                                    cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, false);
                                                                                                                }
                                                                                                            }}
                                                                                                        // onChange={() => {
                                                                                                        //     cotermPlansWithDateSelection(plan.currentSubscriptionId, option.allowedCustomTermEndDate, plan.planId, plan.quantity, plan.isAddonPlan, false);
                                                                                                        // }}
                                                                                                        /> Align end date with calendar month ({option.allowedCustomTermEndDate})
                                                                                                    </label>
                                                                                                </div>))}
                                                                                    </Form>}
                                                                                {
                                                                                    !cotermData &&
                                                                                    <div className={styles["loading-plans-card"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </Tab.Pane>
                                                            ))}
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                                <div className='text-right mt-2 mx-2'>
                                                    {!processingFlag && <><Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant='light' onClick={() => {
                                                            setIsCotermFLag(false);
                                                            GetNCEPlans(subscriptionTableAPIData.subscriptionId);
                                                            setCotermChosen(null);
                                                            setExpandedRow(null);
                                                            setActiveKey(0);
                                                            setSelectedOption(null);
                                                            setCotermDateSelectedObject([]);
                                                            setEmptyDataError(false); setServerError(false); setCoreUpgradeDetailsStep2(null);
                                                        }}>
                                                        Back
                                                    </Button>
                                                        <Button
                                                            className="ml-3 px-4 w-md-auto col-xs-12"
                                                            onClick={() => {
                                                                // console.log("Migration Initiation");
                                                                InitiateMigration();
                                                            }}
                                                        >
                                                            Initiate Migration
                                                        </Button></>}
                                                    {processingFlag &&
                                                        <>
                                                            {
                                                                <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light" disabled sx={{ mr: 1 }}>
                                                                    Cancel
                                                                </Button>
                                                            }
                                                            {
                                                                <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light" disabled sx={{ mr: 1 }}>
                                                                    Processing...
                                                                </Button>
                                                            }
                                                        </>}
                                                </div>
                                            </Tab.Container>
                                        }
                                    </Typography>}
                                    {/* buttons for migration */}
                                    {!loadingRequestFlag && !serverError &&
                                        <div className='text-right pt-4'>
                                            {activeStep === 0 && !emptyDataError &&
                                                <React.Fragment>
                                                    {!processingFlag && <>{<Button onClick={() => close()} className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Cancel
                                                    </Button>}
                                                        {checkEligibiltyFlag === "Initial" && <Button
                                                            className="ml-3 px-3 w-md-auto col-xs-12"
                                                            onClick={() => CheckEligibility(subscriptionTableAPIData.subscriptionId)}
                                                        >
                                                            Check Eligibility
                                                        </Button>}
                                                        {checkEligibiltyFlag === "Success" && <Button
                                                            className="ml-3 w-md-auto col-xs-12"
                                                            onClick={() => { setActiveStep(1); setIsCotermFLag(false); GetNCEPlans(subscriptionTableAPIData.subscriptionId); }}
                                                        >
                                                            Proceed
                                                        </Button>}</>}
                                                    {processingFlag &&
                                                        <>
                                                            {
                                                                <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light" disabled sx={{ mr: 1 }}>
                                                                    Cancel
                                                                </Button>
                                                            }
                                                            {
                                                                <Button className="ml-3 mr-3 px-4 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light" disabled sx={{ mr: 1 }}>
                                                                    Processing...
                                                                </Button>
                                                            }
                                                        </>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 1 && !isCotermFLag &&
                                                <React.Fragment>
                                                    {!processingFlag && <>{<Button onClick={() => { setEmptyDataError(false); setServerError(false); setCoreUpgradeDetailsStep2(null); setActiveStep(0) }} className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Back
                                                    </Button>}
                                                        {!emptyDataError ? <Button
                                                            className="ml-3 w-md-auto col-xs-12"
                                                            onClick={() => { setIsCotermFLag(true); }}
                                                        >
                                                            Proceed
                                                        </Button> : <Button
                                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled
                                                        >
                                                            Proceed
                                                        </Button>}
                                                    </>}
                                                </React.Fragment>
                                            }
                                        </div>}
                                </React.Fragment>
                            </Box>
                        </div>}
                        {
                            statusMessageFlag &&
                            <>
                                <Container className='text-center'>
                                    <p>
                                        {errorStatusMessageFlag ? <div className='order-cross'></div> : <div className='success-page'></div>}
                                    </p>
                                    {!errorStatusMessageFlag && activeStep === 1 && <><p className='text-regular text-success text-strong'>{CSPMigrateSuccess}</p>
                                        <p className='text-regular'>
                                            {CSPMigrateEmailConfirmationMessage}<br />
                                        </p></>}
                                    {errorStatusMessageFlag && activeStep === 0 && <><p className='text-regular text-bold text-danger text-strong'>{CSPMigrateEligibilityFail}</p>
                                        <p className='text-regular'>
                                            {CSPMigrateNonEligiblePlans}<br />
                                            Please <a href="#" onClick={() => {
                                                toggleDrawer(true)();
                                                setSelectService(defaultServiceName);
                                                setSelectCategory(defaultCategoryName)
                                            }}>click here</a> to submit a ticket if you are unable to resolve the issue.
                                        </p></>}
                                    {errorStatusMessageFlag && activeStep === 1 && <><p className='text-regular text-bold text-danger text-strong'>Migration failed</p>
                                        <p className='text-regular'>
                                            {CSPMigrateFailError}
                                        </p></>}
                                    <div>
                                        <Button
                                            className="px-4 mx-2"
                                            onClick={() => { close(); }}>
                                            Ok
                                        </Button>
                                    </div>
                                </Container>
                            </>
                        }
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default CSPMigrate