import React, { useState, useEffect } from 'react'
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import Toast from '../../../../../GlobalComponents/Toast';
import { Button, Col, Container, Row, Tab } from 'react-bootstrap';
import styles from './addWidget.module.scss'
import NoAddWidget from '../../../../../Assets/Images/Illustrations/no-add-widget-found.svg'
import { TEXT_SECONDARY } from '../../../../../Utils/ColorsConfiguration';
import { WidgetsEmptyError } from '../../../../../Utils/StaticMessages/GenericErrorMessages';

const AddWidgets = ({ addWidgetPopup, setAddWidgetPopup, masterWidget, widgetPositionData, SaveDashboardWidget }) => {

    const [addWidgetsAvailable, setAddWidgetsAvailable] = useState(null);
    const [addWidgetsTemporaryCart, setAddWidgetsTemporaryCart] = useState([]);
    const [addWidgetsTemporaryCartBlueColor, setAddWidgetsTemporaryCartBlueColor] = useState([]);

    // Get The widgets that can be added
    function GetAddWidgetDahboard() {
        let localAddWidgets = [];
        let localMasterAddWidgets = [];
        widgetPositionData.map((widget) => {
            localMasterAddWidgets.push(widget.widgetName)
        })
        // console.log("localMasterAddWidgets :", localMasterAddWidgets)
        masterWidget.map((widget) => {
            // console.log("Widget : ",localMasterAddWidgets,widget.widgetName,localMasterAddWidgets.includes(widget.widgetName));
            if (!(localMasterAddWidgets.includes(widget.widgetName))) {
                localAddWidgets.push({
                    "id": widget.id,
                    "widgetName": widget.widgetName
                })
            }
        })
        setAddWidgetsAvailable(localAddWidgets);
        // console.log("localAddWidgets :", localAddWidgets)
    };

    // Adding widget to cart
    function AddWidgetToCart(widget) {
        let localCart = addWidgetsTemporaryCart;
        let localCartBlue = [];
        // console.log("localCart :",localCart,widget)
        if (localCart.includes(widget)) {
            let local = [];
            localCart.map((lc) => {
                if (lc.widgetName !== widget.widgetName) {
                    local.push(lc);
                }
            })
            localCart = local;
        }
        else {
            localCart.push(widget);
        }
        localCart.map((lc) => {
            localCartBlue.push(lc.id);
        })
        // console.log("localCartBlue :", localCartBlue);
        setAddWidgetsTemporaryCartBlueColor(localCartBlue);
        setAddWidgetsTemporaryCart(localCart);

    };

    // Adding widget to cart
    function AddWidget() {
        let localCart = [];
        widgetPositionData.map((wp) => {
            if (wp.widgetName !== "add") {
                // console.log("Widgets :", wp);
                localCart.push(wp);
            }
        })
        addWidgetsTemporaryCart.map((widget) => {
            localCart.push(widget)
        })
        // console.log("addWidgetsTemporaryCart :", localCart);
        setAddWidgetPopup(false);
        setAddWidgetsTemporaryCart([]);
        setAddWidgetsTemporaryCartBlueColor([]);
        SaveDashboardWidget(localCart, true);

    };

    useEffect(() => {
        // console.log(",HI", masterWidget, widgetPositionData)
        if (masterWidget && widgetPositionData) {
            // console.log("HI")
            GetAddWidgetDahboard();
        }
    }, [masterWidget, widgetPositionData])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={addWidgetPopup}
                onClose={() => { setAddWidgetPopup(false); setAddWidgetsTemporaryCart([]); setAddWidgetsTemporaryCartBlueColor([]); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Add Widgets</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 '>
                                {/* <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} /> */}

                                {addWidgetsAvailable && addWidgetsAvailable.length > 0 && <Container fluid>
                                    <Row>
                                        {
                                            addWidgetsAvailable.map((widget) => {
                                                return (
                                                    <Col lg={2} md={3} xs={5} className={addWidgetsTemporaryCartBlueColor.includes(widget.id) ? `border-primary ${styles["widgets"]}` : styles["widgets"]} onClick={() => AddWidgetToCart(widget)}>
                                                        <span className={styles["widgets-icon-background"]}>
                                                            <span className={`${widget.widgetName === "Customers" ? 'add-customers' : (
                                                                    widget.widgetName === "New Customers" ? 'add-newcustomer' : (
                                                                        widget.widgetName === "Subscriptions Renewal" ? 'add-subscripnrenewal' : (
                                                                            widget.widgetName === "Vendor Revenue Contribution" ? 'add-vendor-revenue-contribution' : (
                                                                                widget.widgetName === "Subscriptions" ? 'add-subscription' : (
                                                                                    widget.widgetName === "Unpaid Invoices Summary" ? 'add-unpaid-invoices-summary' : (
                                                                                        widget.widgetName === "Invoice Amount" ? 'add-invoice-amount' : (
                                                                                            widget.widgetName === "Subscriptions Overview" ? 'add-subscription-overview' : ('add-trendingplans')))
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                                } cpointer`}>&nbsp;</span>
                                                        </span>
                                                        <h3 className='mb-0 py-2 pt-4 text-center'>{widget.widgetName}</h3>
                                                    </Col>
                                                )
                                            })

                                        }
                                    </Row>
                                    <div className="btn-wrapper text-right">
                                        <Button
                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {addWidgetsTemporaryCartBlueColor.length > 0 ? <Button
                                            className="px-4 mx-2"
                                            onClick={() => {
                                                // console.log("clicked");
                                                AddWidget();
                                            }}
                                        >Add</Button> :
                                            <Button
                                                className="btn btn-border-light btn-lg mx-2"
                                                variant="light"
                                                disabled
                                            >Add</Button>}
                                    </div>
                                </Container>}
                                {addWidgetsAvailable && addWidgetsAvailable.length === 0 &&
                                    <div className={styles["no-data-customers"]}>
                                        <img src={NoAddWidget} className="empty-cloud-invoice-table-svg" />
                                        <h2 className="mt-4 mb-3">{WidgetsEmptyError}</h2>
                                    </div>
                                }

                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default AddWidgets