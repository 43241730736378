import React, { useState, useEffect } from 'react'
import { ACCEPT_LOGIN_TERMS_AND_CONDITIONS, GET_LOGIN_TERMS_AND_CONDITIONS, LOGIN_ALERTS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Popup from 'reactjs-popup';
import { Button, Container } from 'react-bootstrap';
import './styles.scss';
import Toast from '../../../../../GlobalComponents/Toast';
import { TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../Utils/ColorsConfiguration';

const NotificationsPopup = ({ notificationsPopupFlag, setNotificationsPopupFlag,setTriggerNotificationsPopup,triggerNotificationsPopup, Logout}) => {
  const [loginAlertData, setLoginAlertData] = useState(null);  
  const [ipAddress, setIpAddress] = useState(null);

  const userAgent = navigator.userAgent;
  const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
  const browserVersion = userAgent.match(/\((.*?)\)/);

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                                         // toast message displaying success message
  const [toastError, setToastError] = useState(false);                                             // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                            // toast message 

  const [remindLaterArray, setRemindLaterArray] = useState(localStorage.getItem("remind_later_array") ? localStorage.getItem("remind_later_array") : []);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Terms and Condition Get Popup Data
  async function LoginAlertPopupDataLogin() {
    await api.get(LOGIN_ALERTS, config).then((resp) => {
      // handle success
      if(resp.status==204){
        setNotificationsPopupFlag(false);
        setTriggerNotificationsPopup(false);
      }
      else{
        console.log("tnc statusssss::::::::", (resp.data));
        let localData = [];
        resp.data.map((data)=>{
          if(!remindLaterArray.includes(data.alertId)){
            localData.push(data);
            setLoginAlertData(localData);
          }
        })
        // setLoginAlertData(resp.data);
        setNotificationsPopupFlag(true);
        setTriggerNotificationsPopup(false);
      }
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  }

  function generateRandomIp() {
    // Generate random IP address components
    const octets = [];
    for (let i = 0; i < 4; i++) {
      octets.push(Math.floor(Math.random() * 256).toString());
    }

    // Join the octets with dots to form the IP address
    const ipAddress = octets.join(".");

    return ipAddress;
  }

  // Login Alert Okay Popup Data
  async function LoginAlertAcceptFunction(alertId,ipAddress,browser,close) {
    (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
    let body = {
      "browser": browser,
      "ipAddress": ipAddress,
      "alertId": alertId
    }
    await api.post(LOGIN_ALERTS,body, config).then((resp) => {
      // handle success
      if(resp.status==200){
        close();
        if(!remindLaterArray.includes(alertId)){
        setRemindLaterArray((prev)=>[...prev,alertId])
        }
      }
      
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        } else {

        }
      });
  }

  useEffect(() => {
    if(triggerNotificationsPopup){
      LoginAlertPopupDataLogin();
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => setIpAddress(data.ip));
    }
  }, [triggerNotificationsPopup])

  useEffect(() => {
    if(remindLaterArray.length>0){
      console.log("Inside remindLaterArray : ",remindLaterArray,localStorage.getItem("remind_later_array"))
      localStorage.setItem("remind_later_array", remindLaterArray);
    }
    
  }, [remindLaterArray])

  return (
    <>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
      {loginAlertData && 
      loginAlertData.map((data)=>{
        return(
          <Popup
        className="custom-modal custom-modal-xl"
        open={notificationsPopupFlag}
        closeOnEscape={false}
        modal
        nested
        //  contentStyle={{ width: '90% ' }}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="modal terms-conditions">
            <Container fluid className="p-4">
              <div className="content" dangerouslySetInnerHTML={{ __html: data.content }}>

              </div>

            </Container>
            <div className="actions">
              <Container fluid className="px-3 py-3">
                <div className="d-flex justify-content-end">
                  <Button
                    variant="light"
                    className="px-4 btn-border-light w-md-auto col-xs-12 mx-2 btn btn-md"
                    onClick={() => {
                      if(!remindLaterArray.includes(data.alertId)){
                        setRemindLaterArray((prev)=>[...prev,data.alertId])
                        close();
                      }
                      
                    }}
                  >
                    Remind Me Later
                  </Button>
                  <Button
                    className="btn btn-md mx-2 "
                    onClick={() => {
                      // console.log("IP ADDRESS :",ipAddress,browserName[1],browserVersion[1]);
                      let browser = browserName[1] + " " + browserVersion[1];
                      LoginAlertAcceptFunction(data.alertId,ipAddress,browser,close);
                    }}
                  >
                    OK
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        )}
      </Popup>
        )
      })
      }
    </>
  )
}

export default NotificationsPopup