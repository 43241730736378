import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { CLOUD_SUMMARY_LISTING_TABLE, DASHBOARD_INVOICE_AMOUNT, DASHBOARD_INVOICE_AMOUNT_DOWNLOAD_REPORT, GET_SERVICES } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import Toast from '../../../../../../../GlobalComponents/Toast';
import { LineChart } from '@mui/x-charts';
import LazyTable from './LocalComponents/LazyTable';
import { BACKGROUND_COLOR_SECONDARY, INVOICE_AMOUNT_WIDGET_BLENDED_COLOR, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericServerError, SummariesEmptyError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { GenericDownloadEmptyToast } from '../../../../../../../Utils/StaticMessages/ToastMessages';

const Summary = ({ Logout, setSummaryPopup, summaryPopup, serviceId, setServiceId, tabName, setTabName, filterType, setFilterType, serviceSelected, setServiceSelected, invoiceDateFrom, setInvoiceDateFrom, setInvoiceDateTo, invoiceDateTo }) => {

    const date = new Date();                                                    // for js date time function
    const location = useLocation();

    const [lineChartDataSeries, setLineChartDataSeries] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);
    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const searchInputRef = useRef();
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Summary No.", "Cloud Service",                              // table headers used to make object of react data table component headers
        "Summary Date", "Invoice Amount",
        "Credit Note Amount", "Balance Amount"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table

    // Get Table Data Function Parameters
    const [sortCol, setSortCol] = useState("summaryNumber");                            // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    const [isDescInvoiceDate, setIsDescInvoiceDate] = useState(true);                   // sort state check for invoice date column
    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown

    const [serverErrorCloudInvoice, setServerErrorCloudInvoice] = useState(false);      // server error check state in table during api call to load data
    const [emptyCloudInvoice, setEmptyCloudInvoice] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCloudInvoiceFilters, setEmptyCloudInvoiceFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCloudInvoiceSearch, setEmptyCloudInvoiceSearch] = useState(false);      // empty search response check state in table during api call to load data

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading

    // Function to call invoice amount widget data
    async function GetInvoiceAmountData() {
        await api.get(`${DASHBOARD_INVOICE_AMOUNT}?duration=${filterType}&service=${serviceSelected}`, config)
            .then(resp => {
                setLineChartData(resp.data.graphData)
                setLineChartDataSeries(resp.data.xAxisData)
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    async function GetAllServices(selectedServiceName) {
        await api.get(`${GET_SERVICES}`, config)
            .then(resp => {
                setServiceData(resp.data);
                resp.data.map((service) => {
                    if (service.name === selectedServiceName) {
                        setServiceId(service.id);
                        setServiceSelected(selectedServiceName);
                    }
                })
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }

        let urls = '';
        if (serviceId == "")
            urls = `${url}?duration=${filterType}`
        else
            urls = `${url}/${serviceId}?duration=${filterType}`

        await api.get(urls, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
        (invoiceDateFrom === undefined || invoiceDateFrom === "undefined/undefined/" || invoiceDateFrom === null || invoiceDateFrom === "null") ? invoiceDateFrom = "" : invoiceDateFrom = invoiceDateFrom;
        (invoiceDateTo === undefined || invoiceDateTo === "undefined/undefined/" || invoiceDateTo === null || invoiceDateTo === "null") ? invoiceDateTo = "" : invoiceDateTo = invoiceDateTo;

        await api.get(`${CLOUD_SUMMARY_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&serviceId=${serviceId}&fromDate=${invoiceDateFrom}&toDate=${invoiceDateTo}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search == "" && invoiceDateFrom == "" && invoiceDateTo == "") {
                        setEmptyCloudInvoice(true);
                    }
                    else if (invoiceDateFrom != "" || invoiceDateTo != "") {
                        setEmptyCloudInvoiceFilters(true);
                    }
                    else if (search != "") {
                        setEmptyCloudInvoiceSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorCloudInvoice(false);
                    setEmptyCloudInvoice(false);
                    setEmptyCloudInvoiceFilters(false);
                    setEmptyCloudInvoiceSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Summary No.": td.summaryNumber,
                                    "Cloud Service": `${td.serviceName}`,
                                    "Summary Date": td.summaryDate,
                                    "Invoice Amount": td.invoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.invoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.invoiceAmount)).toFixed(2))}`,
                                    "Credit Note Amount": td.creditNoteAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.creditNoteAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.creditNoteAmount)).toFixed(2))}`,
                                    "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount)).toFixed(2))}`,
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Invoice Amount" || td === "Balance Amount" || td === "Credit Note Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else if (td === "Summary No.") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescInvoiceDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescInvoiceDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescInvoiceDate(false); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescInvoiceDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("summaryNumber"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Cloud Service") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCloudInvoice(true);
                }
            });
    };

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);
    }, [isDescInvoiceDate])

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        GetAllServices();
    }, []);

    useEffect(() => {
        if (filterType || serviceSelected) {
            GetInvoiceAmountData();
            setScrollToDivTop(true);
            setPageNo(1);
            GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir);

            let todayDate = `${String(date.getDate()).padStart(2, '0')}`
            let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
            let todayYear = `${date.getFullYear()}`
            if (filterType === "This Year") {
                let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
                setInvoiceDateFrom("01/01/" + date.getFullYear());
                setInvoiceDateTo(fromDate);
            }
            else {
                setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
                setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
            }
        }
    }, [filterType, serviceSelected]);

    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let date = new Date();
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            // console.log("Event Key :", eventKey, fromDate)
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            // console.log("Event Key : ", eventKey, `01/01/${date.getFullYear() - 1}`, `31/12/${date.getFullYear() - 1}`);
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
    };

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        if (selectedServiceName === "All Services") {
            // If "All Services" is selected, set service id to 0 and integration code to empty string
            setServiceSelected("All Services");
            setServiceId("");
        } else {
            // If a specific service is selected, fetch its details
            GetAllServices(selectedServiceName);
        }
    };

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCloudInvoice(false); setEmptyCloudInvoice(false); setEmptyCloudInvoiceFilters(false); setEmptyCloudInvoiceSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Invoice Amount</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills" defaultActiveKey={null}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={(e) => { DownloadAction(DASHBOARD_INVOICE_AMOUNT_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_invoice-amount.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div>
                                                        <Row>
                                                            <Col md={8}></Col>
                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown-2" className={`servcies-dropdown dropdown-specialclass-fortwo ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item
                                                                            eventKey="Last Year"
                                                                            className={filterType === "Last Year" ? "selected" : ""}
                                                                        >Last Year</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="This Year"
                                                                            className={filterType === "This Year" ? "selected" : ""}
                                                                        >This Year</NavDropdown.Item> </NavDropdown>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <NavDropdown title={serviceSelected} id="cloud-service-dropdown-3" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                                                                        <NavDropdown.Item
                                                                            eventKey="All Services"
                                                                            className={serviceSelected === "All Services" ? "selected" : ""}
                                                                        >All Services</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Microsoft CSP"
                                                                            className={serviceSelected === "Microsoft CSP" ? "selected" : ""}
                                                                        >Microsoft CSP</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Microsoft CSP - Azure"
                                                                            className={serviceSelected === "Microsoft CSP - Azure" ? "selected" : ""}
                                                                        >Microsoft CSP - Azure</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Google Workspace"
                                                                            className={serviceSelected === "Google Workspace" ? "selected" : ""}
                                                                        >Google Workspace</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Google Cloud"
                                                                            className={serviceSelected === "Google Cloud" ? "selected" : ""}
                                                                        >Google Cloud</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Acronis Cyber Cloud"
                                                                            className={serviceSelected === "Acronis Cyber Cloud" ? "selected" : ""}
                                                                        >Acronis Cyber Cloud</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Avast CloudCare"
                                                                            className={serviceSelected === "Avast CloudCare" ? "selected" : ""}
                                                                        >Avast CloudCare</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="IBM Cloud"
                                                                            className={serviceSelected === "IBM Cloud" ? "selected" : ""}
                                                                        >IBM Cloud</NavDropdown.Item> </NavDropdown>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className={`${styles['bar-chart-height']}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                            {lineChartData &&
                                                                <LineChart
                                                                    series={lineChartData}
                                                                    grid={{ horizontal: true, vertical: true }}
                                                                    labelRotation={45}
                                                                    slotProps={{
                                                                        legend: {
                                                                            direction: 'row',
                                                                            position: { vertical: 'bottom', horizontal: 'middle' },
                                                                            padding: 0,
                                                                            labelStyle: {
                                                                                fill: `${TEXT_SECONDARY}`
                                                                            },
                                                                        },
                                                                        popper: {
                                                                            sx: {
                                                                                zIndex: 99999,
                                                                            },
                                                                        },
                                                                    }}
                                                                    colors={INVOICE_AMOUNT_WIDGET_BLENDED_COLOR}
                                                                    sx={{
                                                                        [`.MuiAreaElement-root`]: {
                                                                            opacity: 0.5,
                                                                        }
                                                                    }}
                                                                    xAxis={[{
                                                                        data: lineChartDataSeries,
                                                                        scaleType: 'band',
                                                                        tickPlacement: 'middle',
                                                                        categoryGapRatio: 0.6,
                                                                        tickLabelPlacement: 'middle',
                                                                    }]}
                                                                    yAxis={[{
                                                                        tickNumber: 5,
                                                                        valueFormatter: (value) => value > 1000 ? `$${(value / 1000).toLocaleString()}K` : `$${value}`,
                                                                    }]}
                                                                    margin={{ top: 20, bottom: 60, left: 50, right: 50 }}
                                                                />}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row >
                                                            <Col md={3} lg={3}>
                                                                <h2 className='mx-7'>Summaries {!emptyCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            {/* desktop and tab search and filter */}
                                                            <Col className={`px-1 d-none d-md-block mb-2`} md={3} lg={5}>
                                                                <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCloudInvoice && !emptyCloudInvoice && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }
                                                                    </Col>}
                                                                </Row>
                                                            </Col>
                                                            <Col md={3} lg={2}>
                                                                <Form.Group>
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown-2" className={`servcies-dropdown dropdown-specialclass-fortwo`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item
                                                                            eventKey="Last Year"
                                                                            className={filterType === "Last Year" ? "selected" : ""}
                                                                        >Last Year</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="This Year"
                                                                            className={filterType === "This Year" ? "selected" : ""}
                                                                        >This Year</NavDropdown.Item> </NavDropdown>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3} lg={2}>
                                                                <Form.Group>
                                                                    <NavDropdown
                                                                        className="servcies-dropdown dropdown-specialclass-fortwo"
                                                                        title={serviceSelected}
                                                                        id="cloud-service-dropdown"
                                                                        onSelect={handleSelectService}
                                                                        value={serviceSelected}
                                                                    >
                                                                        <NavDropdown.Item
                                                                            eventKey="All Services"
                                                                            className={serviceSelected === "All Services" ? "selected" : ""}
                                                                        >All Services</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Microsoft CSP"
                                                                            className={serviceSelected === "Microsoft CSP" ? "selected" : ""}
                                                                        >Microsoft CSP</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Microsoft CSP - Azure"
                                                                            className={serviceSelected === "Microsoft CSP - Azure" ? "selected" : ""}
                                                                        >Microsoft CSP - Azure</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Google Workspace"
                                                                            className={serviceSelected === "Google Workspace" ? "selected" : ""}
                                                                        >Google Workspace</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Google Cloud"
                                                                            className={serviceSelected === "Google Cloud" ? "selected" : ""}
                                                                        >Google Cloud</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Acronis Cyber Cloud"
                                                                            className={serviceSelected === "Acronis Cyber Cloud" ? "selected" : ""}
                                                                        >Acronis Cyber Cloud</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="Avast CloudCare"
                                                                            className={serviceSelected === "Avast CloudCare" ? "selected" : ""}
                                                                        >Avast CloudCare</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="IBM Cloud"
                                                                            className={serviceSelected === "IBM Cloud" ? "selected" : ""}
                                                                        >IBM Cloud</NavDropdown.Item>
                                                                    </NavDropdown>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{SummariesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{SummariesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{SummariesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoice && !emptyCloudInvoiceSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCloudInvoice}
                                                                                emptyUser={emptyCloudInvoice}
                                                                                emptyUserFilters={emptyCloudInvoiceFilters}
                                                                                emptyUserSearch={emptyCloudInvoiceSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col md={10}>
                                                                <p className='mx-2 notes-confirmation'><a href={localStorage.getItem("b2b_navigation") + "Invoice-Payment/"} target='_blank'>Click here</a> to pay invoices.</p>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className="btn-wrapper text-right">
                                                                    <Button
                                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                        variant="light"
                                                                        onClick={() => {
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary