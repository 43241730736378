import React, { useState, useEffect } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Col, Form, NavDropdown, Row } from 'react-bootstrap';
import styles from './vendorRevenueContribution.module.scss'
import Summary from './LocalComponents/Summary';
import { DASHBOARD_VENDOR_REVENUE_DOWNLOAD_REPORT, DASHBOARD_VENDOR_REVENUE } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Toast from '../../../../../GlobalComponents/Toast';
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, WIDGETS_ALTERNATE_PRIMARY_COLOR, WIDGETS_ALTERNATE_SECONDARY_COLOR, WIDGETS_ALTERNATE_TERTIARY_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR, WIDGETS_TERTIARY_COLOR } from '../../../../../Utils/ColorsConfiguration';
import { GenericDownloadEmptyToast } from '../../../../../Utils/StaticMessages/ToastMessages';

const VendorRevenueContribution = ({ SaveDashboardWidget, id, widgetPositionData, Logout }) => {
    const date = new Date();                                                    // for js date time function

    const [barChartData, setBarChartData] = useState(null)
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api
    const [summaryPopup, setSummaryPopup] = useState(false);                            // My Profile Update failure flag
    const [tabName, setTabName] = useState("Summary");                        // tab name in popup
    const [filterType, setFilterType] = useState('This Year');                  // dropdown filter filter type
    const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);                       // getTableData() invoice from date
    const [invoiceDateTo, setInvoiceDateTo] = useState(null);                           // getTableData() invoice to date

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?duration=${filterType}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
    };

    // Function to call vendor revenue contribution data
    async function GetAllVendorRevenueContribution() {
        await api.get(`${DASHBOARD_VENDOR_REVENUE}?duration=${filterType}`, config)
            .then(resp => {
                {
                    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
                    if (isMobile) {
                        if (resp.data.graphData.length > 5) {
                            let localData = [];
                            let addArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                            let i = 1;
                            resp.data.graphData.map((data) => {
                                if (i <= 2) {
                                    localData.push(data);
                                }
                                else {
                                    addArray = addArray.map((dt, idx) => {
                                        return dt + data.data[idx];
                                    })
                                }
                                i++;
                            })
                            localData.push(
                                { data: addArray, stack: 'A', label: 'Others' }
                            )
                            // console.log("Local Data vendor:", localData, addArray, i);
                            setBarChartData(localData);
                        }
                        else {
                            setBarChartData(resp.data.graphData);
                        }
                    }
                    else {
                        if (resp.data.graphData.length > 5) {
                            let localData = [];
                            let addArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                            let i = 1;
                            resp.data.graphData.map((data) => {
                                if (i <= 5) {
                                    localData.push(data);
                                }
                                else {
                                    addArray = addArray.map((dt, idx) => {
                                        return dt + data.data[idx];
                                    })
                                }
                                i++;
                            })
                            localData.push(
                                { data: addArray, stack: 'A', label: 'Others' }
                            )
                            // console.log("Local Data vendor:", localData, addArray, i);
                            setBarChartData(localData);
                        }
                        else {
                            setBarChartData(resp.data.graphData);
                        }
                    }
                }
                setBarChartDataSeries(resp.data.xAxisData);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to delete widget in dashboard
    async function DeleteWidget() {
        let body = [];
        widgetPositionData.map((wd) => {
            if (wd.widgetName !== "add" && wd.id !== id) {
                body.push(wd);
            }
        })
        SaveDashboardWidget(body, true);
    };

    //   useEffect for calling get api on filter change
    useEffect(() => {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (filterType === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
        GetAllVendorRevenueContribution();
    }, [filterType])


    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

            <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
                <Col lg={8} md={6} xs={9}>
                    <h3 className='mb-0'>Vendor Revenue Contribution</h3>
                </Col>
                <Col md={6} lg={4} xs={3} className="text-right">
                    <span onClick={() => { setSummaryPopup(true); setTabName("Summary"); }} className="dashboard-arrow mx-1 cpointer d-none-mobile"></span>
                    <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details"); }}></span>
                    <span className="dashboard-download mx-1 cpointer d-none-mobile"
                        onClick={() => { DownloadAction(DASHBOARD_VENDOR_REVENUE_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_vendor-revenue-contribution.csv`, "csv") }}>
                    </span>
                    <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
                </Col>
                {
                    summaryPopup &&
                    <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} filterType={filterType} setFilterType={setFilterType} invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo} Logout={Logout} />
                }
            </Row>
            <Row className={`pt-2 ${styles["widget-header"]} `}>
                <Col lg={8} md={8} xs={8}></Col>
                <Col lg={4} md={4} xs={4}>
                    <Form.Group >
                        <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                            <NavDropdown.Item
                                eventKey="Last Year"
                                className={filterType === "Last Year" ? "selected" : ""}
                            >Last Year</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="This Year"
                                className={filterType === "This Year" ? "selected" : ""}
                            >This Year</NavDropdown.Item>
                        </NavDropdown>
                    </Form.Group>
                </Col>
            </Row >
            {barChartData &&
                <BarChart
                    series={barChartData}
                    grid={{ horizontal: true, vertical: true }}
                    sx={{
                        [`.MuiChartsLegend-series *`]: {
                            fontSize: `0.75rem !important`,
                        }
                    }}
                    slotProps={{
                        legend: {
                            direction: 'row',
                            position: { vertical: 'bottom', horizontal: 'middle' },
                            padding: 0,
                            itemGap: 2,
                            markGap: 2,
                            labelStyle: {
                                fill: `${TEXT_SECONDARY}`
                            }
                        },
                    }}
                    colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR, WIDGETS_ALTERNATE_PRIMARY_COLOR, WIDGETS_TERTIARY_COLOR, WIDGETS_ALTERNATE_SECONDARY_COLOR, WIDGETS_ALTERNATE_TERTIARY_COLOR]}
                    xAxis={[{
                        data: barChartDataSeries,
                        scaleType: 'band',
                        tickPlacement: 'middle',
                        categoryGapRatio: 0.6,
                    }]}
                    yAxis={[
                        {
                            valueFormatter: (value) => value > 1000 ? `$${(value / 1000).toLocaleString()}K` : `$${value}`,
                        }
                         
                      ]}
                    margin={{ top: 10, bottom: 70, left: 50, right: 50 }}
                />

            }
        </>
    )
}

export default VendorRevenueContribution