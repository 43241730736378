import React, { useContext, useEffect, useRef, useState } from 'react'
import CustomNumberInput from '../../../../../../../../GlobalComponents/CustomNumberInput';
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, OverlayTrigger, Row, Tooltip, useAccordionButton } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import styles from './GWSPurchaseAddOns.module.scss';
import DataTable, { createTheme } from 'react-data-table-component';
import Toast from '../../../../../../../../GlobalComponents/Toast';
import { api } from '../../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_EDIT_QUANTITY_UPDATE, GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_GET, GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_POST } from '../../../../../../../../Utils/GlobalConstants';
import failureImg from '../../../../../../../../Assets/Images/Illustrations/failure.svg';
import QuantityEditor from '../../../../QuantityEditer';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import { BACKGROUND_COLOR_SECONDARY, DISABLE_EXPAND_BUTTON_COLOR, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY } from '../../../../../../../../Utils/ColorsConfiguration';
import { GenericCloudProvisionEmptyPlan, GWSEmptyAddonError, PurchaseAddonQuantityError, PurchaseAddonSeatsError } from '../../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { PurchaseAddonErrorToast, PurchaseAddonSuccessToast } from '../../../../../../../../Utils/StaticMessages/ToastMessages';
import { GWSUpgardeNote } from '../../../../../../../../Utils/StaticMessages/NotesDisclaimers';

const GWSPurchaseAddOns = ({ subscriptionTableAPIData, subscriptionId, purchaseAddOnsActionPopupModalOpen, setPurchaseAddOnsActionPopupModalOpen, Logout,
  columnsSubscriptionTable, tableSubscriptionTable, GetTableData, minQuantity, maxQuantity, setToastSuccess, setToastError, setToastMessage, customerId, setSubscriptionTableAPIData, setActionCompletedRefreshSubscriptionTable,
setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {

  const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number

  const [confirmUpgradeLoadingFlag, setConfirmUpgradeLoadingFlag] = useState(false); //onclose popup functionality and processing button after confirmation
  const [editQuantityActionsValue, setEditQuantityActionsValue] = useState(1);
  const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
  const [loading, setLoading] = useState(false);

  const [newQuantity, setNewQuantity] = useState(1);
  const [isFocused, setIsFocused] = useState(false);

  const [selectedAddOn, setSelectedAddOn] = useState();
  const [activeQtyCheckFlag, setActiveQtyCheckFlag] = useState(false);

  const [editQuantityStepTwo, setEditQuantityStepTwo] = useState(false);
  let editQuantityStepTwoColumnName = ["Plan", "Billing Term", "Unit Price", "Quantity", "Sub-Total Price"];
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);
  const [editQuantityStepTwoErrorModalOpen, setEditQuantityStepTwoErrorModalOpen] = useState(false);

  const [isEditingQuantity, setIsEditingQuantity] = useState(false);
  let EditedColumnName = ["Plan", "SKU", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity", "Sub-Total Price"];
  const [columnsEditQuantity, setColumnsEditQuantity] = useState([]);
  const [editTable, setEditTable] = useState([]);
  const [poNumber, setPONumber] = useState("");
  const [planId, setPlanId] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState(null);
  const editQuantityRef = useRef();
  const [addOnTable, setAddOnTable] = useState([])
  const [purchaseAddonsSelectedData, setPurchaseAddonsSelectedData] = useState();
  const [emptyAddOnsFlag, setEmptyAddOnsFlag] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedAddOnRows, setSelectedAddOnRows] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [noAddons, setNoAddons] = useState(false); // No Purchase addon available

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // To Increase Edited Quantity
  const handleIncrease = (qty) => {
    if (newQuantity <= maxQuantity) {
      setNewQuantity(parseInt(qty) + 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  // To Decrease Edited Quantity
  const handleDecrease = (qty) => {
    if (newQuantity >= minQuantity) {
      setNewQuantity(parseInt(qty) - 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  // accordion
  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <span
        className='pt-1 align-content-center'
        onClick={decoratedOnClick}
      >{children}
        {isCurrentEventKey ? (
          <span className='up-accordion' />
        ) : (
          <span className='down-accordion' />
        )}
      </span>
    );
  }

  //edit quantity save and close action core tab
  const handleEditQuantityClose = () => {
    setNewQuantity(subscriptionTableAPIData.quantity);
    setIsEditingQuantity(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // POST Updated ADD-ON Quantity
  async function updateAddOns() {
    setConfirmUpgradeLoadingFlag(true);

    let requestBody = {
      "subscriptionId": subscriptionId,
      "customerId": customerId,
      "poNumber": poNumber,
      "addonPlans": [{
        "planId": planId,
        "quantity": newQuantity,
        "licenseAutoRenewal": true
      }]

    };

    await api.post(GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_POST, requestBody, config)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmUpgradeLoadingFlag(false);
          setToastMessage(PurchaseAddonSuccessToast);
          setToastSuccess(true);
          setSelectedAddOn("");
          setNewQuantity(null);
          setIsEditingQuantity(false);
          setEditQuantityStepTwoErrorModalOpen(false);
          setPurchaseAddOnsActionPopupModalOpen(false);
          setEditQuantityStepTwo(false);
          setActionCompletedRefreshSubscriptionTable(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
          setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);

        }
      })
      .catch(error => {
        setConfirmUpgradeLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setToastError(true);
        setToastMessage(PurchaseAddonErrorToast);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
        setIsActionInProgress(true);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(false);
      })
  }

  // Update Edit Quantity - put request
  async function GetPurchaseAddonsSubscriptions() {
    await api.get(`${GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_GET}?subscriptionId=${subscriptionId}&searchText=""`, config)
      .then(resp => {
        if (resp.status == 200) {
          setEmptyAddOnsFlag(false);
          setNoAddons(false);
          setAddOnTable(resp.data.content);
          let f = [];
          (resp.data.content).map((td) => {
            f.push
              (
                {
                  "Plan": td.planName,
                  "SKU": td.planCode,
                  "Quantity": <div className='d-flex flex-wrap my-2 align-items-center'>
                    <input type='number' defaultValue={td.quntity} value={newQuantity} onChange={e => setNewQuantity(e.target.value)} />
                  </div>,
                  "Billing Term": td.billingTerm,
                  "Min": td.minQuantity,
                  "Max": td.maxQuantity,
                  "RRP (Inc GST)": td.rrp,
                  "Reseller Price (Ex GST)": td.price,
                  "Sub-Total Price": td.quantity * td.price,
                }
              );
          })
          setTimeout(() => {
            setEditTable(f);         // Setting Table Data
          }, 50);
          let d = []
          EditedColumnName.map((td) => {
            if (td === "Sub-Total Price" || td === "RRP (Inc GST)" || td === "Reseller Price (Ex GST)") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                right: true,
                grow: 1.5,
              })
            }
            else if (td === "Billing Term") {
              d.push({
                id: `${td}`,
                name: <div>{td}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                  >
                    <span className="infoIcon-support-table ml-1"></span>
                  </OverlayTrigger></div>,
                selector: row => <div className="text-wrap">
                  {row[`${td}`]}</div>,
                left: true,
                grow: 3,
                allowOverflow: true,
              })
            }
            else {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
              })
            }
          })
          setColumnsEditQuantity(d);     // Setting Column Data  
        }
        else {
          setNoAddons(true);
          setEmptyAddOnsFlag(true);
        }
      })
      .catch(error => {
        setEmptyAddOnsFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setNoAddons(false);
      })
  }


  useEffect(() => {
    GetPurchaseAddonsSubscriptions();
  }, []);

  // React Data Table Custom Theme
  createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

  // React Data Table Custom Styles
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for data cells
        paddingRight: '1rem',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for head cells
        paddingRight: '1rem',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: BACKGROUND_COLOR_SECONDARY,
        fontWeight: '500',
        fontSize: '0.875rem',
        color: `${TEXT_SECONDARY} !important`,
        textTransform: 'capitalize',
      },
    },
    expanderButton: {
      style: {
        '&:hover:enabled': {
          cursor: 'pointer',
        },
        '&:disabled svg path': {
          color: DISABLE_EXPAND_BUTTON_COLOR,
        },
        svg: {
          margin: 'auto',
        },
      },
    },
  };

  let updatedSelectedPlan;

  useState(() => {
    setSelectedPlan(prevState => {
      const updatedAddOnTable = [
        ...prevState.slice(0, 1), // Add object at index 1 (between index 0 and 1)
        updatedSelectedPlan,
        ...prevState.slice(1) // Add the rest of the objects after the new one
      ];
      return updatedAddOnTable;
    });
  }, [editQuantityStepTwo]);

  return (
    <>
      {/* Edit Quantity Error Modal */}
      <Popup
        open={editQuantityStepTwoErrorModalOpen}
        onClose={() => { setEditQuantityStepTwoErrorModalOpen(false) }}
        className="custom-modal custom-modal-md custom-modal-ticket-details"
        modal
        nested
      >
        {(close) => (
          <div className="modal">

            <div className="header">
              <h2>Order Failed</h2>
              <XLg
                size={24}
                className="cpointer close-icon-popup"
                onClick={() => { if (!confirmUpgradeLoadingFlag) close() }}
              />
            </div>
            <Container fluid>
              <Row className="pb-3 pt-5">
                <Col md={12} className="text-center my-4">
                  <img src={failureImg} alt="Edit Quantity Failure" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className="red text-strong text-center">{PurchaseAddonQuantityError}</p>
                </Col>

                <Col md={12}>
                  <p>{PurchaseAddonSeatsError}</p>
                </Col>
              </Row>

              <Row className="mt-2 mb-4">

                <Col md={12}>
                  <div className="btn-wrapper text-center">

                    <Button
                      className="px-4 mx-2"
                      onClick={() => { handleEditQuantityClose(); setEditQuantityStepTwoErrorModalOpen(false); }}>
                      Ok
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

        )}
      </Popup>

      <Popup
        className="custom-modal custom-modal-xl"
        open={purchaseAddOnsActionPopupModalOpen}
        onClose={() => { setPurchaseAddOnsActionPopupModalOpen(false); setEditQuantityStepTwo(false); setNewQuantity(null); setIsEditingQuantity(false); setSubscriptionTableAPIData(null); }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            {!subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2>{editQuantityStepTwo ? "Order Confirmation - Purchase Addons" : "Purchase Addons"}</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={() => { if (!confirmUpgradeLoadingFlag) close() }}
                    color={TEXT_SECONDARY}
                  />
                </div>
                <Container className={`action-modal-height ${styles['modal-width']}`}>
                  <Accordion defaultActiveKey="1">
                    <Card className={`${styles['accordion-upgradeGWS']}`}>
                      <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                        <Col md={7} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                        </Col>
                        <Col md={4} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                        </Col>
                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                      </Row>

                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                            {tableSubscriptionTable &&
                              <DataTable
                                columns={columnsSubscriptionTable}
                                data={tableSubscriptionTable}
                                theme="solarized"
                                customStyles={customStyles}
                                persistTableHead={false}
                                noDataComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                width="100%"
                                fixedHeaderScrollHeight="31vh" progressPending={loading}
                                progressComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                fixedHeader
                              />
                            }
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {editQuantityStepTwo ? (
                    <div className={`${styles['gws-edit-quantity-table-order-confirmation']} mt-4`}>
                      <div className='table-responsive col-12 my-3'>
                        <table className={`table ${styles['gws-purchase-addon-table']}`}>
                          <thead className="sticky-top">
                            <th><span>Plan</span></th>
                            <th><span>Billing Term<OverlayTrigger
                              placement="top"
                              overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                              <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                            </OverlayTrigger></span></th>

                            <th className="text-right"><span>Reseller Price (Ex GST)</span></th>
                            <th className="text-right"><span>Renewal Price (Ex GST)*</span></th>
                            <th className="text-center"><span>Quantity</span></th>
                            <th className="text-right"><span>Sub-Total Price</span></th>
                          </thead>
                          <tbody>
                            {(selectedPlan && selectedPlan.length > 0) &&
                              selectedPlan.map((td, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td><span>{td.planName}</span></td>
                                      <td><span>{td.billingTerm}</span></td>
                                      <td className="text-right"><span>${td.price}</span></td>
                                      <td className="text-right"><span>${td.rrpExclusiveTax}</span></td>
                                      <td className="text-right">
                                        <div className={styles['qty-wrapper']}>
                                          {selectedAddOn != td.planId &&
                                            <div className={`${styles['show-qty-btn-wrapper']}`} onClick={(e) => {

                                              e.preventDefault();
                                              setPlanId(td.planId);
                                              setNewQuantity(newQuantity);
                                              setSelectedAddOn(e.target.id);
                                              setActiveQtyCheckFlag(true);

                                            }}>
                                              <label
                                                id={td.planId}
                                                className={`${styles['show-qty-btn']}`}
                                              >{td.quantity}</label>

                                              <div className={`${styles['btn-wrapper']}`}>
                                                <button className={`${styles['plus']}`}
                                                  id={td.planId}
                                                >+</button>
                                                <button className={`${styles['minus']}`}
                                                  id={td.planId}
                                                >
                                                  <span>&nbsp;</span>
                                                </button>
                                              </div>
                                            </div>
                                          }

                                          {(selectedAddOn == td.planId) &&
                                            <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                              <>
                                                <div class="number-input">
                                                  <input
                                                    type="number"
                                                    id={td.planId}
                                                    className="no-arrows"
                                                    defaultValue={parseInt(td.quantity)}
                                                    value={parseInt(newQuantity)}
                                                    min={td.minQuantity}
                                                    max={td.maxQuantity}
                                                    onChange={(e) => {
                                                      setNewQuantity(e.target.value);

                                                      if ((e.target.value) > td.maxQuantity) {
                                                        setNewQuantity(td.maxQuantity)
                                                      }

                                                      if ((e.target.value) < td.minQuantity) {
                                                        setNewQuantity(td.minQuantity)
                                                      }

                                                      if ((e.target.value) === parseInt(td.quantity)) {
                                                        setNewQuantity(parseInt(td.quantity))
                                                      }

                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.code === 'Minus') {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                  />

                                                  <div className="btn-wrapper">
                                                    {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                      <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                                      }>+</button> :
                                                      <button className="plus disabled" disabled>+</button>
                                                    }

                                                    {parseInt(newQuantity) > td.minQuantity ?
                                                      <button className="minus" onClick={
                                                        () => handleDecrease(parseInt(newQuantity))
                                                      }> <span>&nbsp;</span> </button>
                                                      :
                                                      <button className="minus disabled" disabled>
                                                        <span>&nbsp;</span>
                                                      </button>}

                                                  </div>

                                                </div>
                                                {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                  <div className='mx-1'
                                                    onClick={(e) => {
                                                      td.quantity = parseInt(newQuantity);

                                                      setSelectedRowId([...selectedRowId, td.planId]);
                                                      setSelectedAddOnRows([...selectedAddOnRows, td]);
                                                      // console.log("selectedPlan", selectedPlan);
                                                      setNewQuantity(parseInt(td.quantity));
                                                      setPlanId(td.planId);
                                                      setSelectedAddOn("");
                                                      setActiveQtyCheckFlag(!activeQtyCheckFlag);

                                                      // Update Quantity Corresponding in Main Table if we change here
                                                      const indexToUpdate = addOnTable.findIndex(item => item.planId === td.planId);
                                                      const updatedProductDetails = [...addOnTable];

                                                      // Update the quantity of the object at the specified index
                                                      updatedProductDetails[indexToUpdate] = {
                                                        ...updatedProductDetails[indexToUpdate],
                                                        quantity: newQuantity// Update the quantity to 4
                                                      };

                                                      setAddOnTable(updatedProductDetails);
                                                    }
                                                    }>
                                                    <span className="quantity-tick" />
                                                  </div>
                                                }

                                                {parseInt(newQuantity) == parseInt(td.quantity) &&
                                                  <div className='mx-1'>
                                                    <span className="tick-grey" />
                                                  </div>
                                                }


                                                <div className='mx-1' onClick={(e) => {
                                                  setNewQuantity(parseInt(td.quantity));
                                                  setPlanId(null);
                                                  setSelectedAddOn("");
                                                  setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                }
                                                }>
                                                  <span className="quantity-cross">&nbsp;</span>
                                                </div>
                                              </>
                                            </div>

                                          }

                                        </div>

                                      </td>
                                      <td className="text-right">
                                        <span>
                                          {
                                            (selectedAddOn == td.planId) ?
                                              "$" + Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100 :
                                              "$" + Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100
                                          }

                                        </span>
                                      </td>
                                    </tr>

                                  </>
                                )
                              })
                            }


                            {selectedPlan.length < 1 &&
                              <tr>
                                <td colSpan={8} className="text-center">
                                  <p className="mb-0 py-3">{GenericCloudProvisionEmptyPlan}</p>
                                </td>
                              </tr>
                            }

                          </tbody>
                        </table>
                      </div>

                      <p className='mt-4'><span className='text-strong'>Note* :</span> {GWSUpgardeNote}</p>
                      <Row>
                        <Col>
                          <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                          <Form.Control
                            type="text"
                            className="w-auto d-inline ml-3"
                            id="inputPONumber"
                            aria-describedby="inputPONumber"
                            placeholder='Add PO Number'
                            value={poNumber}
                            maxlength="50"
                            onChange={(e) => {
                              alphanumericRegex.test(e.target.value)
                                ? setPONumber(e.target.value)
                                : setPONumber(poNumber)// console.log("Error")
                            }}
                          />
                          <Form.Text id="poNumber" />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6}>

                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="termsCheckBox"
                            label=""
                            disabled
                            checked={isTermsChecked}
                            className="d-inline-block w-auto"
                          />
                          <p className="d-inline-block  w-auto mb-0">
                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                          </p>

                        </Col>
                        <Col md={6}>
                          <div className="btn-wrapper text-right d-md-block d-none mb-1">
                            {isTermsChecked &&
                              <>
                                {!confirmUpgradeLoadingFlag && <Button
                                  className="px-4 mx-2 btn-border-light"
                                  variant="light"
                                  onClick={() => {
                                    setEditQuantityStepTwo(false);

                                    setPONumber("");
                                  }}>Back
                                </Button>}
                                {confirmUpgradeLoadingFlag && <Button
                                  className="px-4 mx-2"
                                  disabled
                                >Back</Button>}
                                {!confirmUpgradeLoadingFlag && <Button
                                  className="px-4 mx-2"
                                  onClick={updateAddOns}>
                                  Confirm
                                </Button>}
                                {confirmUpgradeLoadingFlag &&
                                  <Button
                                    disabled
                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                  >
                                    Processing . . .
                                  </Button>}
                              </>
                            }
                            {!isTermsChecked && editQuantityStepTwo &&
                              <>
                                <Button
                                  className="px-4 mx-2 btn-border-light"
                                  variant="light"
                                  onClick={() => {
                                    setEditQuantityStepTwo(false);

                                  }}>Back
                                </Button>
                                <Button
                                  variant="light"
                                  className="px-4 mx-2"
                                  disabled
                                >
                                  Confirm
                                </Button>
                              </>
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    // Content when setEditQuantityStepTwo is false
                    <>
                      {!noAddons &&
                        <div className='table-responsive col-12 my-3'>
                          {
                            addOnTable.length == 0 &&
                            <div className='text-center'>
                              <div className={styles["no-data-user"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans...</p></div>
                            </div>
                          }
                          {
                            addOnTable.length > 0 &&
                            <table className={`table ${styles['gws-purchase-addon-table']}`}>
                              <thead className="sticky-top">
                                <th><span>Plan</span></th>
                                <th><span>SKU</span></th>
                                <th><span>Billing Term<OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                  <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                </OverlayTrigger></span></th>
                                <th className="text-right"><span>RRP (Inc GST)</span></th>
                                <th className="text-right"><span>Reseller Price (Ex GST)</span></th>
                                <th className="text-center"><span>Quantity</span></th>
                                <th className="text-right"><span>Sub-Total Price</span></th>
                              </thead>
                              <tbody>

                                {
                                  addOnTable.map((td, index) => {
                                    return (
                                      <tr key={index} id={'row-' + index} className={selectedRowId.includes(td.planId) ? styles["selected-row"] : ""}>
                                        <td className={styles['small-width']}>{td.planName}</td>
                                        <td >{td.planCode}</td>
                                        <td className={styles['big-width']}>{td.billingTerm}</td>
                                        <td className='text-right'>${td.rrp}</td>
                                        <td className='text-right'>${td.price}</td>
                                        <td className={`text-right ${styles['big-width']}`}>

                                          <div className={styles['qty-wrapper']}>
                                            {selectedAddOn != td.planId &&
                                              <div className={`${styles['show-qty-btn-wrapper']}`}>
                                                <label
                                                  id={td.planId}
                                                  className={`${styles['show-qty-btn']}`}
                                                  onClick={(e) => {
                                                    if (!activeQtyCheckFlag) {
                                                      e.preventDefault();
                                                      setPlanId(td.planId);
                                                      setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                      setSelectedAddOn(e.target.id);
                                                      setActiveQtyCheckFlag(true);
                                                    }
                                                  }
                                                  }>{td.quantity}</label>

                                                <div className={`${styles['btn-wrapper']}`}>
                                                  <button className={`${styles['plus']}`}
                                                    id={td.planId}
                                                    onClick={(e) => {
                                                      if (!activeQtyCheckFlag) {
                                                        e.preventDefault();
                                                        setPlanId(td.planId);
                                                        setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                        setSelectedAddOn(e.target.id);
                                                        setActiveQtyCheckFlag(true);
                                                      }
                                                    }
                                                    }
                                                  >+</button>
                                                  <button className={`${styles['minus']}`} disabled="">
                                                    <span>&nbsp;</span>
                                                  </button>
                                                </div>
                                              </div>
                                            }

                                            {(selectedAddOn == td.planId) &&
                                              <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                                <>
                                                  <div className="number-input">
                                                    <input
                                                      type="number"
                                                      id={td.planId}
                                                      className="no-arrows"
                                                      defaultValue={td.quantity}
                                                      value={parseInt(newQuantity)}
                                                      min={td.minQuantity}
                                                      max={td.maxQuantity}
                                                      onChange={(e) => {
                                                        setNewQuantity(e.target.value);

                                                        if ((e.target.value) > td.maxQuantity) {
                                                          setNewQuantity(td.maxQuantity)
                                                        }

                                                        if ((e.target.value) < td.minQuantity) {
                                                          setNewQuantity(td.minQuantity)
                                                        }

                                                        if ((e.target.value) === td.quantity) {
                                                          setNewQuantity(td.quantity)
                                                        }

                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.code === 'Minus') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                    />

                                                    <div className="btn-wrapper">
                                                      {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                        <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                                        }>+</button> :
                                                        <button className="plus disabled" disabled>+</button>
                                                      }

                                                      {parseInt(newQuantity) > td.minQuantity ?
                                                        <button className="minus" onClick={
                                                          () => handleDecrease(parseInt(newQuantity))
                                                        }> <span>&nbsp;</span> </button>
                                                        :
                                                        <button className="minus disabled" disabled>
                                                          <span>&nbsp;</span>
                                                        </button>}

                                                    </div>

                                                  </div>
                                                  {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                    <div className='mx-1'
                                                      onClick={(e) => {
                                                        td.quantity = parseInt(newQuantity);
                                                        setSelectedRowId([td.planId]);
                                                        setSelectedAddOnRows([td]);
                                                        // console.log("selectedAddOnRows", selectedAddOnRows);
                                                        setSelectedPlan([]);

                                                        const updatedSelectedPlan = {
                                                          planName: td.planName,
                                                          quantity: newQuantity,
                                                          billingTerm: td.billingTerm,
                                                          commitmentTerm: td.commitmentTerm,
                                                          subscriptionType: td.subscriptionType,
                                                          startDate: td.startDate,
                                                          renewalDate: td.renewalDate,
                                                          status: td.status,
                                                          subscriptionId: td.subscriptionId,
                                                          assignedSeat: td.assignedSeat,
                                                          vendorSubscriptionId: td.vendorSubscriptionId,
                                                          planId: td.planId,
                                                          minQuantity: td.minQuantity,
                                                          maxQuantity: td.maxQuantity,
                                                          price: td.price,
                                                          rrp: td.rrp,
                                                          rrpExclusiveTax:td.rrpExclusiveTax,
                                                          autoRenew: td.autoRenew,
                                                          planCode: td.planCode,
                                                          planType: td.planType,
                                                          parentSubscriptionId: td.parentSubscriptionId,
                                                          trailEndDate: td.trailEndDate,
                                                          isTrial: td.isTrial,
                                                          isSuspendReasonFlag: td.isSuspendReasonFlag,
                                                          actions: td.actions
                                                        };

                                                        setSelectedPlan([updatedSelectedPlan]);
                                                        setEditQuantityStepTwo(true);
                                                        setPlanId(td.planId);
                                                        setSelectedAddOn("");
                                                        setActiveQtyCheckFlag(!activeQtyCheckFlag);

                                                        setAddOnTable(addOnTable.map((plan) => {
                                                          if (plan.planId === td.planId) {
                                                            return { ...plan, quantity: newQuantity };
                                                          } else {
                                                            return { ...plan, quantity: 0 };
                                                          }
                                                        }));
                                                      }
                                                      }>
                                                      <span className="quantity-tick" />
                                                    </div>
                                                  }

                                                  {parseInt(newQuantity) == td.quantity &&
                                                    <div className='mx-1'>
                                                      <span className="tick-grey" />
                                                    </div>
                                                  }

                                                  <div className='mx-1' onClick={(e) => {
                                                    setNewQuantity(td.quantity);
                                                    setPlanId(null);
                                                    setSelectedAddOn("");
                                                    setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                  }
                                                  }>
                                                    <span className="quantity-cross">&nbsp;</span>
                                                  </div>
                                                </>
                                              </div>
                                            }
                                          </div>
                                        </td>

                                        <td className='text-right'>
                                          <span>

                                            {
                                              (selectedAddOn == td.planId) ?
                                                "$" + Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100 :
                                                "$" + Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100
                                            }

                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }

                              </tbody>
                            </table>
                          }
                        </div>
                      }

                      {noAddons &&
                        <div className={`py-5 pb-5 ${styles["gws-edit-quantity-table-order-confirmation"]}`}>
                          <p className='text-center'>{GWSEmptyAddonError}</p>
                        </div>
                      }

                    </>
                  )}
                </Container>
              </>
            }
            {
              subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2 className="mb-0">Edit Quantity</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color={TEXT_SECONDARY}
                  />
                </div>
                <Container>
                  <div className="empty-subscription-detail">
                    <center><h2> Loading . . .</h2></center>
                  </div>
                </Container>
              </>
            }
          </div>
        )}
      </Popup>
    </>
  )
}

export default GWSPurchaseAddOns