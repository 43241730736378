import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Accordion, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from '../../../../../../../GlobalComponents/Toast';
import { api } from '../../../../../../../Utils/API';
import { CSP_CHECK_DOMAIN_AVAILABILITY, CSP_CHECK_TENANT_AVAILABILITY, CSP_DOMAIN_TRANSITION, CSP_GET_GDAP_DETAILS, CSP_MCA_AGREEMENT_DETAILS, CSP_GDAP_TRANSITION_STATUS, CSP_TRANSITION_ONBOARD_STATUS, MCA_AGREEMENT_DOWNLOAD, FRONTEND_LOGS } from '../../../../../../../Utils/GlobalConstants';
import { alphanumericRegex, emailRegEx, phoneRegex } from '../../../../../../../GlobalComponents/RegExPatterns';
import DomainCheck from './LocalComponents/DomainCheck';
import TransitionDomain from './LocalComponents/TransitionDomain';
import MCAAgreement from './LocalComponents/MCAAgreement';
import GDAPAssignment from './LocalComponents/GDPADetails';
import BuyDomainSuccess from './LocalComponents/BuyDomain';
import BuyDomainFailed from './LocalComponents/BuyDomainFailed';
import { TOAST_SUCCESS_ICON_COLOR } from '../../../../../../../Utils/ColorsConfiguration';
import { CustomerDomainError, DomainNameError, MicrosoftInvalidId } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { CspCustomerPrerequisteNote } from '../../../../../../../Utils/StaticMessages/NotesDisclaimers';

const CSPCustomerPrerequisite = (props) => {
  const location = useLocation();                                             // get Location
  const navigate = useNavigate();                                             // to use navigate function
  const domainPattern = /^(.*)\.onmicrosoft\.com$/;                           // Domain Pattern to check "onmicrosoft.com"
  const fullDomainPatern = /^[a-zA-Z0-9]+\.onmicrosoft\.com$/                 // Domain Pattern to check Alpha Numeric.
  const tenantIdRegex = /^[a-zA-Z0-9\-]{36}$/;                                // Tenant ID Validation 36 Letters and atleast one hyphen

  const newCustomerOnBoard = "New Customer (Tenant) Creation";  // New Customer (Tenant) Creation Option
  const transitionDomain = "Existing Customer (Tenant) Onboarding";              // Existing Customer (Tenant) Onboarding Domain Option

  const [updatedToastMessage, setUpdatedToastMessage] = useState(false);      // To check Toast Message is active
  const [toastMessage, setToastMessage] = useState("");                       // Set Toast Message


  const [expandedAll, setExpandedAll] = useState(false);                      // Expand All Accordions
  const [activeAccordion, setActiveAccordion] = useState(location.state?.activeAccordion ? location.state?.activeAccordion : ["domainCheck"]);              // Default Active Accordion Id is "0"
  const [isDomainBuySuccess, setIsDomainBuySuccess] = useState(false);          // Display Success Message

  const [customerData, setCustomerData] = useState([]);                               // data in status dropdown
  const [submittingMCA, setSubmittingMCA] = useState(false);
  const [isMCACompleted, setIsMCACompleted] = useState(false);
  const [isDomainProvisioned, setIsDomainProvisioned] = useState(location.state?.isDomainProvisioned ? location.state?.isDomainProvisioned : false);

  // Search Value for Customer Name From DropDown
  const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState(location.state !== null ? location.state.customerName : "");

  // Check Domain status including checking progress status and errors from Front end.
  const [isCheckingDomain, setIsCheckingDomain] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const [domainCheckBlockErrors, setDomainCheckBlockErrors] = useState(false);
  const [transitionErrorBlock, setTransitionErrorBlock] = useState(false);
  const [isCompletinTtansaction, setIsCompletinTtansaction] = useState(false);
  const [transitionErrorMessage, setTransitionErrorMessage] = useState("");

  // Header Authorization for uploading file API
  const configFile = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Header Authorization for Download PDF API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };


  // Initial Status of Form and Buttons and also used as Reset Values
  const initStatus = {
    domainName: "",
    updatedDomainName: "",
    isCheckDomain: false,
    domainValidationFE: {
      isValid: "",
      message: CustomerDomainError
    },

    isDomainType: {
      isDomainTransition: false,
      isDomainProceed: location.state?.isDomainProvisioned ? location.state?.isDomainProvisioned : false,
    },

    domainCheckStatusBE: {
      isExistsInSynnex: "",
      isSuccess: "",
      message: "",
      tenantId: ""
    },

    btnChecks: {
      btnCheckAvailability: false,
      btnClear: false,
      btnProceed: false,
      btnBuySubscription: false,
      btnTransition: false,
      btnGDAP: false
    },

    stepsCompletedCheck: {
      isDomainCheckCompleted: location.state?.isDomainProvisioned ? location.state?.isDomainProvisioned : false, // Domain check completed to Buy or Transition
      isDomainTransitionCompleted: false, // Domain Transition status
      isMCACompleted: false,  // MCA Agreement completed
      isGDAPCompleted: false, // GDAP completed
      isDomainFlowCompleted: false, // Domain (Buy or Transition) Flow Completed
    },

    mcaAgreementDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      mcaAcceptanceDate: "",
      customerCode: "",
      domainName: ""
    },

    transitionDomainChecks: {
      organizationProfileChecked: false,
      acceptInvitationChecked: false
    },

    getDomainTrasnitionUri: {
      microsoftCspUri: "",
      tenantUri: ""
    },
    gdapDetails: {
      gdapUri: "",
      delegateAdminRelationshipId: "",
      message: "",
      isSuccess: true,
      gdapStatus: "",
      domainEnable: true,
      assignedRoles: ""
    },
    onBoardDetails: {
      status: "",
      message: "",
      gdapUrl: "",
      delegateRelationshipId: "",
      assignedRoles: ""
    },
    delegateAdminRelationshipApproved: ""
  };

  // GDAP Roles Assignment and Delegation Relationship status
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [delegateAdminRelationshipId, setDelegateAdminRelationshipId] = useState(null);
  const [gdapURI, setGDAPUri] = useState(null);

  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);  // Check all steps are completed or not

  const [domainName, setDomainName] = useState(location.state?.domainName ? location.state?.domainName : initStatus.domainName);                                  // Domain Name
  const [domainCheckFE, setDomainCheckFE] = useState(initStatus.domainValidationFE);                    // Domain validations from Front End
  const [isDomainAvailable, setIsDomainAvailable] = useState(initStatus.domainCheckStatusBE);           // Checks the domain availability
  const [isDomainType, setIsDomainType] = useState(initStatus.isDomainType);                            // Proceed Domain or Transition Domain
  const [btnChecks, setBtnChecks] = useState(initStatus.btnChecks);                                     // Keeps all button states
  const [stepsCompletedCheck, setStepsCompletedCheck] = useState(initStatus.stepsCompletedCheck);       // Check all steps
  const [delegateAdminRelationshipApproved, setDelegateAdminRelationshipApproved] = useState(null);       // Get Delegate Admin Details
  const [mcaAgreementDetails, setMCAAgreementDetails] = useState(initStatus.mcaAgreementDetails);        // MCA Agreement Form
  const [customerId, setCustomerId] = useState(location.state?.customerId);
  const [isMCATermsAgreed, setIsMCATermsAgreed] = useState(false);          // Check all MCA Terms agreed or not
  const [ismcaFormHasNoErros, setIsmcaFormHasNoErros] = useState(false);    // MCA Form Errors 
  const [domainChecked, setDomainChecked] = useState(false);                // If it's true disable Enter Domain Input Field      
  const [integrationCode, setIntegrationCode] = useState(location.state?.integrationCode || "");  // Used to check the service Type microsoftsaas or google workspace
  const [microsoftID, setMicrosoftID] = useState();  // Microsoft Tenent ID from Partner Center 
  const [domainError, setDomainError] = useState(null);
  const [selectDomainCheckOption, setSelectDomainCheckOption] = useState("New Customer (Tenant) Creation");
  // MCA Form Errors
  const [mcaFormErrors, setMCAFormErrors] = useState({
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    phoneNumberError: null,
    dateError: null
  });

  const [validDate, setValidDate] = useState(false);    // MCA Date Validation
  const [isGDAPTermsChecked, setIsGDAPTermsChecked] = useState(false);  // GDAP Terms Agreed check box
  const [mcaErrorMessage, setMCAErrorMessage] = useState();   // Dispay Error message if MCA Submission fails
  const [mcaErrorMessageFlag, setMCAErrorMessageFlag] = useState(false);   // Dispay Error flag MCA Submission fails

  const defaultServiceName = integrationCode == "microsoftazure" ? "Microsoft Azure" : "Microsoft CSP"; // Contact support form default Service values
  const defaultCategoryName = "Cloud Technical Support";  // Contact support form default Category

  // Transition Domain Checks
  const [transitionDomainChecks, setTransitionDomainChecks] = useState(initStatus.transitionDomainChecks);
  const [getDomainTrasnitionUri, setGetDomainTrasnitionUri] = useState(initStatus.getDomainTrasnitionUri);
  const [gdapDetails, setGDAPDetails] = useState(initStatus.gdapDetails);
  const [onBoardDetails, setOnBoardDetails] = useState(initStatus.onBoardDetails); // GET GDAP onboard Status
  // const [intervalId, setIntervalId] = useState(null);
  const [serviceId, setServiceId] = useState(location.state.serviceId ? location.state.serviceId : null);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Check Domain Availability API
  async function getDomainAvailability(e) {
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsCheckingDomain(true);

    e.preventDefault();

    setDomainCheckFE({
      ...domainCheckFE,
      isValid: true
    });

    await api.get(`${CSP_CHECK_DOMAIN_AVAILABILITY}?domainName=${domainName}&isTransitionCustomerFlag=${selectDomainCheckOption == transitionDomain}`, config)
      .then(resp => {
        setIsDomainAvailable(resp.data);
        setMicrosoftID(resp?.data?.tenantId);
        if (resp.status == 200) {
          if (resp.data.isSuccess === false && resp.data.isExistsInSynnex === false) {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: true,
              isDomainProceed: false
            });
            setDomainChecked(true);
          } else if (resp.data.isSuccess === true && resp.data.isExistsInSynnex === false) {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: false,
              isDomainProceed: true
            });
            setDomainChecked(true);
          } else {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: false,
              isDomainProceed: false
            })
          }
        }
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });


    setIsCheckingDomain(false);
  }

  // Check Tenant Availability
  async function getTenantAvailability(e) {
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsCheckingDomain(true);

    e.preventDefault();

    setDomainCheckFE({
      ...domainCheckFE,
      isValid: true
    });

    if (tenantIdRegex.test(microsoftID)) {
      setDomainError(null);
      await api.get(`${CSP_CHECK_TENANT_AVAILABILITY}?tenantId=${microsoftID}`, config)
        .then(resp => {
          setIsDomainAvailable(resp.data);

          if (resp.status == 200) {
            if (resp.data.isSuccess === true) {
              setIsDomainType({
                ...isDomainType,
                isDomainTransition: true,
                isDomainProceed: false
              });
              setDomainChecked(true);
            } else {
              setIsDomainType({
                ...isDomainType,
                isDomainTransition: false,
                isDomainProceed: false
              })
            }
          }
        })
        .catch(error => {

          if (error?.status == 401 || error?.response?.status == 401) {
            props.Logout();
          }

        });
    } else {
      setDomainError(MicrosoftInvalidId);
    }


    setIsCheckingDomain(false);
  }

  // GET MCA Agreement Details
  async function GetMCADetails() {

    await api.get(`${CSP_MCA_AGREEMENT_DETAILS}?customerCode=${customerId}`, config)
      .then(resp => {


        setMCAAgreementDetails(resp.data);
        // setIsMCACompleted(resp.data.)
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });
  }

  // Download MCA Agreement
  const [mcaDisplayUri, setMCAdisplayUri] = useState("");
  const [mcaDownloadUri, setMCADownloadUri] = useState("");


  // GET API to check MCA Agreement
  async function GetMCAAgreement() {

    await api.get(`${MCA_AGREEMENT_DOWNLOAD}`, config)
      .then(resp => {
        setMCAdisplayUri(resp.data.displayUri);
        setMCADownloadUri(resp.data.displayUri);
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });
  }

  // Call MCA Agreement Details on initial page load
  useEffect(() => {
    GetMCAAgreement();
  }, [])

  // Download File using Js blobs

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }


  // Download Function for MCA Agreement
  async function DownloadMCAAgreement(url, name, fileType) {
    let configuration;
    if (fileType === "pdf") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 200) {

          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });

        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });
  };




  // Check Domain Availability
  const checkDomainAvailability = (e) => {
    if (selectDomainCheckOption == "New Customer (Tenant) Creation") {
      if (alphanumericRegex.test(e.target.value)) {
        setDomainName(e.target.value);
        setDomainError(null);
      } else {
        setDomainName(e.target.value);
        setDomainError(DomainNameError);
      }
    } else {
      setMicrosoftID(e.target.value);
      setDomainError(null);
    }

    if (e.target.value.length > 0) {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: true,
        btnClear: true
      })
    } else {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: false,
        btnBuySubscription: false,
        btnClear: false
      })
    }
  }


  // Reset all to Initial state
  const clearDomainCheck = () => {
    setDomainError("");
    setSelectDomainCheckOption(newCustomerOnBoard)
    setDomainCheckFE(initStatus.domainValidationFE);
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsDomainType(initStatus.isDomainType);
    setDomainChecked(false);
    setBtnChecks(initStatus.btnChecks);
    setIsCheckingDomain(false);
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: false,
      isDomainTransitionCompleted: false,
      isMCACompleted: false,
      isGDAPCompleted: false,
      isDomainFlowCompleted: false,
    });
    setDomainName(initStatus.domainName);
    setMicrosoftID("");
    setMCAAgreementDetails(initStatus.mcaAgreementDetails);
    setIsMCATermsAgreed(false);
    setTransitionDomainChecks(initStatus.transitionDomainChecks);
    setGetDomainTrasnitionUri(initStatus.getDomainTrasnitionUri);
    setMCAFormErrors({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneNumberError: null,
      dateError: null
    });
    setValidDate(false);
    setMCAErrorMessage("");
    setMCAErrorMessageFlag(false);
    setTransitionErrorBlock(false);
  }


  // Proceed Flow 
  const proceedDomainFlow = () => {
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: true
    });

    setActiveAccordion(["mcaDetails"]);


  }

  // Transition Flow
  async function getTransitionDomain() {
    setSelectDomainCheckOption(transitionDomain);

    await api.get(CSP_DOMAIN_TRANSITION, config)
      .then(resp => {

        if (resp.status == 200) {
          setGetDomainTrasnitionUri(resp.data);
          setTransitionErrorBlock(false);
          setGetDomainTrasnitionUri({
            ...getDomainTrasnitionUri,
            microsoftCspUri: resp.data.microsoftCspUri,
            tenantUri: resp.data.tenantUri

          });
        }

        if (resp.status == 204) {
          setTransitionErrorBlock(true);
        }
      })
      .catch(error => {

        setTransitionErrorBlock(true);
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      })
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: true
    });

    setActiveAccordion(["transitionDomain"]);

  }

  // function submitMCAForm() {
  //   let mcaFormValid = true;


  //   if (!emailRegEx.test(mcaAgreementDetails.email)) {
  //     setMCAFormErrors({
  //       ...mcaFormErrors,
  //       emailError: true
  //     });
  //     mcaFormValid = false
  //   } else {
  //     setMCAFormErrors({
  //       ...mcaFormErrors,
  //       emailError: false
  //     });

  //     mcaFormValid = true
  //   }

  //   if (mcaFormValid) {
  //     saveMCADetails()
  //   }
  // }

  // Post MCA Details 
  async function auditSubmitMCAForm(message) {
    let body = {
      auditType: `${FRONTEND_LOGS}`,
      comments: message,
      auditLog: `${mcaAgreementDetails.firstName} + ${mcaAgreementDetails.lastName} + ${mcaAgreementDetails.email} + ${mcaAgreementDetails.phoneNumber} + ${mcaAgreementDetails.mcaAcceptanceDate} + ${customerId} + ${domainName} + ${serviceId}`
    };
    await api.post(FRONTEND_LOGS, body, config)
      .then(resp => {
        if (resp.status == 200) {
          console.log("Audit Done");
        }


      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      })
  }

  // function submitMCAForm() {
  //   let mcaFormValid = true;


  //   if (!emailRegEx.test(mcaAgreementDetails.email)) {
  //     setMCAFormErrors({
  //       ...mcaFormErrors,
  //       emailError: true
  //     });
  //     mcaFormValid = false
  //   } else {
  //     setMCAFormErrors({
  //       ...mcaFormErrors,
  //       emailError: false
  //     });

  //     mcaFormValid = true
  //   }

  //   if (mcaFormValid) {
  //     saveMCADetails()
  //   }
  // }

  // Post MCA Details 
  async function submitMCAForm() {
    auditSubmitMCAForm("Frontend - Before Calling MCA Post Api");
    if (!emailRegEx.test(mcaAgreementDetails.email)) {
      setMCAFormErrors({
        ...mcaFormErrors,
        emailError: true
      });
    }
    else {
      setMCAFormErrors({
        ...mcaFormErrors,
        emailError: false
      });

      setSubmittingMCA(true);
      setMCAErrorMessage("");
      setMCAErrorMessageFlag(false);
      const body = {
        firstName: mcaAgreementDetails.firstName,
        lastName: mcaAgreementDetails.lastName,
        email: mcaAgreementDetails.email,
        phoneNumber: mcaAgreementDetails.phoneNumber,
        mcaAcceptanceDate: `${mcaAgreementDetails.mcaAcceptanceDate[2]}/${mcaAgreementDetails.mcaAcceptanceDate[1]}/${mcaAgreementDetails.mcaAcceptanceDate[0]}`,
        customerCode: customerId,
        serviceId: serviceId,
        // domainName: domainName.match(domainPattern)[1] // Send only one word i.e; before ".onmicrosoft.com"
        domainName: domainName.includes(".") ? domainName.split(".")[0] : domainName // Send only one word i.e; before ".onmicrosoft.com"
      };

      await api.post(CSP_MCA_AGREEMENT_DETAILS, body, config)
        .then(resp => {
          if (resp.status == 200) {
            auditSubmitMCAForm("Frontend - MCA Post Api Success");
            if (resp.data.isSuccess == true) {
              setStepsCompletedCheck({
                ...stepsCompletedCheck,
                isMCACompleted: true
              });
              setSubmittingMCA(false);
              setIsMCACompleted(true);
              setIsDomainBuySuccess(true);
            } else {
              setSubmittingMCA(false);
              setIsMCACompleted(false);
            }
          }


        })
        .catch(error => {
          auditSubmitMCAForm("Frontend - MCA Post Api Error");
          setSubmittingMCA(false);
          setMCAErrorMessageFlag(true);
          setMCAErrorMessage(error.response.data.errors[0]);
          if (error?.status == 401 || error?.response?.status == 401) {
            props.Logout();
          }
        })
    }
  }

  let intervalId;

  // Get GDAP Details
  async function getGDAPDetails() {
    setIsProceeding(true);
    setTransitionErrorBlock(false);

    const body = {
      customerCode: customerId,
      vendorAccountId: microsoftID,
    };

    try {
      const response = await api.post(CSP_GET_GDAP_DETAILS, body, config);
      const data = response.data;

      if (response.status === 200) {
        setGDAPDetails({
          gdapUri: data.gdapUri,
          delegateAdminRelationshipId: data.delegateAdminRelationshipId,
          message: data.message,
          isSuccess: data.isSuccess,
          assignedRoles: data.assignedRoles,
        });

        if ((data.message === '504' && !data.isSuccess) ||
          (data.gdapUri === null || data.assignedRoles.length < 1)) {
          intervalId = setInterval(getTransitionDomainStatusRoles, 20000);
          setTimeout(() => {
            clearInterval(intervalId);
            setDelegateAdminRelationshipApproved(false);
          }, 600000);
        } else {
          setAssignedRoles(data.assignedRoles || []);
          setDelegateAdminRelationshipId(data.delegateAdminRelationshipId);
          setGDAPUri(data.gdapUri);
        }
        
      }
      
      if (response.data.isSuccess) {
        setStepsCompletedCheck({
          ...stepsCompletedCheck,
          isDomainTransitionCompleted: true
        });
        setActiveAccordion(["gdap"]);
        setTransitionErrorBlock(false);
        setTransitionErrorMessage("")
      } else {
        setTransitionErrorBlock(true);
        setTransitionErrorMessage(response.data.message)
      }

    } catch (error) {
      console.error('error', error);
      if (error?.status == 401 || error?.response?.status == 401) {
        props.Logout();
      }
    } finally {

    }
    setIsProceeding(false);
  }

  // Function to get GDAP details incase of delay from Microsoft. This is Backup GET API
  async function getTransitionDomainStatusRoles() {
    await api.get(`${CSP_TRANSITION_ONBOARD_STATUS}?vendorAccountId=${microsoftID}`, config)
      .then((resp) => {

        if (resp.status === 200 && resp.data.status === 'Success') {
          setOnBoardDetails({
            ...onBoardDetails,
            status: resp.data.status,
            gdapUri: resp.data.gdapUrl,
            delegateAdminRelationshipId: resp.data.delegateRelationshipId,
            message: resp.data.message,
            isSuccess: resp.data.isSuccess,
            assignedRoles: resp.data.assignedRoles,
          });
          clearInterval(intervalId);
          setAssignedRoles(resp.data.assignedRoles);
          setDelegateAdminRelationshipId(resp.data.delegateRelationshipId);
          setGDAPUri(resp.data.gdapUrl);
        }
      })
      .catch((error) => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });


    setTimeout(() => {
      clearInterval(intervalId);
    }, 200000);
  }


  // Function to Post GDAP Transition Post
  async function GDAPTransitionStatusPOST() {
    setIsCompletinTtansaction(true);
    const body = {
      delegateAdminRelationshipId: delegateAdminRelationshipId
    };

    await api.post(`${CSP_GDAP_TRANSITION_STATUS}?delegateAdminRelationshipId=${delegateAdminRelationshipId}`, body, config)
      .then(resp => {
        setDelegateAdminRelationshipApproved(true);
        setIsGDAPTermsChecked(true)
        setIsAllStepsCompleted(true);
        setIsDomainBuySuccess(true);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        setDelegateAdminRelationshipApproved(true);
        setIsGDAPTermsChecked(true)
        setIsAllStepsCompleted(true);
        setIsDomainBuySuccess(true);
      })
    setIsCompletinTtansaction(false);
  }


  // MCA Agreement Details Update
  const handleMCAFormChange = (e) => {
    const { name, value } = e.target;

    setMCAAgreementDetails(prev => {
      return { ...prev, [name]: value }
    })
  }

  // MCA Agreement Details Update
  useEffect(() => {
    let todayDate = new Date();


    if (
      (mcaAgreementDetails.firstName.length > 0 && mcaAgreementDetails.firstName.length <= 30 && /^[a-zA-Z\s]*$/.test(mcaAgreementDetails.firstName)) &&
      (mcaAgreementDetails.lastName.length > 0 && mcaAgreementDetails.lastName.length <= 30 && /^[a-zA-Z\s]*$/.test(mcaAgreementDetails.lastName)) &&
      (mcaAgreementDetails.email?.length > 0) &&
      // (phoneRegex.test(mcaAgreementDetails.phoneNumber)) &&
      (mcaAgreementDetails.mcaAcceptanceDate?.length > 1 && mcaAgreementDetails.mcaAcceptanceDate <= todayDate.toISOString().split('T')[0])  &&
      (validDate === true)
    ) {
      setIsmcaFormHasNoErros(true);
    } else {
      setIsmcaFormHasNoErros(false);
    }
  }, [mcaAgreementDetails])

  useEffect(() => {
    GetMCADetails();
  }, [customerId]);

  useEffect(() => {
    if ((domainName !== "" || isDomainProvisioned) && !isMCACompleted) {
      setStepsCompletedCheck({
        ...stepsCompletedCheck,
        isDomainCheckCompleted: true,
      });

      setIsDomainType({
        ...isDomainType,
        isDomainTransition: false
      });

      setDomainName(domainName);

      setActiveAccordion("mcaDetails");
    }
  }, []);

  return (
    <>
      <Toast toastCheckUseState={updatedToastMessage} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
      <div className="main-content">

        <Container fluid>
          <Row>
            <Col className="justify-content-between d-flex align-items-center">
              <label className="gotoback cpointer">
                <span className="back" onClick={() => navigate('/cloud-provision')}>&nbsp;</span>&nbsp;<strong>Domain Check & Transition Domain</strong>
              </label>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col lg={3} md={4} className='my-3'>
              <Form.Group className="form-group col-md-12 mb-0">
                <Form.Label>Customer Name</Form.Label>
              </Form.Group>

              <NavDropdown
                title={customerNameDefaultValue}
                id="cloud-service-dropdown"
                className="servcies-dropdown dropdown-disabled"
                disabled>

              </NavDropdown>
            </Col>

            <Col lg={3} md={4} className='my-3'>
              <Form.Group className="form-group col-md-12 mb-0">
                <Form.Label>Vendor</Form.Label>
              </Form.Group>

              <NavDropdown
                title="Microsoft"
                id="cloud-service-dropdown"
                className="servcies-dropdown dropdown-disabled"
                disabled>

              </NavDropdown>
            </Col>

          </Row>
          {!customerId &&
            <BuyDomainFailed />
          }

          {customerId && <>
            {(isDomainBuySuccess == false && !isDomainProvisioned) &&
              <div className="accordion-panel">
                <Accordion
                  className="pre-requisite-panels mb-5"
                  activeKey={activeAccordion}
                  onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                  alwaysOpen>
                  <DomainCheck
                    domainCheckBlockErrors={domainCheckBlockErrors}
                    stepsCompletedCheck={stepsCompletedCheck}
                    getDomainAvailability={getDomainAvailability}
                    getTenantAvailability={getTenantAvailability}
                    isDomainType={isDomainType}
                    domainName={domainName}
                    isDomainAvailable={isDomainAvailable}
                    domainCheckFE={domainCheckFE}
                    btnChecks={btnChecks}
                    checkDomainAvailability={checkDomainAvailability}
                    getTransitionDomain={getTransitionDomain}
                    clearDomainCheck={clearDomainCheck}
                    proceedDomainFlow={proceedDomainFlow}
                    isCheckingDomain={isCheckingDomain}
                    domainChecked={domainChecked}
                    domainError={domainError}
                    setDomainError={setDomainError}
                    selectDomainCheckOption={selectDomainCheckOption}
                    setSelectDomainCheckOption={setSelectDomainCheckOption}
                    microsoftId={microsoftID}
                  />

                  {(isDomainType.isDomainProceed == true && selectDomainCheckOption !== transitionDomain) &&
                    <MCAAgreement
                      domainCheckBlockErrors={domainCheckBlockErrors}
                      isDomainType={isDomainType}
                      stepsCompletedCheck={stepsCompletedCheck}
                      mcaAgreementDetails={mcaAgreementDetails}
                      setMCAAgreementDetails={setMCAAgreementDetails}
                      handleMCAFormChange={handleMCAFormChange}
                      setIsMCATermsAgreed={setIsMCATermsAgreed}
                      isMCATermsAgreed={isMCATermsAgreed}
                      ismcaFormHasNoErros={ismcaFormHasNoErros}
                      setActiveAccordion={setActiveAccordion}
                      submittingMCA={submittingMCA}
                      setMCAFormErrors={setMCAFormErrors}
                      mcaFormErrors={mcaFormErrors}
                      submitMCAForm={submitMCAForm}
                      setValidDate={setValidDate}
                      DownloadMCAAgreement={DownloadMCAAgreement}
                      mcaErrorMessage={mcaErrorMessage}
                      mcaErrorMessageFlag={mcaErrorMessageFlag}
                      MCA_AGREEMENT_DOWNLOAD={MCA_AGREEMENT_DOWNLOAD}
                      GetMCAAgreement={GetMCAAgreement}
                      mcadisplayUri={mcaDisplayUri}
                      mcaDownloadUri={mcaDownloadUri}
                    />
                  }

                  {(isDomainType.isDomainTransition == true && selectDomainCheckOption == transitionDomain) &&
                    <TransitionDomain
                      domainCheckBlockErrors={domainCheckBlockErrors}
                      stepsCompletedCheck={stepsCompletedCheck}
                      domainName={domainName}
                      getDomainTrasnitionUri={getDomainTrasnitionUri}
                      transitionDomainChecks={transitionDomainChecks}
                      setTransitionDomainChecks={setTransitionDomainChecks}
                      setActiveAccordion={setActiveAccordion}
                      getGDAPDetails={getGDAPDetails}
                      getTransitionDomainStatusRoles={getTransitionDomainStatusRoles}
                      transitionErrorBlock={transitionErrorBlock}
                      transitionErrorMessage={transitionErrorMessage}
                      isProceeding={isProceeding}
                      toggleDrawer={props.toggleDrawer}
                      setSelectService={props.setSelectService}
                      setSelectCategory={props.setSelectCategory}
                      defaultServiceName={defaultServiceName}
                      defaultCategoryName={defaultCategoryName}
                      setMicrosoftID={setMicrosoftID}
                      microsoftID={microsoftID}
                    />
                  }


                  {stepsCompletedCheck.isDomainTransitionCompleted &&
                    <GDAPAssignment
                      domainCheckBlockErrors={domainCheckBlockErrors}
                      stepsCompletedCheck={stepsCompletedCheck}
                      setActiveAccordion={setActiveAccordion}
                      isGDAPTermsChecked={isGDAPTermsChecked}
                      setIsGDAPTermsChecked={setIsGDAPTermsChecked}
                      setIsAllStepsCompleted={setIsAllStepsCompleted}
                      isDomainType={isDomainType}
                      gdapDetails={gdapDetails}
                      GDAPTransitionStatusPOST={GDAPTransitionStatusPOST}
                      assignedRoles={assignedRoles}
                      delegateAdminRelationshipApproved={delegateAdminRelationshipApproved}
                      gdapURI={gdapURI}
                      isCompletinTtansaction={isCompletinTtansaction}
                    />
                  }

                  <p className="red text-small mt-2 d-none">
                    {CspCustomerPrerequisteNote}
                  </p>
                </Accordion>
              </div>
            }


            {(!isMCACompleted && isDomainProvisioned) &&

              <div className="accordion-panel">
                <Accordion
                  className="pre-requisite-panels"
                  activeKey={activeAccordion}
                  onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                  alwaysOpen>

                  <DomainCheck
                    domainCheckBlockErrors={domainCheckBlockErrors}
                    stepsCompletedCheck={stepsCompletedCheck}
                    getDomainAvailability={getDomainAvailability}
                    getTenantAvailability={getTenantAvailability}
                    isDomainType={isDomainType}
                    domainName={domainName}
                    isDomainAvailable={isDomainAvailable}
                    domainCheckFE={domainCheckFE}
                    btnChecks={btnChecks}
                    checkDomainAvailability={checkDomainAvailability}
                    getTransitionDomain={getTransitionDomain}
                    clearDomainCheck={clearDomainCheck}
                    proceedDomainFlow={proceedDomainFlow}
                    isCheckingDomain={isCheckingDomain}
                    domainChecked={domainChecked}
                    microsoftId={microsoftID}
                  />

                  <MCAAgreement
                    domainCheckBlockErrors={domainCheckBlockErrors}
                    isDomainType={isDomainType}
                    stepsCompletedCheck={stepsCompletedCheck}
                    mcaAgreementDetails={mcaAgreementDetails}
                    setMCAAgreementDetails={setMCAAgreementDetails}
                    handleMCAFormChange={handleMCAFormChange}
                    setIsMCATermsAgreed={setIsMCATermsAgreed}
                    isMCATermsAgreed={isMCATermsAgreed}
                    ismcaFormHasNoErros={ismcaFormHasNoErros}
                    setActiveAccordion={setActiveAccordion}
                    submittingMCA={submittingMCA}
                    setMCAFormErrors={setMCAFormErrors}
                    mcaFormErrors={mcaFormErrors}
                    submitMCAForm={submitMCAForm}
                    setValidDate={setValidDate}
                    DownloadMCAAgreement={DownloadMCAAgreement}
                    mcaErrorMessage={mcaErrorMessage}
                    MCA_AGREEMENT_DOWNLOAD={MCA_AGREEMENT_DOWNLOAD}
                    mcaDownloadUri={mcaDownloadUri}
                    GetMCAAgreement={GetMCAAgreement}
                    mcadisplayUri={mcaDisplayUri}
                  />

                </Accordion>
              </div>

            }

            {isDomainBuySuccess == true &&
              <BuyDomainSuccess
                isDomainType={isDomainType}
                customerId={customerId}
                customerName={customerNameDefaultValue}
                domainName={domainName}
                serviceId={serviceId}
                isMCACompleted={isMCACompleted}
                isDomainProvisioned={isDomainProvisioned}
                setActiveLocalKey={props.setActiveLocalKey}
              />
            }
          </>}
        </Container >
      </div>
    </>
  )
}

export default CSPCustomerPrerequisite