import React from 'react'
import { Alert } from "react-bootstrap";
import { Check2Circle , XCircle, XLg } from "react-bootstrap-icons";
import { TOAST_SUCCESS_ICON_COLOR } from '../../Utils/ColorsConfiguration';

const Toast = (props) => {
  return (
    <div className={`landing-page-alert ${props.toastCheckUseState ? "active" : ""}`}>
        <Alert variant={props.toastType}>
        <div className='d-flex align-items-center'>
          {props.toastIconColor===TOAST_SUCCESS_ICON_COLOR ? <Check2Circle size={24} color={props.toastIconColor} /> : <XCircle size={24} color={props.toastIconColor} />}
            <span className="mx-3 text-medium">{props.toastMessage}</span>
            <span>
              <XLg
                  size={16}
                  className="cpointer text-strong close-icon-popup-sm"
                  onClick={()=>props.toastSetUseState(false)}
                  color={props.toastIconColor}
              />
            </span>
        </div>
        </Alert>
    </div>
  )
}

export default Toast
