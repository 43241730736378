import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, NavDropdown, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import { emailRegEx } from '../../../../../GlobalComponents/RegExPatterns';
import Toast from '../../../../../GlobalComponents/Toast';
import { api } from '../../../../../Utils/API';
import { CANCEL_RENEWAL_NOTIFICATION, CONFIGURE_EMAIL_NOTIFICATION, RENEWAL_CONFIGURATIONS, RENEWAL_SETTINGS_CONFIG_DOWNLOAD, RENEWALSETTINGS_DURATION } from '../../../../../Utils/GlobalConstants';
import styles from './configureNotification.module.scss';
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../Utils/ColorsConfiguration';
import { CancelNotificationErrorToast, CancelNotificationSuccessToast, DownloadReportErrorToast, GenericDownloadEmptyToast, RenewalSettingsNotificationSuccessToast, ScheduleNotificationErrorToast } from '../../../../../Utils/StaticMessages/ToastMessages';
import { CspRenewalSettingNote } from '../../../../../Utils/StaticMessages/NotesDisclaimers';

const ConfigureNotificationsTab = ({ Logout, renewalSettingsRef }) => {
  const [subscriptionsNotificationEmail, setSubscriptionsNotificationEmail] = useState('');
  const [subscriptionsNotificationEmailList, setSubscriptionsNotificationEmailList] = useState([]);
  const [errorStateInvoiceNotification, setErrorStateInvoiceNotification] = useState(false);
  const [errorStateMessage, setErrorStateMessage] = useState('');
  const [tabName, setTabName] = useState("View Template");
  const [durationOptions, setDurationOptions] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState('30 days');
  const [selectedDate, setSelectedDate] = useState('1st'); 
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isCancelConfirmationPopupOpen, setIsCancelConfirmationPopupOpen] = useState(false);
  const [isNotificationScheduled, setIsNotificationScheduled] = useState(false);
  const [buttonCheck, setButtonCheck] = useState(false);

  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [scheduledDuration, setScheduledDuration] = useState('');
  const [scheduledDate, setScheduledDate] = useState('1st');
  const [hasChanges, setHasChanges] = useState(false);



  const EmailSubmitInvoiceNotification = (e) => {
    const emailList = subscriptionsNotificationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;
    emailList.forEach(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateInvoiceNotification(true);
      }
    });

    if (isValid && subscriptionsNotificationEmailList.length < 5) {
      if (subscriptionsNotificationEmailList.some(email => emailList.includes(email))) {
        setErrorStateInvoiceNotification(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        setSubscriptionsNotificationEmailList([...subscriptionsNotificationEmailList, ...emailList]);
        setSubscriptionsNotificationEmail('');
        setErrorStateInvoiceNotification(false);
      }
    } else {
      setErrorStateInvoiceNotification(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

   const handleSchedule = async () => {
    try {
      const emailString = subscriptionsNotificationEmailList.join(';');
      const scheduledDay = parseInt(selectedDate);
      const durationNumber = selectedDuration === "All" ? 0 : parseInt(selectedDuration);
      const payload = [{
        scheduledDay: scheduledDay,
        duration: durationNumber,
        emails: emailString
      }];

      const response = await api.post(CONFIGURE_EMAIL_NOTIFICATION, payload, config);

      if (response.status === 200) {
        setIsNotificationScheduled(true);
        setScheduledEmails([...subscriptionsNotificationEmailList]);
        setScheduledDuration(selectedDuration);
        setScheduledDate(selectedDate); 
        setHasChanges(false);
        
        setToastMessage(RenewalSettingsNotificationSuccessToast);
        setToastSuccess(true);
        setTimeout(() => {
          setToastSuccess(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Error scheduling notification:', error);
      if (error?.status === 401 || error?.response?.status === 401) {
        Logout();
        return;
      }

      setToastMessage(ScheduleNotificationErrorToast);
      setToastError(true);
      setTimeout(() => {
        setToastError(false);
      }, 2000);
    }
  };


  useEffect(() => {
  if (isNotificationScheduled) {
    const emailsChanged = JSON.stringify(scheduledEmails) !== JSON.stringify(subscriptionsNotificationEmailList);
    const durationChanged = scheduledDuration !== selectedDuration;
    const dateChanged = scheduledDate !== selectedDate; 

    setHasChanges(emailsChanged || durationChanged || dateChanged);
  }
}, [subscriptionsNotificationEmailList, selectedDuration, selectedDate, isNotificationScheduled]); // Add selectedDate to dependencies
  
  
  const handleCancelNotification = () => {
    setIsCancelConfirmationPopupOpen(true);
  };

 const confirmCancelNotification = async () => {
    try {
      const response = await api.delete(CANCEL_RENEWAL_NOTIFICATION, config);

      if (response.status === 200) {
      setSubscriptionsNotificationEmailList([]);
      setSubscriptionsNotificationEmail('');
      setErrorStateInvoiceNotification(false);
      setErrorStateMessage('');
      setIsCancelConfirmationPopupOpen(false);
      setIsNotificationScheduled(false);
      setScheduledEmails([]);
      const defaultDuration = durationOptions.find(option => option.id === '30') || durationOptions[0];
      if (defaultDuration) {
        setSelectedDuration(defaultDuration.name);
        setScheduledDuration(defaultDuration.name);
      }
      setSelectedDate('1st');
      setScheduledDate('1st');
      setHasChanges(false);
      
      setToastMessage(CancelNotificationSuccessToast);
      setToastSuccess(true);
      setTimeout(() => {
        setToastSuccess(false);
      }, 2000);
    }
  } catch (error) {
      console.error('Error canceling notification:', error);
      if (error?.status === 401 || error?.response?.status === 401) {
        Logout();
        return;
      }

      setToastMessage(CancelNotificationErrorToast);
      setToastError(true);
      setTimeout(() => {
        setToastError(false);
      }, 2000);
    }
  };

  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };
  
  useEffect(() => {
  const initializeData = async () => {
    try {
      const resp = await api.get(`${RENEWALSETTINGS_DURATION}`, config);
      const filteredOptions = resp.data.filter(option => option.id !== 'All');
      const allOptions = [ 
        ...filteredOptions,
        { id: 'All', name: 'All' }
      ];
      setDurationOptions(allOptions);

      //get renewal configurations with the available options
      const renewalResp = await api.get(RENEWAL_CONFIGURATIONS, config);
      if (renewalResp.data) {
        const day = renewalResp.data.scheduledDay;
        setSelectedDate(getOrdinalSuffix(day));
        setScheduledDate(getOrdinalSuffix(day));

        const duration = renewalResp.data.duration;
        console.log("Duration from API:", duration);
        console.log("Available options:", allOptions);
        
        if (duration === 0) {
          setSelectedDuration('All');
          setScheduledDuration('All');
        } else {
          const durationOption = allOptions.find(option => parseInt(option.id) === duration);
          console.log("Found duration option:", durationOption);
          
          if (durationOption) {
            setSelectedDuration(durationOption.name);
            setScheduledDuration(durationOption.name);
          }
        }

        if (renewalResp.data.emails) {
          const emailArray = renewalResp.data.emails.split(';').map(email => email.trim());
          setSubscriptionsNotificationEmailList(emailArray);
          setScheduledEmails(emailArray);
        }

        if (renewalResp.data.emails || renewalResp.data.duration || renewalResp.data.scheduledDay) {
          setIsNotificationScheduled(true);
        }
      }
    } catch (error) {
      console.error('Error in initialization:', error);
      if (error?.status === 401 || error?.response?.status === 401) {
        Logout();
      }
    }
  };
  
  initializeData();
}, []);

  const getOrdinalSuffix = (n) => {
    const suffixes = ['st', 'nd', 'rd'];
    const tens = Math.floor(n / 10);
    const ones = n % 10;
    return (tens === 1) ? `${n}th` : `${n}${suffixes[(ones - 1) % 10] || 'th'}`;
  };

  const isButtonsEnabled = subscriptionsNotificationEmailList.length > 0;


  const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

const handleDownloadReport = async () => {
        try {
            const date = new Date();
            const durationValue = selectedDuration === "All" ? "" : selectedDuration.split(" ")[0];
            
            // Get current values from RenewalSettingsTab
            const renewalSettings = renewalSettingsRef.current;
            const {
                pageNo = 1,
                pageSize = 15,
                sortCol = "remainingDayNumber",
                sortDir = "ASC"
            } = renewalSettings?.getValues?.() || {};

            const integrationCode = "";
            const serviceId = 0;
            const customerId = "";
            const searchValueTable = "";

            const response = await api.get(
                `${RENEWAL_SETTINGS_CONFIG_DOWNLOAD}?duration=${durationValue || ''}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerId || ''}&PageNo=${pageNo}&PageSize=${pageSize}&searchText=${searchValueTable || ''}&SortCol=${sortCol || ''}&SortDir=${sortDir || ''}`,
                configBlob
            );

            if (response.status === 204) {
                setToastMessage(GenericDownloadEmptyToast);
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 2000);
                return;
            }

            downloadFile({
                data: response.data,
                fileName: `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_subscriptions renewal.csv`,
                fileType: "application/csv",
            });

        } catch (error) {
            console.error('Error downloading report:', error);
            if (error?.status === 401 || error?.response?.status === 401) {
                Logout();
                return;
            }

            setToastMessage(DownloadReportErrorToast);
            setToastError(true);
            setTimeout(() => {
                setToastError(false);
            }, 2000);
        }
    };

  return (
  <div className="main-content">
    <Toast 
      toastSetUseState={setToastSuccess} 
      toastCheckUseState={toastSuccess} 
      toastType="success" 
      toastIconColor={TOAST_SUCCESS_ICON_COLOR} 
      toastMessage={toastMessage} 
    />
    <Toast 
      toastSetUseState={setToastError} 
      toastCheckUseState={toastError} 
      toastType="danger" 
      toastIconColor={TOAST_ERROR_ICON_COLOR} 
      toastMessage={toastMessage} 
    />

    <Popup
      open={isCancelConfirmationPopupOpen}
      modal
      className="custom-modal custom-modal-md"
      onClose={() => setIsCancelConfirmationPopupOpen(false)}
    >
      {(close) => (
        <div className="modal">
          <div className="header p-4 py-3">
            <h2>Cancel Notification</h2>
            <XLg
              size={16}
              className="cpointer text-strong"
              onClick={() => {
                close();
                setIsCancelConfirmationPopupOpen(false);
              }}
              color={TEXT_SECONDARY}
            />
          </div>

          <Container className="p-4">
            <p>Are you sure you want to cancel the notification?</p>
            <div className="actions">
              <div className="d-flex justify-content-end">
                <Button
                  className={`btn btn-lg mx-3 btn-border-light ${styles['configure-notification-button']}`}
                  variant="light"
                  onClick={() => {
                    setIsCancelConfirmationPopupOpen(false);
                    close();
                  }}
                >
                  No
                </Button>
                <Button
                  className={`btn btn-md ${styles['configure-notification-button']}`}
                  onClick={() => {
                    confirmCancelNotification();
                    close();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </Popup>

    <div className="details-box">
      <Container fluid>
        <Row className={`py-3 mb-4 ${styles['border-header']}`}>
          <Col xs={12} lg={6} md={9} className="d-flex align-items-center justify-content-between">
            <h2 className="mb-0">Configure Notification</h2>
            
            {/* Mobile-only download button */}
            <div className='d-md-none'>
              <Button
                variant="light"
                className={`btn-border-light ${styles['configure-notification-button']}`}
                onClick={handleDownloadReport}
              >
                Download Report
              </Button>
            </div>
          </Col>
          
          {/* Desktop Download Report button */}
          <Col xs={12} lg={6} md={3} className={`text-right ${styles['downloadreport-header']} d-none d-md-block`}>
            <Button 
              variant="light" 
              className={`mr-1 px-4 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
              onClick={handleDownloadReport}
            >
              Download Report
            </Button>
          </Col>
        </Row>

        <div className={`${styles['content']}`}>
          <div className={`${styles['notificationSettings']}`}>
    <div className={`${styles['notification-group']}`}>
      <span>Send notification email on</span>
      <NavDropdown
        className={`servcies-dropdown dropdown-specialclass-fortwo dropdown-service ${styles['border-width-config-date']}`}
        title={selectedDate}
        id="cloud-service-dropdown"
        onSelect={(eventKey) => {
          setSelectedDate(getOrdinalSuffix(parseInt(eventKey)));
        }}
      >
        {Array.from({ length: 25 }, (_, index) => (
          <NavDropdown.Item key={index} eventKey={index + 1}>
            {getOrdinalSuffix(index + 1)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>of each month showing</span>
      <NavDropdown 
        className={`servcies-dropdown dropdown-specialclass-fortwo dropdown-service ${styles['border-width-config-month']}`}
        title={`${selectedDuration}`}
        id="duration-dropdown"
        onSelect={(eventKey) => {
          setSelectedDuration(eventKey);
        }}
      >
        {durationOptions.map(option => (
          <NavDropdown.Item 
            key={option.id}
            eventKey={option.id}
            className={selectedDuration === option.id ? "selected" : ""}
          >
            {`${option.name}`}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>renewal date</span>
    </div>
  </div>

          <div className={`${styles['reportNote']}`}>
            Report will be sent to the reseller in an <span>CSV format</span> with customer subscription renewal details.
          </div>

          <div className="send-notifications-tab__resellers-email">
            <div className='px-2'>
              <Row className='px-3 align-items-center'>
                <h3>Resellers Email Id</h3>
                <Col md={4}>
                  <Form.Group controlId="subscriptionsNotificationEmail">
                    <Form.Label className='justify-content-between d-flex col-12'>
                      <span>Add Email</span>
                      <span className={`float-right ${styles['maximum-add']} ${subscriptionsNotificationEmailList.length >= 5 ? "text-danger" : ""}`}>
                        Max 5
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={subscriptionsNotificationEmail}
                      maxLength="250"
                      className={errorStateInvoiceNotification ? "border-danger" : ""}
                      name="subscriptionsNotificationEmail"
                      onChange={(e) => {
                        setErrorStateInvoiceNotification(false);
                        setErrorStateMessage("");
                        let count = e.target.value.split(';').length;
                        if (subscriptionsNotificationEmailList.length + count <= 5) {
                          setSubscriptionsNotificationEmail(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        let count = e.target.value.split(';').length;
                        if (e.key === 'Enter' && subscriptionsNotificationEmailList.length < 5 && subscriptionsNotificationEmailList.length + count <= 5) {
                          EmailSubmitInvoiceNotification(e);
                        }
                      }}
                    />
                  </Form.Group>
                  {!errorStateInvoiceNotification && subscriptionsNotificationEmail.length > 0 && 
                    <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>
                  }
                  {errorStateInvoiceNotification ? 
                    <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                    <p className='empty-paragragh mb-6'></p>
                  }
                </Col>
                <Col md={8}>
                  <div className='mt-1 mb-0 d-flex flex-wrap'>
                    {console.log("all email ids;,", subscriptionsNotificationEmailList)}
                    {subscriptionsNotificationEmailList.map((email, index) => (
                      <div key={index} className={`mr-2 mb-0 px-1 col-lg-3 col-md-4 col-xs-5 ${styles['email-member-options']}`}>
                        <label className='ml-0'>
                          <span>{email}</span>
                          <XLg
                            size={16}
                            className={`cpointer text-strong ${styles['close-icon-popup-sm']}`}
                            onClick={() => {
                              const updatedEmailList = [...subscriptionsNotificationEmailList];
                              updatedEmailList.splice(index, 1);
                              setSubscriptionsNotificationEmailList(updatedEmailList);
                            }}
                            color={TEXT_SECONDARY}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          
          <Row className="py-3 mb-4">
          <Col md={7} lg={6} className={`${styles['note']} ${styles['notes-confirmation-configure-notification']}`}>
          <span>Note:</span> {CspRenewalSettingNote}
          </Col>
          <Col md={5} lg={6} className="d-flex justify-content-end">
          {isNotificationScheduled ? (
          <>
          <Button 
          variant="light"
          className={`ml-3 mr-3 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
          onClick={handleCancelNotification}
          >
          Cancel Notification
          </Button>
          <Button 
          variant={hasChanges ? "primary" : "light"} 
          className={`ml-3 ${hasChanges ? "text-white" : "btn-border-light"} w-md-auto col-xs-12  ${styles['configure-notification-button']}`}
          onClick={handleSchedule}
          disabled={!hasChanges}
        >
          Update
        </Button>
        </>
        ) : (
        <>
        <Button 
          variant="light"
          className={`ml-3 mr-3 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
          disabled={!isButtonsEnabled}
          onClick={() => {
            setSubscriptionsNotificationEmailList([]);
            setSubscriptionsNotificationEmail('');
          }}
        >
          Clear
        </Button>
        <Button
          variant={isButtonsEnabled ? "primary" : "light"}
          className={`ml-3 ${isButtonsEnabled ? "text-white" : "btn-border-light"} w-md-auto col-xs-12  ${styles['configure-notification-button']}`}
          disabled={!isButtonsEnabled}
          onClick={handleSchedule}
        >
          Schedule
        </Button>
        </>
        )}
          </Col>
          </Row>
        </div>
      </Container>
      </div>
    </div>
  );
};

export default ConfigureNotificationsTab;