import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import styles from './gdapRelationship.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../../../../../Utils/API";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { GDAP_STATUS_TYPES, GDAP_GET_TABLE } from '../../../../../../../Utils/GlobalConstants';
import 'reactjs-popup/dist/index.css';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import Expandable from './LocalComponents/Expandable';
import FilterBox from './LocalComponents/FilterBox';
import { GenericEmptyStateError, GenericServerError } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';


const GDAPRelationship = ({ vendorAccountId, tabName ,Logout,resellerId}) => {
    const [searchIconFlag, setSearchIconFlag] = useState(false);                         // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                      // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                 // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                           // showing filter box
    const searchInputRef = useRef();                                                     // Search Input Box

    // Table Parameters
    const [columns, setColumns] = useState([]);                                          // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                              // data state used to display data in react data table component
    let ColumnName = ["Name", "Start Date",                                 // table headers used to make object of react data table component headers
        "End Date", "Status", "Duration",
        "Roles"];
    const [initialLoading, setInitialLoading] = useState(true);                          // loader for table

    const [serverErrorOrderHistory, setServerErrorOrderHistory] = useState(false);       // server error check state in table during api call to load data
    const [emptyOrderHistory, setEmptyOrderHistory] = useState(false);                   // empty table check state in table during api call to load data
    const [emptyOrderHistoryFilters, setEmptyOrderHistoryFilters] = useState(false);     // empty filter response check state in table during api call to load data
    const [emptyOrderHistorySearch, setEmptyOrderHistorySearch] = useState(false);       // empty search response check state in table during api call to load data

    const [IsDescStartDate, setIsDescStartDate] = useState(true);                            // sort state check for order History id column
    const [isDescEndDate, setIsDescEndDate] = useState(null);                        // sort state check for order History date column

    // Get Table Data Function Parameters
    const [sortCol, setSortCol] = useState("sortStartDate");                                // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [statusData, setStatusData] = useState(null);                                         // data in status dropdown
    const [status, setStatus] = useState(null);                                         // data in status dropdown

    const [gdapTableData, setGdapTableData] = useState(null);                        // sort state check for order History date column

    const [displayName, setDisplayName] = useState(null);                                         // display name for opening expandable

    // ----------------------------------------------------------------
    // bootsrap table:
    const [expandedRow, setExpandedRow] = useState(null);
    const [refreshStatusArray, setRefreshStatusArray] = useState([]);

    const toggleRow = (index) => {
        if (expandedRow === index) {
            setExpandedRow(null); // Collapse if already expanded
        } else {
            setExpandedRow(index); // Expand the clicked row
        }
    };

    const arrowDirection = (index) => (expandedRow === index ? 'up' : 'down');



    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(status, search, sortCol, sortDir) {
        setInitialLoading(false);
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        await api.get(`${GDAP_GET_TABLE}?vendorAccountId=${vendorAccountId}&status=${status}&searchText=${search}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setInitialLoading(true);
                if (resp.status == 204) {
                    setRefreshStatusArray([]);
                    if (search === "" && status === "") {
                        setEmptyOrderHistory(true);
                    }
                    if (search != "") {
                        setEmptyOrderHistorySearch(true);
                    }
                    if (status != "") {
                        setEmptyOrderHistoryFilters(true);
                    }
                }
                // handle success
                else {
                    setServerErrorOrderHistory(false);
                    setEmptyOrderHistory(false);
                    setEmptyOrderHistoryFilters(false);
                    setEmptyOrderHistorySearch(false);
                    setGdapTableData(resp.data);
                    let f = [];
                    let statusArray = [];
                    (resp.data).map((td) => {
                        f.push
                            (
                                {
                                    "Name": td.displayName,
                                    "Start Date": `${td.startDate}`,
                                    "End Date": `${td.endDate === null ? "" : td.endDate}`,
                                    "Duration": `${td.duration} days`,
                                    "Roles": ``,
                                    "ExpandableStatus": td.status,
                                    "AssignedRoles": td.assignedRoles,
                                    "ApprovalLink": td.approvalLink,
                                    "Status": td.status === 'Expired' ? (
                                        <div className={`${styles['status-orderhistory']}`}>
                                            <span className="status-expired"></span>
                                            <div className="py-1 text-muted text-small">Expired</div>
                                        </div>
                                    ) : td.status === 'Active' ? (
                                        <div className={`${styles['status-orderhistory']}`}>
                                            <span className="status-paid"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : (
                                        <div className={`${styles['status-orderhistory']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>

                                    ),
                                }
                            );
                        statusArray.push(td.status);
                    })
                    setRefreshStatusArray(statusArray);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Start Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {IsDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStartDate(true); setSortCol("startDate"); setIsDescEndDate(null); }}></span></>}
                                    {IsDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescStartDate(false); setSortCol("startDate"); }}></span></>}
                                    {IsDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStartDate(true); setSortCol("startDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "End Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescEndDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEndDate(true); setSortCol("endDate"); setIsDescStartDate(null); }}></span></>}
                                    {isDescEndDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescEndDate(false); setSortCol("endDate"); }}></span></>}
                                    {isDescEndDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEndDate(true); setSortCol("endDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorOrderHistory(true);
                }

            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GDAP_STATUS_TYPES, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    useEffect(() => {
        if (searchValueTable!== undefined && searchValueTable!== null) {
          const timer = setTimeout(() => {
            GetTableData(status, searchValueTable, sortCol, sortDir);
          }, 800);
          return () => clearTimeout(timer);
        }
      }, [searchValueTable, status, sortCol, sortDir])
      
      useEffect(() => {
        if (refreshStatusArray.length > 0 && refreshStatusArray.includes("Approval Pending") && tabName === "GDAP Relationships") {
          const timer = setTimeout(() => {
            GetTableData(status, searchValueTable, sortCol, sortDir);
          }, 10000);
          return () => clearTimeout(timer);
        }
      }, [refreshStatusArray, tabName, status, searchValueTable, sortCol, sortDir])
      

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        GetTableData(status, searchValueTable, sortCol, sortDir)
    }, [sortCol, sortDir])

    useEffect(() => {
        if(tabName==="GDAP Relationships"){
           GetTableData(status, searchValueTable, sortCol, sortDir);          
        }
        else{
            setDisplayName(null);
            setExpandedRow(null);
        }
    }, [tabName])


    useEffect(() => {
        GetAllStatuses();
    }, []);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <div className={`mgmt-console-table-row mt-4 ${styles['gdap-height']}`}>
                <div >
                    <div className="my-0 pb-2 align-items-center row">
                        <Col className={`pl-1`}>
                            <h2 className="mx-6 mb-0">Admin Relationships</h2>
                        </Col>
                        {/* desktop screen search */}
                        <Col className="px-1 d-none d-md-block">
                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub pr-4">
                                {!serverErrorOrderHistory && !emptyOrderHistory && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                    {!searchIconFlag &&
                                        <span className="mx-3">&nbsp;</span>
                                    }
                                    {!searchIconFlag && !emptyOrderHistoryFilters &&
                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                    }
                                    {searchIconFlag && !emptyOrderHistoryFilters &&
                                        <InputGroup className="search-input-box search-input-box-large">
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                <span className="searchgrey cpointer"
                                                >
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search admin relationship"
                                                aria-label="Search admin relationship"
                                                aria-describedby="basic-addon2"
                                                className="search-input-box-input"
                                                value={searchValueTable}
                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                ref={searchInputRef}
                                            />
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                onClick={() => {
                                                    setSearchValueTable("");
                                                    setSearchIconFlag(!searchIconFlag);
                                                }}>
                                                <span className="closebtn">
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    }

                                    {!filterFlag && !emptyOrderHistorySearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                        setShowFilterBox(!showFilterBox);
                                    }}></span>}
                                    {initialLoading && filterFlag && !emptyOrderHistorySearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                </Col>}
                            </Row>
                        </Col>
                        {/* mobile screen search */}
                        <Col className="px-1 d-block d-md-none" md={12}>
                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub px-2">
                                {!serverErrorOrderHistory && !emptyOrderHistory && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                    {!emptyOrderHistoryFilters &&
                                        <InputGroup className="search-input-box">
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                <span className="searchgrey cpointer"
                                                >
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search admin relationship"
                                                aria-label="Search admin relationship "
                                                aria-describedby="basic-addon2"
                                                className="search-input-box-input"
                                                value={searchValueTable}
                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                ref={searchInputRef}
                                            />
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                onClick={() => {
                                                    setSearchValueTable("");
                                                    setSearchIconFlag(!searchIconFlag);
                                                }}>
                                                <span className="closebtn">
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    }

                                    {!filterFlag && !emptyOrderHistorySearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                        setShowFilterBox(!showFilterBox);
                                    }}></span>}
                                    {initialLoading && filterFlag && !emptyOrderHistorySearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                </Col>}
                            </Row>
                        </Col>
                    </div>
                    {
                        !emptyOrderHistorySearch && showFilterBox &&
                        <FilterBox GetTableData={GetTableData} searchValueTable={searchValueTable} setShowFilterBox={setShowFilterBox}
                            setFilterFlag={setFilterFlag} status={status} statusData={statusData} setStatus={setStatus} sortCol={sortCol} sortDir={sortDir}
                        />
                    }
                    <Container fluid className="mb-5 p-0">
                        <Row>
                            <Col>
                                <div className={`table-responsive ${styles['order-history-history-table']}`}>
                                    {initialLoading && serverErrorOrderHistory &&
                                        <div className={styles["no-data-order-history"]}>
                                            <img src={EmptyDataTableFilterSearch} className="empty-order-history-table-svg" />
                                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                        </div>
                                    }
                                    {initialLoading && (emptyOrderHistorySearch || emptyOrderHistoryFilters) &&
                                        <div className={styles["no-data-order-history"]}>
                                            <img src={EmptyDataTableFilterSearch} className="empty-order-history-table-svg" />
                                            <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                        </div>
                                    }
                                    {initialLoading && emptyOrderHistory &&
                                        <div className={styles["no-data-order-history"]}>
                                            <img src={RaiseTicketIllustration} className="empty-order-history-table-svg" />
                                            <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                        </div>
                                    }
                                    {!serverErrorOrderHistory && !emptyOrderHistoryFilters && !emptyOrderHistory && !emptyOrderHistorySearch &&
                                        <div className={styles["table-height-adjustment"]}>
                                            <Table responsive="md" className={styles["table-alignment"]}>
                                                <thead className="sticky-top">
                                                    <tr>
                                                        {ColumnName.map(th => {
                                                            if (th === "Start Date") {
                                                                return (<th>
                                                                    {IsDescStartDate === null && <>{th}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); setIsDescEndDate(null); }}></span></>}
                                                                    {IsDescStartDate === true && <>{th}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortStartDate"); }}></span></>}
                                                                    {IsDescStartDate === false && <>{th}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                                                </th>)

                                                            }
                                                            else if (th === "End Date") {
                                                                return (<th>
                                                                    {isDescEndDate === null && <>{th}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); setIsDescStartDate(null); }}></span></>}
                                                                    {isDescEndDate === true && <>{th}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescEndDate(false); setSortCol("sortEndDate"); }}></span></>}
                                                                    {isDescEndDate === false && <>{th}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); }}></span></>}
                                                                </th>)

                                                            }
                                                            else {
                                                                return (<th className={(th === "Status" || th === "Roles") ? "text-center" : ""}>{th}</th>)
                                                            }
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        gdapTableData && gdapTableData
                                                            .map((item, index) => {
                                                                return (
                                                                    <><tr key={item.displayName} className='vertical-align-middle'>
                                                                        <td className={`${styles['height-displayName']}`}>{item.displayName}</td>
                                                                        <td className={`${styles['height-startDate']}`}>{item.startDate}</td>
                                                                        <td className={`${styles['height-startDate']}`}>{item.endDate === null ? "" : item.endDate}</td>
                                                                        <td className={`text-center ${styles['height-status']}`}>
                                                                            {item.status === 'expired' ? (
                                                                                <div className={`${styles['status-orderhistory']}`}>
                                                                                    <span className="status-expired"></span>
                                                                                    <div className="text-muted text-small">Expired</div>
                                                                                </div>
                                                                            ) : item.status === 'Active' ? (
                                                                                <div className={`${styles['status-orderhistory']}`}>
                                                                                    <span className="status-paid"></span>
                                                                                    <div className="text-muted text-small">Active</div>
                                                                                </div>
                                                                            ) : item.status === 'Approval Pending' ? (
                                                                                <div className={`${styles['status-orderhistory']}`}>
                                                                                    <span className="status-pending"></span>
                                                                                    <div className="text-muted text-small">Pending</div>
                                                                                </div>

                                                                            )  : item.status
                                                                            }
                                                                        </td>
                                                                        <td className={`${styles['height-startDate']}`}>{item.duration} days</td>
                                                                        <td className={`text-center ${styles['height-roles']}`}>
                                                                            <div onClick={() => { setDisplayName(item.displayName); toggleRow(index) }}>
                                                                                <span className='cpointer'>View Roles </span>
                                                                                <span className={`gdap-${arrowDirection(index)}-button`} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                        {displayName === item.displayName && expandedRow !== null &&
                                                                            <tr className={`${styles['expandable-view-roles-gdap']}`}>
                                                                                <td colSpan="6" >
                                                                                    <Expandable data={item} />
                                                                                </td>

                                                                            </tr>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default GDAPRelationship