import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import styles from './avastServiceManagement.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { GET_SERVICES, GET_CUSTOMERS_LIST, AVAST_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, AVAST_SUBSCRIPTION_TABLE } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import serviceLogo from '../../../../../Assets/Images/Illustrations/avast.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import AvastFilterBox from './LocalComponents/FilterBox';
import EmptyServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg';
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg';
import Eye from '../../../../../GlobalComponents/Eye';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY } from '../../../../../Utils/ColorsConfiguration';
import { AddSubscriptionMessage, GenericEmptyMatchingSubscriptionError, GenericEmptyStateError, GenericEmptySubscriptionCloudServiceError, GenericNoSubscriptionsError, GenericServerError, GenericServiceNotProvisionedError } from '../../../../../Utils/StaticMessages/GenericErrorMessages';

const AvastServiceManagement = ({ Logout }) => {

    const location = useLocation();                                                 // use to access data from other page data
    const navigate = useNavigate();                                                 // to use navigate function from react router dom 
    const [searchIconFlag, setSearchIconFlag] = useState(false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                 // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [status, setStatus] = useState(null);                                     // getTableData() status
    const searchInputRef = useRef();                                                // Search Input Box

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(5);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Plan", "Quantity", "Billing Term", "Status"];                // table headers used to make object of react data table component 
    const [serverErrorUser, setServerErrorUser] = useState(false);                  // server error check state in table during api call to load data
    const [emptyUser, setEmptyUser] = useState(false);                              // empty table check state in table during api call to load data
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                  // empty search response check state in table during api call to load data
    const [emptyUserFilters, setEmptyUserFilters] = useState(false);                // empty filter response check state in table during api call to load data
    const [statusLastUsed, setStatusLastUsed] = useState("default");                // status dropdown saved data after applying filters  
    const [loading, setLoading] = useState(false);                                  // getTableData() plan type
    const [provisionRefreshIconFlag, setProvisionRefreshIconFlag] = useState(false); // refresh icon used for refreshing table
    const [customerId, setCustomerId]
        = useState(location.state !== null ? location.state.customerId : "");     // storing customer id
    const [serviceId, setServiceId] = useState(null);                                 // service name for dropdown
    const [serviceData, setServiceData] = useState(null);                           // data in service dropdown at the top
    const [customerData, setCustomerData] = useState([]);                           // data in customer dropdown at the top
    const [modalOpen, setModalOpen] = useState(false);                              // opens modal popup
    const [additionalDetails, setAdditionalDetails] = useState(null);               // storing additionaldetails from api
    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall]
        = useState(null);                                                         // initial loading of subscription table when service is provisioned
    const [passwordType, setPasswordType] = useState(['password', '']);             // password for popup
    const [filterApplied, setFilterApplied] = useState(false);                        // filter in gettabledata

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section 
    async function GetAllServices() {
        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);

                for (let i in resp.data) {
                    if (resp.data[i].id === serviceId) {
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section 
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);

                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "cloudcare") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function to call all account details 
    async function GetAdditionalDetails() {
        await api.get(`${AVAST_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                    setProvisionRefreshIconFlag(false);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                    setProvisionRefreshIconFlag(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }
            });
    };

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(page, newPerPage, search, status, isFilter) {
        setLoading(true);
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${AVAST_SUBSCRIPTION_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&customerId=${customerId}`, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {
                    if (search === "" && status === "") {
                        setEmptyUser(true);
                    }
                    else if (status != "" && isFilter) {
                        setEmptyUserFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyUserSearch(true);
                    }
                }
                else {
                    setServerErrorUser(false);
                    setEmptyUser(false);
                    setEmptyUserSearch(false);
                    setEmptyUserFilters(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Plan": td.planName,
                                    "Quantity": td.quantity,
                                    "Billing Term": td.billingTerm,
                                    "Status": td.status.toUpperCase() === 'ACTIVE' ? (
                                        <div className={`${styles['status-azure']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : td.status.toUpperCase() === 'SUSPENDED' ? (
                                        <div className={`${styles['status-azure']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (
                                        td.status.toUpperCase()
                                    )
                                }
                            );
                    })
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Plan") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                setLoading(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorUser(true);
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                setPageNo(1);
                GetTableData(1, pageSize, searchValueTable, status);
            }, 2000);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px',    // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',  // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
                textTransform: 'capitalize',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, status);
    };

    useEffect(() => {
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    //useEffect called when service is provisioned
    useEffect(() => {
        if (initialSubscriptionTableCall) {
            if (filterApplied)
                GetTableData(pageNo, pageSize, searchValueTable, status, true);
            else
                GetTableData(pageNo, pageSize, searchValueTable, status, false);
        }
    }, [initialSubscriptionTableCall]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Renders when service ID Changes 
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId } });
                    break;
            }
        }


    }, [serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div className='main-content'>
            <div className='container-fluid'>
                {/* Popup for Cloud Portal Details */}
                <Popup
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); }}
                    className="custom-modal custom-modal-md custom-modal-ticket-details"
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header">
                                <h2>Cloud Portal Details</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                />
                            </div>
                            <Container>
                                {additionalDetails && <Row>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Customer</label>
                                        <p><strong>{additionalDetails.customerName}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Start Date</label>
                                        <p><strong>{additionalDetails.startDate}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Customer Portal</label>
                                        <p className='service-management-acronis-portal-link'><a href={additionalDetails.serviceUrl}>{additionalDetails.serviceUrl}<span className='external-link cpointer ml-2'></span></a></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">User Name</label>
                                        <p><strong>{additionalDetails.username}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Initial Password</label>
                                        <span className='d-flex justify-content-between col-8 password-toggle'>
                                            <input type={passwordType[0]} value={additionalDetails.password} readOnly className='plain-text text-strong' />
                                            <Eye setPasswordType={setPasswordType} passwordType={passwordType} />
                                        </span>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Partner Portal</label>
                                        <p className='service-management-acronis-portal-link'><a href={additionalDetails.partnerServiceUrl}>{additionalDetails.partnerServiceUrl}<span className='external-link cpointer ml-2'></span></a></p>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <label className="text-medium">Partner Password</label>
                                        <p><strong>{additionalDetails.partnerPassword}</strong></p>
                                    </Col>
                                  </Row>}
                            </Container>
                        </div>
                    )}
                </Popup>
                <Row>
                    <Col md={6} className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/management-console')}>
                            <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>Avast Service Management</span></strong>
                        </label>
                    </Col>
                    <Col md={6} className="text-right service-logo">
                        <img className={`${styles['avast-service-management-logo']}`} src={serviceLogo} />
                    </Col>
                </Row>
                <div className='service-management-azure-header mt-5'>
                    <Row className='mb-3'>
                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Cloud Service"
                                formGroupId="serviceNameId"
                                placeholder="Enter Service Name"
                                selectDefaultValue={serviceNameDefaultValue}
                                setSelectDefaultValue={setServiceNameDefaultValue}
                                selectOptions={serviceData}
                                selectOptionsFiltered={serviceNameFiltered}
                                setSelectOptionsFiltered={setServiceNameFiltered}
                                selectSearchValue={serviceNameSearchValue}
                                setSelectSearchValue={setServiceNameSearchValue}
                                updatedOptions={updatedServiceName}
                                setOptionId={setServiceId}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>

                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Customer Name"
                                formGroupId="customerNameId"
                                placeholder="Enter Customer Name"
                                selectDefaultValue={customerNameDefaultValue}
                                setSelectDefaultValue={setCustomerNameDefaultValue}
                                setOptionId={setCustomerId}
                                selectOptions={customerData}
                                selectOptionsFiltered={customerNameFiltered}
                                setSelectOptionsFiltered={setCustomerNameFiltered}
                                selectSearchValue={customerNameSearchValue}
                                setSelectSearchValue={setCustomerNameSearchValue}
                                updatedOptions={updatedCustomerName}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>

                        <Col md={6} className='text-right'>
                            <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                <label class="pt-0 label-sm mr-3">Subscription
                                    <span class="circle-bg label-sm">
                                        <strong class="label-sm">
                                            {additionalDetails ? additionalDetails.subscriptionLicenseCount : 0}
                                        </strong>
                                    </span>
                                </label>

                                {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="service-management-azure-body user-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`p-0 ${styles['user-management-table']}`}>
                                <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">
                                                Subscriptions {!loading && !provisionRefreshIconFlag && <span className='refresh ml-2'
                                                    onClick={() => GetTableData(pageNo, pageSize, searchValueTable, status)}></span>}</h5>
                                        </Col>
                                        <Col className="my-1 d-md-none d-block text-right mr-1">
                                            <Button onClick={() => navigate('')}>Buy Subscription</Button>
                                        </Col>

                                        {/* Filter and Search Box for Desktop and Tablet */}
                                        <Col md={8} className="d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative pr-4">
                                                        {!loading && !searchIconFlag &&
                                                            <span className="mx-3">&nbsp;</span>
                                                        }
                                                        {!loading && !searchIconFlag && !emptyUserFilters &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                        }
                                                        {!loading && searchIconFlag && !emptyUserFilters &&
                                                            <InputGroup className="search-input-box">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search Plan Name"
                                                                    aria-label="Search Plan Name"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }
                                                        {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {!loading && filterFlag && !emptyUserSearch &&
                                                            <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                        <Button className="ml-3 px-4" onClick={() => navigate('')}>Buy Subscription</Button>
                                                    </Col>}
                                            </Row>
                                        </Col>

                                        {/* Filter and Search Box for Mobile */}
                                        <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">

                                                    <InputGroup className="search-input-box">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer"
                                                            >
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Search Plan Name"
                                                            aria-label="Search Plan Name"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box-input"
                                                            value={searchValueTable}
                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                            ref={searchInputRef}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTable("");
                                                                setSearchIconFlag(!searchIconFlag);
                                                            }}>
                                                            <span className="closebtn">
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>

                                                    {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                </Col>}
                                            </Row>
                                        </Col>

                                    </div>
                                    {
                                        !emptyUserSearch && showFilterBox &&
                                        <AvastFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo}
                                            pageSize={pageSize} searchValueTable={searchValueTable} Logout={Logout}
                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} setFilterApplied={setFilterApplied}
                                            status={status} setStatus={setStatus} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed}
                                        />
                                    }
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['user-history-table']}`}>
                                                    {!loading && serverErrorUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUserSearch && !emptyUserFilters && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericEmptyMatchingSubscriptionError}</h2>
                                                        </div>
                                                    }
                                                    {!initialSubscriptionTableCall && initialSubscriptionTableCall != null && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericServiceNotProvisionedError}</h2>
                                                            <p className="mb-3">{GenericNoSubscriptionsError}</p>
                                                            <Button className="btn btn-lg btn-primary px-3">Provision Cloud Service</Button>
                                                        </div>
                                                    }
                                                    {!loading && emptyUserFilters && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericEmptyStateError}</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">{GenericEmptySubscriptionCloudServiceError}</h2>
                                                            <p className="mb-3">{AddSubscriptionMessage}</p>
                                                            <Button className="btn btn-lg btn-primary px-3">Buy Subscription</Button>
                                                        </div>
                                                    }
                                                    {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={table}
                                                            theme="solarized"
                                                            customStyles={customStyles}
                                                            persistTableHead={false}
                                                            noDataComponent={<div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscriptions...</p></div>}
                                                            width="100%"
                                                            fixedHeaderScrollHeight="60vh"
                                                            progressPending={loading}
                                                            progressComponent={<div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscriptions...</p></div>}
                                                            fixedHeader
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={totalRows}
                                                            paginationPerPage={pageSize}
                                                            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                            onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AvastServiceManagement