import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Col, Row, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import { cmsApi, api } from "../../../Utils/API";
import { LANDING_PAGE_CMS_DATA, VALIDATE_USER, VALIDATE_OTP } from '../../../Utils/GlobalConstants';
import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import BrandDetails from "../../../GlobalComponents/BrandDetails";
import {
    Check2Circle,
    XCircle,
} from "react-bootstrap-icons";
import AnonymousPageFormHeader from "../../../GlobalComponents/AnonymousPageFormHeader";
import { TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from "../../../Utils/ColorsConfiguration";

const MfaVerification = (props) => {
    const [mobileActive, setMobileActive] = useState("active");
    const [landingPageData, setLandingPageData] = useState();                     // Landing Page Data from CMS
    const [bannerData, setBannerData] = useState();                               // Banner Data from CMS
    const [backgroundImageUrl, setBackgroundImageUrl] = useState();               // BG Image from CMS
    const globalData = useContext(CmsDetailsContext);                             // Global Data from context
    const [loginEmailAddress, setLoginEmailAddress] = useState("");                 // Login Page Email Address
    const [loginPassword, setLoginPassword] = useState("");                         // Login Page Password
    const [MfaVerificationData, setMfaVerificationData] = useState();              // MFA Data
    const [verificationCode, setVerificationCode] = useState("");                   // MFA Verification Code
    const [loginInitiatedFlag, setLoginInitiatedFlag] = useState(false);                   // MFA Verification Code

    // Use State for Toasts
    const [myProfileSuccess, SetMyProfileSuccess] = useState(false);
    const [myProfileError, setMyProfileError] = useState(false);
    const [myProfileToastMessage, setMyProfileToastMessage] = useState("");

    // Function for calling Landing Page data from Orchid Core CMS
   
    // MFA Code Verify Function
    async function VerifyCode() {
        // console.log("Code : ", verificationCode);
        const data = {
            "userName": loginEmailAddress,
            "password": loginPassword,
            "otp": verificationCode
        };
        await api
            .post(VALIDATE_OTP, data)
            .then((resp) => {
                // handle success
                // console.log(resp.data);
                setMyProfileToastMessage("OTP Verified Successfully");
                SetMyProfileSuccess(true);
                setTimeout(() => {
                    SetMyProfileSuccess(false);
                    setLoginInitiatedFlag(false);
                }, 2000);
                localStorage.setItem("access_token", resp.data.token);
                localStorage.setItem("token_type", resp.data.tokenType);
                localStorage.setItem("Expire_time", resp.data.expire);
                localStorage.setItem("Accepted_Version", resp.data.acceptedTermsVersion);
                props.setCustomerIsAuthenticated(true);
            })
            .catch((error) => {
                // console.log(error);
                setLoginInitiatedFlag(false);
                setMyProfileToastMessage(`${error.response.data.errors[0]} Please try again !`);
                setMyProfileError(true);
                setTimeout(() => {
                    setMyProfileError(false);
                }, 2000);
            });
    };

     
    // Adding CSS Style to Landing Page Main Component
    const loginComponent = {
        backgroundImage: backgroundImageUrl,
        backgroundSize: "cover",
    };

    // To activate CSS Class when on mobile Screen
    const showMobileSceen = () => {
        setMobileActive("active");
    };

    return (

        <>
            <div className={`landing-page-alert ${myProfileSuccess ? "active" : ""}`}>
                <Alert variant="success">
                    <div className='d-flex align-items-center'>
                        <Check2Circle size={24} color={TOAST_SUCCESS_ICON_COLOR} />
                        <span className="mx-3 text-medium">{myProfileToastMessage}</span>
                    </div>
                </Alert>
            </div>

            <div className={`landing-page-alert ${myProfileError ? "active" : ""}`}>
                <Alert variant="danger">
                    <div className='d-flex align-items-center'>
                        <XCircle size={24} color={TOAST_ERROR_ICON_COLOR} />
                        <span className="mx-3 text-medium">{myProfileToastMessage}</span>
                    </div>
                </Alert>
            </div>

            <div className="d-flex flex-column login-page" style={loginComponent}>
                <div className="anonymous-login-main-div active">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <BrandDetails
                                    handleClick={showMobileSceen}
                                    bannerData={bannerData}
                                    customClass="d-none d-md-block"
                                />
                            </Col>
                            <Col md={6}>
                                <div className="anonymous-login-main-div active">
                                    <Col xl={8} lg={10} md={12} className="login-block">

                                      
                                            <>
                                                <Form className="activation-form">
                                                    <div>

                                                        <AnonymousPageFormHeader page="twoFactorAuthentication" pageData={landingPageData} setPageData={setLandingPageData} setBannerData={setBannerData} setBackgroundImageUrl={setBackgroundImageUrl} />
                                                        <Form.Group className="mb-3" controlId="userName">
                                                            <Form.Control
                                                                className="otp-number"
                                                                type="number"
                                                                placeholder="Enter Verification Code"
                                                                value={verificationCode}
                                                                onChange={(e) => setVerificationCode(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                                <div className="mobile-login-btns">
                                                    <div className="w-100">
                                                        {verificationCode.length == 6 &&
                                                            <Button
                                                                className="col-12 mb-4"
                                                                variant="primary"
                                                                type="button"
                                                                onClick={VerifyCode}
                                                            >
                                                                Verify
                                                            </Button>}
                                                        {(verificationCode.length < 6 || verificationCode.length > 6) && <Button
                                                            className="col-12 mb-4"
                                                            type="button"
                                                            disabled
                                                            variant="light"
                                                        >
                                                            Verify
                                                        </Button>}
                                                        <p className="text-center mt-3 login-back-link">
                                                            <Link to="/">
                                                                <label>Back to Sign In?</label>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>

                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>



    )
}

export default MfaVerification
