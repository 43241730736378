import React, { useState, useEffect } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Bell } from "react-bootstrap-icons";
import { Col, Form, NavDropdown, Row } from 'react-bootstrap';
import styles from './newCustomer.module.scss'
import { CUSTOMER_DOWNLOAD_CSV_SUMMARY, DASHBOARD_NEW_CUSTOMERS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Summary from './LocalComponents/Summary';
import Toast from '../../../../../GlobalComponents/Toast';
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR } from '../../../../../Utils/ColorsConfiguration';
import { GenericDownloadEmptyToast } from '../../../../../Utils/StaticMessages/ToastMessages';

const NewCustomers = ({ data, SaveDashboardWidget, id, widgetPositionData, Logout }) => {
    const date = new Date();                                                    // for js date time function

    const [barChartData, setBarChartData] = useState([{ data: [0], stack: 'A', label: 'New Customers' }])
    const [summaryPopup, setSummaryPopup] = useState(false);                    // summary popup
    const [tabName, setTabName] = useState("Summary");                          // tab name info.
    const [filterType, setFilterType] = useState('This Year');                  // dropdown filter filter type
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api
    const [fromDate, setFromDate] = useState(null);                       // getTableData() summary from date
    const [toDate, setToDate] = useState(null);                           // getTableData() summary to date

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?fromData=${fromDate}&toDate=${toDate}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all new Customer widget data
    async function GetAllNewCustomersCount() {
        await api.get(`${DASHBOARD_NEW_CUSTOMERS}?filterType=${filterType}`, config)
            .then(resp => {
                let localData = [];
                if (resp.data.graphData) {
                    localData.push(resp.data.graphData)
                }
                setBarChartData(localData);
                setBarChartDataSeries(resp.data.xAxisData)
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to delete widget in dashboard
    async function DeleteWidget() {
        let body = [];
        widgetPositionData.map((wd) => {
            if (wd.widgetName !== "add" && wd.id !== id) {
                body.push(wd);
            }
        })
        SaveDashboardWidget(body, true);
    };

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let date = new Date();
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            // console.log("Event Key :", eventKey, fromDate)
            setFromDate("01/01/" + date.getFullYear());
            setToDate(fromDate);
        }
        else {
            // console.log("Event Key : ", eventKey, `01/01/${date.getFullYear() - 1}`, `31/12/${date.getFullYear() - 1}`);
            setFromDate(`01/01/${date.getFullYear() - 1}`);
            setToDate(`31/12/${date.getFullYear() - 1}`);
        }
    };

    //   useEffect for calling get api on filter change
    useEffect(() => {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (filterType == "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setFromDate("01/01/" + date.getFullYear());
            setToDate(fromDate);
        }
        else {
            setFromDate(`01/01/${date.getFullYear() - 1}`);
            setToDate(`31/12/${date.getFullYear() - 1}`);
        }
        GetAllNewCustomersCount();
    }, [filterType])

    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
            <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
                <Col md={6} xs={6}>
                    <h3 className='mb-0'>New Customers</h3>
                </Col>
                <Col md={6} xs={6} className="text-right">
                    <span className="dashboard-arrow mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
                    <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details") }}></span>
                    <span className="dashboard-download mx-1 cpointer d-none-mobile"
                        onClick={() => { DownloadAction(CUSTOMER_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_customer.csv`, "csv") }}>
                    </span>
                    <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
                </Col>
                {
                    summaryPopup &&
                    <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} filterType={filterType} setFilterType={setFilterType} fromDate={fromDate} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} Logout={Logout} />
                }
            </Row>
            <Row className={`pt-2 ${styles["widget-header"]} `}>
                <Col lg={8} md={8} xs={8} ></Col>
                <Col lg={4} md={4} xs={4}>
                    <Form.Group >
                        <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                            <NavDropdown.Item
                                eventKey="Last Year"
                                className={filterType === "Last Year" ? "selected" : ""}
                            >Last Year</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="This Year"
                                className={filterType === "This Year" ? "selected" : ""}
                            >This Year</NavDropdown.Item>
                        </NavDropdown>
                    </Form.Group>
                </Col>
            </Row>
            {barChartData && <BarChart
                series={barChartData}
                grid={{ horizontal: true, vertical: true }}
                labelRotation={45}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                        labelStyle: {
                            fill: `${TEXT_SECONDARY}`
                        }
                    },
                }}
                colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR]}
                xAxis={[{
                    data: barChartDataSeries,
                    scaleType: 'band',
                    tickPlacement: 'middle',
                    categoryGapRatio: 0.6,
                    tickLabelPlacement: 'middle',
                }]}
                yAxis={[{
                    // tickNumber: 5,
                    tickMinStep: 1,
                    // valueFormatter: (value) => `$ ${(value / 1000).toLocaleString()}K`,
                }]}
                margin={{ top: 10, bottom: 50, left: 50, right: 50 }}
            />}
        </>
    )
}

export default NewCustomers