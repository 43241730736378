import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Form, NavDropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import loadingGif from "../../../Assets/Images/Animations/loading-management-console.gif";
import { emailRegEx } from '../../../GlobalComponents/RegExPatterns';
import Toast from '../../../GlobalComponents/Toast';
import { api } from '../../../Utils/API';
import { CANCEL_RENEWAL_NOTIFICATION, CONFIGURE_EMAIL_NOTIFICATION, RENEWAL_CONFIGURATIONS, RENEWALSETTINGS_DURATION, SUBSCRIPTIONS, SUBSCRIPTIONS_TYPES, RENEWAL_SETTINGS_CONFIG_DOWNLOAD } from '../../../Utils/GlobalConstants';
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../Utils/ColorsConfiguration';
import { CancelNotificationSuccessToast, ChangesSuccessToast, DownloadReportErrorToast, GenericDownloadEmptyToast, GenericErrorToast, RenewalSettingsNotificationSuccessToast, SavingErrorToast } from '../../../Utils/StaticMessages/ToastMessages';
import styles from './subscription.module.scss';

const Subscriptions = (props) => {

  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);                              // loading state for processing button
  const [subscriptionsTypes, setSubscriptionsTypes] = useState(null);                               // storing subscriptionTypes from get api

  const [errorStateMessage, setErrorStateMessage] = useState("");       // error state message                           

  //InvoiceNotificationEmail
  const [invoiceNotificationEmail, setInvoiceNotificationEmail] = useState([]);                    // storing email while user is adding emails  
  const [invoiceNotificationEmailList, setInvoiceNotificationEmailList] = useState([]);            // updating email list 
  const [invoiceNotificationEmailListDefault, setInvoiceNotificationEmailListDefault] = useState([]);// storing default email list from get api
  const [errorStateInvoiceNotification, setErrorStateInvoiceNotification] = useState(false);       // error state when email format is invalid                           

  //OrderConfirmationEmail
  const [orderConfirmationEmail, setOrderConfirmationEmail] = useState([]);                         // storing email while user is adding emails   
  const [orderConfirmationEmailList, setOrderConfirmationEmailList] = useState([]);                 // updating email list 
  const [orderConfirmationEmailListDefault, setOrderConfirmationEmailListDefault] = useState([]);   // storing default email list from get api
  const [errorStateOrderConfirmation, setErrorStateOrderConfirmation] = useState(false);            // error state when email format is invalid           

  //ItAdminEmail
  const [itAdminEmail, setItAdminEmail] = useState([]);                                             // storing email while user is adding emails 
  const [itAdminEmailList, setItAdminEmailList] = useState([]);                                     // updating email list 
  const [itAdminEmailListDefault, setItAdminEmailListDefault] = useState([]);                       // storing default email list from get api 
  const [errorStateItAdmin, setErrorStateItAdmin] = useState(false);                                // error state when email format is invalid

  //CloudNewsletterEmail
  const [cloudNewsletterEmail, setCloudNewsletterEmail] = useState([]);                             // storing email while user is adding emails 
  const [cloudNewsletterEmailList, setCloudNewsletterEmailList] = useState([]);                     // updating email list 
  const [cloudNewsletterEmailListDefault, setCloudNewsletterEmailListDefault] = useState([]);       // storing default email list from get api
  const [errorStateCloudNewsletter, setErrorStateCloudNewsletter] = useState(false);                // error state when email format is invalid

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                                         // toast message displaying success message
  const [toastError, setToastError] = useState(false);                                             // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                            // toast message  



  const [subscriptionsNotificationEmail, setSubscriptionsNotificationEmail] = useState('');
  const [subscriptionsNotificationEmailList, setSubscriptionsNotificationEmailList] = useState([]);
  const [errorStateSubscriptionsNotification, setErrorStateSubscriptionsNotification] = useState(false);
  const [durationOptions, setDurationOptions] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState('30 days');
  const [selectedDate, setSelectedDate] = useState('1st');
  const [isNotificationScheduled, setIsNotificationScheduled] = useState(false);
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [scheduledDuration, setScheduledDuration] = useState('');
  const [scheduledDate, setScheduledDate] = useState('1st');
  const [hasChanges, setHasChanges] = useState(false);
  const [isCancelConfirmationPopupOpen, setIsCancelConfirmationPopupOpen] = useState(false);
  const [isConfigButtonsEnabled, setIsConfigButtonsEnabled] = useState(false);
   // new state for configure notification popup
  const [isConfigurePopupOpen, setIsConfigurePopupOpen] = useState(false);
  const [configSubscriptionsNotificationEmail, setConfigSubscriptionsNotificationEmail] = useState('');
  const [configErrorState, setConfigErrorState] = useState(false);
  const [configErrorMessage, setConfigErrorMessage] = useState('');
  const [configDurationOptions, setConfigDurationOptions] = useState([]);
  const [configSelectedDuration, setConfigSelectedDuration] = useState('30');
  const [configSelectedDate, setConfigSelectedDate] = useState('1st');
  const [hasConfigChanges, setHasConfigChanges] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isDurationDropdownOpen, setIsDurationDropdownOpen] = useState(false);
  const dateDropdownRef = React.useRef(null);
  const durationDropdownRef = React.useRef(null);

  const [dropdownStates, setDropdownStates] = useState({
  date: false,
  duration: false
  });

 // Created refs for the dropdown containers
  const dropdownRefs = {
  date: useRef(null),
  duration: useRef(null)
  };

  useEffect(() => {
  const handleClickOutside = (event) => {
    Object.entries(dropdownRefs).forEach(([key, ref]) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownStates(prev => ({
          ...prev,
          [key]: false
        }));
      }
    });
  };

  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);

  // Cleanup
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    document.removeEventListener('touchstart', handleClickOutside);
  };
  }, []);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call email account details 
  async function GetSubaccountDetails() {
    await api.get(`${SUBSCRIPTIONS}`, config)
      .then(resp => {
        // console.log("Array Data Vidhi:", resp.data);
        resp.data.map((item) => {
          let EmailArray = item.emails ? item.emails.split(';') : [];
          // console.log("split::", EmailArray);
          if (item.subscriptionTypeId === 1) {
            setInvoiceNotificationEmailList(EmailArray);
            setInvoiceNotificationEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 2) {
            setOrderConfirmationEmailList(EmailArray);
            setOrderConfirmationEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 3) {
            setItAdminEmailList(EmailArray);
            setItAdminEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 4) {
            setCloudNewsletterEmailList(EmailArray);
            setCloudNewsletterEmailListDefault(EmailArray);
          }
        })
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
        }

      });
  };

  // Function to call subscriptions types  
  async function GetSubaccountDetailsTypes() {
    await api.get(`${SUBSCRIPTIONS_TYPES}`, config)
      .then(resp => {
        // console.log(resp.data)
        setSubscriptionsTypes(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
        }

      });
  };

  //post api for saving emails api
  async function SaveSubscriptionsEmail(changedEmailsData) {
    setConfirmLoadingFlag(true);
    const requestBody = changedEmailsData;

    await api.post(SUBSCRIPTIONS, requestBody, config)
      .then(resp => {
        setConfirmLoadingFlag(false);
        if (resp.status == 200) {
          setToastMessage(ChangesSuccessToast);
          setSubscriptionsTypes(null);
          setToastSuccess(true);
          GetSubaccountDetailsTypes();
          GetSubaccountDetails();
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        // console.log(error)
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
          setToastMessage(SavingErrorToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }

      });
  }

  useEffect(() => {
  const hasValidEmails = subscriptionsNotificationEmailList.length > 0;
  setIsConfigButtonsEnabled(hasValidEmails);
  }, [subscriptionsNotificationEmailList]);

  // updating email for InvoiceNotification
  const EmailSubmitInvoiceNotification = (e) => {
    const emailList = invoiceNotificationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateInvoiceNotification(true);
      }
    });

    if (isValid && invoiceNotificationEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (invoiceNotificationEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateInvoiceNotification(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setInvoiceNotificationEmailList([...invoiceNotificationEmailList, ...invoiceNotificationEmail.split(';').filter(email => email.trim() !== '')]);
        setInvoiceNotificationEmail('');
        setErrorStateInvoiceNotification(false);
      }
    } else {
      setErrorStateInvoiceNotification(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

  // updating email for OrderConfirmation
  const EmailSubmitOrderConfirmation = (e) => {
    const emailList = orderConfirmationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateOrderConfirmation(true);
      }
    });

    if (isValid && orderConfirmationEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (orderConfirmationEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateOrderConfirmation(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setOrderConfirmationEmailList([...orderConfirmationEmailList, ...orderConfirmationEmail.split(';').filter(email => email.trim() !== '')]);
        setOrderConfirmationEmail('');
        setErrorStateOrderConfirmation(false);
      }
    } else {
      setErrorStateOrderConfirmation(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };


  // updating email for ItAdmin
  const EmailSubmitItAdmin = (e) => {
    const emailList = itAdminEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateItAdmin(true);
      }
    });

    if (isValid && itAdminEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (itAdminEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateItAdmin(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setItAdminEmailList([...itAdminEmailList, ...itAdminEmail.split(';').filter(email => email.trim() !== '')]);
        setItAdminEmail('');
        setErrorStateItAdmin(false);
      }
    } else {
      setErrorStateItAdmin(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

  // updating email for ItAdmin
  const EmailSubmitCloudNewsletter = (e) => {
    const emailList = cloudNewsletterEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateCloudNewsletter(true);
      }
    });

    if (isValid && cloudNewsletterEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (cloudNewsletterEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateCloudNewsletter(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setCloudNewsletterEmailList([...cloudNewsletterEmailList, ...cloudNewsletterEmail.split(';').filter(email => email.trim() !== '')]);
        setCloudNewsletterEmail('');
        setErrorStateCloudNewsletter(false);
      }
    } else {
      setErrorStateCloudNewsletter(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };


  // function for adding email list data into post api
  const CheckAndSaveEmail = () => {
    let updatedChangedEmailsDataArray = [];

    if (JSON.stringify(invoiceNotificationEmailList) !== JSON.stringify(invoiceNotificationEmailListDefault)) {
      let formatted_emails = invoiceNotificationEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 1,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(orderConfirmationEmailList) !== JSON.stringify(orderConfirmationEmailListDefault)) {
      let formatted_emails = orderConfirmationEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 2,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(itAdminEmailList) !== JSON.stringify(itAdminEmailListDefault)) {
      let formatted_emails = itAdminEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 3,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(cloudNewsletterEmailList) !== JSON.stringify(cloudNewsletterEmailListDefault)) {
      let formatted_emails = cloudNewsletterEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 4,
        emails: formatted_emails
      });
    }

    if (updatedChangedEmailsDataArray.length > 0) {
      // Calling post api here
      SaveSubscriptionsEmail(updatedChangedEmailsDataArray);
    }
    // console.log(updatedChangedEmailsDataArray);
  };

  useEffect(() => {
  localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/subscriptions");
  const initializeData = async () => {
    try {
      // First get duration options
      const resp = await api.get(RENEWALSETTINGS_DURATION, config);
      const filteredOptions = resp.data.filter(option => option.id !== 'All');
      const allOptions = [ 
        ...filteredOptions,
        { id: 'All', name: 'All' }
      ];
      setDurationOptions(allOptions);

         // Then get renewal configurations
    const renewalResp = await api.get(RENEWAL_CONFIGURATIONS, config);

    if (renewalResp.status === 204) {
      setIsNotificationScheduled(false);
      return;
    }
    
    if (renewalResp.data) {
      // Set the date
      const day = renewalResp.data.scheduledDay;
      setSelectedDate(getOrdinalSuffix(day));
      setScheduledDate(getOrdinalSuffix(day));

      // Set the duration
      const duration = renewalResp.data.duration;
       if (duration === 0) {
          setSelectedDuration('All');
          setScheduledDuration('All');
        } else {
          const durationOption = allOptions.find(option => parseInt(option.id) === duration);
          console.log("Found duration option:", durationOption);
          
          if (durationOption) {
            setSelectedDuration(durationOption.name);
            setScheduledDuration(durationOption.name);
          }
        }

      // Set emails - properly split and process all emails
      if (renewalResp.data.emails) {
        const emailArray = renewalResp.data.emails
          .split(';')
          .map(email => email.trim())
          .filter(Boolean);
        
        // Set both lists at once to avoid race conditions
        setSubscriptionsNotificationEmailList(emailArray);
        setScheduledEmails(emailArray);
      }

      setIsNotificationScheduled(renewalResp.data.emails || duration || day);
    }

      
    } catch (error) {
      handleError(error);
    }
  };
  
  initializeData();
  // Get other subscription details
       GetSubaccountDetailsTypes();
       GetSubaccountDetails();
       window.scrollTo(0, 0);
  }, []);

  // Function to handle configuration popup
  const handleModifyClick = async () => {
    setIsConfigurePopupOpen(true);
  };

   // Utility function for ordinal numbers
  const getOrdinalSuffix = (n) => {
    const suffixes = ['st', 'nd', 'rd'];
    const tens = Math.floor(n / 10);
    const ones = n % 10;
    return (tens === 1) ? `${n}th` : `${n}${suffixes[(ones - 1) % 10] || 'th'}`;
  };

  const isButtonsEnabled = subscriptionsNotificationEmailList.length > 0;

  const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
  }

  const handleDownloadReport = async () => {
        try {
            const date = new Date();
            const durationValue = selectedDuration === "All" ? "" : selectedDuration.split(" ")[0];
            
            // Get current values from RenewalSettingsTab
            // const renewalSettings = renewalSettingsRef.current;
            const    pageNo = 1;
            const    pageSize = 15;
            const    sortCol = "remainingDayNumber";
            const    sortDir = "ASC";
            

            const integrationCode = "";
            const serviceId = 0;
            const customerId = "";
            const searchValueTable = "";

            const response = await api.get(
                `${RENEWAL_SETTINGS_CONFIG_DOWNLOAD}?duration=${durationValue || ''}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerId || ''}&PageNo=${pageNo}&PageSize=${pageSize}&searchText=${searchValueTable || ''}&SortCol=${sortCol || ''}&SortDir=${sortDir || ''}`,
                configBlob
            );

            if (response.status === 204) {
                setToastMessage(GenericDownloadEmptyToast);
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 2000);
                return;
            }

            downloadFile({
                data: response.data,
                fileName: `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_subscriptions renewal.csv`,
                fileType: "application/csv",
            });

        } catch (error) {
            console.error('Error downloading report:', error);
            if (error?.status === 401 || error?.response?.status === 401) {
                props.Logout();
                return;
            }

            setToastMessage(DownloadReportErrorToast);
            setToastError(true);
            setTimeout(() => {
                setToastError(false);
            }, 2000);
        }
  };

  // Handle notification scheduling
  const handleSchedule = async () => {
  try {
    // Add validation to ensure to have required data
    if (!subscriptionsNotificationEmailList.length) {
      setConfigErrorMessage("Please add at least one email address");
      setConfigErrorState(true);
      return;
    }

    const emailString = subscriptionsNotificationEmailList.join(';');
    const scheduledDay = parseInt(selectedDate);
    const durationNumber = selectedDuration === "All" ? 0 : parseInt(selectedDuration);

    const payload = [{
      scheduledDay: scheduledDay,
      duration: durationNumber,
      emails: emailString
    }];

    const response = await api.post(CONFIGURE_EMAIL_NOTIFICATION, payload, config);

    if (response.status === 200) {
      // Close the popup
      setIsConfigurePopupOpen(false);
      setIsNotificationScheduled(true);
      setHasConfigChanges(false);
      
      // Update main view
      setSelectedDuration(configSelectedDuration);
      setSelectedDate(configSelectedDate);
      setScheduledEmails([...subscriptionsNotificationEmailList]);
      // Recall renewal configurations API to get updated data
      await GetRenewalConfigurations();
       // Show success message
      setToastMessage(RenewalSettingsNotificationSuccessToast);   
      setToastSuccess(true);
      setTimeout(() => {
        setToastSuccess(false);
      }, 2000);
    }
  } catch (error) {
    handleError(error);
  }
  };

  // Handle email submission in configuration popup
  const handleConfigEmailSubmit = (e) => {
  const emailList = configSubscriptionsNotificationEmail.split(';').filter(email => email.trim() !== '');
  e.preventDefault();

  let isValid = true;
  emailList.forEach(email => {
    if (!emailRegEx.test(email.trim())) {
      isValid = false;
      setConfigErrorState(true);
      setConfigErrorMessage("Please enter valid email addresses");
    }
  });

  if (isValid && subscriptionsNotificationEmailList.length < 5) {
    if (subscriptionsNotificationEmailList.some(email => emailList.includes(email))) {
      setConfigErrorState(true);
      setConfigErrorMessage("Duplicate email is not allowed");
    } else {
      setSubscriptionsNotificationEmailList([...subscriptionsNotificationEmailList, ...emailList]);
      setConfigSubscriptionsNotificationEmail('');
      setConfigErrorState(false);
      setConfigErrorMessage('');
      setHasConfigChanges(true);
      // This will trigger the useEffect to enable buttons
    }
  } else if (subscriptionsNotificationEmailList.length >= 5) {
    setConfigErrorState(true);
    setConfigErrorMessage("Maximum 5 email addresses allowed");
  } else {
    setConfigErrorState(true);
    setConfigErrorMessage("Please enter valid email addresses");
  }
  };

  // Add Configure Notification Popup JSX
  const renderConfigurePopup = () => (
    <Popup
      open={isConfigurePopupOpen}
      modal
      className="custom-modal custom-modal-xxl"
      onClose={() => setIsConfigurePopupOpen(false)}
    >
      {close => (
        <div className="modal py-3">
          <Container fluid>
        <Row className={`mb-4 py-3 ${styles['border-header']}`}>
          <Col xs={9} lg={5} md={8} className="d-flex align-items-center justify-content-between">
            <h2 className="mb-0">Configure Notification</h2>     
          </Col>
          
          {/* Desktop Download Report button */}
          <Col xs={12} lg={6} md={3} className={`text-right ${styles['downloadreport-header']} d-none d-md-block`}>
            <Button 
              variant="light" 
              className={`mr-1 px-4 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
              onClick={handleDownloadReport}
            >
              Download Report
            </Button>
          </Col>

          <Col xs={3} lg={1} md={1} className={`py-2 text-right`}>
          <XLg
              size={16}
              className="cpointer text-strong"
              onClick={close}
              color="#6A6A6A"
            />
          </Col>

          {/* Mobile-only download button */}
          <Col xs={12} className={`text-right mt-2 d-md-none`}>
              <Button
                variant="light"
                className={`btn-border-light ${styles['configure-notification-button']}`}
                onClick={handleDownloadReport}
              >
                Download Report
              </Button>
          </Col>
          
        </Row>

         <div className={`${styles['content']}`}>
          <div className={`${styles['notificationSettings']}`}>
    <div className={`${styles['notification-group']}`}>
      <span>Send notification email on</span>
      <div ref={dropdownRefs.date}>
  <NavDropdown
    show={dropdownStates.date}
    onToggle={(isOpen) => setDropdownStates(prev => ({ ...prev, date: isOpen }))}
    className={`servcies-dropdown dropdown-specialclass-forfour dropdown-service ${styles['border-width-config-date']}`}
    title={selectedDate}
    id="cloud-service-dropdown"
    onSelect={(eventKey) => {
      setSelectedDate(getOrdinalSuffix(parseInt(eventKey)));
      setDropdownStates(prev => ({ ...prev, date: false }));
    }}
  >
    {Array.from({ length: 25 }, (_, index) => (
      <NavDropdown.Item key={index} eventKey={index + 1}>
        {getOrdinalSuffix(index + 1)}
      </NavDropdown.Item>
    ))}
  </NavDropdown>
</div>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>of each month showing</span>
      <div ref={dropdownRefs.duration}>
  <NavDropdown 
    show={dropdownStates.duration}
    onToggle={(isOpen) => setDropdownStates(prev => ({ ...prev, duration: isOpen }))}
    className={`servcies-dropdown dropdown-specialclass-forthree dropdown-service ${styles['border-width-config-month']}`}
    title={`${selectedDuration}`}
    id="duration-dropdown"
    onSelect={(eventKey) => {
      setSelectedDuration(eventKey);
      setDropdownStates(prev => ({ ...prev, duration: false }));
    }}
  >
    {durationOptions.map(option => (
      <NavDropdown.Item 
        key={option.id}
        eventKey={option.id}
        className={selectedDuration === option.id ? "selected" : ""}
      >
        {`${option.name}`}
      </NavDropdown.Item>
    ))}
  </NavDropdown>
</div>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>renewal date</span>
    </div>
  </div>

          <div className={`${styles['reportNote']}`}>
            Report will be sent to the reseller in an <span>CSV format</span> with customer subscription renewal details.
          </div>

          <div className="send-notifications-tab__resellers-email">
            <div className='px-2'>
              <Row className='px-3 align-items-center'>
                <h3>Resellers Email Id</h3>
                <Col md={4}>
                    <Form.Group controlId="configSubscriptionsNotificationEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${subscriptionsNotificationEmailList.length >= 5 ? "text-danger" : ""}`}>
                          Max 5
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={configSubscriptionsNotificationEmail}
                        maxLength="250"
                        className={configErrorState ? "border-danger" : ""}
                        onChange={(e) => {
                          setConfigErrorState(false);
                          setConfigErrorMessage("");
                          let count = e.target.value.split(';').length;
                          if (subscriptionsNotificationEmailList.length + count <= 5) {
                            setConfigSubscriptionsNotificationEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleConfigEmailSubmit(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!configErrorState && configSubscriptionsNotificationEmail.length > 0 && 
                      <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>
                    }
                    {configErrorState ? 
                    <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                    <p className='empty-paragragh mb-6'></p>
                    }
                  </Col>
                <Col md={8}>
                  <div className='mt-1 mb-0 d-flex flex-wrap'>
                    {console.log("all email ids;,", subscriptionsNotificationEmailList)}
                    {subscriptionsNotificationEmailList.map((email, index) => (
                      <div key={index} className={`mr-2 mb-0 px-1 col-lg-3 col-md-4 col-xs-5 ${styles['email-member-options']}`}>
                        <label className='ml-0'>
                          <span>{email}</span>
                          <XLg
                            size={16}
                            className={`cpointer text-strong ${styles['close-icon-popup-sm']}`}
                            onClick={() => {
                              const updatedEmailList = [...subscriptionsNotificationEmailList];
                              updatedEmailList.splice(index, 1);
                              setSubscriptionsNotificationEmailList(updatedEmailList);
                            }}
                            color="#6A6A6A"
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          
          <Row className="py-3 mb-4">
          <Col md={7} lg={7.5} className={`${styles['note']} ${styles['notes-confirmation-configure-notification']}`}>
          <span>Note:</span> Renewal price is based on current pricing, please check back closer to renewal date.
          </Col>
          <Col md={5} lg={4.5} className="d-flex justify-content-end">
          {isNotificationScheduled ? (
          <>
          <Button 
          variant="light"
          className={`ml-3 mr-3 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
          onClick={handleCancelNotification}
          >
          Cancel Notification
          </Button>
          <Button 
          variant={hasChanges ? "primary" : "light"} 
          className={`ml-3 ${hasChanges ? "text-white" : "btn-border-light"} w-md-auto col-xs-12  ${styles['configure-notification-button']}`}
          onClick={handleSchedule}
          disabled={!hasChanges}
        >
          Update
        </Button>
        </>
        ) : (
        <>
        <Button 
          variant="light"
          className={`ml-3 mr-3 btn-border-light w-md-auto col-xs-12 ${styles['configure-notification-button']}`}
          disabled={!isButtonsEnabled}
          onClick={() => {
            setSubscriptionsNotificationEmailList([]);
            setSubscriptionsNotificationEmail('');
          }}
        >
          Clear
        </Button>
        <Button
          variant={isButtonsEnabled ? "primary" : "light"}
          className={`ml-3 ${isButtonsEnabled ? "text-white" : "btn-border-light"} w-md-auto col-xs-12  ${styles['configure-notification-button']}`}
          disabled={!isButtonsEnabled}
          onClick={handleSchedule}
        >
          Schedule
        </Button>
        </>
        )}
          </Col>
          </Row>
        </div>
      </Container>
        </div>
      )}
    </Popup>
  );

  const handleCancelNotification = () => {
    setIsCancelConfirmationPopupOpen(true);
  };

  const confirmCancelNotification = async () => {
    try {
      const response = await api.delete(CANCEL_RENEWAL_NOTIFICATION, config);

      if (response.status === 200) {
        setSubscriptionsNotificationEmailList([]);
        setSubscriptionsNotificationEmail('');
        setErrorStateSubscriptionsNotification(false);
        setErrorStateMessage('');
        setIsCancelConfirmationPopupOpen(false);
        setIsConfigurePopupOpen(false);
        setIsNotificationScheduled(false);
        setScheduledEmails([]);
        setScheduledDuration('');
        setSelectedDate('1st');
        setScheduledDate('1st');
        setHasChanges(false);
        setToastMessage(CancelNotificationSuccessToast);
        await GetRenewalConfigurations();
        setToastMessage(CancelNotificationSuccessToast);
        setToastSuccess(true);
        setTimeout(() => setToastSuccess(false), 2000);
      }
    } catch (error) {
      handleError(error);
    }
  };

  async function GetRenewalConfigurations() {
  try {
    const response = await api.get(RENEWAL_CONFIGURATIONS, config);
    
    if (response.data) {
      // Set the date
      const day = response.data.scheduledDay;
      setSelectedDate(getOrdinalSuffix(day));
      setScheduledDate(getOrdinalSuffix(day));

      // Set the duration
      const duration = response.data.duration;

      if (duration === 0) {
          setSelectedDuration('All');
          setScheduledDuration('All');
        } else {
          const durationOption = durationOptions.find(option => parseInt(option.id) === duration);
          console.log("Found duration option:", durationOption);
          
          if (durationOption) {
            setSelectedDuration(durationOption.name);
            setScheduledDuration(durationOption.name);
          }
        }

      // Set emails - split by comma and handle potential whitespace
      if (response.data.emails) {
        const emailArray = response.data.emails.split(';').map(email => email.trim());
        setSubscriptionsNotificationEmailList(emailArray);
        setScheduledEmails(emailArray);
      }

      if (response.data.emails || duration || day) {
        setIsNotificationScheduled(true);
      }
    }
  } catch (error) {
    handleError(error);
  }
  }

  // Error handler
  const handleError = (error) => {
    if (error?.status === 401 || error?.response?.status === 401) {
      props.Logout();
      return;
    }
    setToastMessage(GenericErrorToast);
    setToastError(true);
    setTimeout(() => setToastError(false), 2000);
  };

  const EmailSubmitSubscriptionsNotification = (e) => {
    const emailList = subscriptionsNotificationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;
    emailList.forEach(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateSubscriptionsNotification(true);
      }
    });

    if (isValid && subscriptionsNotificationEmailList.length < 5) {
      if (subscriptionsNotificationEmailList.some(email => emailList.includes(email))) {
        setErrorStateSubscriptionsNotification(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        setSubscriptionsNotificationEmailList([...subscriptionsNotificationEmailList, ...emailList]);
        setSubscriptionsNotificationEmail('');
        setErrorStateSubscriptionsNotification(false);
        setHasChanges(true);
      }
    } else {
      setErrorStateSubscriptionsNotification(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };


  useEffect(() => {
  if (isNotificationScheduled) {
    const emailsChanged = JSON.stringify(scheduledEmails) !== JSON.stringify(subscriptionsNotificationEmailList);
    const durationChanged = scheduledDuration !== selectedDuration;
    const dateChanged = scheduledDate !== selectedDate;
    console.log("haschanges", emailsChanged, durationChanged, dateChanged );

    setHasChanges(emailsChanged || durationChanged || dateChanged);
  }
  }, [subscriptionsNotificationEmailList, selectedDuration, selectedDate, isNotificationScheduled]);


  return (
    <div className="main-content">
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />


    <Popup
      open={isCancelConfirmationPopupOpen}
      modal
      className="custom-modal custom-modal-md"
      onClose={() => setIsCancelConfirmationPopupOpen(false)}
    >
      {(close) => (
        <div className="modal">
          <div className="header p-4 py-3">
            <h2>Cancel Notification</h2>
            <XLg
              size={16}
              className="cpointer text-strong"
              onClick={() => {
                close();
                setIsCancelConfirmationPopupOpen(false);
              }}
              color="#6A6A6A"
            />
          </div>

          <Container className="p-4">
            <p>Are you sure you want to cancel the notification?</p>
            <div className="actions">
              <div className="d-flex justify-content-end">
                <Button
                  className={`btn btn-lg mx-3 btn-border-light ${styles['configure-notification-button']}`}
                  variant="light"
                  onClick={() => {
                    setIsCancelConfirmationPopupOpen(false);
                    close();
                  }}
                >
                  No
                </Button>
                <Button
                  className={`btn btn-md ${styles['configure-notification-button']}`}
                  onClick={() => {
                    confirmCancelNotification();
                    close();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </Popup>


      {/* Render Configure Notification Popup */}
      {renderConfigurePopup()}


    <Popup
      open={isCancelConfirmationPopupOpen}
      modal
      className="custom-modal custom-modal-md"
      onClose={() => setIsCancelConfirmationPopupOpen(false)}
    >
      {(close) => (
        <div className="modal">
          <div className="header p-4 py-3">
            <h2>Cancel Notification</h2>
            <XLg
              size={16}
              className="cpointer text-strong"
              onClick={() => {
                close();
                setIsCancelConfirmationPopupOpen(false);
              }}
              color="#6A6A6A"
            />
          </div>

          <Container className="p-4">
            <p>Are you sure you want to cancel the notification?</p>
            <div className="actions">
              <div className="d-flex justify-content-end">
                <Button
                  className={`btn btn-lg mx-3 btn-border-light ${styles['configure-notification-button']}`}
                  variant="light"
                  onClick={() => {
                    setIsCancelConfirmationPopupOpen(false);
                    close();
                  }}
                >
                  No
                </Button>
                <Button
                  className={`btn btn-md ${styles['configure-notification-button']}`}
                  onClick={() => {
                    confirmCancelNotification();
                    close();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </Popup>


      {/* Render Configure Notification Popup */}
      {renderConfigurePopup()}

      <div className={`${styles['profile-table']}`}>
        <h1>Subscriptions
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Manage your synnex cloud marketplace subscriptions.</Tooltip>}

          >
            <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
          </OverlayTrigger>
        </h1>
        <div className={`my-2 ${styles['my-profile-last-section']}`}>
          <div className='mt-3 py-4 details-box'>

            {!subscriptionsTypes &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
              </div>
            }

            {subscriptionsTypes &&
              <div className='px-2'>
                <Row className='px-3 align-items-center'>
                  <h3>Invoice Notification</h3>
                  <Col md={4}>
                    <Form.Group controlId="invoiceNotificationEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${invoiceNotificationEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={invoiceNotificationEmail}
                        maxLength="250"
                        className={errorStateInvoiceNotification ? "border-danger" : ""}
                        name="invoiceNotificationEmail"
                        onChange={(e) => {
                          setErrorStateInvoiceNotification(false);
                          setErrorStateMessage("");
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,invoiceNotificationEmail.length+count<=5);
                          if(invoiceNotificationEmailList.length+count<=5){
                          setInvoiceNotificationEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && invoiceNotificationEmailList.length < 5 && invoiceNotificationEmailList.length+count<=5) {
                            EmailSubmitInvoiceNotification(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateInvoiceNotification && invoiceNotificationEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateInvoiceNotification ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {invoiceNotificationEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-lg-3 col-md-4 col-xs-5 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...invoiceNotificationEmailList];
                                updatedEmailList.splice(index, 1);
                                setInvoiceNotificationEmailList(updatedEmailList);
                              }}
                              color={TEXT_SECONDARY}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className="px-3 py-3 align-items-center">
                  <h3>Order Confirmation</h3>
                  <Col md={4}>
                    <Form.Group controlId="orderConfirmationEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${orderConfirmationEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={orderConfirmationEmail}
                        className={errorStateOrderConfirmation ? "border-danger" : ""}
                        maxLength="250"
                        name="orderConfirmationEmail"
                        onChange={(e) => {
                          setErrorStateOrderConfirmation(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,orderConfirmationEmailList.length+count<=5);
                          if(orderConfirmationEmailList.length+count<=5){
                            setOrderConfirmationEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                            if (e.key === 'Enter' && orderConfirmationEmailList.length < 5 && orderConfirmationEmailList.length+count<=5) {
                              EmailSubmitOrderConfirmation(e);
                            }
                        }}
                      />
                    </Form.Group>
                    {!errorStateOrderConfirmation && orderConfirmationEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateOrderConfirmation ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {orderConfirmationEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...orderConfirmationEmailList];
                                updatedEmailList.splice(index, 1);
                                setOrderConfirmationEmailList(updatedEmailList);
                              }}
                              color={TEXT_SECONDARY}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className='px-3 py-3 align-items-center'>
                  <h3>IT Admin Credentials</h3>
                  <Col md={4}>
                    <Form.Group controlId="itAdminEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${itAdminEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={itAdminEmail}
                        className={errorStateItAdmin ? "border-danger" : ""}
                        maxLength="250"
                        name="itAdminEmail"
                        onChange={(e) => {
                          setErrorStateItAdmin(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,itAdminEmail.length+count<=5);
                          if(itAdminEmailList.length+count<=5){
                          setItAdminEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && itAdminEmailList.length < 5 && itAdminEmailList.length+count<=5) {
                            EmailSubmitItAdmin(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateItAdmin && itAdminEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateItAdmin ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {itAdminEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...itAdminEmailList];
                                updatedEmailList.splice(index, 1);
                                setItAdminEmailList(updatedEmailList);
                              }}
                              color={TEXT_SECONDARY}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className='px-3 py-3 align-items-center'>
                  <h3>Cloud Newsletter</h3>
                  <Col md={4}>
                    <Form.Group controlId="cloudNewsletterEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${cloudNewsletterEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={cloudNewsletterEmail}
                        className={errorStateCloudNewsletter ? "border-danger" : ""}
                        maxLength="250"
                        name="cloudNewsletterEmail"
                        onChange={(e) => {
                          setErrorStateCloudNewsletter(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,cloudNewsletterEmail.length+count<=5);
                          if(cloudNewsletterEmailList.length+count<=5){
                          setCloudNewsletterEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && cloudNewsletterEmailList.length < 5 && cloudNewsletterEmailList.length+count<=5) {
                            EmailSubmitCloudNewsletter(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateCloudNewsletter && cloudNewsletterEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateCloudNewsletter ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {cloudNewsletterEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...cloudNewsletterEmailList];
                                updatedEmailList.splice(index, 1);
                                setCloudNewsletterEmailList(updatedEmailList);
                              }}
                              color={TEXT_SECONDARY}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>}
            {subscriptionsTypes && <div className="btn-wrapper text-right mx-4">
              {!confirmLoadingFlag && <>
                {
                  (
                    JSON.stringify(invoiceNotificationEmailList) === JSON.stringify(invoiceNotificationEmailListDefault) &&
                    JSON.stringify(orderConfirmationEmailList) === JSON.stringify(orderConfirmationEmailListDefault) &&
                    JSON.stringify(itAdminEmailList) === JSON.stringify(itAdminEmailListDefault) &&
                    JSON.stringify(cloudNewsletterEmailList) === JSON.stringify(cloudNewsletterEmailListDefault)) ?
                    <Button
                      className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      disabled  >Clear</Button> :
                    <Button
                      className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      onClick={() => {
                        setInvoiceNotificationEmailList(invoiceNotificationEmailListDefault);
                        setOrderConfirmationEmailList(orderConfirmationEmailListDefault);
                        setItAdminEmailList(itAdminEmailListDefault);
                        setCloudNewsletterEmailList(cloudNewsletterEmailListDefault);
                        setCloudNewsletterEmail('');
                        setItAdminEmail('');
                        setOrderConfirmationEmail('');
                        setInvoiceNotificationEmail('');
                      }}
                    >
                      Clear
                    </Button>
                }
                {(
                  JSON.stringify(invoiceNotificationEmailList) === JSON.stringify(invoiceNotificationEmailListDefault) &&
                  JSON.stringify(orderConfirmationEmailList) === JSON.stringify(orderConfirmationEmailListDefault) &&
                  JSON.stringify(itAdminEmailList) === JSON.stringify(itAdminEmailListDefault) &&
                  JSON.stringify(cloudNewsletterEmailList) === JSON.stringify(cloudNewsletterEmailListDefault)) ?
                  <Button
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled  >Save</Button> :
                  <Button
                    className="px-4 mx-2"
                    onClick={() => {
                      CheckAndSaveEmail();
                    }}
                  >Save</Button>
                }
              </>}
              {
                confirmLoadingFlag &&
                <>
                  <Button
                    variant="light"
                    disabled
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                  >Clear
                  </Button>
                  <Button
                    disabled
                    className="ml-3 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                  >
                    Processing . . .
                  </Button>
                </>
              }
            </div>}
          </div>

        </div>
      </div>

      {/* New Notification Configuration Section */}
      <div className={`${styles['profile-table']}`}>
      <div className="details-box mt-4">
      <Container fluid>
      <Row className="py-3">
        <Col xs={12}>
          <h3>Current Renewal Configuration</h3>
        </Col>
      </Row>

      <div className={`${styles['content']} ${styles['my-profile-last-section']} mb-4 py-3`}>
        {isNotificationScheduled ? (
          // Show full content when notification is configured
          <div className="pb-4">
           <div className={`${styles['subscriptionsRenewalConfiguration']}`}>
    <div className={`${styles['notification-group']}`}>
      <span>Send notification email on</span>
      <NavDropdown
        className={`disabled servcies-dropdown dropdown-specialclass-fortwo dropdown-service ${styles['border-width-subscriptions-date']}`}
        title={<span className="fw-bold">{selectedDate}</span>}
        id="cloud-service-dropdown"
        disabled={true}
        onSelect={(eventKey) => {
          setSelectedDate(getOrdinalSuffix(parseInt(eventKey)));
        }}
      >
        {Array.from({ length: 25 }, (_, index) => (
          <NavDropdown.Item key={index} eventKey={index + 1}>
            {getOrdinalSuffix(index + 1)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>of each month showing</span>
      <NavDropdown 
        className={`disabled servcies-dropdown dropdown-specialclass-fortwo dropdown-service ${styles['border-width-subscriptions-month']}`}
        title={<span className="fw-bold">{selectedDuration}</span>}
        disabled={true}
        id="duration-dropdown"
        onSelect={(eventKey) => {
          setSelectedDuration(eventKey);
        }}
      >
        {durationOptions.map(option => (
          <NavDropdown.Item 
            key={option.id}
            eventKey={option.id}
            className={selectedDuration === option.id ? "selected" : ""}
          >
            {`${option.name}`}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </div>

    <div className={`${styles['notification-group']}`}>
      <span>renewal date</span>
    </div>
           </div>

           <div className={`${styles['subscriptionsRenewalConfiguration']}`}>
    <div className="d-flex align-items-center flex-wrap">
      {subscriptionsNotificationEmailList.map((email, index) => (
        <div key={index} className={`${styles.emailItem} me-3`}>
          <span>{email}</span>
        </div>
      ))}
    </div>
           </div>

           <div className="d-flex justify-content-end mt-4">
    <Button 
      variant="light" 
      className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
      onClick={handleModifyClick}
    >
      Modify
    </Button>
           </div>
          </div>
          ) : (
          // Show only message and Schedule button when no notification is configured
          <div className="pb-4">
            <div className="mb-4 mt-3">
              <p>There are no Notifications configured.</p>
            </div>
            <div className="d-flex justify-content-end">
              <Button 
                variant="primary" 
                className="mr-1 px-4 text-white btn-border-light w-md-auto col-xs-12"
                onClick={handleModifyClick}
              >
                Configure
              </Button>
            </div>
          </div>
          )}
      </div>
      </Container>
      </div>
      </div>
    </div>
  )
}

export default Subscriptions
