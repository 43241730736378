export const TEXT_SECONDARY = "#6A6A6A";
export const BACKGROUND_COLOR_SECONDARY = "#FAFAFA";
export const BACKGROUND_COLOR_TERTIARY = "#F6F6F6";
export const TOAST_SUCCESS_ICON_COLOR = "#1D874C";
export const TOAST_ERROR_ICON_COLOR = "#9C3B35";
export const REACT_DATA_TABLE_COLOR_THEME = {
    text: {
        primary: '#000000',
    },
    background: {
        default: '#FFFFFF',
    },
    divider: {
        default: '#d7dadf',
    },
    striped: {
        default: '#f5f5f5',
    },
};
export const WIDGETS_PRIMARY_COLOR = "#3599DA"; // Also used in Notifications Icon
export const WIDGETS_SECONDARY_COLOR = "#F9A932";
export const WIDGETS_ALTERNATE_PRIMARY_COLOR = "#77BA1D";
export const WIDGETS_ALTERNATE_SECONDARY_COLOR = "#5A63E7";
export const WIDGETS_TERTIARY_COLOR = "#E11F13";
export const WIDGETS_ALTERNATE_TERTIARY_COLOR = "#FF53BA";
export const WIDGETS_LABEL_FILL_COLOR = "#585757";
export const NOTIFICATIONS_READ_COLOR = "#FFFFFF";
export const BORDER_DATA_TABLE_BOTTOM_COLOR = "#DDDDDD";
export const ROW_HIGHLIGHT_TABLE_BG_COLOR = "#EFF5FB";
export const DISABLE_EXPAND_BUTTON_COLOR = "#999999";
export const INVOICE_AMOUNT_WIDGET_BLENDED_COLOR = ['hsla(204, 69%, 53%, 0.17)', '#F9A932'];