// All the toast messages for entire application will be dispalyed here

export const GenericDownloadEmptyToast = "No Data available to Download !";
export const AgreementMappingBulkUploadToast = "Report downloaded successfully!";
export const BulkUploadFileUploadSuccessToast = "File uploaded successfully!";
export const AgreementMappingUnmapSuccessToast = "Your subscription has been unmapped successfully.";
export const AgreementMappingUnmapFailureToast = "Your subscription unmapping is failed.";
export const GenericErrorMessageToast = "We encountered an issue while submitting the request. Please try again later.";
export const CreditCardPrimarySuccessToast = "Primary Card Updated.";
export const CreditCardPrimaryFailureToast = "We encountered an issue while trying to update your primary card. Please try again later.";
export const CreditCardDeleteSuccessToast = "Card deleted successfully.";
export const CreditCardDeleteFailureToast = "We encountered an issue while deleting the card. Please try again later.";
export const DirectDebitSucessToast = "Request submitted successfully.";
export const DirectDebitFailureToast = "We encountered an issue while submitting the request.";
export const PaymentMethodsUpdationSuccessToast = "Payment method updated successfully";
export const PaymentMethodsUpdationFailureToast = "We encountered an issue while trying to update your payment method. Please try again later.";
export const AzureProvisionErrorToast = "Azure Product Provision Failed at the moment, try again later.";
export const ServerErrorToast = "Difficult in reaching servers, try again later.";
export const CSPProvisionErrorToast = "CSP Product Provision Failed at the moment, try again later.";
export const GCPProvisionErrorToast = "GCP Product Provision Failed at the moment, try again later.";
export const EmailSuccessToast = "Email sent successfully.";
export const SubmitRequestErrorToast = "There is some issue while submitting the request. Please try again later.";
export const UserDeletionSuccessToast = "User Deleted Successfully";
export const UserDeletionErrorToast = "User could not be deleted at the moment, try again later!";
export const SubaccountActivateSuccessToast = "Subaccount activated successfully.";
export const SubaccountDeactivateSuccessToast = "Subaccount deactivated successfully.";
export const UserUpdationSuccessToast = "User details updated successfully.";
export const UserUpdationErrorToast = "We encountered an issue while updating user details. Please try again later.";
export const UserCloneSuccessToast = "User cloned successfully.";
export const UserCreationSuccessToast = "User created successfully.";
export const RenewalSettingsNotificationSuccessToast = "Renewal settings email notification saved";
export const ScheduleNotificationErrorToast = "Error scheduling notification. Please try again.";
export const CancelNotificationSuccessToast = "Notification is cancelled successfully";
export const CancelNotificationErrorToast = "Error canceling notification. Please try again.";
export const DownloadReportErrorToast = "Error downloading report. Please try again.";
export const CustomerFetchingErrorToast = "Error fetching customer list. Please try again.";
export const ServiceFetchingErrorToast = "Error fetching services. Please try again.";
export const RenewalSettingSuccessToast = "Renewal setting updated  successfully. ";
export const RenewalSettingErrorToast = "We encountered an issue while updating the renewal setting. Please try again later.";
export const ChangesSuccessToast = "Changes successfully updated.";
export const SavingErrorToast = "There seems an error while saving. Please try again later.";
export const GenericErrorToast = "An error occurred. Please try again.";
export const ProfileSuccessToast = "Profile updated successfully.";
export const PasswordSuccessToast = "Password changed successfully.";
export const MPNIdErrorToast = "Error while saving MPN ID!";
export const MPNIdSavedSuccessToast = "MPN ID Saved Successfully.";
export const MPNIdUpdationSuccessToast = "MPN ID Updated Successfully";
export const NotificationDeletionSuccessToast = "Notifications deleted successfully.";
export const NotificationDeletionErrorToast = "We encountered an issue while deleting the notification. Please try again later.";
export const NotificationSuccessToast = "All Notifications deleted successfully.";
export const NotificationErrorToast = "Some issue in reading notification, please try again after some time!";
export const CustomerActivatedSuccessToast = "Customer activated successfully";
export const CustomerDeactivatedSuccessToast = "Customer deactivated successfully.";
export const ChangeStatusErrorToast = "We encountered an issue while changing status of the customer. Please try again later.";
export const CustomerCreationSuccessToast = "Customer created successfully.";
export const TrialtoPaidSuccessToast = "The Trial Plan has been successfully converted to a Paid Plan.";
export const TrialtoPaidErrorToast = "Trial Plan could not be converted to Paid Plan at the moment, try again later.";
export const AcronisCommitmentSuccessToast = "Commitment Value Upgraded Successfully";
export const AcronisCommitmentErrorToast = "Commitment Value could not be Upgraded at the moment, try again later.";
export const SubscriptionActivateErrorToast = "Subscription activation failed. Please try again.";
export const SubscriptionActivateSuccessToast = "Subscription activated successfully";
export const SubscriptionSuspensionSuccessToast = "Subscription suspended successfully";
export const SubscriptionSuspensionErrorToast = "Subscription suspension failed. Please try again.";
export const RoleDeletionSuccessToast = "Role deleted successfully";
export const RoleDeletionErrorToast = "We encountered an issue while deleting the role. Please try again later.";
export const GDAPrequestSuccessToast = "New GDAP request has been sent successfully.";
export const ReactivationgErrorToast = "Facing issue in reactivating subscription. Please try again later.";
export const SuspendSubscriptionErrorToast = "We encountered an issue while suspending the subscription. Please try again later.";
export const CancelSubscriptionSuccessToast = "Subscription is cancelled successfully.";
export const CancelSubscriptionErrorToast = "We encountered an issue while cancelling the subscription. Please try again later.";
export const QuantitySuccessToast = "Quantity has been Updated Successfully";
export const LegacyEligibilitySuccessToast = "Legacy plan selected is eligible for migration.";
export const LegacyEligibilityErrorToast = "We encountered an issue while checking legacy plan eligibily. Please try again later.";
export const PurchaseAddonSuccessToast = "Addon Purchased Successfully.";
export const PurchaseAddonFailureToast = "Purchase addons failed.";
export const ManageRenewalSuccessToast = "Plan Renewed Successfully.";
export const DeleteScheduleRenewalSuccessToast = "Scheduled Renewal is Deleted Successfully.";
export const DeleteScheduleRenewalErrorToast = "Facing issue in deleting scheduled Renewal. Please try again later.";
export const AutoRenewErrorToast = "We encountered an issue while changing the Auto Renew setting. Please try again later.";
export const TrialToPaidErrorToast = "Trial Plan could not be converted to Paid Plan at the moment. Try again later.";
export const UpgradeSuccessToast = "Upgrade successful.";
export const UpgradeFailedToast = "Upgrade failed.";
export const GCPUserRolesSuccessToast = "User roles have been saved successfully.";
export const ChangePaymentPlanSuccessToast = "Payment plan updated successfully.";
export const ChangePaymentPlanErrorToast = "We encountered an issue while updating the payment plan. Please try again later.";
export const PurchaseAddonErrorToast = "We encountered an issue while purchasing an Addon plan. Please try again later.";
export const QuantityUpdateErrorToast = "We encountered an issue while updating the quantity. Please try again later.";
export const TrialToPaidSubscriptionSuccessToast = "Subscription has been converted to Paid Plan successfully.";
export const UpgradeSubscriptionSuccessToast = "Subscription upgraded successfully.";
export const UpgradeSubscriptionErrorToast = "We encountered an issue while upgrading the subscription. Please try again later.";
export const QuantityUpdateSuccessToast = "Quantity Updated Successfully";
export const CreationErrorToast = "There is an error in completing request. Please try creating it again.";
export const ReactivitingSuccessToast = "Subscription is Reactivated Successfully";
export const ActivitingErrorToast = "We encountered an issue while activating the subscription. Please try again later.";
export const ReactivitingErrorToast = "We encountered an issue while reactivating the subscription. Please try again later.";
export const DowngradeSubscriptionErrorToast = "We encountered an issue while downgrading the subscription. Please try again later.";
export const ContactUsSuccessToast = "Contact Form submitted successfully!";
export const ContactUsErrorToast = "Contact Form not submitted!";
export const FeedbackSuccessToast = "Feedback Form submitted successfully!";
export const FeedbackErrorToast = "Feedback Form not submitted!";
export const DownloadErrorToast = "We encountered an issue while downloading files. Please try again later.";
export const IntegrationSettingsSuccessToast = "Integration settings saved successfully.";
export const UnbilledIaasSuccessToast = "Alert Threshold Value Updated Successfully";
export const UnbilledIaasErrorToast = "Alert Threshold Value could not be updated at the moment, try again later.";
export const CustomerDetailsSuccessToast = "Customer details updated successfully.";
export const GWProvisionErrorToast = "GWS Product Provision Failed at the moment, try again later.";