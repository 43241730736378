import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Accordion, Form, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from '../../../../../../../Utils/API';
import {
  PROVISIONING_GET_CUSTOMER_DOMAIN,
  GOOGLE_WORKSPACE_CHECK_CUSTOMER_DOMAIN_AVAILABILITY,          // GET API To Check Domain Avilabilit
  GOOGLE_WORKSPACE_RESELLER_PREREQUISITES,                      // GET API for Cloud Identity ID from Reseller Prerequistes
  GOOGLE_WORKSPACE_DIRECT_TRANSFER_SUBSCRIPTION,                // GET and POST API for Subscriptions of Direct Transfer Subscription
  GOOGLE_WORKSPACE_PARTNER_CONSOLE_TRANSFER_SUBSCRIPTION,       // GET and POST API for Subscriptions of Partner Subscription
  GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_SUBSCRIPTION,    // GET and POST API Subscriptions of Different Reseller
  GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_R1_R2           // ONLY POST API for different reseller.
} from '../../../../../../../Utils/GlobalConstants';
import { domainRegex, alphanumericRegex ,alphanumericRegexWithHyphen} from '../../../../../../../GlobalComponents/RegExPatterns';
import styles from './GWSCustomerPrerequisite.module.scss';
import { initStatus } from './Utils';
import GWSCustomerDomainCheck from './LocalComponents/DomainCheck';
import GWSCustomerTransferDomain from './LocalComponents/TransferDomain';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import BuyDomainSuccess from './LocalComponents/BuyDomainSuccess';
import { BORDER_DATA_TABLE_BOTTOM_COLOR, TEXT_SECONDARY } from '../../../../../../../Utils/ColorsConfiguration';
import { GenericErrorMessage } from '../../../../../../../Utils/StaticMessages/GenericErrorMessages';

const GWSCustomerPrerequisite = (props) => {
  const location = useLocation();                                             // get Location
  const navigate = useNavigate();                                             // to use navigate function
  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const [serviceId, setServiceId] = useState(location.state.serviceId ? location.state.serviceId : null);
  // const [customerCode, setCustomerCode] = useState("4C75F099-31E5-4FD2-8126-2E33A966C004")  // Get it from provision screen
  const [customerId, setCustomerId] = useState(location.state !== null ? location.state.customerId : ""); // Get it from provision screen
  const [customerName, setCustomerName] = useState(location.state !== null ? location.state.customerName : ""); // Get it from provision screen
  const [accountHoldStatus, setAccountHoldStatus] = useState(location.state !== null ? location.state.accountHoldStatus : ""); // Get it from provision screen

  const [serviceName, setServiceName] = useState("Google Workspace");                       // Get it from provision screen
  const [activeAccordion, setActiveAccordion] = useState(["domainCheck"]);              // Default Active Accordion Id is "0"
  const [domainName, setDomainName] = useState(initStatus.domainName);        //Domain Name
  const [domainValidationFE, setDomainValidationFE] = useState(initStatus.domainValidationFE);  // Frontend Domain Validations
  const [isDomainFormatValid, setIsDomainFormatValid] = useState(true);                           // Check VAlid Domain Format
  const [stepsCompletedCheck, setStepsCompletedCheck] = useState(initStatus.stepsCompletedCheck);       // Check all steps
  const [domainTransferSuccess, setDomainTransferSuccess] = useState({
    isSuccess: false,
    message: "",
    orderCode: ""
  });

  // All buttons are availabel here with single use state
  const [btnChecks, setBtnChecks] = useState(initStatus.btnChecks);
  const [isDomainAvailable, setIsDomainAvailable] = useState(initStatus.domainCheckStatusBE); // Response from backend stores here
  const [isCheckingDomain, setIsCheckingDomain] = useState(false);
  const [errorBlock, setErrorBlock] = useState(false);
  const [gwsDirectInitiating, setGwsDirectInitiating] = useState(false);
  const integrationCode = "googleworkspace";
  const [gwIsTransferOrderInProgress, setGwIsTransferOrderInProgress] = useState(true);



  const [isTransferInitiated, setIsTransferInitiated] = useState(false);   // Is initiation Started
  const [transferType, setTransferType] = useState(""); // Set the tranfer Type Google Direct or Diffrent Reseller or My Own Partner Console
  const [googleDirectData, setGoogleDirectData] = useState([]);         // Google Direct Transfer
  const [updatedGoogleDirectData, setUpdatedGoogleDirectData] = useState([]); // Modified Data to display in Transfer Subscription
  const [newQuantity, setNewQuantity] = useState(null);
  const [selectedBillingDetails, setSelectedBillingDetails] = useState({});
  const [maxQuantity, setMaxQuantity] = useState(null);
  const [minQuantity, setMinQuantity] = useState(null);
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);            // Terms and Conditions
  const [poNumber, setPONumber] = useState("");                           // PO Number
  const [orderConfirmationSuccess, setOrderConfirmationSuccess] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);    // Is Processing for Order Confirmation Button
  const [gwsProductDetailsPopupModalOpen, setGWSProductDetailsPopupModalOpen] = useState(false);
  const [finalRows, setFinalRows] = useState([]);
  const [initProductDetailsData, setInitProductDetailsData] = useState(null);
  const [finalPostRequest, setFinalPostRequest] = useState(null);    // Confirm Button Post Request
  const [isCheckIdentity, setisCheckIdentity] = useState({
    isSuccess: null,
    owned: null,
    statusMessage: ""
  });   // Is diffferent reseller check

  // Get from Reseller Prerequisite
  const [resellerPrerequisiteDetails, setResellerPrerequisiteDetails] = useState({
    isGWAlreadyConfigured: "",
    isGWEducationEnabled: "",
    isGCPEnabled: "",
    domainName: "",
    domainState: "",
    identityState: "",
    partnerState: "",
    linkState: "",
    cloudIdentityId: "",
    partnerAdvantageEnrollUri: "",
    partnerInviteLinkUri: ""
  });


  const [cloudIdentityId, setCloudIdentityId] = useState(null);     // Cloud Identity ID
  const [channelPartnerId, setChannelPartnerId] = useState(null);   // Channel Partner ID
  const [isOwned, setIsOwned] = useState(false);                    // Owned to Synnex or Not
  const [purchasedTransferablePlans, setPurchasedTransferablePlans] = useState([]); // Purchased Transferable Plans
  const [lastObjects, setLastObjects] = useState([]); // Last Updated Array of Objects before updating POST Object.
  const [transferError, setTransferError] = useState(null);         // Domain Transfer Error 
  const [domainCheckError, setDomainCheckError] = useState(null);
  // Post Direct Reseller Body 
  const [directResellerBody, setDirectResellerBody] = useState({
    channelPartnerId: channelPartnerId,
    customerCode: customerId,
    domainName: domainName,
    poNumber: poNumber,
    purchasedTransferablePlans: purchasedTransferablePlans
  });

  // Post DDifferent Reseller Body 
  const [differentResellerBody, setDifferentResellerBody] = useState({
    channelPartnerId: channelPartnerId,
    customerCode: customerId,
    domainName: domainName,
  })

  const [accountHoldStatusPopup,setAccountHoldStatusPopup]=useState(false); //useState for the popup shown when accountStatus is manual or system hold
  
  //function to handle the transfer button click by showing a popup if the account is on "System Hold" or "Manual Hold", otherwise enables the transfer.
  const handleTransferClick = () => {
    if (accountHoldStatus === "System Hold" || accountHoldStatus==="Manual Hold") {
      setAccountHoldStatusPopup(true); 
    } else {
      enableTransfer(); 
    }
  };


  // Function to Get Plan Details based on Selection of plan type
  const handleSelectChange = (billingDetail, plan, plans) => {
    const updatedPlanId = billingDetail.offerSku
    setSelectedBillingDetails((oldData) => {
      let updatedData = { ...oldData };
      const key = plan.planName;
      updatedData[key] = updatedPlanId;
      return updatedData;
    })
  };

  // Function to Enable Check Domain Availability
  const checkDomainAvailability = (e) => {
    setDomainName(e.target.value);
    if (e.target.value.length > 0) {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: true,
        btnClear: true
      })
    } else {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: false,
        btnBuySubscription: false,
        btnClear: false
      })
    }
  }

  // Get Domain Availability
  async function getDomainAvailability(e) {
    setDomainCheckError("");
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    e.preventDefault();
    if (domainRegex.test(domainName)) {
      setIsCheckingDomain(true);
      setIsDomainFormatValid(true);
      await api.get(`${GOOGLE_WORKSPACE_CHECK_CUSTOMER_DOMAIN_AVAILABILITY}?domain=${domainName}&customerCode=${customerId}`, config)
        .then(resp => {
          if (resp.status == 200) {

            setIsDomainAvailable(resp.data);
            setCloudIdentityId(resp.data.cloudIdentityId);
            setIsOwned(resp.data.owned);
          }
        })
        .catch(error => {
          if (error?.status == 401 || error?.response?.status == 401) {
            props.Logout();
          } else if (error.response.status == 400) {
            setDomainCheckError(error.response.data.errors?.domain || error.response.data.title || GenericErrorMessage);
          } else if (error.status == 500) {
            setDomainCheckError(GenericErrorMessage);
          } else {
            setDomainCheckError(GenericErrorMessage);
          }
        });
      setIsCheckingDomain(false);
    } else {
      setIsDomainFormatValid(false);
    }
  }

  // Get Reseller Prerequisites from this function
  async function enableTransfer() {
    setActiveAccordion(["tarnsferDomain"]);
    await api.get(GOOGLE_WORKSPACE_RESELLER_PREREQUISITES, config)
      .then(resp => {

        setStepsCompletedCheck({
          ...stepsCompletedCheck,
          isDomainCheckCompleted: true
        });
        setResellerPrerequisiteDetails(resp.data);
        setChannelPartnerId(resp.data.cloudIdentityId);
      })
      .catch(error => {
        props.Logout();
      })
  }

  // Google Direct Trsnfer Subscription GET API
  async function gwsDirecTransfer() {
    setGwsDirectInitiating(true);
    await api.get(`${GOOGLE_WORKSPACE_DIRECT_TRANSFER_SUBSCRIPTION}?domainName=${domainName}&customerCode=${customerId}&channelPartnerId=${channelPartnerId}&cloudIdentityId=${cloudIdentityId}&isOwned=${isOwned}`, config)
      .then(resp => {
        if (resp.status == 200) {
          setGoogleDirectData(resp.data);
          setActiveAccordion(['transferSubscription']);
          setErrorBlock(false);

          setStepsCompletedCheck({
            ...stepsCompletedCheck,
            isDomainTransitionCompleted: true
          });
        }

      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

        setTransferError(error.response.data.errors);
        if (error.response.status == 400) {
          setErrorBlock(true);

        }
      });

    setGwsDirectInitiating(false);
  }

  // Google Direct Trsnfer Subscription POST API
  async function gwsDirecTransferPOST() {
    setIsProcessing(true);

    
    await api.post(`${GOOGLE_WORKSPACE_DIRECT_TRANSFER_SUBSCRIPTION}`, directResellerBody, config)
      .then(resp => {
        if (resp.status == 200) {

          setDomainTransferSuccess(prev => (
            {
              ...prev,
              isSuccess: resp.data.isSuccess,
              message: resp.data.message,
              orderCode: resp.data.orderCode
            }
          )
          );
        }


        setGWSProductDetailsPopupModalOpen(false);

        setPONumber("");
        setOrderConfirmationSuccess(false);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });

    setIsProcessing(false);
  }

  // My Own Partner Console GET API
  async function gwsOwnPartnerConsoleTransfer() {
    setGwsDirectInitiating(true);
    await api.get(`${GOOGLE_WORKSPACE_PARTNER_CONSOLE_TRANSFER_SUBSCRIPTION}?domainName=${domainName}&customerCode=${customerId}&channelPartnerId=${channelPartnerId}&cloudIdentityId=${cloudIdentityId}&isOwned=${isOwned}`, config)
      .then(resp => {
        if (resp.status == 200) {
          setGoogleDirectData(resp.data);

          setActiveAccordion(['transferSubscription']);
          setStepsCompletedCheck({
            ...stepsCompletedCheck,
            isDomainTransitionCompleted: true
          });
        }


      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

        if (error.response.status == 400) {
          setErrorBlock(true);
        }
      });
    setGwsDirectInitiating(false);
  }

  // My Own Partner Console POST API
  async function gwsOwnPartnerConsoleTransferPOST() {
    setIsProcessing(true)
    await api.post(`${GOOGLE_WORKSPACE_PARTNER_CONSOLE_TRANSFER_SUBSCRIPTION}`, directResellerBody, config)
      .then(resp => {
        if (resp.status == 200) {

          setDomainTransferSuccess(prev => (
            {
              ...prev,
              isSuccess: resp.data.isSuccess,
              message: resp.data.message,
              orderCode: resp.data.orderCode
            }
          )
          );

          GetCustomerDomain();
        }


        setGWSProductDetailsPopupModalOpen(false);

        setPONumber("");
        setOrderConfirmationSuccess(false);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });

    setIsProcessing(false);
  }

  // Different Reseller Transfer GET API
  async function gwsDifferentResellerTransfer() {
    setGwsDirectInitiating(true);
    await api.get(`${GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_SUBSCRIPTION}?domainName=${domainName}&customerCode=${customerId}&channelPartnerId=${channelPartnerId}&cloudIdentityId=${cloudIdentityId}&isOwned=${isOwned}`, config)
      .then(resp => {
        if (resp.status == 200) {

          setGoogleDirectData(resp.data);

          setActiveAccordion(['transferSubscription']);
          setStepsCompletedCheck({
            ...stepsCompletedCheck,
            isDomainTransitionCompleted: true
          });
        }

        setErrorBlock(false);

      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

        if (error.response.status == 400) {
          setErrorBlock(true);

        }
      });
    setGwsDirectInitiating(false);
  }

  // Different Reseller Transfer POST API
  async function gwsDifferentResellerTransferPOST() {

    setIsProcessing(true)
    await api.post(`${GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_SUBSCRIPTION}`, directResellerBody, config)
      .then(resp => {
        if (resp.status == 200) {

          setDomainTransferSuccess(prev => (
            {
              ...prev,
              isSuccess: resp.data.isSuccess,
              message: resp.data.message,
              orderCode: resp.data.orderCode
            }
          )
          );
        }


        setGWSProductDetailsPopupModalOpen(false);

        setPONumber("");
        setOrderConfirmationSuccess(false);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });

    setIsProcessing(false);
  }


  // Different Reseller Transfer GET API
  async function gwsDifferentResellerR1R2POST() {
    setActiveAccordion(['transferSubscription']);

    await api.post(`${GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_R1_R2}`, { domainName: domainName, customerCode: customerId, channelPartnerId: channelPartnerId }, config)
      .then(resp => {
        if (resp.status == 200) {
          setDomainTransferSuccess(prev => (
            {
              ...prev,
              isSuccess: resp.data.isSuccess,
              message: resp.data.message,
              orderCode: resp.data.orderCode
            }
          )
          );
        }


        setGWSProductDetailsPopupModalOpen(false);

        setPONumber("");
        setOrderConfirmationSuccess(false);
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });

  }

  // // Google Direct Trsnfer Subscription
  function gwsDifferentReseller() {

  }

  // Function to check the domain is associated with a customer or not
  async function GetCustomerDomain() {
    await api.get(`${PROVISIONING_GET_CUSTOMER_DOMAIN}?customerCode=${customerId}&serviceId=${serviceId}&integrationCode=${integrationCode}`, config)
      .then(resp => {
        if (resp.status == 200) {
          setDomainName(resp?.data.domainName ? resp.data.domainName : domainName);
          setGwIsTransferOrderInProgress(resp?.data.gwIsTransferOrderInProgress);
        }

        if (resp.status == 204) {
          setDomainName("");
          setGwIsTransferOrderInProgress(true)
        }
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });
  }

  // Reset Function
  const resetToInitStatus = () => {
    setDomainCheckError("");
    setDomainName(initStatus.domainName);
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setDomainValidationFE(initStatus.domainValidationFE);
    setBtnChecks(initStatus.btnChecks);
    setIsDomainFormatValid(true);
    setGoogleDirectData([]);
    setSelectedBillingDetails({});
    setPurchasedTransferablePlans([]);
    setIsTransferInitiated(false);
    setErrorBlock(false);
    setLastObjects([]);
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: false,
      isDomainTransitionCompleted: false
    });
  }

  const getBillingDetails = (plan) => {
    const key = plan["planName"];
    const selectedPlanId = selectedBillingDetails[key];
    const billingRow = plan && plan.data.billingDetails.find(billingDetail => billingDetail.offerSku == selectedPlanId);

    return billingRow || {};
  }


  // Updated Quantity in On Selecting and Updating based on the plan name
  const setQuantityInBillingDetails = (newValue, plan) => {
    const key = plan["planName"];
    const selectedPlanId = selectedBillingDetails[key];

    const updatedPlan = {
      ...plan,
      data: {
        ...plan.data,
        billingDetails: plan.data.billingDetails.map((billingDetail) =>
          billingDetail.offerSku === selectedPlanId
            ? { ...billingDetail, value: newValue, planName: plan["planName"] }
            : billingDetail
        ),
      },
    };
    const updatedData = updatedGoogleDirectData.map((item) =>
      item.planName === plan.planName ? updatedPlan : item
    );

    const updatedRow = updatedData.reduce((acc, curr) => {
      const billingDetail = curr.data.billingDetails.find(detail => detail.offerSku === selectedPlanId);
      if (billingDetail) {
        return billingDetail;
      }
      return acc;
    }, {});

    setUpdatedGoogleDirectData(updatedData);
    setFinalRows([...finalRows, updatedRow]);
  };


  // Transfeorm RAW API Data into Transfer Subscription Format
  useEffect(() => {
    const transformedData = googleDirectData.reduce((acc, curr) => {
      const existingPlan = acc.find(item => item.planName === curr.planName);
      if (existingPlan) {
        existingPlan.data.billingDetails.push({
          id: existingPlan.data.billingDetails.length,
          planId: curr.planId,
          billingTerm: curr.billingTerm,
          quantity: curr.quantity,
          value: curr.quantity || 0,
          rrpExclusiveTax: curr.rrpExclusiveTax,
          rrpInclusiveTax: curr.rrpInclusiveTax,
          min: curr.minQuantity,
          max: curr.maxQuantity,
          price: curr.price,
          offerSku: curr.offerSku,
          skuName: curr.skuName
        });
      } else {
        acc.push({
          planName: curr.planName,
          data: {
            billingDetails: [{
              id: 0,
              planId: curr.planId,
              billingTerm: curr.billingTerm,
              quantity: curr.quantity,
              value: curr.quantity || 0,
              rrpExclusiveTax: curr.rrpExclusiveTax,
              rrpInclusiveTax: curr.rrpInclusiveTax,
              min: curr.minQuantity,
              max: curr.maxQuantity,
              price: curr.price,
              offerSku: curr.offerSku,
              skuName: curr.skuName
            }],
            maxQuantity: curr.maxQuantity,
            minQuantity: curr.minQuantity,
            planName: curr.planName
          }
        });
      }
      return acc;
    }, []);

    setUpdatedGoogleDirectData(transformedData);

  }, [googleDirectData]);

  useEffect(() => {
    const uniqueIds = new Set();
    const lastObjectsArray = [];

    for (let i = finalRows.length - 1; i >= 0; i--) {
      const currentId = finalRows[i].planName;
      if (!uniqueIds.has(currentId)) {
        uniqueIds.add(currentId);
        lastObjectsArray.push(finalRows[i]);
      }
    }

    setLastObjects(lastObjectsArray);

  }, [finalRows]);

  useEffect(() => {
    const updatedLastObject = lastObjects.map(obj => ({
      planId: obj.planId,
      quantity: obj.value,
      poNumber: poNumber,
      minQuantity: obj.min,
      skuName: obj.skuName,
      offerSku: obj.offerSku
    }))
    setPurchasedTransferablePlans(updatedLastObject);
  }, [lastObjects, poNumber])

  useEffect(() => {
    setDirectResellerBody({
      channelPartnerId: channelPartnerId,
      customerCode: customerId,
      domainName: domainName,
      purchasedTransferablePlans: purchasedTransferablePlans
    });

    console.log("directResellerBody", directResellerBody)

  }, [cloudIdentityId, customerId, domainName, poNumber, purchasedTransferablePlans])



  useEffect(() => {
    GetCustomerDomain();
  }, [domainTransferSuccess])

  return (
    <>

      <Popup
        className={`custom-modal  ${!orderConfirmationSuccess ? "custom-modal-xl" : "custom-modal-md"}`}
        open={gwsProductDetailsPopupModalOpen}
        onClose={() => {

          setGWSProductDetailsPopupModalOpen(false);

          setPONumber("");
          setOrderConfirmationSuccess(false);
        }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">


            <div className="header">
              <h2>Order Confirmation - Cloud Provision</h2>
              {!isProcessing &&
                <XLg
                  size={18}
                  className="cpointer text-strong"
                  onClick={close}
                  color={TEXT_SECONDARY}
                />
              }

              {isProcessing &&
                <XLg
                  size={18}
                  className="text-strong"
                  color={BORDER_DATA_TABLE_BOTTOM_COLOR}
                  disabled
                />
              }
            </div>

            {!orderConfirmationSuccess &&
              <Container className={`action-modal-height ${styles['edit-quantity-width']}`}>

                <div className={`${styles["csp-purchase-addon-table-outer"]}`}>
                  <Row>
                    <Col>
                      <h3>
                        Customer Name: <span className="text-regular">{customerName}</span>
                      </h3>
                    </Col>
                    <Col>
                      <h3>
                        Cloud Service: <span className="text-regular">Google Workspace</span>
                      </h3>
                    </Col>
                  </Row>
                  <div className={`table-responsive col-12 my-3 ${styles['csp-popup-table']}`}>
                    <table className={`table ${styles['gws-prerequisite-table']}`}>
                      <thead className="sticky-top">
                        <tr>
                          <th><span>Plan Name</span></th>
                          <th><span>Billing Term</span></th>
                          <th className="text-right"><span>RRP (Inc GST)</span></th>
                          <th className="text-right"><span>Reseller Price (Ex. GST)</span></th>
                          <th className="text-center"><span className="d-flex justify-content-center align-items-center">Add Quantity<OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>The minimum quantity is set at the number of seats assigned to the users under the corresponding entitlement.</Tooltip>}
                                          >
                                            <span className="infoIcon-support-table ml-1 mb-1 "></span>
                                          </OverlayTrigger>
                                          </span></th>
                          
                          <th className="text-right"><span>Sub Total Price</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {updatedGoogleDirectData?.map((plan, index) => (
                          <tr key={index}>
                            <td>{plan.planName}</td>
                            <td>

                              {
                                lastObjects.map((item) => {
                                  if (item.offerSku === getBillingDetails(plan).offerSku) {
                                    return (
                                      <div key={item.offerSku}>
                                        {item.billingTerm}
                                      </div>
                                    );
                                  }

                                })
                              }


                            </td>
                            <td className="text-right">
                              ${getBillingDetails(plan) && getBillingDetails(plan).rrpInclusiveTax}
                            </td>
                            <td className="text-right">${getBillingDetails(plan) && getBillingDetails(plan).price}</td>

                            <td className="text-right">



                              {getBillingDetails(plan).offerSku ?

                                // <input type='number' value={selectedRows[plan.planName].quantity} />
                                <>
                                  <div className="d-flex justify-content-center">
                                    <div className="number-input">
                                      <input
                                        type="number"
                                        id={"quantity_" + getBillingDetails(plan).offerSku}
                                        name="quantity"
                                        min={getBillingDetails(plan)?.min}
                                        max={getBillingDetails(plan)?.max}
                                        value={getBillingDetails(plan)?.value || 0}
                                        onChange={(e) => {

                                          const newValue = Number(e.target.value);
                                          setQuantityInBillingDetails(newValue, plan);

                                          setPurchasedTransferablePlans(
                                            [
                                              {
                                                planId: getBillingDetails(plan).planId,
                                                quantity: getBillingDetails(plan)?.value,
                                                poNumber: poNumber,
                                                minQuantity: getBillingDetails(plan)?.min,
                                                skuName: getBillingDetails(plan)?.skuName,
                                                offerSku: getBillingDetails(plan)?.offerSku
                                              }
                                            ]
                                          )
                                        }}
                                      />
                                      <div className="btn-wrapper">

                                        {getBillingDetails(plan)?.value < getBillingDetails(plan)?.max ? (
                                          <button className="plus" onClick={() => {
                                            const newValue = Number(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value);
                                            document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value = Number(newValue + 1);
                                            setQuantityInBillingDetails(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value, plan);


                                          }}>
                                            +
                                          </button>
                                        ) : (
                                          <button className="plus disabled" disabled>
                                            +
                                          </button>
                                        )}

                                        {getBillingDetails(plan)?.value > getBillingDetails(plan)?.min ? (
                                          <button className="minus" onClick={() => {
                                            const newValue = Number(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value);
                                            document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value = Number(newValue - 1);
                                            setQuantityInBillingDetails(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value, plan);


                                          }}>
                                            <span>&nbsp;</span>
                                          </button>
                                        ) : (
                                          <button className="minus disabled" disabled>
                                            <span>&nbsp;</span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-disabled col-12 text-center">
                                    <span className="text-small">Min {getBillingDetails(plan)?.min}, </span>
                                    <span className="text-small">Max {getBillingDetails(plan)?.max}</span>
                                  </div>
                                </>
                                :
                                <>
                                  <div className="d-flex justify-content-center">
                                    <div className="number-input">
                                      <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        min="0"
                                        max="0"
                                        value="0"
                                        className='disabled'
                                        disabled
                                      />
                                      <div className="btn-wrapper">

                                        <button className="plus disabled" disabled>
                                          +
                                        </button>


                                        <button className="minus disabled" disabled>
                                          <span>&nbsp;</span>
                                        </button>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-disabled col-12 text-center">
                                    <span className="text-small">Min {getBillingDetails(plan)?.min}, </span>
                                    <span className="text-small">Max {getBillingDetails(plan)?.max}</span>
                                  </div>
                                </>

                              }

                            </td>
                            <td className="text-right">
                              ${getBillingDetails(plan).value ? parseFloat(getBillingDetails(plan).value * getBillingDetails(plan).price).toFixed(2) : 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {selectedPlan.length > 0 &&
                    <Row>
                      <Col>
                        <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                        <Form.Control
                          type="text"
                          className="w-auto d-inline ml-3"
                          id="inputPONumber"
                          aria-describedby="inputPONumber"
                          placeholder='Add PO Number'
                          value={poNumber}
                          maxlength="50"
                          onChange={(e) => {
                            alphanumericRegexWithHyphen.test(e.target.value)
                              ? setPONumber(e.target.value)
                              : console.log("")
                          }}
                        />
                        <Form.Text id="poNumber" />
                      </Col>
                    </Row>
                  }

                  <Row className="mt-2">
                    <Col md={6}>
                      {selectedPlan.length > 0 &&
                        <>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="termsCheckBox"
                            label=""
                            disabled
                            checked={isTermsChecked}
                            className="d-inline-block w-auto"
                          />
                          <p className="d-inline-block  w-auto mb-0">
                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                          </p>
                        </>
                      }
                    </Col>
                    <Col md={6}>
                      <div className="btn-wrapper text-right mb-1">
                        {!isProcessing &&
                          <Button
                            variant="light"
                            className="px-4 btn-border-light w-md-auto col-xs-12"
                            onClick={() => { setGWSProductDetailsPopupModalOpen(false); setPONumber(""); }}>
                            Cancel
                          </Button>
                        }
                        {isProcessing &&
                          <Button
                            variant="light"
                            className="px-4 mx-2"
                            disabled
                          >
                            Cancel
                          </Button>
                        }
                        {(isTermsChecked && selectedPlan.length > 0) && !isProcessing && <>

                          {transferType == "googleDirect" &&
                            <Button
                              className="px-4 mx-2"
                              onClick={gwsDirecTransferPOST}>
                              Confirm
                            </Button>
                          }

                          {transferType == "differentReseller" &&
                            <Button
                              className="px-4 mx-2"
                              onClick={gwsDifferentResellerTransferPOST}>
                              Confirm
                            </Button>
                          }

                          {transferType == "ownPartnerConsole" &&
                            <Button
                              className="px-4 mx-2"
                              onClick={gwsOwnPartnerConsoleTransferPOST}>
                              Confirm
                            </Button>
                          }
                        </>
                        }
                        {(!isTermsChecked || selectedPlan.length < 1) && !isProcessing &&
                          <>
                            <Button
                              variant="light"
                              className="px-4 mx-2"
                              disabled
                            >
                              Confirm
                            </Button>
                          </>

                        }

                        {isProcessing &&
                          <>
                            <Button
                              variant="light"
                              className="px-4 mx-2"
                              disabled
                            >
                              Processing...
                            </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>

                </div>

              </Container>
            }

            {
              orderConfirmationSuccess &&
              <Container className='text-center'>
                <p>
                  <div className='success-page'></div>
                </p>
                <p className='text-regular'>poConfirmation.message</p>
                <p className='text-regular'><strong>Provision Order ID:</strong> poConfirmation.orderCode</p>
                <div>
                  <Button
                    className="px-4 mx-2"
                    onClick={() => { close(); }}>
                    Ok
                  </Button>
                </div>
              </Container>
            }

          </div>
        )}
      </Popup>
      <div className="main-content">

        <Container fluid>
          <Row className="align-items-center">
            <Col className="gotoback col-md-6" onClick={() => navigate('/cloud-provision')}>
              <span className="back">&nbsp;</span>&nbsp;<strong>Domain Check & Transition Domain</strong>
            </Col>
          </Row>

          <div className='service-management-header mt-5'>
            <Row className='mb-3'>
              <Col md={3} className='mb-3'>
                <div className="servcies-dropdown nav-item dropdown disabled">
                  <a id="cloud-service-dropdown" aria-expanded="false" role="button" className="dropdown-toggle nav-link" tabindex="0" href="#">
                    {customerName}
                  </a>
                </div>
              </Col>
              <Col md={3} className='mb-3'>
                <div className="servcies-dropdown nav-item dropdown disabled">
                  <a id="cloud-service-dropdown" aria-expanded="false" role="button" className="dropdown-toggle nav-link" tabindex="0" href="#">
                    {serviceName}
                  </a>
                </div>
              </Col>
            </Row>
          </div>

          {!domainTransferSuccess.isSuccess &&
            <div className="accordion-panel">
              <Accordion
                className="pre-requisite-panels mb-5"
                activeKey={activeAccordion}
                onSelect={(e) => { setActiveAccordion(e) }}
                alwaysOpen>

                <GWSCustomerDomainCheck
                  domainName={domainName}
                  btnChecks={btnChecks}
                  stepsCompletedCheck={stepsCompletedCheck}
                  checkDomainAvailabilityGWS={checkDomainAvailability}
                  getDomainAvailability={getDomainAvailability}
                  isDomainFormatValid={isDomainFormatValid}
                  resetToInitStatus={resetToInitStatus}
                  isDomainAvailable={isDomainAvailable}
                  enableTransfer={enableTransfer}
                  customerName={customerName}
                  customerCode={customerId}
                  isCheckingDomain={isCheckingDomain}
                  domainCheckError={domainCheckError}
                  serviceId={serviceId}
                  accountHoldStatus={accountHoldStatus}
                  setAccountHoldStatus={setAccountHoldStatus}
                  accountHoldStatusPopup={accountHoldStatusPopup}
                  setAccountHoldStatusPopup={setAccountHoldStatusPopup}
                  handleTransferClick={handleTransferClick}
                />

                {/* Transfer Subscription Accordion */}
                {((isDomainAvailable.isSuccess === false && isDomainAvailable.isExistInSynnex === false) && (isDomainAvailable.owned == true || isDomainAvailable.cloudIdentityId)) &&
                  <GWSCustomerTransferDomain
                    domainName={domainName}
                    errorBlock={errorBlock}
                    transferType={transferType}
                    setTransferType={setTransferType}
                    isDomainAvailable={isDomainAvailable}
                    stepsCompletedCheck={stepsCompletedCheck}
                    isTransferInitiated={isTransferInitiated}
                    setIsTransferInitiated={setIsTransferInitiated}
                    setActiveAccordion={setActiveAccordion}
                    isCheckIdentity={isCheckIdentity}
                    gwsDifferentReseller={gwsDifferentReseller}
                    gwsDirecTransfer={gwsDirecTransfer}
                    gwsDifferentResellerTransfer={gwsDifferentResellerTransfer}
                    gwsOwnPartnerConsoleTransfer={gwsOwnPartnerConsoleTransfer}
                    gwsDifferentResellerR1R2POST={gwsDifferentResellerR1R2POST}
                    setFinalPostRequest={setFinalPostRequest}
                    transferError={transferError}
                    setErrorBlock={setErrorBlock}
                    gwsDirectInitiating={gwsDirectInitiating}

                  />

                }

                {stepsCompletedCheck.isDomainTransitionCompleted &&
                  <>
                    {((isDomainAvailable.isSuccess === false && isDomainAvailable.isExistInSynnex === false) || (isDomainAvailable.isSuccess === false && isDomainAvailable.isExistInSynnex === true)) &&
                      <Accordion.Item className={`mt-5 ${stepsCompletedCheck.isDomainTransitionCompleted ? "" : "disabled"}`} eventKey="transferSubscription">
                        <Accordion.Header>
                          <h3 className={`${styles['accordion-header-wrapper']}`}>
                            <label>
                              {stepsCompletedCheck.isDomainFlowCompleted ?
                                <span className="step-completed">&nbsp;</span>
                                : <span className={`${styles['accordion-number']}`}>3</span>
                              }
                            </label>
                            <label className="pl-2">
                              <span className={`${styles['accordion-name']}`}>Transfer Subscription</span>
                            </label>
                          </h3>
                        </Accordion.Header>
                        <Accordion.Body className='px-0 pt-0'>
                          <div className={`${styles['accordion-content-has-table']}`}>
                            <Row>
                              <Col md={12}>
                                <div className={`table-responsive col-12 mb-3 ${styles['gws-table-outer']}`}>
                                  <table className={`table ${styles['gws-prerequisite-table']}`}>
                                    <thead className="sticky-top">
                                      <tr>
                                        <th><span>Plan Name</span></th>
                                        <th><span>Billing Term</span></th>
                                        <th className="text-right"><span>RRP (Inc GST)</span></th>
                                        <th className="text-right"><span>Reseller Price (Ex. GST)</span></th>
                                        <th className="text-center"><span className="d-flex justify-content-center align-items-center">Add Quantity<OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>The minimum quantity is set at the number of seats assigned to the users under the corresponding entitlement.</Tooltip>}
                                          >
                                            <span className="infoIcon-support-table ml-1 mb-1 "></span>
                                          </OverlayTrigger>
                                          </span></th>
                                        <th className="text-right"><span>Sub Total Price</span></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {updatedGoogleDirectData && updatedGoogleDirectData.map((plan, index) => (
                                        <tr key={index}>
                                          <td>{plan.planName}</td>
                                          <td>

                                            <Form.Group
                                              className={`form-group mb-0 position-relative ${updatedGoogleDirectData.length > 1 ? "" : "gws-plans-dropdown"}`}
                                              controlId="billingTermId"
                                            >
                                              <NavDropdown title="Select Billing Term" id={plan.planName}
                                                className={`servcies-dropdown ${styles['billing-term-td-dropdown']}`}>
                                                {plan && plan.data && plan.data.billingDetails.map((term, index) => (
                                                  <>
                                                    <NavDropdown.Item
                                                      key={index}
                                                      id={term.id}
                                                      value={term.billingTerm}
                                                      className=""
                                                      onClick={(e) => {
                                                        handleSelectChange({ ...term, planName: plan.planName }, plan.data, updatedGoogleDirectData)
                                                        document.getElementById(plan.planName).setAttribute('title', term.billingTerm);
                                                        document.getElementById(plan.planName).innerHTML = term.billingTerm;
                                                      }}>
                                                      {term.billingTerm}
                                                    </NavDropdown.Item>
                                                  </>
                                                ))}
                                              </NavDropdown>
                                            </Form.Group>
                                          </td>
                                          <td className="text-right">
                                            ${getBillingDetails(plan) &&
                                              <>
                                                {
                                                  getBillingDetails(plan).rrpInclusiveTax ? getBillingDetails(plan).rrpInclusiveTax : 0
                                                }
                                              </>
                                            }
                                          </td>
                                          <td className="text-right">
                                            ${getBillingDetails(plan) &&
                                              <>
                                                {
                                                  getBillingDetails(plan).price ? getBillingDetails(plan).price : 0
                                                }
                                              </>
                                            }
                                          </td>

                                          <td className="text-right">



                                            {getBillingDetails(plan).offerSku ?

                                              // <input type='number' value={selectedRows[plan.planName].quantity} />
                                              <>
                                                <div className="d-flex justify-content-center">
                                                  <div className="number-input">
                                                    <input
                                                      type="number"
                                                      id={"quantity_" + getBillingDetails(plan).offerSku}
                                                      name="quantity"
                                                      min={getBillingDetails(plan)?.min}
                                                      max={getBillingDetails(plan)?.max}
                                                      value={getBillingDetails(plan)?.value || 0}
                                                      onChange={(e) => {

                                                        const newValue = Number(e.target.value);
                                                        setQuantityInBillingDetails(newValue, plan);

                                                      }}
                                                    />
                                                    <div className="btn-wrapper">

                                                      {getBillingDetails(plan)?.value < getBillingDetails(plan)?.max ? (
                                                        <button className="plus" onClick={() => {
                                                          const newValue = Number(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value);
                                                          document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value = Number(newValue + 1);
                                                          setQuantityInBillingDetails(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value, plan);

                                                        }}>
                                                          +
                                                        </button>
                                                      ) : (
                                                        <button className="plus disabled" disabled>
                                                          +
                                                        </button>
                                                      )}

                                                      {getBillingDetails(plan)?.value > getBillingDetails(plan)?.min ? (
                                                        <button className="minus" onClick={() => {
                                                          const newValue = Number(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value);
                                                          document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value = Number(newValue - 1);
                                                          setQuantityInBillingDetails(document.getElementById("quantity_" + getBillingDetails(plan).offerSku).value, plan);
                                                        }}>
                                                          <span>&nbsp;</span>
                                                        </button>
                                                      ) : (
                                                        <button className="minus disabled" disabled>
                                                          <span>&nbsp;</span>
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="text-disabled col-12 text-center">
                                                  <span className="text-small">Min {getBillingDetails(plan)?.min}, </span>
                                                  <span className="text-small">Max {getBillingDetails(plan)?.max}</span>
                                                </div>
                                              </>
                                              :
                                              <>
                                                <div className="d-flex justify-content-center">
                                                  <div className="number-input">
                                                    <input
                                                      type="number"
                                                      id="quantity"
                                                      name="quantity"
                                                      min="0"
                                                      max="0"
                                                      value="0"
                                                      className='disabled'
                                                      disabled
                                                    />
                                                    <div className="btn-wrapper">

                                                      <button className="plus disabled" disabled>
                                                        +
                                                      </button>


                                                      <button className="minus disabled" disabled>
                                                        <span>&nbsp;</span>
                                                      </button>

                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="text-disabled col-12 text-center">
                                                  <span className="text-small">Min {getBillingDetails(plan)?.min}, </span>
                                                  <span className="text-small">Max {getBillingDetails(plan)?.max}</span>
                                                </div>
                                              </>

                                            }

                                          </td>
                                          <td className="text-right">
                                            ${getBillingDetails(plan).value ? parseFloat(getBillingDetails(plan).value * getBillingDetails(plan).price).toFixed(2) : 0}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>

                          </div>

                          <div className={`pr-5 ${styles['accordion-content']}`}>
                            <Row>
                              <Col className="text-right">
                                <div className="button-wrapper">
                                  <Button
                                    className="btn btn-lg btn-border-light px-4"
                                    variant="light"
                                    onClick={() => setActiveAccordion(["domainCheck"])}
                                  >
                                    Back
                                  </Button>

                                  {Object.keys(lastObjects).length !== updatedGoogleDirectData.length &&
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      variant="light"
                                      disabled
                                    >
                                      Proceed
                                    </Button>
                                  }
                                  {Object.keys(lastObjects).length == updatedGoogleDirectData.length &&
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => {
                                        setGWSProductDetailsPopupModalOpen(true);
                                        setSelectedPlan(lastObjects);
                                        setInitProductDetailsData(lastObjects);
                                      }}
                                    >
                                      Proceed
                                    </Button>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    }
                  </>
                }

              </Accordion>

            </div>
          }

          {
            domainTransferSuccess.isSuccess &&
            <BuyDomainSuccess
              customerCode={customerId}
              transferType={transferType}
              serviceId={serviceId}
              customerId={customerId}
              gwIsTransferOrderInProgress={gwIsTransferOrderInProgress}
              setActiveLocalKey={props.setActiveLocalKey}
            />
          }

        </Container >
      </div >
    </>
  )
}

export default GWSCustomerPrerequisite;