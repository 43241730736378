import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
import styles from '../../GWSCustomerPrerequisite.module.scss';
import {  useNavigate } from "react-router-dom";
import { CustomerPrerequisiteBuyDomainSuccess, CustomerPrerequisiteTransferSuccess } from '../../../../../../../../Utils/StaticMessages/GenericErrorMessages';



const BuyDomainSuccess = (props) => {
                                           // get Location
    const navigate = useNavigate();                                             // to use navigate function
    return (

        <div className={`${styles['success-panel']} text-center`}>
            <Row className="mx-0 w-100">

                <Col md={12}>
                    <span className='success-page'>&nbsp;</span>
                </Col>
                <Col md={12} className="text-center">

                    {
                        props.gwIsTransferOrderInProgress == false &&
                        <p>{CustomerPrerequisiteTransferSuccess}</p>
                    }

                    {
                        props.gwIsTransferOrderInProgress == true &&
                        <p>{CustomerPrerequisiteBuyDomainSuccess}</p>
                    }
                </Col>
                <Col md={12} className="text-center">
                    <div className="button-wrapper">
                        <Button
                            className="btn btn-lg btn-border-light px-4"
                            variant="light"

                            onClick={() => {
                                props.setIsViewDetails(true);
                                props.GetCustomerDomain();
                            }}
                            id={props.customerCode}
                        >
                            View Details

                            {/* Edit Csutomer Cloud Requorements Screen  */}
                        </Button>

                        {
                            props.transferType == "googleDirect" &&
                            <Button
                                className="btn btn-lg ml-2 px-4"
                                onClick={() => {
                                    props.setActiveLocalKey("cloudProvision");
                                    navigate("/cloud-provision", {
                                        state: {
                                            customerId: props.customerId,
                                            serviceId: props.serviceId,
                                            isManagementConsoleNavigation: true,
                                        }
                                    })
                                }}
                            >
                                Buy Subscriptions
                                {/* Provision screen  */}
                            </Button>
                        }

                        {
                            props.transferType == "ownPartnerConsole" &&
                            <Button
                                className="btn btn-lg ml-2 px-4"
                                onClick={() => {
                                    props.setActiveLocalKey("cloudProvision");
                                    navigate("/cloud-provision", {
                                        state: {
                                            customerId: props.customerId,
                                            serviceId: props.serviceId,
                                            isManagementConsoleNavigation: true,
                                        }
                                    })
                                }}
                            >
                                Buy Subscriptions
                                {/* Provision screen  */}
                            </Button>
                        }

                        {
                            props.transferType == "differentReseller" &&
                            <Button
                                className="btn btn-lg ml-2 px-4"
                                onClick={() => {
                                    props.setActiveLocalKey("cloudProvision");
                                    navigate("/cloud-provision", {
                                        state: {
                                            customerId: props.customerId,
                                            serviceId: props.serviceId,
                                            isManagementConsoleNavigation: true,
                                            isGWSTransferPending: true
                                        }
                                    })
                                }}
                            >
                                View Plans
                                {/* Pending Messge with PopUp */}
                            </Button>
                        }
                    </div>
                </Col>
            </Row>
        </div>

    );
}

export default BuyDomainSuccess;