import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Nav, NavDropdown, Row, Tab } from 'react-bootstrap'
import styles from './profile.module.scss'
import { api } from "../../../Utils/API";
import { CHANGE_PASSWORD, GET_CSP_MPNID_DATA, GOOGLE_GET_RESELLER_PREQUISITES, PROFILE_DETAILS } from '../../../Utils/GlobalConstants';
import PhoneNumber from "../../../GlobalComponents/PhoneNumber";
import { useLocation } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import { emailRegEx, numberRegEx, phoneOnChangeRegex } from '../../../GlobalComponents/RegExPatterns';
import Toast from "../../../GlobalComponents/Toast";
import PasswordToggle from '../../../GlobalComponents/PasswordToggle';
import { useMsal } from '@azure/msal-react';
import ProfileMicrosoftResellerPrerequisite from './LocalComponents/Reseller/Prerequisite/Microsoft';
import ProfileGoogleWorkspaceResellerPrerequisite from './LocalComponents/Reseller/Prerequisite/Googleworkspace';
import { TEXT_SECONDARY, TOAST_SUCCESS_ICON_COLOR } from '../../../Utils/ColorsConfiguration';
import { MPNIdExpiringError, ProfileVendorRequirementsMessage } from '../../../Utils/StaticMessages/GenericErrorMessages';
import { PasswordSuccessToast, ProfileSuccessToast } from '../../../Utils/StaticMessages/ToastMessages';

const Profile = (props) => {
  const location = useLocation();                                                        // use to access data from other page data

  const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Prerequisite") ? true : false);                                     // for read only data
  const [isDisabledPrerequisite, setIsDisabledPrerequisite] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Prerequisite") ? true : false);     // for disabled data
  const [dummyProfileDetails, setDummyProfileDetails] = useState(null);                  // updated default profile details information 
  const [profileDetails, setProfileDetails] = useState(null);                            // updated profile details information 

  // data in get profile api 
  const [tabName, setTabName] = useState("My Profile")                                   // to know the tab name
  const [vendorReqClicked, setVendorReqClicked]                                          // when we are coming from google provision screen 
    = useState(location.state !== null ? location.state.vendorReqClicked : "");

  const [updatedToastMessage, setUpdatedToastMessage] = useState(false);                 // toast message for showing active state passing as a prop so that popup comes at the top over another popup
  const [toastMessage, setToastMessage] = useState("");                                  // toast message to be shown in case of success and error
  const [isProfileUpdateConfirmationBoxFlag, setIsProfileUpdateConfirmationBoxFlag,]     // profile confirmation box popup
    = useState(false);

  const [mpnData, setMpnData] = useState("");                                            // data storing from mpn id get api
  const [preRequisiteData, setPreRequisiteData] = useState("");                          // data from get api regarding google prerequisite details

  const [emailIdError, setEmailIdError] = useState(false);                               // for email id error

  const { instance } = useMsal();                                                        // used for logging purpose
  const [selectedOption, setSelectedOption] = useState("Select");                        // for vendor requirements tab dropdown selection

  const [isEditPasswordPopup, setIsEditPasswordPopup] = useState(false);                  // Edit Password Popup Open Close
  const [passwordIsMatchedValidationCheckFlag, setPasswordIsMatchedValidationCheckFlag] = useState(false);     // To display condition check

  // Password Usestate Input Field (Edit Password)
  const [enableBtn, setEnableBtn] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Password Validation UseState
  const [passwordLength, setPasswordLength] = useState("text-disabled");
  const [passwordFormat, setPasswordFormat] = useState("text-disabled");
  const [passwordMatch, setPasswordMatch] = useState("text-disabled");
  const [passwordMatchError, setPasswordMatchError] = useState("Passwords are matched");

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call account details (profile details)
  async function GetProfileDetails() {
    await api.get(PROFILE_DETAILS, config)
      .then(resp => {
        setProfileDetails(resp.data);
        setDummyProfileDetails(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Function to get details whether mpn id is already configured or not
  async function GetMPNData() {
    await api.get(GET_CSP_MPNID_DATA, config)
      .then(resp => {
        setMpnData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Function to get details whether it is already configured or not and contains domain name or not
  async function GetGooglePreRequisiteData() {
    await api.get(GOOGLE_GET_RESELLER_PREQUISITES, config)
      .then(resp => {
        if(resp.status==204){
          setPreRequisiteData({
            "isGWAlreadyConfigured": false,
            "isGWEducationEnabled": false,
            "isGCPEnabled": false,
            "domainName": null,
            "domainState": null,
            "identityState": null,
            "partnerState": null,
            "linkState": null,
            "cloudIdentityId": null,
            "distributorId": null,
            "partnerAdvantageEnrollUri": null,
            "partnerInviteLinkUri": null,
            "gwServiceState": null,
            "gwEduServiceState": null,
            "gcpServiceState": null
        });
        }else{
          setPreRequisiteData(resp.data);
        }
        
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Function to call account details (profile details)
  async function updateProfileDetails() {
    setIsProfileUpdateConfirmationBoxFlag(false);
    await api.put(PROFILE_DETAILS, profileDetails, config)
      .then(resp => {
        GetProfileDetails();
        if (resp.status == 200) {
          setToastMessage(ProfileSuccessToast);
          setUpdatedToastMessage(true);
          setTimeout(() => {
            setUpdatedToastMessage(false);
          }, 2000);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Checking Password Validation of length and regex constaint
  const getPassword = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length > 0) {
      if (e.target.value.length > 7 && e.target.value.length < 17) {
        setPasswordLength("green");
      } else {
        setPasswordLength("red");
      }
    } else {
      setPasswordLength("text-disabled");
    }

    if (e.target.value.length > 0) {
      let password = e.target.value;
      const lowercaseCount = (password.match(/[a-z]/g) || []).length > 0 ? 1 : 0;
      const uppercaseCount = (password.match(/[A-Z]/g) || []).length > 0 ? 1 : 0;
      const digitCount = (password.match(/\d/g) || []).length > 0 ? 1 : 0;
      const specialCharCount = (password.match(/[^a-zA-Z0-9]/g) || []).length > 0 ? 1 : 0;

      const total = lowercaseCount + uppercaseCount + digitCount + specialCharCount;
      // console.log("Logs :", lowercaseCount, uppercaseCount, digitCount, specialCharCount, lowercaseCount + uppercaseCount + digitCount + specialCharCount)
      if (total >= 3) {
        setPasswordFormat("green");
      } else {
        setPasswordFormat("red");
      }
    } else {
      setPasswordFormat("text-disabled");
    }
  };

  // Password is matched or not Function
  const getPasswordMatch = (e) => {
    if (e.target.value.length <= 16) {
      setConfirmPassword(e.target.value);
    } else {
      setConfirmPassword("");
    }

    if (e.target.value === newPassword) {
      setPasswordMatch("green");
      setPasswordMatchError("Passwords are matched");

      if (oldPassword !== e.target.value) {
      } else {
      }

    } else {
      setPasswordMatch("red");
      setPasswordMatchError("Passwords are not matched");
    }
  };

  // Reset Edit Password Form on close
  const resetPwdForm = () => {
    setPasswordLength("text-disabled");
    setPasswordFormat("text-disabled");
    setPasswordMatch("text-disabled");
    setIsEditPasswordPopup(false);
  }

  const checkPwd = async () => {
    const data = {
      newPassword: newPassword,
    };
    await api.put(CHANGE_PASSWORD, data, config)
      .then(resp => {
        GetProfileDetails();
        if (resp.status == 200) {
          setToastMessage(PasswordSuccessToast);
          setUpdatedToastMessage(true);
          setTimeout(() => {
            setIsEditPasswordPopup(false);
            setUpdatedToastMessage(false);
          }, 2000);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  }

  useEffect(() => {
    if (confirmPassword.length > 0 && confirmPassword.length <= 16) {
      setPasswordIsMatchedValidationCheckFlag(true);
    }
    else {
      setPasswordIsMatchedValidationCheckFlag(false);
    }
  }, [confirmPassword])

  // Validation Check in Edit Password
  useEffect(() => {
    if (
      confirmPassword && newPassword) {
      if (
        (newPassword === confirmPassword && passwordFormat == "green")
      ) {
        setEnableBtn(true);
      }
      if (
        (newPassword != confirmPassword || passwordFormat != "green")
      ) {
        setEnableBtn(false);
      }
      if (confirmPassword != newPassword) {
        setPasswordMatch("red");
        setPasswordMatchError("Passwords are not matched");
      }
      if (confirmPassword === newPassword) {
        setPasswordMatch("green");
        setPasswordMatchError("Passwords are matched");
      }
    }
  }, [oldPassword, confirmPassword, newPassword]);


  // Use Effect to get the account details (profile details) pre-filled in the form.
  useEffect(() => {
    localStorage.getItem("navigation_link") ? localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link", "/profile");
    GetProfileDetails();
    GetMPNData();
    GetGooglePreRequisiteData();
  }, []);

  const resetProfileForm = () => {
    setProfileDetails(dummyProfileDetails);
  }

  //for tab change
  useEffect(() => {
    GetMPNData();
    GetGooglePreRequisiteData();
    if (tabName === "Vendor Requirements") {
    }
  }, [tabName])

  //when user is coming from provision screen
  useEffect(() => {
    if (vendorReqClicked == "Clicked") {
      setTabName("Vendor Requirements");
      setSelectedOption("Google");
    }
  }, [])

  return (
    <div className="main-content">
      <Toast toastCheckUseState={updatedToastMessage} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />

      <Popup
        open={isProfileUpdateConfirmationBoxFlag}
        modal
        className="custom-modal custom-modal-md"
        onClose={() => setIsProfileUpdateConfirmationBoxFlag(false)}
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2>Update Profile</h2>
              <XLg
                size={16}
                className="cpointer text-strong"
                onClick={() => {
                  close();
                  setIsProfileUpdateConfirmationBoxFlag(false);
                }}
                color={TEXT_SECONDARY}
              />
            </div>

            <Container className="p-4">
              <p>Are you sure you want Update your Profile ?</p>
              <div className="actions">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      setIsProfileUpdateConfirmationBoxFlag(false);
                      close();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={updateProfileDetails}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>

      <Popup
        open={isEditPasswordPopup}
        modal
        className="custom-modal custom-modal-sm"
        onClose={() => {
          setPasswordLength("text-disabled");
          setPasswordFormat("text-disabled");
          setPasswordMatch("text-disabled");
          setIsEditPasswordPopup(false);
        }}
      >
        {(close) => (
          <div className="modal">
            <div className="header">
              <h2>Edit Password</h2>
              <XLg
                size={16}
                className="cpointer"
                onClick={() => {
                  close();
                }}
              />
            </div>
            <Container>
              <Row>
                <Col md={12}>

                  <>
                    <Col lg={12}>
                      <Form autoComplete='off'>
                        <Form.Group className="mb-4" controlId="password">
                          <Form.Label>New Password</Form.Label>
                          <PasswordToggle
                            value={newPassword}
                            placeholder="Enter New Password"
                            passwordToggleEvent={getPassword}
                            maxLength="16"
                          />

                          <Form.Text className="text-danger password-validation">
                            <p className='mt-2'>Password must contain</p>
                            <ul>
                              <li className={passwordLength}>
                                <span>
                                  Minimum 8 characters and Maximum 16 characters.
                                </span>
                              </li>
                              <li className={passwordFormat}>
                                <span>
                                  Contain atleast three of the following: Uppercase, Lowercase, Numbers, Special characters.
                                </span>
                              </li>
                            </ul>
                          </Form.Text>
                        </Form.Group>
                        <Form.Group
                          className="mb-4"
                          controlId="confirmPassword"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <PasswordToggle
                            value={confirmPassword}
                            placeholder="Re - Enter New Password"
                            passwordToggleEvent={getPasswordMatch}
                            maxLength="16"
                          />
                          <Form.Text className="text-danger password-validation">
                            {passwordIsMatchedValidationCheckFlag && <ul>
                              <li className={passwordMatch}>
                                {passwordMatchError}
                              </li>
                            </ul>}
                            {!passwordIsMatchedValidationCheckFlag &&
                              <ul className='password-is-matched-disabled'>
                                <li className={passwordMatch}>
                                  {passwordMatchError}
                                </li>
                              </ul>
                            }
                          </Form.Text>
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                          <Button
                            className="btn-lg mx-1 btn-border-light"
                            variant="light"
                            type="reset"
                            onClick={resetPwdForm}
                          >
                            Cancel
                          </Button>
                          {enableBtn ?
                            <Button
                              className="btn mx-1 btn-primary btn-lg"
                              variant="primary"
                              type="button"
                              onClick={checkPwd}
                            >
                              Save
                            </Button>
                            :
                            <Button
                              className="btn mx-1 btn-lg"
                              variant="light"
                              disabled
                            >
                              Save
                            </Button>
                          }

                        </div>
                      </Form>
                    </Col>
                  </>

                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Popup>

      <div className="customer-management-console-main-div mb-5">
        <h1 className="d-block d-md-none mobile-h1">Profile</h1>

        <Tab.Container id="main-tab-bar" defaultActiveKey="My Profile">
          <div className="mgmt-console-table-row">
            <div className="my-3 d-flex">
              <Col className={`py-0 ${styles['profile-table']}`}>

                <Tab.Container activeKey={tabName}>
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="My Profile" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                            onClick={() => { setTabName("My Profile") }}
                          >
                            My Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          {!isDisabledPrerequisite && <Nav.Link eventKey="Vendor Requirements" className={isreadOnly ? "d-none" : 'addons-core-gws-manage pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                            onClick={() => { setTabName("Vendor Requirements") }}
                          >
                            Vendor Requirements</Nav.Link>}
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="My Profile">
                          <div className='cloud-invoice-table-inner table-details details-box'>
                            <div className="my-0 table-heading align-items-center row">
                              <Col className='pl-1'>
                                <Row className='px-3 py-2'>
                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3" controlId="formUserName">

                                      <Form.Label className={`${styles['my-profile-section']}`}>Username </Form.Label>
                                      <Form.Control
                                        type="text"
                                        disabled
                                        readOnly
                                        value={profileDetails ? profileDetails.userName : ''}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={2} md={3}>
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                      <Form.Label className={`${styles['my-profile-section']}`}>First Name <span className="red">*</span></Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={profileDetails ? profileDetails.firstName : ""}
                                        onChange={
                                          (e) => setProfileDetails({ ...profileDetails, firstName: e.target.value })
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={2} md={3}>
                                    <Form.Group className="mb-3" controlId="formLastName">
                                      <Form.Label className={`${styles['my-profile-section']}`}>Last Name <span className="red">*</span></Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={profileDetails ? profileDetails.lastName : ""}
                                        onChange={
                                          (e) => setProfileDetails({ ...profileDetails, lastName: e.target.value })
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}></Col>
                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3" controlId="formPassword">
                                      <Form.Label className={`${styles['my-profile-section']}`}>Password </Form.Label>
                                      <Form.Control
                                        type="password"
                                        disabled
                                        readOnly
                                        value={profileDetails ? profileDetails.password : ''}
                                      />
                                      <span
                                        className="float-right cpointer text-primary text-small mt-1"
                                        onClick={() => {
                                          setIsEditPasswordPopup(true);
                                        }}
                                      >
                                        Edit Password
                                      </span>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={2} md={3}>
                                    <PhoneNumber
                                      phoneNumber={(profileDetails && profileDetails.phoneNumber) ? profileDetails.phoneNumber : ""}
                                      placeholder="Enter Phone Number"
                                      updatePhoneNumber={(e) => setProfileDetails({ ...profileDetails, phoneNumber: phoneOnChangeRegex.test(e.target.value) ? e.target.value : '' })}
                                      isBold={true}
                                    ></PhoneNumber>
                                  </Col>
                                  <Col lg={2} md={3}>
                                    <Form.Group className="mb-3" controlId="formPrimaryEmail">
                                      <Form.Label className={`${styles['my-profile-section']}`}>Primary Email<span className="red">*</span></Form.Label>
                                      <Form.Control
                                        type="email"
                                        disabled
                                        readOnly
                                        value={profileDetails ? profileDetails.email : ''}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}></Col>
                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3" controlId="formPasswordExpiry">
                                      <Form.Label className={`${styles['my-profile-section']}`}>Password Expiry </Form.Label>
                                      <Form.Control
                                        type="text"
                                        disabled
                                        readOnly
                                        value={profileDetails ? profileDetails.passwordExpiry : ''}
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3" controlId="formCloudEmail">
                                      <Form.Label className={`${styles['my-profile-section']}`}>Cloud Email </Form.Label>
                                      <Form.Control
                                        type="email"
                                        value={profileDetails ? profileDetails.cloudEmail : ""}
                                        onChange={
                                          (e) => { setEmailIdError(false); setProfileDetails({ ...profileDetails, cloudEmail: e.target.value }) }
                                        }
                                        className={emailIdError ? "border-danger" : ""}
                                      />
                                      {emailIdError && <span className='text-small pt-0 text-danger'>Please enter valid email id</span>}
                                    </Form.Group>

                                  </Col>
                                </Row>
                              </Col>
                              <Row>
                                <Col className={"my-3 text-right btn-wrapper-outer"}>
                                  {profileDetails &&
                                    !(JSON.stringify(profileDetails) == JSON.stringify(dummyProfileDetails)) && (profileDetails.phoneNumber && (profileDetails.phoneNumber.length >=8 && profileDetails.phoneNumber.length <=15)) ?
                                    <Button Button
                                      className="btn btn-lg mx-3"
                                      onClick={() => {
                                        if (emailRegEx.test(profileDetails.cloudEmail)) {
                                          setEmailIdError(false); setIsProfileUpdateConfirmationBoxFlag(true);
                                        }
                                        else {
                                          setEmailIdError(true);
                                        }
                                      }}
                                    >
                                      Save
                                    </Button> :
                                    <Button
                                      className="btn btn-border-light btn-lg mx-3"
                                      variant="light"
                                      disabled
                                    >
                                      Save
                                    </Button>
                                  }
                                  {(JSON.stringify(profileDetails) == JSON.stringify(dummyProfileDetails)) &&
                                    <Button
                                      className="ml-3 btn-border-light w-md-auto col-xs-12"
                                      variant="light"
                                      disabled
                                    >
                                      Cancel
                                    </Button>
                                  }
                                  {!(JSON.stringify(profileDetails) == JSON.stringify(dummyProfileDetails)) &&
                                    <Button
                                      className="ml-3 btn-border-light w-md-auto col-xs-12"
                                      variant="light"
                                      onClick={resetProfileForm}
                                    >
                                      Cancel
                                    </Button>
                                  }
                                </Col>
                              </Row>

                              {!isDisabledPrerequisite && <div className={`py-3 px-4 ${styles['my-profile-last-section']}`}>
                                <h1>Vendor requirements</h1>
                                <div className='pt-4'>
                                  <Row><Col md={2} className='text-regular mb-4'>
                                    Google
                                  </Col>
                                  </Row>
                                </div>
                                {(preRequisiteData && preRequisiteData.isGWAlreadyConfigured) &&
                                  <span className="status-success mr-1">&nbsp;</span>
                                }<span >Google Cloud Public ID (CID)</span>
                                <Row className='mt-2'>
                                  <Col md={3}>
                                    {preRequisiteData && preRequisiteData.isGWAlreadyConfigured && <Form.Control
                                      type="text"
                                      value={`${preRequisiteData.distributorId}-${preRequisiteData.cloudIdentityId}`}
                                      disabled
                                    />}
                                    {preRequisiteData && !preRequisiteData.isGWAlreadyConfigured &&
                                      <>
                                        <Form.Control
                                          type="text"
                                          className={`${styles['borderRed']}`}
                                          readOnly
                                        />
                                        <span className='red text-small'>{ProfileVendorRequirementsMessage}</span>
                                      </>
                                    }
                                  </Col>
                                  <Col md={4} className={isreadOnly ? "d-none" : 'ml-4 d-none-mobile'}>
                                    {preRequisiteData && preRequisiteData.isGWAlreadyConfigured && <Button className="btn btn-lg btn-border-light px-4"
                                      variant="light"
                                      onClick={() => {
                                        setTabName("Vendor Requirements");
                                        setSelectedOption("Google");
                                      }}
                                    >View Details</Button>}
                                    {preRequisiteData && !preRequisiteData.isGWAlreadyConfigured && <Button className="btn btn-lg btn-border-light px-4"
                                      variant="light"
                                      onClick={() => {
                                        setTabName("Vendor Requirements");
                                        setSelectedOption("Google");
                                      }}
                                    >Configure</Button>}
                                  </Col>
                                </Row>
                                <div className='pt-4'>

                                  {mpnData && <Row>
                                    <Col md={2} className='text-regular mb-4'>
                                      Microsoft
                                    </Col>
                                  </Row>}
                                </div>
                                {(!mpnData.isMPNIdExpired && mpnData.isAlreadyConfigured) &&
                                  <span className="status-success mr-1">&nbsp;</span>
                                } {mpnData && <span >MPN ID</span>}
                                <Row className='mt-2'>
                                  <Col md={3}>
                                    {mpnData && !mpnData.isMPNIdExpired && mpnData.isAlreadyConfigured &&
                                      <Form.Control
                                        type="number"
                                        value={mpnData.mpnId}
                                        disabled
                                      />}
                                    {mpnData && mpnData.isMPNIdExpired && mpnData.isAlreadyConfigured &&
                                      <>
                                        <Form.Control
                                          type="number"
                                          value={mpnData.mpnId}
                                          className={`${styles['borderRed']}`}
                                          readOnly
                                        />
                                        <span className='red text-small'>{MPNIdExpiringError}</span>
                                      </>
                                    }
                                    {mpnData && !mpnData.isAlreadyConfigured &&
                                      <>
                                        <Form.Control
                                          type="number"
                                          value={mpnData.mpnId}
                                          className={`${styles['borderRed']}`}
                                          readOnly
                                        />
                                        <span className='red text-small'>{ProfileVendorRequirementsMessage}</span>
                                      </>
                                    }
                                  </Col>
                                  <Col md={4} className={isreadOnly ? "d-none" : 'ml-4 d-none-mobile'}>
                                    {mpnData && mpnData.isAlreadyConfigured && <Button className="btn btn-lg btn-border-light px-4"
                                      variant="light"
                                      onClick={() => {
                                        setSelectedOption("Microsoft");
                                        setTabName("Vendor Requirements");

                                      }}
                                    >Edit MPN ID</Button>}
                                    {mpnData && !mpnData.isAlreadyConfigured && <Button className="btn btn-lg btn-border-light px-4"
                                      variant="light"
                                      onClick={() => {
                                        setSelectedOption("Microsoft");
                                        setTabName("Vendor Requirements");

                                      }}
                                    >Configure</Button>}
                                  </Col>  </Row>
                              </div>}
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* vendor requirement tab : Vendor Requirements- reseller prerequisite */}
                        <Tab.Pane eventKey="Vendor Requirements">
                          <div className='service-management-csp-header mt-3 ml-7'>
                            <Row>
                              <Col md={3}>
                                <Form.Group>
                                  <Form.Label>Vendor</Form.Label>
                                  <NavDropdown
                                    title={selectedOption}
                                    id="cloud-service-dropdown"
                                    onSelect={handleSelect}
                                    className="servcies-dropdown"
                                    value={selectedOption}
                                  >
                                    <NavDropdown.Item eventKey="Microsoft"
                                      className={selectedOption === "Microsoft" ? "selected" : ""}
                                    >Microsoft</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="Google"
                                      className={selectedOption === "Google" ? "selected" : ""}
                                    >Google</NavDropdown.Item>
                                  </NavDropdown>
                                </Form.Group>

                              </Col>
                            </Row>
                          </div>

                          {selectedOption === 'Microsoft' && (
                            <ProfileMicrosoftResellerPrerequisite Logout={props.Logout} />
                          )}

                          {selectedOption === 'Google' && (
                            <ProfileGoogleWorkspaceResellerPrerequisite tabName={tabName} Logout={props.Logout} />
                          )}

                          {selectedOption === "Select" && (

                            <div className={`${styles['no-data-found']}`}>
                              <div className='mb-4 text-regular'><strong>Select vendor</strong></div>
                              <span>Please select vendor to view or configure vendor requirements.</span>
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </div >

          </div >
        </Tab.Container>
      </div>
    </div>

  )
}

export default Profile