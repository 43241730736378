
// all the static messages including error messages, server error,or any specific message.

export const GenericEmptyStateError = "No Data Found";
export const GenericServerError = "Facing issues in reaching servers, Try again later.";
export const IntegrationMappingSuccess = "Your subscription is mapped successfully.";
export const IntegrationMappingFailure = "Subscription mapping failed.";
export const IntegrationSettingsError = "Credential error.";
export const IntegrationSettingsSuccess = "Authentication successful.";
export const IntegrationSettingsAuthenticationError = "Authentication not successful.";
export const SummariesEmptyError = "No Summaries Found";
export const CreditNotesEmptyError = "No Credit Notes Found";
export const InvoicesEmptyError = "No Invoices Found";
export const DirectDebitSuccess = "Your request has been submitted successfully. Your bank details are currently being reviewed. We kindly ask you to wait for approval.";
export const GenericEmptySubscriptionCloudServiceError = "No Subscriptions in this Cloud Service.";
export const AddSubscriptionMessage = "Please click on Buy Subscriptions to Add Subscriptions";
export const GenericLoadingSubscriptions = "Loading Subscription Details . . .";
export const GenericEmptyAddonsError = "No Addons Found !";
export const CustomerDomainError = "Customer domain is not valid. Please enter a valid domain.";
export const DomainNameError = "Domain names can only contain letters and numbers.";
export const GenericSubmittingSuccess = "Your request has been submitted successfully.";
export const CSPTransitioningSuccess = "Your domain is transitioned successfully.";
export const MCAError = "Update failed. MCA details not saved. Please retry the process.";
export const GenericErrorMessage = "Something went wrong. Please try again";
export const CustomerPrerequisiteTransferSuccess = "Your domain is transferred successfully";
export const CustomerPrerequisiteBuyDomainSuccess = "Your transfer request has been initiated. We will inform you once your transfer request is complete";
export const CustomerPrerequisiteDoaminCheck = "We are unable to process the transfer order. Please contact your Synnex account manager or email accounts@au.synnex-grp.com for more information.";
export const GenericPhoneNumberError = "Invalid phone number";
export const GenericWebsiteURLError = "Invalid website URL";
export const GenericEmailError = "Invalid email";
export const GenericUsernameError = "Invalid username.Please enter valid email";
export const ConfirmationRegisteredEmailError = "Confirmation email must match with registered email";
export const AcronisManageServiceError = "You already have an acronis cyber cloud account, please click on Manage service to go to service management page.";
export const AcronisOrderInProgressError = "Order is already in progress. Please wait for order to be completed.";
export const CreditCardExpiryError = "Your default payment method of credit card has an expired primary card, please proceed to add a new card";
export const DirectDebitExpiryError = "Your default payment method of Direct Debit has all expired statuses, please proceed to add a new application";
export const GenericCloudProvisionOrderError = "There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order.";
export const GenericCloudProvisionRequestError = "We are unable to process your request at this time. Please contact Synnex Accounts team at 'accounts@au.synnex-grp.com' or '0385408888' for further instructions";
export const GenericCloudProvisionNoPaymentError = "No payment method is chosen by default, click on proceed to add payment method.";
export const AcronisOrederSuccess = "Your order is placed successfully. Please wait till it is processed completely.";
export const AvastEmailError = "You have not entered User Email id, please";
export const AvastProceedMessage = "enter it to proceed.";
export const GenericEmailMessage = "Enter valid email ID";
export const GenericAddCustomerPrerequisteMessage = "Please add customer pre-requisites to provision subscriptions.";
export const ResetMPNId = "Please click on proceed to reset your MPNID.";
export const AddVendorRequirements = "Please add vendor requirements to provision subscriptions.";
export const GenericEmailSelecter = "Please Select Email Id";
export const GenericCloudProvisionPlanSelecter = "1 plan selected";
export const GenericCloudProvisionEmptyPlan = "No plans in the list. Please go back to provision module to buy subscriptions.";
export const CotermTrialPlansEligibility = "Trial Plans are not eligible for Co-term. Click on Proceed Button for order confirmation.";
export const GenericEmptySubscriptionError = "No subscriptions found.";
export const GenericEmptyPlanError = "No plans available.";
export const CSPPurchaseAddonEmptyPlan = "No plans in the list. Please go back to buy subscriptions.";
export const UserManagementEmptyMatchingError = "No Matching User Found.";
export const UserManagementEmptyError = "No users added.";
export const UserManagementAddUserError = "There are no users created yet, please click on add user to create a user";
export const UserManagementRoleSelection = "Please select role from the dropdown to view settings";
export const UserMangementNoRoleSelection = "No Role Selected";
export const GenericEmptyMatchingSubscription = "No Matching Subscription Found.";
export const CSPRenewalSettingsTrailPlanError = "Trial Plan cannot be chosen in renewal plan";
export const MPNIdExpiringError = "MPN ID is expired, please update the MPN ID";
export const ProfileVendorRequirementsMessage = "Please click on Configure to add the details";
export const ResellerGooglePrerequisteSubmit = "Your request is submitted successfuly.This may take 3 business days to complete.";
export const ResellerGooglePrerequisteInform = "We will inform you once done.";
export const ResellerGooglePrerequisteDomainName = "Invalid domain name. Type valid domain.";
export const OrderHistoryEmptyError = "No Orders found";
export const NotificationEmptyError = "No notifications found";
export const BulkUploadEmptyError = "No Successfully Uploaded Records!";
export const BulkUploadLoadingMessage = "Loading Customer Details . . .";
export const GenericEmptyCustomerError = "No Customer Found.";
export const WidgetsEmptyError = "No Widgets available to add";
export const MicrosoftInvalidId = "Invalid Microsoft ID (Tenant ID)";
export const CSPPrerequisteSuccess = "Your pre-requisites are successfully completed";
export const CSPDomainProvisionError = "Your transfer request is being reviewed.";
export const GenericNoMatchingCustomerError = "No Matching Customer Found.";
export const AcronisUpgradeAvailability = "Upgrades Available";
export const AcronisNoUpgradeAvailability = "No Upgrades Available";
export const AcronisServiceError = "Cloud Service is Inactive";
export const AcronisRaiseTicket = "Please raise ticket to activate the cloud service.";
export const GenericServiceNotProvisionedError = "Selected Cloud Service is not provisioned";
export const GenericNoSubscriptionsError = "No Subscriptions are currently available for this cloud service.";
export const GenericUpgradeError = "This Subscription can't be upgraded";
export const CSPMigrateFailError = "Your migration process initiation failed";
export const CSPMigrateSuccess = "Your Migration Process is initiated successfully";
export const CSPMigrateEligibilityFail = "Migration plans eligibility failed";
export const CSPMigrateEmailConfirmationMessage = "You will receive the confirmation on your email id. You can monitor the status in service management.";
export const CSPMigrateNonEligiblePlans = "You can’t initiate migration due to non-eligible plans in the selection. Please try again later.";
export const PurchaseAddonQuantityError = "Order failed as the edit didn’t satisfy";
export const CSPPurchaseAddonEmptyProvisionError = "No plans in this list. Please go back to provision module to buy subscriptions.";
export const CSPTrialtoPaidError = "This subscription can't be converted";
export const GenericEmptyPlansError = "No Plans Found";
export const GenericEmptyMatchingSubscriptionError = "No Matching Subscriptions Found.";
export const GCPEmptyUserError = "No users assigned to the role";
export const PurchaseAddonSeatsError = "The requested no. of seats exceeded the remaining limit of 300 seats allowed per subscription. If you wish to proceed further with change quantity request, please revise the quantity to available limit";
export const GWSEmptyAddonError = "No Add-ons available";