import React, { useState, useEffect } from 'react';
import { Drawer } from "@mui/material";
import { Button, Col, Container, Form, Nav, NavDropdown, Row, Tab } from "react-bootstrap";
import './styles.scss';
import { XLg } from 'react-bootstrap-icons';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { GET_FEEDBACK_QUESTIONS, POST_FEEDBACK_REVIEWS, PROFILE_DETAILS } from '../../Utils/GlobalConstants';
import { api } from '../../Utils/API';
import Toast from '../Toast';
import { TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../Utils/ColorsConfiguration';
import { FeedbackErrorToast, FeedbackSuccessToast } from '../../Utils/StaticMessages/ToastMessages';

const FeedbackDrawer = ({ open, toggleDrawer, setOpen, Logout }) => {
    const [comments, setComments] = useState("")                                       // feedback comment
    const [profileDetails, setProfileDetails] = useState("")                           // to set the profile data 
    const [feedbackData, setFeedbackData] = useState([]);                              // to store the feedback data given by the user
    const [defaultFeedbackData, setDefaultFeedbackData] = useState([]);                // this default usestate is for button check
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);               // loading state for processing button

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const labels = {
        0: '‏‏‎ ‎',
        0.5: 'Poor',
        1: 'Below Expectations',
        1.5: 'Unsatisfactory',
        2: 'Below Average',
        2.5: 'Average',
        3: 'Neutral',
        3.5: 'Good',
        4: 'Satisfactory',
        4.5: 'Very Good',
        5: 'Outstanding',
    };

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    function ChangeRating(id, name, hoverId, newValue) {
        let newData = {
            "id": id,
            "name": name,
            "value": (newValue == null ? 0 : newValue),
            "hover": hoverId
        }
        let localDataChanged = [];
        feedbackData.map((data) => {
            if (data.id === id) {
                localDataChanged.push(newData);
            }
            else {
                localDataChanged.push(data);
            }
        })
        setFeedbackData(localDataChanged);
    }

    function ChangeHover(id, name, value, newHover) {
        let newData = {
            "id": id,
            "name": name,
            "value": value,
            "hover": newHover
        }
        let localDataChanged = [];
        feedbackData.map((data) => {
            if (data.id === id) {
                localDataChanged.push(newData);
            }
            else {
                localDataChanged.push(data);
            }
        })
        setFeedbackData(localDataChanged);
    }

    function ExpectedFormatForPost() {
        const updatedData = feedbackData.map((data) => ({
            "question": data.name,
            "rating": data.value
        }));

        // console.log("Updated data:", (updatedData));
        PostFeedbackData(updatedData);
    }

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call Feedback questions
    async function GetFeedbackQuestions() {
        await api.get(`${GET_FEEDBACK_QUESTIONS}`, config)
            .then(resp => {
                let localFeedbackData = []
                resp.data.map((data) => {
                    localFeedbackData.push({
                        "id": data.id,
                        "name": data.name,
                        "value": 0,
                        "hover": -1,
                    })
                })
                // console.log("localFeedbackData :", localFeedbackData);
                setFeedbackData(localFeedbackData);
                setDefaultFeedbackData(localFeedbackData);
                // console.log("vidhi", resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call account details (profile details)
    async function GetProfileDetails() {
        await api.get(PROFILE_DETAILS, config)
            .then(resp => {
                setProfileDetails(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //post api for feedback data
    async function PostFeedbackData(feedbackData) {
        setConfirmLoadingFlag(true);

        const data = {
            "questionRatings": feedbackData,
            "contactName": (profileDetails?.firstName + " " + profileDetails?.lastName),
            "contactEmail": profileDetails?.email,
            "contactPhoneNumber": profileDetails?.phoneNumber,
            "comments": comments
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(POST_FEEDBACK_REVIEWS, data, configFile)
            .then(resp => {
                setConfirmLoadingFlag(false);
                if (resp.status = 200) {
                    setFeedbackData(defaultFeedbackData);
                    setComments("");
                    setToastSuccess(true);
                    setToastMessage(FeedbackSuccessToast);
                    setTimeout(() => {
                        setToastSuccess(false);
                        setOpen(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setFeedbackData(defaultFeedbackData);
                    setComments("");
                    setToastError(true);
                    setToastMessage(FeedbackErrorToast);
                    setTimeout(() => {
                        setToastError(false);
                    }, 3000);
                }
            })
    };

    //useEffect for calling get api on initial load
    useEffect(() => {
        if (toggleDrawer && open) {
            GetFeedbackQuestions();
            GetProfileDetails();
        }
    }, [toggleDrawer])


    const hasZeroValue = feedbackData.filter(item => item.value === 0).length > 0;

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

            <Drawer anchor="right" open={open} onClose={() => { setOpen(false); toggleDrawer(false); setComments(""); setFeedbackData([]); }} className="feedback-drawer-outer">
                <div className='feedback-drawer-main pl-7 pt-5 pr-4'>
                    <Row className='pb-1 feedback-buttons'>
                        <Col>
                            <h1>Feedback</h1>
                        </Col>
                        <Col className='text-right'>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={() => { setOpen(false); setComments(""); setFeedbackData([]); toggleDrawer(false); }}
                                color={TEXT_SECONDARY}
                            />
                        </Col>
                    </Row>
                    <hr className='mb-0'></hr>
                    {<Container className='feedback-height'>
                        {
                            feedbackData && feedbackData.map((data) => {
                                return (
                                    <Row className='rating-main-div mt-6'>
                                        <p>{data.name}<span className='text-danger'>*</span></p>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Rating
                                                name="hover-feedback"
                                                value={data.value}
                                                precision={0.5}
                                                getLabelText={getLabelText}
                                                onChange={(event, newValue) => {
                                                    ChangeRating(data.id, data.name, data.hover, newValue);
                                                }}
                                                onChangeActive={(event, newHover) => {
                                                    ChangeHover(data.id, data.name, data.value, newHover);
                                                }}
                                                emptyIcon={<StarIcon style={{ opacity: 0.4 }} fontSize="inherit" />}
                                            />
                                            {data.value !== null && (
                                                <Box className="" sx={{ ml: 2 }}>{labels[data.hover !== -1 ? data.hover : data.value]}</Box>
                                            )}
                                        </Box>
                                    </Row>
                                )
                            })
                        }
                        {feedbackData.length > 0 && <Row className='mt-6'>
                            <Form.Group className="form-group mb-3" controlId="comments">
                                <Form.Label>Suggestions & Improvements</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    className='mt-2'
                                    rows={6}
                                    value={comments}
                                    placeholder=""
                                    onWheel={e => e.preventDefault()}  // Disable scrolling on wheel event
                                    style={{ resize: "none" }}  // Disable resizing by dragging
                                    onChange={e => (e.target.value.length <= 2000 ? setComments(e.target.value) : "")}
                                />
                                <span className="text-small text-right w-100 d-block mt-1">({comments.length}/2000)</span>
                            </Form.Group>
                        </Row>}
                    </Container>}
                    <Row className='feedback-buttons'>
                        <Col className={"text-right btn-wrapper-outer right-overlay-btn"}>
                            {!confirmLoadingFlag && <>
                                {((JSON.stringify(defaultFeedbackData) === JSON.stringify(feedbackData)) && comments == "") ?
                                    <Button
                                        className="mx-2 btn-border-light w-md-auto col-xs-12"
                                        variant="light"
                                        disabled
                                    >
                                        Clear
                                    </Button> :
                                    <Button
                                        className="mx-2 btn-border-light w-md-auto col-xs-12"
                                        variant="light"
                                        onClick={() => { setFeedbackData(defaultFeedbackData); setComments(""); }}>
                                        Clear
                                    </Button>}
                                {!hasZeroValue ?
                                    <Button className="mx-2 btn btn-lg" onClick={() => { ExpectedFormatForPost() }}>
                                        Submit
                                    </Button> :
                                    <Button
                                        className="btn btn-border-light btn-lg mx-2"
                                        variant="light"
                                        disabled
                                    >
                                        Submit
                                    </Button>}
                            </>}
                            {
                                confirmLoadingFlag && <>
                                    <Button
                                        className="btn btn-border-light btn-lg mx-2"
                                        variant="light"
                                        disabled
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        className="btn btn-border-light btn-lg mx-2"
                                        variant="light"
                                        disabled
                                    >
                                        Processing ...
                                    </Button>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </>
    )
}

export default FeedbackDrawer