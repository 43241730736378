import React, { useState, useEffect } from 'react'
import { ACCEPT_LOGIN_TERMS_AND_CONDITIONS, GET_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Popup from 'reactjs-popup';
import { Button, Container } from 'react-bootstrap';
import './styles.scss';
import Toast from '../../../../../GlobalComponents/Toast';
import { TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../../Utils/ColorsConfiguration';

const LoginTermsAndCondition = ({ customerIsAuthenticated,termsAndConditionPopupFlag, setTermsAndConditionPopupFlag,setTriggerNotificationsPopup, Logout }) => {
  const [termsAndConditionData, setTermsAndConditionData] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);

  const userAgent = navigator.userAgent;
  const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
  const browserVersion = userAgent.match(/\((.*?)\)/);


  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                                         // toast message displaying success message
  const [toastError, setToastError] = useState(false);                                             // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                            // toast message 

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Terms and Condition Get Popup Data
  async function TermsAndConditionPopupDataLogin() {
    await api.get(GET_LOGIN_TERMS_AND_CONDITIONS + "/Login", config).then((resp) => {
      // handle success
      // console.log("tnc statusssss::::::::", (resp.data.data[0]));
      setTermsAndConditionData(resp.data.data[0].htmlContent);
      setTermsAndConditionPopupFlag(!(resp.data.data[0].isLatestTncAccepted));
      if(resp.data.data[0].isLatestTncAccepted){
        setTriggerNotificationsPopup(true);
      }
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  }

  // Terms and Condition Get Popup Provision Data
  async function TermsAndConditionPopupDataCloud() {
    await api.get(GET_LOGIN_TERMS_AND_CONDITIONS + "/Cloud", config).then((resp) => {
      // handle success
      // console.log("tnc cloud statusssss::::::::", (resp.data.data[0].isLatestTncAccepted));
      localStorage.setItem("cloud_terms_and_conditions", resp.data.data[0].isLatestTncAccepted);
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {

        }
      });
  }

  function generateRandomIp() {
    // Generate random IP address components
    const octets = [];
    for (let i = 0; i < 4; i++) {
      octets.push(Math.floor(Math.random() * 256).toString());
    }

    // Join the octets with dots to form the IP address
    const ipAddress = octets.join(".");

    return ipAddress;
  }

  // Terms and Condition accept Popup Data
  async function TermsAndConditionAcceptFunction(browser, ipAddress) {
    (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
    let body = {
      "browser": browser,
      "ipAddress": ipAddress,
      "module": "Login",
    }
    await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS, body, config).then((resp) => {
      // handle success
      setTermsAndConditionPopupFlag(false);
      setTriggerNotificationsPopup(true);
      if (!(resp.data.isSuccess)) {
        setToastMessage(resp.data.message);
        setToastError(true);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
      }
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        } else {

        }
      });
  }

  useEffect(() => {
    if(customerIsAuthenticated){
      TermsAndConditionPopupDataLogin();
      TermsAndConditionPopupDataCloud();
    }
    
  }, [customerIsAuthenticated])


  useEffect(() => {
    if (termsAndConditionPopupFlag) {
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => setIpAddress(data.ip));
      // fetch("http://jsonip.com", { mode: 'cors'} )
      // .then(response => response.json())
      // .then(data => setIpAddress(data.ip));
    }
  }, [termsAndConditionPopupFlag]);

  return (
    <>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />
      {termsAndConditionData && <Popup
        className="custom-modal custom-modal-xl"
        open={termsAndConditionPopupFlag}
        closeOnEscape={false}
        modal
        nested
        //  contentStyle={{ width: '90% ' }}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="modal terms-conditions">
            <Container fluid className="p-4">
              <div className="content" dangerouslySetInnerHTML={{ __html: termsAndConditionData }}>

              </div>

            </Container>
            <div className="actions">
              <Container fluid className="px-3 py-3">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      let browser = browserName[1] + " " + browserVersion[1];
                      // console.log("IP ADDRESS :",ipAddress,browserName[1],browserVersion[1]);
                      TermsAndConditionAcceptFunction(browser, ipAddress);
                    }}
                  >
                    I Accept
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        )}
      </Popup>}
    </>
  )
}

export default LoginTermsAndCondition