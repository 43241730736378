// All the Notes/Disclaimers for entire application will be dispalyed here

export const InvoicesCloudCareNote = "Unit prices shown are based on the total amount of quantity used across cloud care offers.";
export const InvoicesAcronisNote = "Unit prices shown are based on the total amount of quantity used across Acronis offers.";
export const CloudProvisionNote = "Note: Please select customer to proceed.";
export const CustomerCloudProvisionNote = "Your transfer request is being reviewed. Kindly await completion before provisioning subscriptions.";
export const CspCustomerPrerequisteNote = "Note: Organization Details section is not complete. Please fill all the details to proceed.";
export const CspRenewalSettingNote = "Any changes in the plan will automatically delete the scheduled renewal.";
export const NotificationNote = "Renewal price is based on current pricing, please check back closer to renewal date.";
export const ResellerGWSPrerequisteNote = "Note: You have not accepted partner invite link, please accept to proceed";
export const CSPTransitionDomainNote = "Accepting Microsoft Cloud Solution Provider invitation is mandatory before you click on proceed.";
export const DiscountedPlanNote = "Contract pricing displayed is when renewal setting is set to annual plan, prices may differ for flexible plan";
export const GWSUpgardeNote = "Renewal price is based on current pricing. This will change if new prices are published before renewal by Google.";
export const EditQuantityNote = "Plan cost is provided under 'Sub - Total Price', final cost may vary based on vendor’s billing logic.";
export const GCPServiceManagementNote = "Note: After issuing a suspension, customers can't create new resources on the suspended account. Customers can still manage existing resources while a suspension is active & all existing resources are still billable.";
export const ChangeStatusCancellationAllowedNote = "Cancellation allowed until";
export const ChangeStatus7DayCancellationNote = "Your 7 Day Cancellation window is closed.";