export const VALIDATE_USER = "/b2b2c-user/api/validate-user/";
export const VALIDATE_OTP = "/b2b2c-user/api/validate-otp/";
export const FORGOT_PASSWORD = "/b2b2c-user/api/forgot-password/";
export const RESET_PASSWORD = "/b2b2c-user/api/reset-password/";
export const LANDING_PAGE_CMS_DATA = "/cloud-marketplace-cms";
export const LOGOUT = "/b2b2c-customer/api/profile/logout/";
export const CLOUD_SUMMARY_LISTING_TABLE = "/cloud-marketplace-billing/api/summaries/";

// Billing
// Cloud Summary
export const CLOUD_SUMMARY_DOWNLOAD_CSV_SUMMARY = "/cloud-marketplace-billing/api/summaries/download-csv/";
export const CLOUD_SUMMARY_DOWNLOAD_CSV_RECON = "/cloud-marketplace-billing/api/summaries/download-recon/";
export const CLOUD_SUMMARY_DOWNLOAD_PDF_SUMMARY = "/cloud-marketplace-billing/api/summaries/download-pdf/";
export const CLOUD_SUMMARY_DOWNLOAD_PDF_SLIPS = "/cloud-marketplace-billing/api/summaries/download-slips/";
export const CLOUD_SUMMARY_DOWNLOAD_DAILY_USAGE_CSV = "cloud-marketplace-billing/api/summaries/download-dailyusage/";
export const DOWNLOAD_ACRONIS_USAGE_REPORT = "/cloud-marketplace-billing/api/summaries/download-acronis-usage-report/";

// Credit Note
export const GET_CREDIT_NOTE_STATUS_LIST = "/cloud-marketplace-billing/api/credit-notes/status-types";
export const CLOUD_CREDIT_NOTE_DOWNLOAD_CSV_SUMMARY = "/cloud-marketplace-billing/api/credit-notes/download-csv/";
export const CLOUD_CREDIT_NOTE_DOWNLOAD_PDF_SUMMARY = "/cloud-marketplace-billing/api/credit-notes/download-pdf/";
export const CLOUD_CREDIT_NOTE_LISTING_TABLE = "/cloud-marketplace-billing/api/credit-notes/";

export const GET_SERVICES = "/cloud-marketplace-product/api/services";
export const GET_SERVICES_ALL = "/cloud-marketplace-product/api/online-services";
export const GET_CUSTOMERS_LIST = "/cloud-marketplace-customer/api/customers/select-list";
export const GET_STATUS_LIST = "/cloud-marketplace-billing/api/invoices/status-types";

// Cloud Invoice
export const CLOUD_INVOICE_LISTING_TABLE = "/cloud-marketplace-billing/api/invoices/";
export const CLOUD_INVOICE_DOWNLOAD_CSV_SUMMARY = "/cloud-marketplace-billing/api/invoices/download-csv/";
export const CLOUD_INVOICE_DOWNLOAD_CSV_RECON = "/cloud-marketplace-billing/api/invoices/download-recon/";
export const CLOUD_INVOICE_DOWNLOAD_PDF_SUMMARY = "/cloud-marketplace-billing/api/invoices/download-pdf/";

//Unbilled Usage Report
export const RESELLER_UNBILLED_USAGE_REPORT = "/cloud-marketplace-billing/api/unbilled-usage/reseller-report";
export const UPDATE_RESELLER_UNBILLED_USAGE_REPORT = "/cloud-marketplace-billing/api/unbilled-usage/change-reseller-threshold";
export const CUSTOMER_UNBILLED_USAGE_REPORT = "/cloud-marketplace-billing/api/unbilled-usage/customer-report";
export const UPDATE_CUSTOMER_UNBILLED_USAGE_REPORT = "/cloud-marketplace-billing/api/unbilled-usage/change-customer-threshold";

//Order History
export const GET_ORDER_TYPE_LIST = "/cloud-marketplace-order/api/orders/types";
export const GET_ORDER_STATUS_LIST = "/cloud-marketplace-order/api/orders/status-types";
export const ORDER_HISTORY_LISTING_TABLE = "/cloud-marketplace-order/api/orders";
export const ORDER_HISTORY_ADDITIONAL_DETAILS = "/cloud-marketplace-order/api/orders/";

//My Customer
export const CUSTOMER_LISTING_TABLE = "/cloud-marketplace-customer/api/customers";
export const GET_CUSTOMER_STATUS_LIST = "/cloud-marketplace-customer/api/master-data/status-types";
export const CUSTOMER_DOWNLOAD_CSV_SUMMARY = "/cloud-marketplace-customer/api/customers/download-csv";
export const UPDATE_CUSTOMER_STATUS = "/cloud-marketplace-customer/api/customers/change-status";
export const DOWNLOAD_BULK_UPLOAD_TEMPLATE = "/cloud-marketplace-customer/api/bulk-upload";

//ACRONIS Service Management
export const ACRONIS_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace-acronis/api/account-details";
export const ACRONIS_SERVICE_MANAGEMENT_PRICE_LIST = "/cloud-marketplace-acronis/api/download-price";
export const ACRONIS_SERVICE_MANAGEMENT_UPGRADE_VALUES = "/cloud-marketplace-acronis/api/upgrade";
export const ACRONIS_CONVERT_TRIAL_TO_PAID = "/cloud-marketplace-acronis/api/trail-to-paid";

//AZURE Service Mangement
export const AZURE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/azure/api/subscriptions/account-details";
export const GET_AZURE_SUBSCRIPTION_TABLE = "/cloud-marketplace/azure/api/subscriptions";
export const AZURE_STATUS_TYPE = "/cloud-marketplace/azure/api/subscriptions/status-types";
export const AZURE_MONTHLY_FOREX = "/cloud-marketplace/azure/api/subscriptions/monthly-forex";
export const AZURE_RESOURCE_GROUP = "/cloud-marketplace/azure/api/subscriptions/resource-group";
export const AZURE_RESOURCE_LIST = "/cloud-marketplace/azure/api/subscriptions/resource-list";
export const AZURE_CHANGE_STATUS = "/cloud-marketplace/azure/api/subscriptions/";
export const GET_AZURE_ROLES = "/cloud-marketplace/azure/api/subscriptions/user-roles";
export const DELETE_AZURE_ROLES = "/cloud-marketplace/azure/api/subscriptions/delete-user-permission";

//CSP Service Management
export const CSP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/microsoft-csp/api/subscriptions/account-details";
export const GET_CSP_SUBSCRIPTION_TABLE = "/cloud-marketplace/microsoft-csp/api/subscriptions";
export const CSP_SUBSCRIPTION_TYPE = "/cloud-marketplace/microsoft-csp/api/subscriptions/subscription-types";
export const CSP_STATUS_TYPE = "/cloud-marketplace/microsoft-csp/api/subscriptions/status-types";
export const CSP_BILLING_TERMS_TYPE = "/cloud-marketplace/microsoft-csp/api/subscriptions/billing-terms";
export const CSP_ADDONS = "/cloud-marketplace/microsoft-csp/api/add-ons/";
export const CSP_SUBSCRIPTION_DETAILS = "/cloud-marketplace/microsoft-csp/api/subscriptions/";
export const CSP_EDIT_QUANTITY_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/change-quantity";
export const CSP_TRIAL_TO_PAID_GET_PLANS = "/cloud-marketplace/microsoft-csp/api/subscriptions/trial-or-paid-plans/";
export const CSP_TRIAL_TO_PAID_POST_PLANS = "/cloud-marketplace/microsoft-csp/api/subscriptions/trail-to-paid";
export const CSP_BILLING_COMMITMENT_DROPDOWN = "/cloud-marketplace/microsoft-csp/api/subscriptions/billing-terms";
export const CSP_PURCHASE_ADD_ONS_GET = "/cloud-marketplace/microsoft-csp/api/add-ons/purchasable-addons";
export const CSP_PURCHASE_ADD_ONS_POST = "/cloud-marketplace/microsoft-csp/api/add-ons";
export const CSP_MIGRATION_CHECK_ELIGIBILITY = "/cloud-marketplace/microsoft-csp/api/subscriptions/migration-eligiblity";
export const CSP_MIGRATION_NCE_PLANS = "/cloud-marketplace/microsoft-csp/api/subscriptions/nce-migration-plan";
export const CSP_MIGRATION = "/cloud-marketplace/microsoft-csp/api/subscriptions/migrate-plan";
export const CSP_MIGRATION_COTERM_GET ="/cloud-marketplace/microsoft-csp/api/subscriptions/migration/co-term";

//csp Customer Pre-Requisite  
export const CSP_CHECK_DOMAIN_AVAILABILITY = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/check-domain-availability";
export const CSP_CHECK_TENANT_AVAILABILITY = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/check-tenant-availability";
export const CSP_DOMAIN_TRANSITION = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/transition-domain";
export const CSP_DOMAIN_SUBMIT_GDAP = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/submit-gdap";
export const CSP_MCA_AGREEMENT_DETAILS = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/mca-agreement/";
export const CSP_GET_GDAP_DETAILS = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/transition-domain-onboard";
export const CSP_GDAP_TRANSITION_STATUS = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/gdap-domain-transition-status";
export const CSP_TRANSITION_ONBOARD_STATUS = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/transition-domain-onboard-status";
export const MCA_AGREEMENT_DOWNLOAD = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/mca-agreement-template";

//renewal settings
export const CSP_RENEWAL_DELETE_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/delete-manage-renewal";
export const CSP_RENEWAL_SETTINGS_GET = "/cloud-marketplace/microsoft-csp/api/subscriptions/manage-renewal";
export const CSP_RENEWAL_CANCEL_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/cancel-auto-renewal";
export const CSP_RENEWAL_COTERM = "/cloud-marketplace/microsoft-csp/api/subscriptions/manage-renewal/co-term";

//upgrade subscriptions
export const CSP_UPGRADE_PLANS_STEP1 = "/cloud-marketplace/microsoft-csp/api/subscriptions/upgrade/";
export const CSP_UPGRADE_PLANS_STEP2 = "/cloud-marketplace/microsoft-csp/api/subscriptions/upgrade-plans";
export const CSP_UPGRADE_EXISTING_SUBSCRIPTIONS_STEP2 = "/cloud-marketplace/microsoft-csp/api/subscriptions/upgrade/eligible-subscriptions";

//change status 
export const CSP_REACTIVATE_STATUS_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/activate";
export const CSP_SUSPEND_STATUS_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/suspend";
export const CSP_CANCEL_STATUS_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/cancel";

//csp provision
export const CSP_PRODUCT_PLANS = "/cloud-marketplace-product/api/csp/product-plans";
export const CSP_PRODUCT_ORDERING = "/cloud-marketplace-product/api/product-provision/microsoft-csp";
export const CSP_PROVISION_COTERM_DATA = "/cloud-marketplace/microsoft-csp/api/subscriptions/provision/co-term";
export const CSP_LEGACY_BILLING_TERM = "/cloud-marketplace-product/api/legacy-billing-terms";

//csp Reseller Pre-Requisite 
export const CSP_VERIFY_MPNID = "/cloud-marketplace/microsoft-csp/api/reseller/prerequisites/verify-mpnid";
export const CSP_SAVE_MPNID = "/cloud-marketplace/microsoft-csp/api/reseller/prerequisites/save-mpnid";
export const GET_CSP_MPNID_DATA = "/cloud-marketplace/microsoft-csp/api/reseller/prerequisites";
export const UPDATE_CSP_MPNID = "/cloud-marketplace/microsoft-csp/api/reseller/prerequisites/update-mpnid";

//Google Workspace
export const GOOGLE_WORKSPACE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/gws/api/subscriptions/account-details";
export const GOOGLE_WORKSPACE_SUBSCRIPTION_TABLE = "/cloud-marketplace/gws/api/subscriptions";
export const GOOGLE_WORKSPACE_SUBSCRIPTION_TYPE = "/cloud-marketplace/gws/api/subscriptions/subscription-types";
export const GOOGLE_WORKSPACE_STATUS_TYPE = "/cloud-marketplace/gws/api/subscriptions/status-types";
export const GOOGLE_WORKSPACE_BILLING_TERMS_TYPE = "/cloud-marketplace/gws/api/subscriptions/billing-terms";
export const GOOGLE_WORKSPACE_ADDONS = "/cloud-marketplace/gws/api/add-ons";
export const GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS = "/cloud-marketplace/gws/api/subscriptions/";
export const GOOGLE_WORKSPACE_UPGRADE_CORE = "/cloud-marketplace/gws/api/subscriptions/upgrade/purchasablesku";
export const GOOGLE_WORKSPACE_CORE_PURCHASABLE_OFFERS = "/cloud-marketplace/gws/api/subscriptions/purchasable-offer";
export const GOOGLE_WORKSPACE_UPGRADE_PLAN_OFFER = "/cloud-marketplace/gws/api/subscriptions/upgrade";
export const GOOGLE_WORKSPACE_DOWNGRADE_CORE = "/cloud-marketplace/gws/api/subscriptions/downgrade/purchasablesku";
export const GOOGLE_WORKSPACE_DOWNGRADE_PLAN_OFFER = "/cloud-marketplace/gws/api/subscriptions/downgrade";
export const GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN = "/cloud-marketplace/gws/api/subscriptions/trail-to-paid-plans";
export const GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN_POST = "cloud-marketplace/gws/api/subscriptions/upgrade";
export const GOOGLE_WORKSPACE_RENEWAL_SETTINGS_GET = "cloud-marketplace/gws/api/subscriptions/renewal-settings";
export const GOOGLE_WORKSPACE_RENEWAL_SETTINGS_POST = "cloud-marketplace/gws/api/subscriptions/change-renewal-setting";
export const GOOGLE_WORKSPACE_CHANGE_STATUS_POST = "cloud-marketplace/gws/api/subscriptions/";
export const GOOGLE_WORKSPACE_TRIAL_TO_PAID_GET_PLANS = "/cloud-marketplace/gws/api/subscriptions/trail-to-paid-plans";
export const GOOGLE_WORKSPACE_TRIAL_TO_PAID_POST_PLANS = "/cloud-marketplace/gws/api/subscriptions/start-Paid-Service";
export const GOOGLE_WORKSPACE_EDIT_QUANTITY_UPDATE = "/cloud-marketplace/gws/api/subscriptions/change-quantity";
export const GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_GET = "/cloud-marketplace/gws/api/add-ons/purchasable-addon";
export const GOOGLE_WORKSPACE_PURCHASE_ADD_ONS_POST = "/cloud-marketplace/gws/api/add-ons/purchase-addon";
export const GOOGLE_WORKSPACE_CHECK_IDENTITY = "/cloud-marketplace/gws/api/customer/prerequisites/check-identity-account-exists";
export const GOOGLE_WORKSPACE_CHECK_DOMAIN_AVAILABILITY = "/cloud-marketplace/gws/api/subscriptions/check-domain-availability";
export const GOOGLE_WORKSPACE_CHECK_CUSTOMER_DOMAIN_AVAILABILITY = "/cloud-marketplace/gws/api/customer/prerequisites/check-domain-availability";
export const GOOGLE_WORKSPACE_DIRECT_TRANSFER_SUBSCRIPTION = "/cloud-marketplace/gws/api/customer/prerequisites/direct-transfer";
export const GOOGLE_WORKSPACE_PARTNER_CONSOLE_TRANSFER_SUBSCRIPTION = "/cloud-marketplace/gws/api/customer/prerequisites/myown-partner-sales-console-transfer";
export const GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_SUBSCRIPTION ="/cloud-marketplace/gws/api/customer/prerequisites/indirect-transfer-different-reseller";
export const GOOGLE_WORKSPACE_DIFFERENT_RESELLER_TRANSFER_R1_R2 = "/cloud-marketplace/gws/api/customer/prerequisites/transfer-different-reseller";
export const GOOGLE_WORKSPACE_RESELLER_PREREQUISITES = "/cloud-marketplace/gws/api/reseller/prerequisites";
export const GOOGLE_CHECK_IDENTITY_ACCOUNT = "/cloud-marketplace/gws/api/customer/prerequisites/check-identity-account-exists";
export const GOOGLE_WORKSPACE_PRODUCT_ORDERING = "/cloud-marketplace-product/api/product-provision/google-workspace";
export const GOOGLE_WORKSPACE_CORE_PURCHASABLE_OFFERS_DOWNGRADE = "/cloud-marketplace/gws/api/subscriptions/downgrade/purchasable-offer";

//google reseller prerequisite
export const GOOGLE_VERIFY_DOMAIN = "/cloud-marketplace/gws/api/reseller/prerequisites/verify-domain";
export const GOOGLE_GET_RESELLER_PREQUISITES = "/cloud-marketplace/gws/api/reseller/prerequisites";
export const UPDATE_PARTENER_ADVANTAGE_STAGE2 = "/cloud-marketplace/gws/api/reseller/prerequisites/update-partner-state";
export const GET_PARTENER_LINK_ACCEPTANCE_STATUS = "/cloud-marketplace/gws/api/reseller/prerequisites/check-partner-link-status";
export const POST_ENABLE_GOOGLE_SERVICE = "/cloud-marketplace/gws/api/reseller/prerequisites/enable-service";
export const GOOGLE_RESELLER_PREREQUISITE = "/cloud-marketplace/gws/api/reseller/prerequisites";
export const GOOGLE_RESELLER_RESET = "/cloud-marketplace/gws/api/reseller/prerequisites/reset-authorized-reseller";

// product plan-provision
export const GOOGLE_WORKSPACE_PRODUCT_PLANS = "/cloud-marketplace-product/api/gws/product-plans";

//Avast Service Management
export const AVAST_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/avast/api/subscriptions/account-details";
export const AVAST_SUBSCRIPTION_TABLE = "/cloud-marketplace/avast/api/subscriptions";
export const AVAST_STATUS_TYPE = "/cloud-marketplace/avast/api/subscriptions/status-types";
export const AVAST_BILLING_TERM = "/cloud-marketplace/avast/api/subscriptions/billing-term";

//Google Cloud Platform
export const GCP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/gcp/api/subscriptions/account-details";
export const GCP_SUBSCRIPTION_TABLE = "/cloud-marketplace/gcp/api/subscriptions/";
export const GCP_STATUS_TYPE = "/cloud-marketplace/gcp/api/subscriptions/status-types";
export const GCP_CHANGE_STATUS = "/cloud-marketplace/gcp/api/subscriptions/";
export const GCP_LISTING_ROLES_EMAILS = "/cloud-marketplace/gcp/api/subscriptions/billing-accounts";
export const GCP_ROLES = "/cloud-marketplace/gcp/api/subscriptions/master-roles";
export const GCP_SAVE_EDIT_ROLES = "/cloud-marketplace/gcp/api/subscriptions/save-new-member";
export const GCP_CHECK_CUSTOMER_DOMAIN_AVAILABILITY="/cloud-marketplace/gcp/api/customer/prerequisites/check-domain-availability";

//IBM
export const IBM_SERVICE_MANAGEMENT_ACCOUNT_DETAILS = "/cloud-marketplace/ibm/api/subscriptions/account-details";
export const IBM_RESOURCE_GROUP = "/cloud-marketplace/ibm/api/subscriptions/resource-group";
export const IBM_RESOURCE_LIST = "/cloud-marketplace/ibm/api/subscriptions/resource-list";

//User Management
export const SUB_ACCOUNTS = "/user/api/sub-accounts";
export const UPDATE_SUB_ACCOUNT_STATUS = "/user/api/sub-accounts/change-status";
export const DELETE_SUB_ACCOUNT = "/user/api/sub-accounts/";
export const GET_ROLES = "/user/api/roles";
export const GET_CLOUD_ACCOUNTS = "/user/cloud-accounts";
export const GET_ROLE_MODULES = "/user/api/roles/role-module-features/";
export const GET_ROLES_FILTER = "/user/api/roles";
export const GET_STATUS_FILTER = "/user/api/sub-accounts/status-types";
export const DOWNLOAD_SUBACCOUNTS = "/user/api/sub-accounts/download";
export const GET_EDIT_SUBACCOUNT_DETAILS = "/user/api/sub-accounts/";
export const GET_MODULE_FEATURES = "/user/api/roles/module-features";
export const GET_CLONE_DETAILS = "/user/api/sub-accounts/clone-details/";
export const RESET_PASSWORD_SUBACCOUNT = "/user/azure-ad-b2c/reset-password";
export const RESEND_EMAIL_SUBACCOUNT = "/user/api/sub-accounts/resend-email/";
export const GET_DEPARTMENT_CATEGORIES = "/user/api/sub-accounts/department-categories";
export const SUBMIT_CONTACT_FORM = "/user/api/sub-accounts/save-contact-query";
export const INTEGRATION_CONTACT_FORM = "/integrations/api/integration/create-support-ticket";
export const CONTACT_LIST="/user/api/sub-accounts/contact-list";
export const PUBLIC_CONTACT_FORM = "/user/azure-ad-b2c/save-contact-query";

// User Management Download CSV Files
export const GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV = "/user/cloud-accounts/download-csv-csp";
export const GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV = "/user/cloud-accounts/download-csv-gws";

// Profile Details
export const PROFILE_DETAILS = "/user/api/profile";
export const CHANGE_PASSWORD = '/user/api/profile/change-password';

//Subscriptions
export const SUBSCRIPTIONS = "/user/api/subscriptions";
export const SUBSCRIPTIONS_TYPES = "/user/api/subscriptions/types";

// Management Console
export const MANAGEMENT_CONSOLE_ACCOUNT_TYPES = "/user/cloud-accounts/account-types";
export const MANAGEMENT_CONSOLE_STATUS_TYPES = "/user/cloud-accounts/status-types";
export const RESELLER_API = "/user/api/resellers";

// Product Provisioning
export const PROVISIONING_GET_SERVICES = "/cloud-marketplace-product/api/services";
export const PROVISIONING_GET_PRODUCT = "/cloud-marketplace-product/api/products";
export const PROVISIONING_GET_CURRENT_SUBSCRIPTION = "/cloud-marketplace-product/api/check-existing-customer-subscriptions";
export const PROVISIONING_GET_CUSTOMER_DOMAIN = "/cloud-marketplace-product/api/customer-domain";

export const AZURE_PRODUCT_PLANS = "/cloud-marketplace-product/api/microsoft-azure/product-plans";
export const GCP_PRODUCT_PLANS = "/cloud-marketplace-product/api/gcp/product-plans";
export const IBM_PRODUCT_PLANS = "/cloud-marketplace-product/api/ibm/product-plans";

//Acronis provisioning:
export const ACRONIS_PRODUCT_PLANS = "/cloud-marketplace-product/api/acronis/product-plans";
export const ACRONIS_PLANS_TYPES = "/cloud-marketplace-product/api/acronis/plan-types";
export const ACRONIS_PRICING_PLANS = "/cloud-marketplace-product/api/acronis/pricing-plans";
export const ACRONIS_DOWNLOAD_PRICELIST = "/cloud-marketplace-product/api/acronis/download-pdf";
export const ACRONIS_EXISTING_ACCOUNT_CHECK = "/cloud-marketplace-product/api/acronis/existing-subscription";

export const GET_FLAVOR_CATEGORY = "/cloud-marketplace-product/api/category";
export const PROVISION_DOWNLOAD_CLOUD_PRICING = '/cloud-marketplace-product/api/download-price-list';
export const GET_RESELLER_DATA = "/user/api/profile/reseller-detail";
export const POST_ACRONIS_PROVISIONING = "/cloud-marketplace-product/api/product-provision/acronis";

//AVAST PRODUCT PROVISIONING
export const AVAST_LISTING_TABLE = "/cloud-marketplace-product/api/avast/product-plans";
export const DOWNLOAD_AVAST_LISTING_PRICE = "/cloud-marketplace-product/api/avast/download-pdf";

//Integration
export const INTEGRATION_LISTING = "/integrations/api/integration";
export const AUDIT_REPORTING_LISTING_TABLE = "/integrations/api/audit-report";
export const DOWNLOAD_AUDIT_REPORT = "/integrations/api/audit-report/download-csv";
export const AUDIT_STATUS_TYPES = "/integrations/api/audit-report/status-types";
export const AUDIT_ACTION_TYPES = "/integrations/api/audit-report/action-types";
export const GET_AUDIT_DETAILS = "/integrations/api/audit-report/";
export const DOWNLOAD_CONNECTWISE_USER_GUIDE = "/integrations/api/integration/download-user-guide";
export const AUDIT_REPORT_PLAN_SELECT_LIST = "/integrations/api/agreement-mapping/plans-select-list";

//integration settings
export const INTEGRATION_CONNECTWISE_SETTINGS = "/integrations/api/connectwise-settings";
export const POST_TEST_CREDENTIALS = "/integrations/api/connectwise-settings/test-credential";

//Agreement Mapping
export const GET_AGREEMENT_MAPPING = "/integrations/api/agreement-mapping";
export const DOWNLOAD_AGREEMENT_REPORT = "/integrations/api/agreement-mapping/download-report-csv";
export const UPLOAD_AGREEMENT_MAPPING = "/integrations/api/agreement-mapping/upload-file-csv";
export const DONWLOAD_AGREEMENT_MAPPING_TEMPLATE = "/integrations/api/agreement-mapping/download-sample-template";
export const UNMAP_AGREEMENT_MAPPING = "/integrations/api/agreement-mapping/";
export const GET_CONNECTWISE_COMPANY = "/integrations/api/agreement-mapping/connectwise-companies";
export const GET_CONNECTWISE_AGREEMENT_ADDITION = "/integrations/api/agreement-mapping/";
export const GET_SYNCHRONIZATION_DATA = "/integrations/api/syncronization-settings/";
export const POST_AGREEMENT_MAPPING = "/integrations/api/agreement-mapping";
export const DOWNLOAD_AGREEMENT_MAPPING = "/integrations/api/agreement-mapping/download-report-csv";
export const DOWNLOAD_BULK_MAPPING_MAPPING = "/integrations/api/agreement-mapping/download-bulk-mapping-csv";

// Agreement Mapping-filter
export const AGREEMENT_MAPPING_PLANS = "/integrations/api/agreement-mapping/plans-select-list";
export const AGREEMENT_MAPPING_BILLING_TERM = "/integrations/api/agreement-mapping/billing-period";
export const AGREEMENT_MAPPING_COMPANY = "/integrations/api/agreement-mapping/company-select";
export const AGREEMENT_MAPPING_SERVICES = "/integrations/api/agreement-mapping/services/";
export const AGREEMENT_MAPPING_AGREEMENT = "/integrations/api/agreement-mapping/agreement-select-list";

//Product Provisioning 
export const IBM_PRODUCT_ORDERING = "/cloud-marketplace-product/api/product-provision/ibm-cloud";
export const AZURE_PRODUCT_ORDERING = "/cloud-marketplace-product/api/product-provision/microsoft-azure";
export const AZURE_GET_EMAIL_ID_USERS = "/cloud-marketplace-product/api/product-provision/azure/admin-email/";
export const GCP_EXISTING_SUBSCRIPTIONS_DETAILS = "/cloud-marketplace-product/api/gcp/existing-subscription";
export const GCP_PRODUCT_ORDERING = "/cloud-marketplace-product/api/product-provision/google-cloud";
export const GET_GST_RATES = "/cloud-marketplace-product/api/gst-rates";

// Audit Report Bulk Upload Tables 
export const AUDIT_REPORT_CUSTOMER_BULK_UPLOAD = "/cloud-marketplace-customer/api/audit/customer-bulk-upload";
export const AUDIT_REPORT_CUSTOMER_BULK_UPLOAD_DOWNLOAD = "/cloud-marketplace-customer/api/audit/download-customer-upload-audit";
export const AUDIT_REPORT_MCA_BULK_UPLOAD ="/cloud-marketplace-customer/api/audit/mca-bulk-upload";
export const AUDIT_REPORT_MCA_BULK_UPLOAD_DOWNLOAD ="/cloud-marketplace-customer/api/audit/download-mca-upload-audit";

//dashboard
export const DASHBOARD_CUSTOMERS = "/cloud-marketplace-customer/api/dashboard/customers";
export const GET_DASHBOARD_WIDGETS = "/user/api/dashboard/widgets"
export const SAVE_DASHBOARD_WIDGETS = "/user/api/dashboard/widgets"
export const DASHBOARD_NEW_CUSTOMERS = "/cloud-marketplace-customer/api/dashboard/newcustomers";
export const DASHBOARD_SUBS_RENEW = "/user/api/dashboard/renewal-subscription-widget";
export const DASHBOARD_UNPAID_INVOICES =  "/cloud-marketplace-billing/api/dashboard/invoices/summary";
export const DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT = "/cloud-marketplace-billing/api/dashboard/invoices/download-report";
export const DASHBOARD_SUBS_RENEW_DETAILS ="/user/api/dashboard/renewal-subscription-details";
export const DASHBOARD_SUBS_RENEW_DOWNLOAD = "/user/api/dashboard/download-renewal-subscription-csv";
export const RENEWAL_SETTINGS_SUBS_DOWNLOAD = "/user/api/RenewalSettings/download-renewal-subscription-csv";
export const DASHBOARD_SUBS_RENEW_AUTO_RENEW_TYPES ="/user/api/dashboard/renewal/select-list";
export const DASHBOARD_SUBS_RENEW_PLAN_TYPES = "/user/api/dashboard/plans/select-list/";
export const DASHBOARD_SUBSCRIPTIONS_WIDGET = "/user/api/dashboard/subscription-count";
export const DASHBOARD_SUBSCRIPTIONS_DOWNLOAD_REPORT =  "/user/api/dashboard/download-subscription-details-csv";
export const DASHBOARD_SUBSCRIPTIONS_DETAILS =  "/user/api/dashboard/subscription-details";
export const DASHBOARD_TRENDING_PLANS =  "/user/api/dashboard/trending-plans";
export const DASHBOARD_TRENDING_PLANS_DOWNLOAD_REPORT = "/user/api/dashboard/download-trending-plans";
export const DASHBOARD_SUBSCRIPTIONS_PLAN_LIST =  "/user/api/dashboard/plans/select-list";
export const DASHBOARD_INVOICE_AMOUNT ="/cloud-marketplace-billing/api/dashboard/revenueamount/summary";
export const DASHBOARD_INVOICE_AMOUNT_DOWNLOAD_REPORT = "/cloud-marketplace-billing/api/dashboard/revenueamount/download-report";
export const DASHBOARD_VENDOR_REVENUE = "/cloud-marketplace-billing/api/dashboard/vendorrevenue/summary";
export const DASHBOARD_VENDOR_REVENUE_DOWNLOAD_REPORT = "/cloud-marketplace-billing/api/dashboard/vendorrevenue/download-report";
export const DASHBOARD_SEATS_SUMMARY = "/user/api/dashboard/seats-summary";
export const UNPAID_INVOICE_STATUS ="/cloud-marketplace-billing/api/dashboard/unpaid-invoice-widget/status-types";
export const DASHBOARD_SUBSCRIPTION_OVERVIEW = "/user/api/dashboard/widget/subscription-overview";
export const DASHBOARD_SUBSCRIPTION_OVERVIEW_DETAILS = "/user/api/dashboard/subscription-overview-details";
export const DASHBOARD_SUBSCRIPTION_OVERVIEW_DOWNLOAD = "/user/api/dashboard/download-subscription-overview-csv";
export const SUBSCRIPTION_OVERVIEW_STATUS ="/user/api/dashboard/subscription-overview/status-types";

export const DASHBOARD_RECENT_CUSTOMERS = "/user/api/dashboard/recent-customers";

// Customer Dashboard Widgets
export const CUSTOMER_DASHBOARD_UNPAID_INVOICES =  "/cloud-marketplace-billing/api/dashboard/customer/invoices/summary";
export const CUSTOMER_DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT = "/cloud-marketplace-billing/api/dashboard/customer/invoices/download-report/";
export const MONTHLY_SUMMARY_WIDGET_REVENUE_DASHBOARD = "/user/api/dashboard/revenue-monthly-summary";
export const MONTHLY_SUMMARY_WIDGET_SEATS_DASHBOARD = "/user/api/dashboard/monthly-summary";
export const MONTHLY_SUMMARY_WIDGET_CUSTOMERS_DASHBOARD = "/user/api/dashboard/customer-monthly-summary";

 

//CUSTOMERS SCREEN WIDGETS API
export const CUSTOMERS_SUBSCRIPTION_WIDGET = "/user/api/dashboard/customer/subscription-count/";
export const CUSTOMERS_SUBSCRIPTION_WIDGET_DETAILS = "/user/api/dashboard/customer/subscription-details";
export const CUSTOMERS_SUBSCRIPTION_DOWNLOAD_WIDGET_REPORT = "/user/api/dashboard/customer/download-subscription-details-csv/";
export const CUSTOMERS_SUBSCRIPTION_RENEWAL_DOWNLOAD_WIDGET_REPORT = "/user/api/dashboard/customer/download-renewal-subscription-csv";
export const CUSTOMERS_SUBSCRIPTION_RENEWAL_WIDGET = "/user/api/dashboard/customer/renewal-subscription-widget";
export const CUSTOMERS_SUBSCRIPTION_RENEWAL_WIDGET_DETAILS = "/user/api/dashboard/customer/renewal-subscription-details";
export const STATUS_TYPE_SUBSCRIPTIONS ="/user/api/dashboard/subscription/status-types";
export const CUSTOMERS_BUSINESS_CONTACT_DETAILS = "/user/api/dashboard/customer/business-contact";
export const CUSTOMERS_MONTHLY_SUMMARY_WIDGET = "/user/api/dashboard/customer/monthly-summary";
export const CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET = "/user/api/dashboard/customer/widget/subscription-overview";
export const CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_DOWNLOAD_WIDGET_REPORT = "/user/api/dashboard/customer/download-subscription-overview-csv";
export const CUSTOMERS_SUBSCRIPTIONS_OVERVIEW_WIDGET_DETAILS ="/user/api/dashboard/customer/subscription-overview-details";
export const MANAGEMENT_CONSOLE_WIDGET = "/user/cloud-accounts/subscriptions-summary";
export const DASHBOARD_VIEWS_WIDGET = "/user/api/dashboard/views";

//GDAP 
export const GDAP_GET_TABLE =  "/cloud-marketplace/microsoft-csp/api/subscriptions/gdap-admin-relationships";
export const GDAP_CREATE_GET = "/cloud-marketplace/microsoft-csp/api/subscriptions/prefetch-gdap-data";
export const GDAP_CREATE_POST = "/cloud-marketplace/microsoft-csp/api/subscriptions/create-gdap-admin-relationships";
export const GDAP_STATUS_TYPES = "/cloud-marketplace/microsoft-csp/api/subscriptions/gdap/status-types";
//Billing Get Widgets
export const GET_SUMMARY_WIDGETS = "/cloud-marketplace-billing/api/summaries/widgets";
export const GET_CUSTOMERS_WIDGETS = "/cloud-marketplace-customer/api/customers/widgets";

//Feedback api's
export const GET_FEEDBACK_QUESTIONS =  "/user/api/sub-accounts/feedback-questions";
export const POST_FEEDBACK_REVIEWS =  "/user/api/sub-accounts/save-user-feedback";

//Payment Gateway
export const GET_PAYMENT_GATEWAY_BILLING_ADDRESS =  "/user/api/billing/address-details";
export const GET_CREDIT_CARD_DETAILS =  "/user/api/billing/credit-cards";
export const GET_DIRECT_DEBITS =  "/user/api/direct-debit";
export const DOWNLOAD_DIRECT_DEBIT_DOCUMENTS = "/user/api/direct-debit/download-document";
export const DOWNLOAD_DIRECT_DEBIT_DDR_FORM = "/user/api/direct-debit/download-dd-form";
export const DIRECT_DEBIT_POST = "/user/api/direct-debit/submit-dd-application";
export const GET_OTHERS_DETAILS = "/user/api/billing/others/info";
export const SAVE_CREDIT_CARD_DETAILS = "/user/api/billing/save-card";
export const UPDATE_PRIMARY_CREDIT_CARD = "/user/api/billing/mark-card-as-primary";
export const DELETE_CREDIT_CARD = "/user/api/billing/remove-card/";
export const GET_CARD_DETAILS = "/user/api/billing/card-detail/";
export const UPDATE_CARD_DETAILS = "/user/api/billing/update-card";
export const UPDATE_PAYMENT_METHOD = "/user/api/billing/update-payment-method"

//Notifications
export const GET_RECENT_NOTIFICATIONS = "/user/api/notifications/recent-notifications";
export const GET_ALL_NOTIFICATIONS = "/user/api/notifications";
export const DELETE_NOTIFICATION = "/user/api/notifications/remove/";
export const DELETE_ALL_NOTIFICATIONS = "/user/api/notifications/remove-all";
export const READ_NOTIFICATION = "/user/api/notifications/read/";
export const DELETE_NOTIFICATIONS_DATE = "/user/api/notifications/remove-all/date";

//Announcements
export const GET_ANNOUNCEMENTS = "/user/api/notifications/announcements";

//Terms and Conditions
export const GET_LOGIN_TERMS_AND_CONDITIONS = "/user/api/terms-and-conditions";
export const ACCEPT_LOGIN_TERMS_AND_CONDITIONS = "/user/api/terms-and-conditions/accepted";

//Cookie Policy
export const COOKIE_POLICY_UNAUTHENTICATED = "user/azure-ad-b2c/save-cookies"
export const COOKIE_POLICY_AUTHENTICATED = "user/api/sub-accounts/save-cookies"

// Training Widget
export const GET_TRAINING_WIDGET_DATA = "/user/api/training"
export const GET_TRAINING_WIDGET_DATA_DOWNLOAD = "/user/api/training/download/"

// Auditing
export const FRONTEND_LOGS = "/cloud-marketplace/microsoft-csp/api/customer/prerequisites/audit-log"

//Subscription Renewal api's
export const RENEWALSETTINGS_DURATION =  "/user/api/RenewalSettings/Duration";
export const RENEWALSETTINGS_TABLE =  "/user/api/RenewalSettings/renewal-settings-details";
export const RENEWAL_SETTINGS_CONFIG_DOWNLOAD = "/user/api/RenewalSettings/download-renewal-configuration-csv";
export const CONFIGURE_EMAIL_NOTIFICATION = '/user/api/RenewalSettings/configure-email-notification';
export const CANCEL_RENEWAL_NOTIFICATION = '/user/api/RenewalSettings/cancelrenewalnotification';
export const RENEWAL_CONFIGURATIONS = '/user/api/RenewalSettings/renewalconfigurations';

//Login Alerts
export const LOGIN_ALERTS =  "/user/api/alerts"