import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../../../Utils/API';
import { GET_CLONE_DETAILS, GET_ROLES, GET_ROLE_MODULES, SUB_ACCOUNTS } from '../../../../Utils/GlobalConstants';
import { Button, Col, Form, Row, NavDropdown } from 'react-bootstrap';
import Toast from '../../../../GlobalComponents/Toast';
import styles from './cloneUser.module.scss';
import loadingGif from "../../../../Assets/Images/Animations/loading-management-console.gif"
import { emailRegEx, phoneOnChangeRegex } from '../../../../GlobalComponents/RegExPatterns';
import { TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../Utils/ColorsConfiguration';
import { UserCloneSuccessToast } from '../../../../Utils/StaticMessages/ToastMessages';

const CloneUser = (props) => {
    const location = useLocation();                                                 // get Location
    const navigate = useNavigate();                                                 // to use navigate function from react router dom    
    const [subaccountDetails, setSubaccountDetails] = useState(null);               // store data from get api 
    const [changedSelectedData, setChangedSelectedData] = useState([]);             // array used for manipulating value and used for put api for updation
    const [isDisabledForAllModule, setIsDisabledForAllModule] = useState(false);    // to check whether all modules are disabled or not so that on disable state we will show error
    const [firstName, setFirstName] = useState("");                                 // storing first name from get api
    const [lastName, setLastName] = useState("");                                   // storing last name from get api
    const [email, setEmail] = useState("");                                         // storing email id from get api
    const [phoneNumber, setPhoneNumber] = useState(null);                           // storing phone number from get api
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);            // loading state for processing button
    const [emailErrorFe, setEmailErrorFe] = useState("");                           // email id error 
    const [roleData, setRoleData] = useState(null);                                 // data in Role dropdown
    const [roleType, setRoleType] = useState(null);                                 // role selected from dropdown of role
    const [roleModuleFeatures, setRoleModuleFeatures] = useState(null);             // Role Module Data on Get Request
    const [id, setid] = useState(location.state !== null ? location.state.id : ""); // data in get profile api   


    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call account details (subaccount details)
    async function GetSubaccountDetails() {
        await api.get(`${GET_CLONE_DETAILS}${id}`, config)
            .then(resp => {
                setSubaccountDetails(resp.data);
                setRoleType(resp.data.currentRole);
                setRoleModuleFeatures(resp.data.userModuleFeatures);
                setChangedSelectedData(resp.data.userModuleFeatures);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Role 
    async function GetAllRoles() {
        await api.get(GET_ROLES, config)
            .then(resp => {
                setRoleData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all module default values of particular roles
    async function GetRoleModuleFeatures(role) {
        await api.get(GET_ROLE_MODULES + role, config)
            .then(resp => {
                setRoleModuleFeatures(resp.data);
                setChangedSelectedData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    //function called when there is any change in radio buttons for selection of options such as Readonly, read and write and disabled 
    function RoleSelectionCheck(moduleFeatureId, moduleName, isReadAccess, isReadWriteAccess, isDisabled) {
        let intermediateArray = [];
        changedSelectedData.map((selectedData) => {
            if (selectedData.moduleFeatureId === moduleFeatureId) {
                intermediateArray.push({
                    moduleFeatureId: moduleFeatureId,
                    moduleName: moduleName,
                    isReadAccess: isReadAccess !== undefined ? isReadAccess : selectedData.isReadAccess,
                    isReadWriteAccess: isReadWriteAccess !== undefined ? isReadWriteAccess : selectedData.isReadWriteAccess,
                    isDisabled: isDisabled !== undefined ? isDisabled : selectedData.isDisabled
                });
            } else {
                intermediateArray.push(selectedData);
            }
        });
        setChangedSelectedData(intermediateArray);

        // to check whether all modules are disabled or not
        let disabledConditionArray = [];
        changedSelectedData.map((selectedDataDisabledState) => {
            // excluding profile module here as it is always read and write
            if (selectedDataDisabledState.moduleFeatureId != 9) {
                if (selectedDataDisabledState.moduleFeatureId === moduleFeatureId) {
                    disabledConditionArray.push(isDisabled)
                }
                else
                    disabledConditionArray.push(selectedDataDisabledState.isDisabled)
            }
        })

        if (disabledConditionArray.includes(false)) {
            setIsDisabledForAllModule(false)
        } else {
            setIsDisabledForAllModule(true);
        }
    }

    //post api for creating clone user
    async function AddUserDetails() {
        setConfirmLoadingFlag(true);
        const requestBody = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phoneNumber": phoneNumber,
            "currentRole": roleType,
            "userModuleFeatures": changedSelectedData
        };
        await api.post(SUB_ACCOUNTS, requestBody, config)
            .then(resp => {
                if (resp.status == 200) {
                    setToastMessage(UserCloneSuccessToast)
                    setToastSuccess(true);
                    setTimeout(() => {
                        setConfirmLoadingFlag(false);
                        setToastSuccess(false);
                        navigate("/user-management");
                    }, 5000);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage(error.response.data.errors);
                    setToastError(true);
                    setTimeout(() => {
                        setConfirmLoadingFlag(false);
                        setToastError(false);
                    }, 5000);
                }
            });
    }

    // function for form validation of email
    const validateForm = () => {
        let valid = true;

        // Validate email Address
        if (!emailRegEx.test(email)) {
            setEmailErrorFe("Please enter a valid email");
            window.scrollTo(0, 0);
            valid = false;
        }
        if (valid) {
            // Proceed with form submission
            AddUserDetails();
        }
    };

    // Use Effect to get the account details (subaccount details) and dropdown roles pre-filled in the form.
    useEffect(() => {
        GetAllRoles();
        GetSubaccountDetails();
        // Scroll to Top on Initial loading
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

            <div className='main-content'>
                <div className="customer-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`py-0 ${styles['profile-table']}`}>
                                <Row className="align-items-center">
                                    <label className="cpointer" onClick={() => navigate('/user-management')}>
                                        <span className="back">&nbsp;</span>&nbsp;<strong>Clone User</strong>
                                    </label>
                                </Row>
                                <div className='cloud-invoice-table-inner table-details details-box'>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className='pl-1'>
                                            <Row className='px-3 py-2'>
                                                <h2 className='pb-2'>User Details</h2>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="firstName">
                                                        <Form.Label >First Name <span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={firstName}
                                                            maxlength="50"
                                                            name="firstName"
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                                                    setFirstName(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="lastName">
                                                        <Form.Label >Last Name <span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="lastName"
                                                            value={lastName}
                                                            maxlength="50"
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                                                    setLastName(e.target.value);
                                                                }
                                                            }} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}></Col>

                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="Email">
                                                        <Form.Label >Email ID <span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="Email"
                                                            value={email}
                                                            maxlength="64"
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                                setEmailErrorFe("");
                                                            }}
                                                        />
                                                        <span className="red text-small">{emailErrorFe}</span>

                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="phoneNumber">
                                                        <Form.Label >
                                                            Phone Number <span className="red">*</span>
                                                        </Form.Label>
                                                        <Row>
                                                            <Col>
                                                                <div className="phone-number-wrapper">
                                                                    <label>+61</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="phone-number form-control col"
                                                                        placeholder="xxxxxxxxxx"
                                                                        name="phoneNumber"
                                                                        value={phoneNumber}
                                                                        maxLength="15"
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            if (inputValue === '' || phoneOnChangeRegex.test(inputValue)) {
                                                                                setPhoneNumber(inputValue);
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}></Col>
                                            </Row>
                                        </Col>
                                        <Row className='px-3 mb-4'>
                                            {roleType && <Col lg={4} md={6}>
                                                <h2 className='pb-2'>Role Settings</h2>
                                                <Form.Group
                                                    className="form-group col-md-5 col-lg-12 mb-0"
                                                    controlId="roleTypeId"
                                                >
                                                    <NavDropdown
                                                        title={roleType}
                                                        id="select-role-dropdown"
                                                        className={`servcies-dropdown ${styles['role-select-dropdown']}`}
                                                    >
                                                        {roleData &&
                                                            roleData.map((dropdownItem) => {
                                                                return (
                                                                    <NavDropdown.Item
                                                                        key={dropdownItem.roleId}
                                                                        id={"roletype_" + dropdownItem.roleId}
                                                                        value={dropdownItem.roleName}
                                                                        defaultValue={"Procurement User"}
                                                                        className=""
                                                                        onClick={() => {
                                                                            setRoleType(dropdownItem.roleName);
                                                                            setRoleModuleFeatures(null);
                                                                            setIsDisabledForAllModule(false);
                                                                            GetRoleModuleFeatures(dropdownItem.roleName);
                                                                        }}>
                                                                        {dropdownItem.roleName}
                                                                    </NavDropdown.Item>
                                                                )
                                                            })}
                                                    </NavDropdown>
                                                    {isDisabledForAllModule && <span className="red text-small">Select either "Read only" or "Read &amp; Write" access for at least one module.</span>}

                                                </Form.Group>
                                            </Col>}
                                        </Row>
                                        {
                                            subaccountDetails === null &&
                                            <div className={styles["no-data-cloud-invoice"]}>
                                                <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                            </div>
                                        }
                                        {roleModuleFeatures && roleModuleFeatures.map((role) => {
                                            return (
                                                <Row className="my-2 pl-4">
                                                    <Col md={2}>
                                                        <span className='text-strong'>{role.moduleName} <span className="red">*</span> :</span>
                                                    </Col>
                                                    {role.moduleName != "Profile" && <>
                                                        <Col md={2}>
                                                            <Form.Check
                                                                type="radio"
                                                                id="ReadOnly"
                                                                label={`Read only`}
                                                                name={role.moduleName}
                                                                defaultChecked={role.isReadAccess}
                                                                onClick={() => {
                                                                    RoleSelectionCheck(role.moduleFeatureId, role.moduleName, true, false, false);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Check
                                                                type="radio"
                                                                id="ReadAndWrite"
                                                                label={`Read & write`}
                                                                name={role.moduleName}
                                                                defaultChecked={role.isReadWriteAccess}
                                                                onClick={() => {
                                                                    RoleSelectionCheck(role.moduleFeatureId, role.moduleName, false, true, false);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Check
                                                                type="radio"
                                                                id="Disable"
                                                                label={`Disable`}
                                                                name={role.moduleName}
                                                                defaultChecked={role.isDisabled}
                                                                onClick={() => {
                                                                    RoleSelectionCheck(role.moduleFeatureId, role.moduleName, false, false, true);
                                                                }}
                                                            />
                                                        </Col>
                                                    </>}
                                                    {role.moduleName === "Profile" &&
                                                        <Col md={2}>
                                                            <span>Read & write</span>
                                                        </Col>
                                                    }
                                                </Row>
                                            )
                                        })}
                                        {
                                            roleModuleFeatures === null && subaccountDetails &&
                                            <div className={styles["no-data-cloud-invoice"]}>
                                                <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                            </div>
                                        }
                                        {roleModuleFeatures && <Row>
                                            <Col className="my-3 text-right btn-wrapper-outer">
                                                {!confirmLoadingFlag && <Button
                                                    className="ml-3 btn-border-light w-md-auto col-xs-12 mb-3"
                                                    variant="light"
                                                    onClick={() => {
                                                        navigate('/user-management')
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                }
                                                {
                                                    isDisabledForAllModule && !confirmLoadingFlag &&
                                                    <Button
                                                        className="ml-3 w-md-auto btn-light col-xs-12 mb-3"
                                                        variant='light'
                                                        disabled
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                                {
                                                    (firstName === "" || lastName === "" || phoneNumber === null || phoneNumber === "" || (phoneNumber?.length < 8 || phoneNumber?.length > 15) || email === "") && !isDisabledForAllModule && !confirmLoadingFlag &&
                                                    <Button
                                                        className="ml-3 w-md-auto btn-light col-xs-12 mb-3"
                                                        variant='light'
                                                        disabled
                                                    >
                                                        Save
                                                    </Button>
                                                }

                                                {(firstName != "" && lastName != "" && (phoneNumber?.length >=8 && phoneNumber?.length <=15) && email != "") && !isDisabledForAllModule && !confirmLoadingFlag &&
                                                    <Button
                                                        className="ml-3 w-md-auto col-xs-12 mb-3"
                                                        onClick={() => {
                                                            validateForm();
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                                {
                                                    confirmLoadingFlag &&
                                                    <>
                                                        <Button
                                                            variant="light"
                                                            disabled
                                                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        >Cancel
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>
                                                    </>
                                                }
                                            </Col>
                                        </Row>}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CloneUser