import { Col, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './payoutstandinginvoices.module.scss'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { api } from "../../../../../../../Utils/API";
import { CUSTOMER_DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT, CUSTOMER_DASHBOARD_UNPAID_INVOICES } from "../../../../../../../Utils/GlobalConstants";
import Toast from "../../../../../../../GlobalComponents/Toast"
import Summary from "./LocalComponents/Summary";
import { useLocation } from "react-router-dom";
import { TOAST_ERROR_ICON_COLOR, WIDGETS_LABEL_FILL_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR } from "../../../../../../../Utils/ColorsConfiguration";
import { GenericDownloadEmptyToast } from "../../../../../../../Utils/StaticMessages/ToastMessages";

const PayOutstandingInvoice = (props) => {
  const date = new Date();                                                   // for js date time function
  const location = useLocation();

  const [pieChartData, setPieChartData] = useState([null])                   // for storing piechart data from api
  const [summaryPopup, setSummaryPopup] = useState(false);                   // for opening summary popup
  const [tabName, setTabName] = useState("Summary");                         // to store tab name
  const [total, setTotal] = useState(null);                                  // total custumers to be displayed in center of piechart label
  const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);              // getTableData() invoice from date
  const [invoiceDateTo, setInvoiceDateTo] = useState(null);                  // getTableData() invoice to date
  const [filterType, setFilterType] = useState('Last Month');                // dropdown filter type
  const [customerName, setCustomerName] = useState(location.state?.customerName ? location.state?.customerName : "");

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 14,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();

    // Convert children to string
    const textContent = children.toString();
    const [labelWithCommas, numberWithCommas] = textContent.split(' ');

    // Remove commas from label
    const label = labelWithCommas.replace(/,/g, '');

    // Format number with commas
    const number = isNaN(parseInt(numberWithCommas.replace(/,/g, ''), 10)) ? '' : parseInt(numberWithCommas.replace(/,/g, ''), 10).toLocaleString();

    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        <tspan fill="grey" dy="-1.2em">{label}</tspan>
        <tspan className="text-strong text-large" fill={WIDGETS_LABEL_FILL_COLOR} x={left + width / 2} dy="1.2em">{number}</tspan>
      </StyledText>
    );
  }

  // Header Authorization for Download CSV API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download CSV File 
  async function DownloadAction(url, name, fileType) {
    let configuration;
    if (fileType === "csv") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}?duration=${filterType}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setToastMessage(GenericDownloadEmptyToast);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Function to call all unpaid invoices widget data
  async function GetOutstandingPayInvoices() {
    await api.get(`${CUSTOMER_DASHBOARD_UNPAID_INVOICES}/${props.customerCode}?duration=${filterType}`, config)
      .then(resp => {
        setPieChartData(resp.data);
        // Calculate the total sum
        let initialTotal = 0;
        resp.data.map((item) => {
          initialTotal += item.value;
        });
        setTotal(initialTotal);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // useEffect for calling get api on filter change
  useEffect(() => {
    if (filterType === "This Month") {
      var endDateMonth = new Date(2008, (date.getMonth() + 1), 0);
      let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else if (filterType === "Last Month") {
      var today = new Date();
      var yesterday = new Date(today.setMonth(today.getMonth() - 1));
      var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
      let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
      let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else {
      let lastQuarterEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
      let lastQuarterStartDate = new Date(date);
      lastQuarterStartDate.setMonth(date.getMonth() - 3);
      lastQuarterStartDate.setDate(1);

      let fromDate = `${String(lastQuarterStartDate.getDate()).padStart(2, '0')}/${String(lastQuarterStartDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterStartDate.getFullYear()}`;
      let toDate = `${String(lastQuarterEndDate.getDate()).padStart(2, '0')}/${String(lastQuarterEndDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterEndDate.getFullYear()}`;
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);
    }
    GetOutstandingPayInvoices();
  }, [filterType])

  return (
    <>
      <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

        <Col md={6}>
          <h3 className="mb-0">Unpaid Invoices Summary</h3>
        </Col>
        <Col md={6} className="text-right">
          <span className="dashboard-arrow mx-1 cpointer" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
          <span className="dashboard-circle-dot mx-1 cpointer" onClick={() => { setSummaryPopup(true); setTabName("Details") }}></span>
          <span className="dashboard-download mx-1 cpointer" onClick={() => { DownloadAction(CUSTOMER_DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT + (props.customerCode), `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerName}_unpaid-invoices-summary.csv`, "csv") }}
          ></span>
        </Col>
        {
          summaryPopup &&
          <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} filterType={filterType} setFilterType={setFilterType} customerCode={props.customerCode} invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo} Logout={props.Logout}
            serviceData={props.serviceData} />
        }
      </Row>
      <Row className={`pt-2 ${styles["widget-header"]} `}>
        <Col lg={8} md={9}></Col>
        <Col lg={4} md={3}>
          <Form.Group >
            <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={(eventKey) => { setFilterType(eventKey); }}>
              <NavDropdown.Item eventKey="This Month"
                className={filterType === "This Month" ? "selected" : ""}
              >This Month</NavDropdown.Item>
              <NavDropdown.Item eventKey="Last Month"
                className={filterType === "Last Month" ? "selected" : ""}
              >Last Month</NavDropdown.Item>
              <NavDropdown.Item eventKey="Last Quarter"
                className={filterType === "Last Quarter" ? "selected" : ""}
              >Last Quarter</NavDropdown.Item>
            </NavDropdown>
          </Form.Group>
        </Col>
      </Row>
      <PieChart
        series={[{ data: pieChartData, innerRadius: 70, outerRadius: 84, }]}
        colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR]}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
            labelStyle: {
              fill: 'grey',
            },
          },
        }}
        height={210}
        width={400}
        margin={{ top: 0, bottom: 40, left: 80 }}
      >
        {pieChartData.length > 0 && <PieCenterLabel>Unpaid&nbsp;Invoices {pieChartData && total}</PieCenterLabel>}
      </PieChart>
    </>
  )
}

export default PayOutstandingInvoice