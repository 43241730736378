import React, { useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { XLg } from 'react-bootstrap-icons'
import Popup from 'reactjs-popup'
import styles from './discountedPlanPopup.module.scss'
import { TEXT_SECONDARY } from '../../../../../../../Utils/ColorsConfiguration'
import { DiscountedPlanNote } from '../../../../../../../Utils/StaticMessages/NotesDisclaimers'

function DiscountedPlanPopup({
    isSubscriptionDetailsPopupOpen,
    setIsSubscriptionDetailsPopupOpen,
    subscriptionType,
    discountedPlanDetails,
    subscriptionDetails,
    subscriptionDetailIsLoadingFlag,
    subscriptionDetailsIsTrial,
    loadingGif,
}) {

    const [tabName, setTabName] = useState("Subscription Details");
    return (
        <div>
            <Popup
                className={`custom-modal custom-modal-xl ${styles['popup-container']}`}
                open={isSubscriptionDetailsPopupOpen}
                onClose={() => {setIsSubscriptionDetailsPopupOpen(false);
                    setTabName("Subscription Details");
                }}
                modal
                nested
            >
                {(close) => (
                    <div className={`modal `}>

                        <Tab.Container defaultActiveKey="Subscription Details" activeKey={tabName}>
                            <div className={`header ${styles['mobile-heading']}`}>

                                <Nav className={`${styles['nav-container']}`}>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Subscription Details"
                                            className={`${styles['nav-headings']} ${tabName === "Subscription Details" && subscriptionType === "Ezdeal" ? `${styles['border-bottom-color']} text-strong`: ''} ${tabName === "Subscription Details" ? `text-strong`: ''}  `}
                                            onClick={() => setTabName("Subscription Details")}
                                        >
                                            Subscription Details
                                        </Nav.Link>
                                    </Nav.Item>
                                    {subscriptionType === "Ezdeal" && (
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="Discounted Plan Details"
                                            className={`${styles['nav-headings']} ${tabName === "Discounted Plan Details" ? `${styles['border-bottom-color']} text-strong`: ''} `}
                                            onClick={() => setTabName("Discounted Plan Details")}
                                        >
                                            Discounted Plans Details
                                        </Nav.Link>
                                    </Nav.Item>)}
                                </Nav>

                                <XLg
                                    size={18}
                                    className="cpointer text-strong"
                                    onClick={close}
                                    color={TEXT_SECONDARY}
                                />

                            </div>
                            <Tab.Content>
                                      {/* Subscription Plan  */}
                                <Tab.Pane eventKey="Subscription Details">
                                    <Container fluid>
                                        {!subscriptionDetailIsLoadingFlag && subscriptionDetails &&

                                            <div className={`pt-5 pb-5 pl-2 ${styles['mobile-padding']}`} >
                                                <Row>
                                                    <Col className="mb-3">
                                                        <label className="text-medium">
                                                            Plan
                                                        </label>
                                                        <p className='pt-2'>
                                                            <strong>
                                                                {subscriptionDetails.planName}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-3">
                                                        <label className="text-medium">
                                                            Vendor Subscription ID
                                                        </label>
                                                        <p className="mb-0 text-medium pt-2">
                                                            <strong>
                                                                {subscriptionDetails.vendorSubscriptionId.toUpperCase()}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col className="mb-3" md={4}>
                                                        <label className="text-medium">
                                                            Licence Auto Renewal
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                {subscriptionDetails.autoRenew? "Yes" : "No" }
                                                            </strong>
                                                        </p>
                                                    </Col>

                                                    <Col md={4}>
                                                        <label className="text-medium">
                                                            Subscription Start Date
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                {subscriptionDetails.startDate}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <label className="text-medium">
                                                            Subscription End Date
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                {subscriptionDetails.subscriptionEndDate}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {subscriptionDetailsIsTrial && (
                                                        <Col lg={4} md={4} className="mb-3">
                                                            <label className="text-medium">
                                                                Is Trial
                                                            </label>
                                                            <p className="text-medium mb-0 pt-2">
                                                                <strong>
                                                                    {subscriptionDetails.isTrial? "Yes" : "No"}
                                                                </strong>
                                                            </p>
                                                        </Col>
                                                    )}
                                                    {subscriptionDetailsIsTrial && (
                                                        <Col lg={8} md={8} className="mb-3">
                                                            <label className="text-medium mb-0">
                                                                Trial End Date
                                                            </label>
                                                            <p className="text-medium pt-2">
                                                                <strong>
                                                                    {subscriptionDetails.trailEndDate}
                                                                </strong>
                                                            </p>
                                                        </Col>
                                                    )}

                                                </Row>
                                            </div>

                                        }
                                        {
                                            subscriptionDetailIsLoadingFlag &&
                                            <>
                                                <Container>
                                                    <div className="empty-subscription-detail">
                                                        <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details...</p>
                                                    </div>
                                                </Container>
                                            </>
                                        }
                                        </Container>
                                </Tab.Pane>

                                {/* Discounted Plan Details */}
                                <Tab.Pane eventKey="Discounted Plan Details" >
                                    <Container fluid>
                                        {!subscriptionDetailIsLoadingFlag && discountedPlanDetails &&

                                            <div className={`pt-5 pb-5 pl-2 ${styles['mobile-padding']}`}>
                                                <Row className='pb-3 pt-3'>
                                                    <Col className="mb-3" md={4}>
                                                        <label className="text-medium">
                                                            Resellers Unit Cost (license per month)
                                                        </label>
                                                        <p className='pt-2'>
                                                            <strong>
                                                                ${discountedPlanDetails.beforeResellerUnitCost}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col className="mb-3" md={3}>
                                                        <label className="text-medium">
                                                            Contract Length
                                                        </label>
                                                        <p className='pt-2'>
                                                            <strong>
                                                                {discountedPlanDetails.contractLength}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col className="mb-3" md={3}>
                                                        <label className="text-medium">
                                                            Contract Period
                                                        </label>
                                                        <p className='pt-2'> 
                                                            <strong>
                                                                {discountedPlanDetails.contractPeriod}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-3" md={4}>
                                                        <label className="text-medium">
                                                            Seats
                                                        </label>
                                                        <p className="mb-0 text-medium pt-2">
                                                            <strong>
                                                                {discountedPlanDetails.quantity}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col className="mb-3" md={3}>
                                                        <label className="text-medium">
                                                            Total Monthly Cost
                                                        </label>
                                                        <p className="mb-0 text-medium pt-2">
                                                            <strong>
                                                                ${discountedPlanDetails.totalMonthlyCost}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col className="mb-3" md={3}>
                                                        <label className="text-medium">
                                                            Total Contract Cost
                                                        </label>
                                                        <p className="mb-0 text-medium pt-2">
                                                            <strong>
                                                                ${discountedPlanDetails.totalContractCost}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <p className='pt-5 pb-2'><strong className="fw-bold">Note :</strong> {DiscountedPlanNote}</p>
                                                <hr />
                                                <Row>
                                                    <Col className="mb-3" md={4}>
                                                        <label className="text-medium">
                                                            After Contract Date
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                {discountedPlanDetails.afterContractDate}
                                                            </strong>
                                                        </p>
                                                    </Col>

                                                    <Col md={3}>
                                                        <label className="text-medium">
                                                            RRP
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                ${discountedPlanDetails.rrp}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <label className="text-medium">
                                                            Resellers Unit Cost (license per month)
                                                        </label>
                                                        <p className="text-medium pt-2">
                                                            <strong>
                                                                ${discountedPlanDetails.afterResellerUnitCost}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </div>

                                        }
                                        {
                                            subscriptionDetailIsLoadingFlag &&
                                            <>
                                                <Container>
                                                    <div className="empty-subscription-detail">
                                                        <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Discounted Plan  Details...</p>
                                                    </div>
                                                </Container>
                                            </>
                                        }
                                        </Container>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>
                )}
            </Popup>


        </div>
    )
}

export default DiscountedPlanPopup