import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import './styles.scss';
import DataTable from "react-data-table-component";
import { BACKGROUND_COLOR_SECONDARY, TEXT_SECONDARY } from "../../Utils/ColorsConfiguration";


const TableBlock = (props) => {
    const [loading, setLoading] = useState(false);
    const [columnNames, setColumnNames] = useState([]);

    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'left',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    return (
        <>
            <Row className="details-box">
                {(props.tableName || props.domainName || props.programName) && <div className="table-heading">
                    {props.programName && <h2 >Program: <span className="text-normal subscription-heading-table-header">{props.programName}</span></h2>}
                    {props.tableName && <h2>{props.tableName}</h2>}
                    {props.domainName && <h3>Domain Name: <span>{props.domainName}</span></h3>}
                    {props.subAccountName && <h3>Subaccount Name: <span>{props.subAccountName}</span></h3>}
                </div>}
                <div className="table-content">
                    <DataTable
                        columns={props.columnNames}
                        data={props.columnData}
                        theme="solarized"
                        customStyles={customStyles}
                        // striped={true}
                        persistTableHead={false}
                        noDataComponent={<div className='loading-support-tickets'><p><b>Loading {props.loadingMessage}</b></p></div>}
                        width="100%"
                        // progressPending={loading}
                        fixedHeader
                    />
                </div>
            </Row>
        </>
    );
}

export default TableBlock;