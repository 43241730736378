import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import styles from './unbilledUsageReport.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../../Utils/API";
import 'reactjs-popup/dist/index.css';
import { RESELLER_UNBILLED_USAGE_REPORT, UPDATE_RESELLER_UNBILLED_USAGE_REPORT } from '../../../../Utils/GlobalConstants';
import 'reactjs-popup/dist/index.css';
import ExpandableTable from './LocalComponents/ExpandableTable';
import Popup from 'reactjs-popup';
import { XLg, Check2 } from 'react-bootstrap-icons';
import Toast from "../../../../GlobalComponents/Toast";
import loadingGif from "../../../../Assets/Images/Animations/loading-management-console.gif"
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR } from '../../../../Utils/ColorsConfiguration';
import { UnbilledIaasErrorToast, UnbilledIaasSuccessToast } from '../../../../Utils/StaticMessages/ToastMessages';


const UnbilledUsageReport = (props) => {                                                                  // to use navigate function from react router dom    
    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                                               // table active page number
    const [pageSize, setPageSize] = useState(5);                                                           // table active page size
    const [totalRows, setTotalRows] = useState(0);                                                         // table total count of data from api 
    const [table, setTable] = useState([]);                                                                // data state used to display data in react data table component
    let ColumnName = ["S.No", "Cloud Service",                                                             // table headers used to make object of react data table component headers
        "Next Billing Date", "Active Billing Cycle", "Estimated Unbilled Amount", "Last Calculated On",
        "Alert Threshold", "Actions"];
    const [editingRowId, setEditingRowId] = useState(0);                                                   //to check particular row for updatIng alert threshold value
    const [editedValue, setEditedValue] = useState();                                                      //Update alert threshold new value

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [unbilledUsageDataId, setUnbilledUsageDataId] = useState();                                      //Value of unbilled usage data id
    const [updateAlertThresholdModalFlag, setUpdateAlertThresholdModalFlag] = useState(false);             //Updating Alert Threshold modal
    const [showTableLoading, setShowTableLoading] = useState("Loading your Unbilled IAAS Usage Report...");

    const [isEditing, setIsEditing] = useState(false);                                                      // for check button

    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        localStorage.clear();
        instance.logoutRedirect();
    }

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    // useEffect(() => {
    //     if (props.customerIsAuthenticated === false) {
    //         navigate("/");
    //     }
    // }, [])// for js date time function

    let d = [];
    ColumnName.map((td) => {
        if (td === "Alert Threshold") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                // selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                right: true,
                cell: (row) => {
                    return editingRowId == row.ServiceId ? (
                        <div className='d-flex justify-content-between'>
                            <div>
                                <input
                                    style={{
                                        maxWidth: '50px'
                                    }}
                                    type="number"
                                    value={editedValue}
                                    min="0"
                                    onChange={(e) => {
                                        setEditedValue(e.target.value);
                                        if ((e.target.value) == (row["Alert Threshold"].slice(1, row["Alert Threshold"].length - 3))) {
                                            setIsEditing(false);
                                            // console.log("hekllo grey")
                                        }
                                        else {
                                            setIsEditing(true);
                                            // console.log("hekllo green")
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Minus') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            {isEditing ? (
                                <div className='mx-1' onClick={() => {
                                    // console.log("td for green button", row["Unbilled UsageData Id"])
                                    setUnbilledUsageDataId(row["Unbilled UsageData Id"]);
                                    // if(editedValue ===  td.slice(1)){
                                    //     setIsEditing(false);
                                    //     }
                                    handleEditSave(row);
                                    setUpdateAlertThresholdModalFlag(true);
                                    // console.log(row["Unbilled UsageData Id"], "Unbilled UsageData Id");
                                    // console.log(editedValue);
                                }}>
                                    <Check2 size={24} color="green" />
                                </div>) :
                                <div onClick={() => {
                                    // console.log("td for grey button", row["Unbilled UsageData Id"])
                                }}>
                                    <Check2 size={24} color="grey" />

                                </div>
                            }
                            <div className='mx-1' onClick={() => handleEditclose(row)}><span className="closebtn">&nbsp;</span></div>
                        </div>
                    ) : (
                        <div className="alert-threshold-cell">
                            <div className="text-wrap">{row[`${td}`]}</div>  {/* Display the alertThreshold value */}
                        </div>
                    )
                },
                left: true,
                allowOverflow: true,
            })
        }

        else if (td === "Estimated Unbilled Amount" || td === "Alert Threshold") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                right: true,
                allowOverflow: true,
            })
        }
        else if (td === "Active Billing Cycle" || td === "Last Calculated On" || td === "Next Billing Date") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                allowOverflow: true,
            })
        }

        else if (td === "Actions" || "Cloud Service") {
            if (td === "Actions") {
                d.push({
                    id: `${td}`,
                    name: <div>{td}</div>,
                    cell: row => <span onClick={() => handleEditClick(row)} className="editpencil">&nbsp;</span>,
                    center: true,
                    allowOverflow: true,
                })
            }
            else {
                d.push({
                    id: `${td}`,
                    name: <div>{td}</div>,
                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                    left: true,
                    allowOverflow: true,
                }
                )
            }
        }
        else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                // sortable: true,
                // allowOverflow: true,
            })
        }

    });

    //save alert threshold updated value
    const handleEditSave = (rowId) => {
        // console.log('Saved value :', editedValue, rowId);
        setEditedValue(editedValue);
    };

    //close alert threshold input box with button
    const handleEditclose = () => {
        setEditingRowId();
        setEditedValue();
    };

    //click on edit actions button
    const handleEditClick = (rowId) => {
        // console.log('clicked for edit', rowId);
        setEditedValue(Number(rowId["Alert Threshold"].slice(1)));
        setEditingRowId(rowId.ServiceId);
    };

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    // useEffect(() => {
    //     if (props.customerIsAuthenticated === false) {
    //         navigate("/");
    //     }
    // }, [])

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };


    // Update Reseller Alert threshold value
    async function UpdateResellerAlertThreshold(unbilledUsageDataId, alertThreshold) {
        // Define the request body for the PUT API
        let requestBody = {
            "unbilledUsageDataId": unbilledUsageDataId.toString(),
            "alertThreshold": alertThreshold
        };
        await api.put(UPDATE_RESELLER_UNBILLED_USAGE_REPORT, requestBody, config)
            .then(resp => {
                // console.log("Response of updation: ", resp.data);
                // handle success
                GetTableData(pageNo, pageSize);
                // console.log("Response of updation: ", resp.data);
                setToastMessage(UnbilledIaasSuccessToast);
                setEditingRowId();
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 2000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                    setToastMessage(UnbilledIaasErrorToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    }

    // Get Table Data Functionality
    async function GetTableData(page, newPerPage) {
        await api.get(`${RESELLER_UNBILLED_USAGE_REPORT}?pageNo=${page}&pageSize=${newPerPage}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setShowTableLoading("No Reports Found")
                }
                // handle success
                else {
                    setShowTableLoading("Loading your Unbilled IAAS Usage Report...")
                    let f = [];
                    let serialNumber = 1; // Initialize the serial number
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push(
                            {
                                "S.No": serialNumber++,      // Increment the serial number and use it 
                                "Cloud Service": `${td.serviceName}`,
                                "Next Billing Date": `${td.nextBillingDate}`,
                                "Active Billing Cycle": `${td.activeBillingCycle}`,
                                "Estimated Unbilled Amount": `AUD ${td.unbilledCostReceivable}`,
                                "ServiceId": td.serviceId,
                                "Unbilled UsageData Id": td.unbilledUsageDataId,
                                "Last Calculated On": `${td.lastCalculatedDate}`,
                                "Alert Threshold": `AUD ${td.alertThreshold}`,
                            }
                        );
                    })
                    // console.log("Test F", f);
                    setTable(f)
                }
            }
            )
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                ccolor: `${TEXT_SECONDARY} !important`,
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // // Function Triggers when Page Number is Changed by Customer
    // const handlePageChange = (pageNo) => {
    //     GetTableData(pageNo, pageSize);
    //     setPageNo(pageNo);
    // };

    // // Function Triggers when Rows Per Page is Changed by Customer
    // const handlePerRowsChange = async (newPerPage, page) => {
    //     setPageNo(page);
    //     setPageSize(newPerPage);
    //     GetTableData(page, newPerPage);
    // };

    // Function to trigger getTable data function 

    useEffect(() => {
        GetTableData(pageNo, pageSize);
    }, [])

    return (
        <>
            <div className="main-content">
                <div className="customer-management-console-main-div mb-5">
                    <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
                    <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

                    <Popup
                        className="custom-modal custom-modal-md"
                        open={updateAlertThresholdModalFlag}
                        onClose={() => setUpdateAlertThresholdModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Message</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color={TEXT_SECONDARY}
                                    />
                                </div>
                                <Container className="p-5">
                                    <p className='pt-0 px-3 pb-5 mb-0'>
                                        <strong>Are you sure you want to change threshold value</strong>
                                    </p>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    // console.log("modal closed ");
                                                    close();
                                                }}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    UpdateResellerAlertThreshold(unbilledUsageDataId, editedValue);
                                                    close();
                                                }}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    <h1 className="d-block d-md-none mobile-h1">Unbilled IAAS Usage Report</h1>
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`py-0 ${styles['unbilled-usage-table']}`}>
                                <div className={`${styles['unbilled-usage-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col >
                                            <h5 className="py-3 mb-0 mobile-padding-search-open subscription-heading-table-header">Unbilled IAAS Usage Report</h5>
                                        </Col>
                                        <div className='unbilled-usage-table-inner-table'>
                                            <Container fluid className="mb-5 p-0">
                                                <Row>
                                                    <Col>
                                                        <DataTable
                                                            columns={d}
                                                            data={table}
                                                            theme="solarized"
                                                            customStyles={customStyles}
                                                            persistTableHead={false}
                                                            noDataComponent={<div className="loading-unbilled-usage">{showTableLoading==="Loading your Unbilled IAAS Usage Report..." && <img className="management-console-loading-icon" src={loadingGif} />}<p className="text-medium text-strong management-console-loading-text">{showTableLoading}...</p></div>}
                                                            width="100%"
                                                            fixedHeaderScrollHeight="60vh"
                                                            // progressPending={loading}
                                                            fixedHeader
                                                            // pagination
                                                            // paginationServer
                                                            // paginationTotalRows={totalRows}
                                                            // paginationPerPage={pageSize}
                                                            // paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                            // onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                            // onChangeRowsPerPage={handlePerRowsChange}
                                                            // paginationResetDefaultPage={resetPaginationToggle}
                                                            expandableRows

                                                            expandOnRowClicked
                                                            expandableRow={(row) => <ExpandableTable serviceId={row.serviceId} />} // Pass the serviceId as a prop
                                                            expandableRowsComponent={ExpandableTable}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnbilledUsageReport

